import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import memoizeOne from 'memoize-one';

import { getOldSupHardwareLink } from '../utils/helpers';

import { getGrafanaNodePageLink } from '../utils/grafanaFrameSrcgenerator';
import { InlineBreadcrumbItem, InlineStatus } from '../components/InlineStatus';
import Hardware from '../components/SingleServerHardwareTab';
import SingleServerInformations from '../components/SingleServerInformations';
import PerformanceTab from '../components/SingleServerPerformance';
import DiskTab from '../components/SingleServerDisk';

import Breadcrumb from '../components/Breadcrumb';
import { Tab } from '../components/Tab';
import { MonitoringAndOperationLink } from '../components/ui/AllPagesItems';
import {
  ContentContainer,
  PageHeader,
  BreadcrumbStatusContainer
} from '../components/ui/PageStructure';

import {
  selectServerFromUrlSelector,
  selectServerDiskSelector
} from '../ducks/servers';
import {
  selectAllVolumesState,
  getVolumesConnectorsSelector,
  getConfigGroupsSelector
} from '../ducks/volumes';

export class SingleServer extends Component {
  onTabClick(tab) {
    this.props.history.push(`${this.props.match.url}/${tab}`);
  }

  findServerConfigGroups = memoizeOne((configGroups, server) => {
    return configGroups.filter(cg => {
      let ip = server.data_ip_address;
      return cg.connectors.some(
        connnector =>
          ip ===
          connnector.address.substring(0, connnector.address.indexOf(':'))
      );
    });
  });

  findServerVolumes = memoizeOne((volumes, serverConfigGroups) => {
    return volumes.filter(v =>
      serverConfigGroups.some(cg => v.id === cg.volume)
    );
  });

  render() {
    const {
      server,
      zones,
      volumes,
      configGroups,
      adminAccess,
      match,
      location,
      serverDisks
    } = this.props;
    const exactPath = location.pathname;

    const ringPage = getOldSupHardwareLink(
      `${server.management_ip_address}:7084`
    );
    const ringGrafanaPage = getGrafanaNodePageLink(server.name);

    const tabs = [
      {
        selected: exactPath.endsWith('/information'),
        title: 'Information',
        onClick: () => this.onTabClick('information')
      },
      {
        selected: exactPath.endsWith('/disks'),
        title: 'Disks',
        onClick: () => this.onTabClick('disks')
      },
      {
        selected: exactPath.endsWith('/hardware'),
        title: 'Hardware',
        onClick: () => this.onTabClick('hardware')
      },
      {
        selected: exactPath.endsWith('performance'),
        title: 'Performance',
        onClick: () => this.onTabClick('performance')
      }
    ];

    const serverConfigGroups = this.findServerConfigGroups(
      configGroups,
      server
    );

    const serverVolumes = this.findServerVolumes(volumes, serverConfigGroups);

    return (
      <ContentContainer>
        <PageHeader>
          <BreadcrumbStatusContainer>
            <Breadcrumb
              routes={[
                {
                  name: 'SERVERS',
                  link: '/servers'
                },
                {
                  name: server.name || '',
                  customName: server ? (
                    <InlineBreadcrumbItem>
                      {server.name}
                      <InlineStatus
                        status={server.status}
                        text={server.state && server.state.join(', ')}
                      />
                    </InlineBreadcrumbItem>
                  ) : null
                }
              ]}
            />
          </BreadcrumbStatusContainer>
          {adminAccess && (
            <MonitoringAndOperationLink
              monitoringLink={ringGrafanaPage}
              operationLink={ringPage}
            />
          )}
        </PageHeader>
        <Tab items={tabs}>
          <Switch>
            <Route
              exact
              path={`${match.url}/information`}
              render={() => (
                <SingleServerInformations
                  server={server}
                  configGroups={serverConfigGroups}
                  volumes={serverVolumes}
                  zones={zones}
                  adminAccess={adminAccess}
                />
              )}
            />
            <Route
              path={`${match.url}/edit`}
              render={() => (
                <SingleServerInformations
                  server={server}
                  configGroups={serverConfigGroups}
                  volumes={serverVolumes}
                  zones={zones}
                  adminAccess={adminAccess}
                />
              )}
            />
            <Route
              path={`${match.url}/disks`}
              render={() => (
                <DiskTab serverDisks={serverDisks} server={server} />
              )}
            />
            <Route
              path={`${match.url}/hardware`}
              render={() => <Hardware server={server} />}
            />
            <Route
              path={`${match.url}/performance`}
              render={() => <PerformanceTab server={server} />}
            />
            <Redirect from={`${match.url}`} to={`${match.url}/information`} />
          </Switch>
        </Tab>
      </ContentContainer>
    );
  }
}

SingleServer.propTypes = {
  server: PropTypes.object,
  serverDisks: PropTypes.array,
  zones: PropTypes.array,
  adminAccess: PropTypes.bool
};

SingleServer.defaultProps = {
  server: {},
  serverDisks: [],
  zones: [],
  configGroups: [],
  volumes: [],
  adminAccess: false
};

const mapStateToProps = (state, ownProps) => ({
  server: selectServerFromUrlSelector(state, ownProps),
  serverDisks: selectServerDiskSelector(state, ownProps),
  zones: state.get('zones').get('list'),
  volumesConnectors: getVolumesConnectorsSelector(state),
  volumes: selectAllVolumesState(state),
  configGroups: getConfigGroupsSelector(state),
  adminAccess: state.get('overview').get('adminAccess')
});

export default withRouter(connect(mapStateToProps)(SingleServer));
