/** Rings, Servers & Disks status **/

export const STATUS_THEME = {
  green: '#21ac4d',
  orange: '#ff8f1c',
  red: '#ef3340',
  grey: '#76828f'
};

export const STATUS_OK = 'OK';
export const STATUS_WARNING = 'WARNING';
export const STATUS_CRITICAL = 'CRITICAL';
export const STATUS_EMPTY = 'EMPTY';
export const STATUS_NO_CONNECTOR = 'NO CONNECTOR';
export const STATUS_NOMINAL = 'NOMINAL';
export const STATUS_DEGRADED = 'DEGRADED';
export const STATUS_UNAVAILABLE = 'UNAVAILABLE';
export const STATUS_ALL = 'ALL';
export const STATUS_ERROR = 'ERROR';
export const STATUS_NOT_DEPLOYED = 'NOT_DEPLOYED';

// Grafana charts related options

export const IFRAME_VARS = {
  DASHBOARD_BEATS: 'd/scality-system-metric-beat/system-metric-beat?',
  DASHBOARD_UI: 'd/scality-ui/ui?',
  DASHBOARD_SOLO: 'd-solo/scality-ui/ui?',
  DASHBOARD_OPERATIONS: 'd/scality-ring-operations/ring-operations?',
  DASHBOARD_DISK_STATS: 'd/scality-disks-stats/disks-stats?',
  RING: '&var-RING=',
  DISK: '&var-DISK=',
  HOST: '&var-HOST=All',
  SPECIFIC_HOST: '&var-HOST=',
  HOST_IP: '&var-IP=',
  BEATS_HOST: '&var-HOSTNAME=',
  FROM: '&from=now-',
  TO: '&to=now',
  PANEL_ID: '&panelId=',
  ORG_ID: '&orgId=1',
  NODE: 'var-NODE=All',
  CONNECTOR: '&var-CONNECTOR=',
  AUTO_REFRESH: '&refresh=30s',
  READ_OPS_VAR: '&var-READ_OPS=',
  WRITE_OPS_VAR: '&var-WRITE_OPS=',
  BW_READ_OPS: ['read', 'nfs_read'],
  BW_WRITE_OPS: ['write', 'nfs_write'],
  READ_OPS: [
    'lookup',
    'getattr',
    'readlink',
    'open',
    'opendir',
    'readdir',
    'access',
    'getxattr',
    'listxattr',
    'nfs_null',
    'nfs_getattr',
    'nfs_lookup',
    'nfs_access',
    'nfs_readlink',
    'nfs_readdir',
    'nfs_readdirplus',
    'nfs_fsstat',
    'nfs_fsinfo',
    'nfs_pathconf'
  ],
  WRITE_OPS: [
    'setattr',
    'mknod',
    'mkdir',
    'unlink',
    'rmdir',
    'symlink',
    'rename',
    'link',
    'release',
    'releasedir',
    'create',
    'setxattr',
    'removexattr',
    'nfs_setattr',
    'nfs_create',
    'nfs_mkdir',
    'nfs_symlink',
    'nfs_mknod',
    'nfs_remove',
    'nfs_rmdir',
    'nfs_rename',
    'nfs_link',
    'nfs_commit'
  ],
  DARK_THEME: '&theme=dark'
};

export const CHART_PANEL_IDS = {
  LATENCY: 1,
  OPERATIONS: 2,
  CAPACITY: 3,
  LOAD_AVERAGE: 4,
  CPU: 7,
  MEMORY: 8,
  SWAP: 9,
  BANDWIDTH: 36
};

export const TWENTY_FOUR_HOURS = { NAME: '24 hours', VALUE: '24h' };
export const TWELVE_HOURS = { NAME: '12 hours', VALUE: '12h' };
export const SIX_HOURS = { NAME: '6 hours', VALUE: '6h' };
export const ONE_HOUR = { NAME: '1 hour', VALUE: '1h' };

export const THIRTY_DAYS = { NAME: '30 days', VALUE: '30d' };
export const NINETY_DAYS = { NAME: '90 days', VALUE: '90d' };
export const ONE_EIGHTY_DAYS = { NAME: '180 days', VALUE: '180d' };

export const ONE_WEEK = { NAME: '1 week', VALUE: '1w' };

export const ONE_MONTH = { NAME: '1 month', VALUE: '1M' };
export const SIX_MONTH = { NAME: '6 month', VALUE: '6M' };

export const BANDWIDTH = { TYPE: 'bandwidth' };
export const NOBANDWIDTH = { TYPE: 'noBandwidth' };

export const NO_ACTION = 'NO_ACTION';
export const ACTION_IN_PROGRESS = 'ACTION_IN_PROGRESS';
export const ACTION_ERROR = 'ACTION_ERROR';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';

export const statusFilterOptions = [
  { value: STATUS_ALL, label: STATUS_ALL },
  { value: STATUS_OK, label: STATUS_OK },
  { value: STATUS_WARNING, label: STATUS_WARNING },
  { value: STATUS_CRITICAL, label: STATUS_CRITICAL }
];

export const timeRangeOptions = [
  { value: ONE_HOUR.VALUE, label: ONE_HOUR.NAME },
  { value: SIX_HOURS.VALUE, label: SIX_HOURS.NAME },
  { value: TWENTY_FOUR_HOURS.VALUE, label: TWENTY_FOUR_HOURS.NAME },
  { value: ONE_WEEK.VALUE, label: ONE_WEEK.NAME },
  { value: ONE_MONTH.VALUE, label: ONE_MONTH.NAME }
];

export const SFUSED_LOWER_BOUND_PORT = 7000;
export const SFUSED_UPPER_BOUND_PORT = 7999;

export const ASC = 'asc';
export const DESC = 'desc';

export const KEY_ROLES = 'roles';
export const KEY_CAPACITY = 'capacity';
export const KEY_HARDWARE_SERVER_TYPE = 'hardware.server_type';
export const KEY_NAME = 'name';
export const KEY_STATUS = 'status';
export const KEY_ZONE = 'zone';
export const KEY_SERVER_NAME = 'serverName';
export const KEY_BACKEND_RING = 'backendRing';
export const KEY_DATA_REPLICATION_LONG_NAME = 'data_replication.long_name';
export const KEY_PROTOCOL = 'protocol';
export const KEY_LOCATION = 'location';
export const KEY_DESCRIPTION = 'description';
export const KEY_DISKSPACE_USED = 'diskspace_used';
export const KEY_CONNECTORS_LENGTH = 'connectors.length';
export const KEY_CONFIG_SHARES_LENGTH = 'config.shares.length';
export const FIFTHTEEN_ITEMS_PER_PAGE = { NAME: '15 per page', VALUE: 15 };
export const THIRTY_ITEMS_PER_PAGE = { NAME: '30 per page', VALUE: 30 };
export const SIXTY_ITEMS_PER_PAGE = { NAME: '60 per page', VALUE: 60 };
export const ONE_TWENTY_ITEMS_PER_PAGE = { NAME: '120 per page', VALUE: 120 };

export const itemsPerPageOptions = [
  { value: FIFTHTEEN_ITEMS_PER_PAGE.VALUE, label: FIFTHTEEN_ITEMS_PER_PAGE.NAME, className: 'opt' },
  { value: THIRTY_ITEMS_PER_PAGE.VALUE, label: THIRTY_ITEMS_PER_PAGE.NAME, className: 'opt' },
  {
    value: SIXTY_ITEMS_PER_PAGE.VALUE,
    label: SIXTY_ITEMS_PER_PAGE.NAME,
    className: 'opt'
  },
  {
    value: ONE_TWENTY_ITEMS_PER_PAGE.VALUE,
    label: ONE_TWENTY_ITEMS_PER_PAGE.NAME,
    className: 'opt'
  }
];
