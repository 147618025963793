import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const RingGlobalHealthTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`RING Global Health`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Global Health presents the current health of the RING service by counting components health checks results.`}
      <Ul>
        <Li>
          {`Status could be RUN (`}
          <span style={{ color: 'green' }}>green</span>
          {`) or OUT_OF_SERVICE (`}
          <span style={{ color: 'orange' }}>orange</span>
          {`).`}
        </Li>
        <Li>
          {`One or more OUT_OF_SERVICE components will result in a WARNING or CRITICAL RING service.`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default RingGlobalHealthTips;
