/*
 * OverviewReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import * as ACTIONS from '../../actions/overview/constants';
import {
  NO_ACTION,
  ACTION_IN_PROGRESS,
  ACTION_ERROR
} from '../../constants';

const initialState = fromJS({
  isLDAPChecked: false,
  apiConnectionError: false,
  invalidBasicAuth: false,
  checkingAPIConnection: true,
  errorMsg: {},
  pageAccessState: NO_ACTION,

  globalOverlay: false,
  adminAccess: false,
  apiRequestState: NO_ACTION,
  apiRequestError: ''
});

function overviewReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_CHECKED_LDAP:
      return state.set('isLDAPChecked', true);
    case ACTIONS.SET_INVALID_BASIC_AUTH:
      return state.set('invalidBasicAuth', true);
    case ACTIONS.RESET_INVALID_BASIC_AUTH:
      return state.set('invalidBasicAuth', false);
    case ACTIONS.CHECK_API_CONNECTION:
      return state.set('checkingAPIConnection', true);
    case ACTIONS.API_CONNECTION_ERROR:
      return state
        .set('apiConnectionError', true)
        .set('loading', false)
        .set('checkingAPIConnection', false);
    case ACTIONS.API_CONNECTION_NO_ERROR:
      return state.set('apiConnectionError', false);
    case ACTIONS.API_CONNECTION_SUCCESS:
      return state.set('checkingAPIConnection', false);
    case ACTIONS.GET_VERSION_SUCCESS:
      return state
        .set('version', action.version)
        .set('release', action.release);
    case ACTIONS.SET_INFRA_METADATA:
      return state.set('platformName', action.platformName);
    case ACTIONS.SEARCH_ZONE: {
      const zonesListSearch = state.get('zones');
      const newStateZonesListFilter = zonesListSearch.filter(val =>
        val.includes(action.value)
      );
      return state.set('zones', newStateZonesListFilter);
    }
    case ACTIONS.EDIT_ZONE_SUCCESS: {
      const zonesListEdit = state.get('zones');
      const ZonesListEditIndex = zonesListEdit.findIndex(
        i => i.name === action.zone[0].name
      );
      const test = zonesListEdit.map((item, index) => {
        if (index !== ZonesListEditIndex) {
          return item;
        }
        const newItem = action.zone[0];
        return newItem;
      });
      return state.set('zones', test);
    }
    case ACTIONS.GET_ACCESS_CONFIGS_SUCCESS:
      return state.set('adminAccess', action.adminAccess);
    case ACTIONS.SET_ERROR_MSG:
      return state.set('cGCreationError', action.errorMsg);
    case ACTIONS.SET_PAGE_ACCESS_NO_ACTION:
      return state.set('pageAccessState', NO_ACTION);
    case ACTIONS.SET_PAGE_ACCESS_ERROR:
      return state.set('pageAccessState', ACTION_ERROR);
    case ACTIONS.ACTIVATE_GLOBAL_OVERLAY:
      return state.set('globalOverlay', true);
    case ACTIONS.DEACTIVATE_GLOBAL_OVERLAY:
      return state.set('globalOverlay', false);
    case ACTIONS.SET_GLOBAL_OVERLAY_TEXT:
      return state.set('globalOverlayText', action.text);
    case ACTIONS.RESET_GLOBAL_OVERLAY_TEXT:
      return state.set('globalOverlayText', null);
    case ACTIONS.SET_API_REQUEST_IN_PROGRESS:
      return state.set('apiRequestState', ACTION_IN_PROGRESS);
    case ACTIONS.SET_API_REQUEST_NO_ACTION:
      return state.set('apiRequestState', NO_ACTION);
    case ACTIONS.SET_API_REQUEST_ERROR:
      return state.set('apiRequestState', ACTION_ERROR);
    case ACTIONS.SET_API_REQUEST_ERROR_MSG:
      return state.set('apiRequestError', action.apiRequestError);
    default:
      return state;
  }
}

export default overviewReducer;
