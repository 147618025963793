import { fromJS } from 'immutable';

import {
  AUTHORIZING,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from '../../actions/login/constants';

const initialState = fromJS({
  loginFail: false,
  loggedOut: true
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHORIZING:
      return state.set('loginFail', false).set('authorizing', true);
    case USER_LOGIN_SUCCESS:
      return state.set('authorizing', false).set('loggedOut', false);
    case USER_LOGIN_FAIL:
      return state.set('loginFail', true).set('authorizing', false);
    case USER_LOGOUT:
      return state
        .set('loginFail', false)
        .set('authorizing', false)
        .set('loggedOut', true);
    default:
      return state;
  }
}

export default loginReducer;
