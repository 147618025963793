import React from 'react';
import PropTypes from 'prop-types';
import { HelpPopupContainer, HelpPopupTitle, HelpPopupContent } from './Tips';

const propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

const SimpleTips = props => (
  <HelpPopupContainer>
    <HelpPopupTitle>{props.title || ''}</HelpPopupTitle>
    <HelpPopupContent>{props.content || ''}</HelpPopupContent>
  </HelpPopupContainer>
);

SimpleTips.propTypes = propTypes;

export default SimpleTips;
