import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent
} from './Tips';

const DiskInfoTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>Disk Information</HelpPopupTitle>
    <HelpPopupContent>

      Disk Information details, dynamically updated (if not updated yet, shows disk information provided at installation).

    </HelpPopupContent>
  </HelpPopupContainer>
);

export default DiskInfoTips;
