import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const SingleStorageServiceListTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`S3 Service List`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Service List presents the status of the main S3 sub services.`}
      <Ul>
        <Li>
          <b>{`Authentication`}</b>
          {`: All components administering the IAM (Identity and Access Management) services for S3 accounts and users (Vault, including IAM GUI)`}
        </Li>
        <Li>
          <b>{`Frontend`}</b>
          {`: All components administering the frontend S3 service (S3 connectors) servicing the S3 API calls to the S3 clients`}
        </Li>
        <Li>
          <b>{`Metadata`}</b>
          {`: All components administering the handling of metadata (bucket information, listing, ACL, tags, headers)`}
        </Li>
        <Li>
          <b>{`RING Connector`}</b>
          {`: All components administering the handling of data, usally connecting the backend RING to an S3 service (sproxyd)`}
        </Li>
        <Li>
          <b>{`Utilisation API`}</b>
          {`: All components administering the collection of metrics for buckets, users, and accounts (UTAPI)`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default SingleStorageServiceListTips;
