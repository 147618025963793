import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AngleDoubleLeft } from 'styled-icons/fa-solid/AngleDoubleLeft';
import { AngleLeft } from 'styled-icons/fa-solid/AngleLeft';
import { AngleDoubleRight } from 'styled-icons/fa-solid/AngleDoubleRight';
import { AngleRight } from 'styled-icons/fa-solid/AngleRight';

const mainBlue = '#2b65ad';
const infoIcnClr = '#76828f';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;

const PaginationItem = styled.div`
  margin: 0px 5px;
  padding: 3px 10px;
  border-radius: 4px;
  color: ${({ active }) => (active ? '#fff' : infoIcnClr)};
  background-color: ${({ active }) => (active ? mainBlue : null)};
  font-size: 16px;
  font-weight: 900;
  &:hover {
    cursor: pointer;
  }
`;

const PaginationAngleDoubleLeft = styled(AngleDoubleLeft)`
  color: ${infoIcnClr};
  &:hover {
    cursor: pointer;
  }
`;

const PaginationAngleLeft = styled(AngleLeft)`
  color: ${infoIcnClr};
  &:hover {
    cursor: pointer;
  }
`;

const PaginationAngleDoubleRight = styled(AngleDoubleRight)`
  color: ${infoIcnClr};
  &:hover {
    cursor: pointer;
  }
`;

const PaginationAngleRight = styled(AngleRight)`
  color: ${infoIcnClr};
  &:hover {
    cursor: pointer;
  }
`;

class Pagination extends React.Component {
  onPagerClick(pagerIndex) {
    if (typeof this.props.switchPage === 'function') {
      this.props.switchPage(pagerIndex);
    }
  }

  getPageRange(totalPages, maxPagesDisplayed, activePage) {
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxPagesDisplayed) {
      const halfTruncPages = Math.trunc(maxPagesDisplayed / 2);

      if (activePage <= Math.round(maxPagesDisplayed / 2)) {
        endPage = maxPagesDisplayed;
      } else if (activePage + halfTruncPages >= totalPages) {
        // End case
        startPage = totalPages - maxPagesDisplayed + 1;
        endPage = totalPages;
      } else {
        // Middle case
        startPage = activePage - halfTruncPages;
        endPage = activePage + halfTruncPages;
      }
    }

    return { startPage, endPage };
  }

  render() {
    const {
      totalItems,
      itemsPerPage,
      activePage,
      maxPagesDisplayed
    } = this.props;

    let pageBalance = totalItems % itemsPerPage;
    let totalPages;

    if (pageBalance > 0) {
      totalPages = (totalItems - pageBalance) / itemsPerPage + 1;
    } else {
      totalPages = totalItems / itemsPerPage;
    }

    let { startPage, endPage } = this.getPageRange(
      totalPages,
      maxPagesDisplayed,
      activePage
    );

    const iconSize = 24;
    return (
      <PaginationContainer>
        <PaginationAngleDoubleLeft
          size={iconSize}
          onClick={() => this.onPagerClick(1)}
        />
        <PaginationAngleLeft
          size={iconSize}
          onClick={() =>
            this.onPagerClick(activePage > 1 ? activePage - 1 : activePage)
          }
        />
        {[...Array(endPage + 1 - startPage).keys()]
          .map(i => startPage + i)
          .map((item, i) => {
            const pageIndex = startPage + i;
            return (
              <PaginationItem
                key={`page_${pageIndex}`}
                active={activePage === pageIndex}
                onClick={() => this.onPagerClick(pageIndex)}
              >
                {pageIndex}
              </PaginationItem>
            );
          })}
        <PaginationAngleRight
          size={iconSize}
          onClick={() =>
            this.onPagerClick(
              activePage < Math.round(totalPages) ? activePage + 1 : activePage
            )
          }
        />
        <PaginationAngleDoubleRight
          size={iconSize}
          onClick={() => this.onPagerClick(totalPages)}
        />
      </PaginationContainer>
    );
  }
}

Pagination.propTypes = {
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  activePage: PropTypes.number,
  maxPagesDisplayed: PropTypes.number
};

Pagination.defaultProps = {
  totalItems: 0,
  itemsPerPage: 10,
  activePage: 1,
  maxPagesDisplayed: 5
};

export default Pagination;
