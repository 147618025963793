import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import { getGrafanaFrameUrl } from '../utils/grafanaFrameSrcgenerator';
import {
  prettifyBytes,
  daysToYears,
  getPrettyScale,
  getPercentage
} from '../utils/helpers';
import HelpPopover from './common/HelpPopover';
import {
  THIRTY_DAYS,
  NINETY_DAYS,
  ONE_EIGHTY_DAYS,
  CHART_PANEL_IDS,
  IFRAME_VARS
} from '../constants';
import PieChart from './PieChart';
import SelectBox from './common/Select';
import GrafanaPanel from './GrafanaPanel';
import * as cssVariables from './ui/variables';
import { SectionHeader, SectionTitle } from './ui/AllPagesItems';
import RingObjectsTips from '../tips/RingObjectsTips';
import RingForcastTips from '../tips/RingForcastTips';
import RingNetCapacityTips from '../tips/RingNetCapacityTips';
import SimpleTips from '../tips/SimpleTips';

const UsageItemContainer = styled.div`
  display: flex;
`;

const UsageItem = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #464f56;
  &:last-child {
    border-right: none;
  }
  padding: 0 2.5% 0 4%;
`;

const UsageTitle = styled.div`
  text-transform: capitalize;
  font-size: 13px;
  font-weight: ${cssVariables.semiBold};
  color: #fff;
  margin: 0;
`;

const StatValue = styled.p`
  font-size: 18px;
  color: #a4acb4;
  line-height: 1;
  margin-bottom: 2px;
`;

const StatMetric = styled.span`
  font-size: 10px;
  margin-left: 3px;
  text-transform: uppercase;
`;

const StatNumber = styled.p`
  font-size: 22px;
  color: ${cssVariables.serviceBlkTitlClr};
  margin: 0;
  line-height: 1;
`;

const StatUnit = styled.span`
  font-size: 14px;
`;

const StatInfo = styled.div`
  margin-top: 30px;
`;

const StatInfoSubTxt = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: ${cssVariables.semiBold};
  color: ${cssVariables.infoIcnSlctClr};
`;

const PieChartHolder = styled.div`
  margin: 10px 0;
`;

export default class Capacity extends Component {
  constructor(props) {
    super(props);

    const options = [
      { value: THIRTY_DAYS.VALUE, label: THIRTY_DAYS.NAME, className: 'opt' },
      { value: NINETY_DAYS.VALUE, label: NINETY_DAYS.NAME, className: 'opt' },
      {
        value: ONE_EIGHTY_DAYS.VALUE,
        label: ONE_EIGHTY_DAYS.NAME,
        className: 'opt'
      }
    ];

    this.state = {
      chartOptions: options,
      selectedChartOption: options[0]
    };
  }

  onChartOptionChange(selectedOption) {
    const { ring } = this.props;

    if (!_.isEmpty(ring)) {
      this.setState({ selectedChartOption: selectedOption });
    }
  }

  generateInfo(
    info,
    prefix,
    suffix,
    text,
    trueValue,
    animated,
    digits = 0,
    numberStyle = {},
    force_unit = false
  ) {
    let count = null;

    if (!_.isNil(info) && animated === null) {
      count = prettifyBytes(trueValue).value;
    } else {
      count = 'N/A';
    }

    return (
      <div>
        {animated !== null ? (
          <StatValue>
            {info}
            <StatMetric>{text}</StatMetric>
          </StatValue>
        ) : (
          <StatNumber style={numberStyle}>
            {prefix}
            {count} {suffix}
            <StatUnit>{info || force_unit ? text : null}</StatUnit>
          </StatNumber>
        )}
      </div>
    );
  }

  render() {
    const { ring } = this.props;
    const { selectedChartOption, chartOptions } = this.state;

    let usedPercentageValue = 'N/A';
    let pieChartData = [];
    let diskspace_total;

    if (!_.isEmpty(ring)) {
      if (ring.diskspace_total > 0) {
        usedPercentageValue = Math.floor(
          getPercentage(ring.diskspace_used, ring.diskspace_total)
        );

        pieChartData = [
          {
            value: usedPercentageValue,
            name: `${usedPercentageValue}%`,
            hoverTitle: 'Used'
          },
          {
            value: 100 - usedPercentageValue,
            name: ` ${100 - usedPercentageValue}%`,
            hoverTitle: 'Available'
          }
        ];
      } else {
        pieChartData = [
          {
            value: 100,
            name: 'N/A',
            hoverTitle: 'N/A'
          }
        ];
      }
    }

    let calculatedPlaningEstimatedNetAvailable = <StatNumber>0</StatNumber>;

    if (!_.isNil(ring.diskspace_est_net_available)) {
      let planingEstimatedNetAvailable = prettifyBytes(
        ring.diskspace_est_net_available
      );
      calculatedPlaningEstimatedNetAvailable = this.generateInfo(
        planingEstimatedNetAvailable.value,
        null,
        null,
        planingEstimatedNetAvailable.unit,
        planingEstimatedNetAvailable.value,
        null
      );
    }

    let calculatedNetUsed = <StatNumber>0</StatNumber>;

    if (!_.isNil(ring.diskspace_net)) {
      let netUsed = prettifyBytes(ring.diskspace_net);
      calculatedNetUsed = this.generateInfo(
        Math.round(netUsed.value),
        null,
        null,
        netUsed.unit,
        Math.round(netUsed.value),
        null
      );
    }

    let calculatedTotalSize = <StatNumber>0</StatNumber>;

    if (!_.isNil(ring.diskspace_total)) {
      diskspace_total = prettifyBytes(ring.diskspace_total);
      calculatedTotalSize = this.generateInfo(
        diskspace_total.value,
        null,
        null,
        diskspace_total.unit,
        null,
        true
      );
    }

    let calculatedUsed = <StatNumber>0</StatNumber>;
    let diskspace_used;
    if (ring.diskspace_used != null) {
      diskspace_used = prettifyBytes(ring.diskspace_used);
      calculatedUsed = this.generateInfo(
        diskspace_used.value,
        null,
        null,
        diskspace_used.unit,
        null,
        true
      );
    }

    let objectsCount = <StatNumber>0</StatNumber>;

    if (!_.isNil(ring.number_of_objects)) {
      let prettyObjectsCount = getPrettyScale(ring.number_of_objects);
      objectsCount = (
        <StatNumber>
          {prettyObjectsCount.value}
          <span className="unit"> {prettyObjectsCount.unit}</span>
        </StatNumber>
      );
    }

    let calculatedAverageSize = <StatNumber>0</StatNumber>;

    if (!_.isNil(ring.object_average_size)) {
      let averageSize = prettifyBytes(ring.object_average_size);
      let trueValue = ring.object_average_size;
      calculatedAverageSize = this.generateInfo(
        averageSize.value,
        null,
        null,
        averageSize.unit,
        trueValue,
        null
      );
    }

    let planningDaysBeforeThreshold = <StatNumber>N/A</StatNumber>;
    let planningDaysBeforeThresholdStyle = {};

    if (!_.isNil(ring.planning_days_before_threshold)) {
      let trueValue = ring.planning_days_before_threshold;
      let threshold = daysToYears(trueValue);

      if (ring.state && ring.state.includes('CRITICAL LOW STORAGE')) {
        planningDaysBeforeThresholdStyle.color = '#ef3340';
      } else if (ring.state && ring.state.includes('LOW STORAGE')) {
        planningDaysBeforeThresholdStyle.color = '#ff8f1c';
      }

      planningDaysBeforeThreshold = this.generateInfo(
        threshold.value,
        null,
        null,
        threshold.unit,
        threshold.value,
        null,
        0,
        planningDaysBeforeThresholdStyle,
        true
      );
    }

    let planningUsageGrowth = <StatNumber>N/A</StatNumber>;

    if (!_.isNil(ring.planning_usage_growth)) {
      let trueValue = ring.planning_usage_growth;
      let prettySize = prettifyBytes(trueValue);

      planningUsageGrowth = this.generateInfo(
        prettySize.value,
        trueValue > 0 ? '+' : '',
        null,
        prettySize.unit,
        prettySize.value,
        null
      );
    }

    const ringCapacityUrl = getGrafanaFrameUrl(
      ring.id,
      CHART_PANEL_IDS.CAPACITY,
      selectedChartOption.value,
      IFRAME_VARS.AUTO_REFRESH
    );

    return (
      <div>
        <SectionHeader>
          <SectionTitle>Usage</SectionTitle>
        </SectionHeader>
        <UsageItemContainer>
          <UsageItem>
            <UsageTitle style={{ textAlign: 'center' }}>
              Total Capacity
              <HelpPopover
                content={
                  <SimpleTips
                    title="RING Total Capacity"
                    content={`The Total Capacity pie chart displays "used" (green) and "available" (gray) space.
                      The Total Capacity is often called "raw" storage.
                      The "used" capacity metric makes no distinction among original data objects,
                      their copies (replicas or coding parts), or the system data.`}
                  />
                }
              />
            </UsageTitle>
            <PieChartHolder>
              <PieChart data={pieChartData} />
            </PieChartHolder>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                {calculatedUsed}
                <StatInfoSubTxt>used</StatInfoSubTxt>
              </div>
              <Separator />
              <div>
                {calculatedTotalSize}
                <StatInfoSubTxt>total</StatInfoSubTxt>
              </div>
            </div>
          </UsageItem>
          <UsageItem>
            <UsageTitle>
              Net Capacity
              <HelpPopover content={<RingNetCapacityTips />} />
            </UsageTitle>
            <StatInfo>
              {calculatedNetUsed}
              <StatInfoSubTxt>NET USED</StatInfoSubTxt>
            </StatInfo>
            <StatInfo>
              {calculatedPlaningEstimatedNetAvailable}
              <StatInfoSubTxt>EST. AVAILABLE</StatInfoSubTxt>
            </StatInfo>
          </UsageItem>
          <UsageItem>
            <UsageTitle>
              Forecasts
              <HelpPopover content={<RingForcastTips />} />
            </UsageTitle>
            <StatInfo>
              {planningUsageGrowth}
              <StatInfoSubTxt>a month</StatInfoSubTxt>
            </StatInfo>
            <StatInfo>
              {planningDaysBeforeThreshold}
              <StatInfoSubTxt>to 80% USED</StatInfoSubTxt>
            </StatInfo>
          </UsageItem>
          <UsageItem>
            <UsageTitle>
              Objects
              <HelpPopover content={<RingObjectsTips />} />
            </UsageTitle>
            <StatInfo>
              {objectsCount}
              <StatInfoSubTxt>objects</StatInfoSubTxt>
            </StatInfo>
            <StatInfo>
              {calculatedAverageSize}
              <StatInfoSubTxt>AVG. SIZE</StatInfoSubTxt>
            </StatInfo>
          </UsageItem>
        </UsageItemContainer>
        <SelectBox
          options={chartOptions}
          name="capacity_chart_options"
          value={selectedChartOption}
          onChange={this.onChartOptionChange.bind(this)}
        />
        <GrafanaPanel
          iFrameSrc={ringCapacityUrl}
          panelTitle="Capacity"
          popOverTemplate={
            <SimpleTips
              title="RING Capacity"
              content={`The Capacity chart graphs the "used" and "total" capacity at the RING level for the period of time selected.`}
            />
          }
        />
      </div>
    );
  }
}

const Separator = styled.div`
  border-right: 1px solid #464f56;
  margin-left: 10px;
  margin-right: 9px;
`;

Capacity.propTypes = {
  ring: PropTypes.object
};

Capacity.defaulProps = {
  ring: {}
};
