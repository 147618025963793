import React from 'react';
import PropTypes from 'prop-types';
import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
/**
 * There is no easy way to change the CSS of rc-tooltip
 * We might want to change this later
 */
import './Tooltip.css';

const Tooltip = props => {
  return (
    <RcTooltip
      placement={props.placement}
      mouseEnterDelay={props.mouseEnterDelay}
      mouseLeaveDelay={props.mouseLeaveDelay}
      destroyTooltipOnHide={props.destroyTooltipOnHide}
      trigger={props.trigger}
      overlay={props.overlay}
    >
      {props.children}
    </RcTooltip>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight'
  ]),
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  destroyTooltipOnHide: PropTypes.bool,
  trigger: PropTypes.array,
  overlay: PropTypes.element
};

Tooltip.defaultProps = {
  placement: 'bottom',
  mouseEnterDelay: 0,
  mouseLeaveDelay: 0,
  destroyTooltipOnHide: true,
  trigger: ['hover'],
  overlay: null
};

export default Tooltip;
