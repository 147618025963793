import styled from 'styled-components';
import * as cssVariables from './variables';

export const StyledTooltipDiv = styled.div`
  font-size: 10px;
  color: ${cssVariables.fontClr};
  font-family: ${cssVariables.fontFamily};
  font-weight: ${cssVariables.semiBold};
  text-align: center;
`;
