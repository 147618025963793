import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { selectAllServersState } from './servers';
import { callApi, callApiPost } from '../utils/callApi';
import { setInvalidBasicAuth, setApiRequestErrorMsg, setApiRequestError } from '../actions/overview';
import { getAverage } from '../sagas/overview';

// Actions
const GET_DISKS_DATA = 'GET_DISKS_DATA';
const GET_DISKS_DATA_SUCCESS = 'GET_DISKS_DATA_SUCCESS';
const GET_DISKS_RELOCATION = 'GET_DISKS_RELOCATION';
const SET_DISKS_RELOCATION = 'SET_DISKS_RELOCATION';

const GET_AVERAGE_SSD_READ = 'GET_AVERAGE_SSD_READ';
const SET_AVERAGE_SSD_READ = 'SET_AVERAGE_SSD_READ';
const GET_AVERAGE_SSD_WRITE = 'GET_AVERAGE_SSD_WRITE';
const SET_AVERAGE_SSD_WRITE = 'SET_AVERAGE_SSD_WRITE';
const GET_AVERAGE_READ = 'GET_AVERAGE_READ';
const SET_AVERAGE_READ = 'SET_AVERAGE_READ';
const GET_AVERAGE_WRITE = 'GET_AVERAGE_WRITE';
const SET_AVERAGE_WRITE = 'SET_AVERAGE_WRITE';

// Reducer
const defaultState = fromJS({
  disksRelocation: []
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case GET_DISKS_DATA_SUCCESS:
      return state.set('list', action.response);
    case SET_DISKS_RELOCATION:
      return state.set('disksRelocation', action.disks);
    case SET_AVERAGE_SSD_READ:
      return state.set('averageSSDRead', action.average);
    case SET_AVERAGE_SSD_WRITE:
      return state.set('averageSSDWrite', action.average);
    case SET_AVERAGE_READ:
      return state.set('averageRead', action.average);
    case SET_AVERAGE_WRITE:
      return state.set('averageWrite', action.average);
    default:
      return state;
  }
}

// Action Creators
function getDisksDataAction() {
  return {
    type: GET_DISKS_DATA
  };
}

function getDisksDataSuccessAction(response) {
  return {
    type: GET_DISKS_DATA_SUCCESS,
    response
  };
}

function getDisksRelocationAction() {
  return {
    type: GET_DISKS_RELOCATION
  };
}

function setDisksRelocationAction(disks) {
  return {
    type: SET_DISKS_RELOCATION,
    disks
  };
}

function getAverageSSDReadAction(timeFrame) {
  return {
    type: GET_AVERAGE_SSD_READ,
    timeFrame
  };
}

function setAverageSSDReadAction(average) {
  return {
    type: SET_AVERAGE_SSD_READ,
    average
  };
}

function getAverageSSDWriteAction(timeFrame) {
  return {
    type: GET_AVERAGE_SSD_WRITE,
    timeFrame
  };
}

function setAverageSSDWriteAction(average) {
  return {
    type: SET_AVERAGE_SSD_WRITE,
    average
  };
}

function getAverageReadAction(timeFrame) {
  return {
    type: GET_AVERAGE_READ,
    timeFrame
  };
}

function setAverageReadAction(average) {
  return {
    type: SET_AVERAGE_READ,
    average
  };
}
function getAverageWriteAction(timeFrame) {
  return {
    type: GET_AVERAGE_WRITE,
    timeFrame
  };
}

function setAverageWriteAction(average) {
  return {
    type: SET_AVERAGE_WRITE,
    average
  };
}

// Sagas
function* getDisksData() {
  let requestURL = 'disks/?limit=5000&embed=server';

  try {
    let responseItems = [];

    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items;

    do {
      if (response._links.hasOwnProperty('next')) {
        requestURL = `disks/?${response._links.next.split('?')[1]}`;

        response = yield call(callApi, requestURL, {
          method: 'GET',
          mode: 'cors'
        });

        responseItems = responseItems.concat(response._items);
      }
    } while (response._links.hasOwnProperty('next'));

    yield put(getDisksDataSuccessAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
    if (error && (error.status >= 500 && error.status < 600)) {
      yield put(setApiRequestErrorMsg({ message: 'Unable to fetch Disks information.' }));
      yield put(setApiRequestError());
    }
  }
}

function* getDisksRelocation() {
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const query = {
    _source: ['host', 'ip', 'diskname', 'status'],
    size: 10000
  };

  const requestURL = 'es_proxy/scality-status-disk-summary/_search';

  try {
    const response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });
    const disks = response.hits.hits.map(disks => disks['_source']);
    yield put(setDisksRelocationAction(disks));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* getAverageSSDRead({ timeFrame }) {
  yield call(
    getAverage,
    timeFrame,
    'ssd',
    'send_data',
    setAverageSSDReadAction
  );
}

function* getAverageSSDWrite({ timeFrame }) {
  yield call(
    getAverage,
    timeFrame,
    'ssd',
    '(receive_data OR get_data)',
    setAverageSSDWriteAction
  );
}

function* getAverageRead({ timeFrame }) {
  yield call(
    getAverage,
    timeFrame,
    'disk',
    'send_data',
    setAverageReadAction
  );
}

function* getAverageWrite({ timeFrame }) {
  yield call(
    getAverage,
    timeFrame,
    'disk',
    '(receive_data OR get_data)',
    setAverageWriteAction
  );
}

function* disksSaga() {
  yield all([
    takeLatest(GET_DISKS_DATA, getDisksData),
    takeEvery(GET_DISKS_RELOCATION, getDisksRelocation),
    takeEvery(GET_AVERAGE_SSD_READ, getAverageSSDRead),
    takeEvery(GET_AVERAGE_SSD_WRITE, getAverageSSDWrite),
    takeEvery(GET_AVERAGE_READ, getAverageRead),
    takeEvery(GET_AVERAGE_WRITE, getAverageWrite),
  ]);
}

// Selectors
function selectDisksState(state) {
  return state.get('disks');
}

function selectAllDisksState(state) {
  return selectDisksState(state).get('list');
}

const selectDisksWithServerNameSelector = createSelector(
  [selectAllDisksState, selectAllServersState],
  (disks, servers) => {
    if (disks && servers) {
      return disks.map(disk => {
        const selectedServer = servers.find(server => {
          return server && disk && disk.server && disk.server.id === server.id;
        });
        return Object.assign({}, disk, {
          serverName: selectedServer ? selectedServer.name : ''
        });
      });
    } else {
      return [];
    }
  }
);

function selectDiskFromUrl(state, props) {
  const disks = selectAllDisksState(state) || [];
  if (props && props.match && props.match.params && props.match.params.diskId) {
    return disks.find(disk => disk.id === props.match.params.diskId) || {};
  } else {
    return {};
  }
}

const selectDiskFromUrlSelector = createSelector(
  selectDiskFromUrl,
  disk => disk
);

const selectDiskServerSelector = createSelector(
  [selectDiskFromUrl, selectAllServersState],
  (disk, servers) => {
    if (!isEmpty(disk) && servers && servers.length > 0) {
      const server = servers.find(server => server.id === disk.server.id);
      if (server) {
        return server;
      }
    }
    return null;
  }
);

const selectAverageSSDReadState = (state) =>
  selectDisksState(state).get('averageSSDRead');

const selectAverageSSDWriteState = (state) =>
  selectDisksState(state).get('averageSSDWrite');

const selectAverageReadState = (state) =>
  selectDisksState(state).get('averageRead');

const selectAverageWriteState = (state) =>
  selectDisksState(state).get('averageWrite');

const selectDisksRelocationState = (state) =>
  selectDisksState(state).get('disksRelocation');

export {
  setDisksRelocationAction,
  getDisksRelocationAction,
  getDisksDataSuccessAction,
  getAverageSSDReadAction,
  setAverageSSDReadAction,
  getAverageSSDWriteAction,
  setAverageSSDWriteAction,
  getAverageReadAction,
  setAverageReadAction,
  getAverageWriteAction,
  setAverageWriteAction,
  getDisksDataAction,
  selectAllDisksState,
  selectDisksWithServerNameSelector,
  selectDiskFromUrlSelector,
  selectDiskServerSelector,
  selectAverageSSDReadState,
  selectAverageSSDWriteState,
  selectAverageReadState,
  selectAverageWriteState,
  selectDisksRelocationState,
  disksSaga
};
