import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import cidrRegex from 'cidr-regex';

import { InformationLine, InformationLabel } from '../components/ui/Tab';
import DLMTips from '../tips/DLMTips';
import SimpleTips from '../tips/SimpleTips';
import HelpPopover from '../components/common/HelpPopover';
import { Modal } from '../components/ui/Modal';
import {
  activateGlobalOverlay,
  deactivateGlobalOverlay,
  setGlobalOverlayText,
  resetGlobalOverlayText
} from '../actions/overview';
import {
  editVolumeAction,
  deleteVolumeAction,
  getVolumeEditionState,
  getVolumeDeletionState
} from '../ducks/volumes';
import { ACTION_IN_PROGRESS } from '../constants';

const VolumeLabel = styled(InformationLabel)`
  display: flex;
  align-items: center;
  color: #a4acb4;
  ${({ isTitle }) =>
    !isTitle
      ? css`
          font-size: 12px;
        `
      : null}
`;

class EditVolume extends React.Component {
  constructor(props) {
    super(props);

    if (isEmpty(props.volume)) {
      this.state = {
        volume: {},
        name: '',
        description: '',
        isLoaded: false,
        deleteModalIsOpen: false,
        isDLMEnabled: false,
        isDLMAddressAuto: false,
        DLMAddress: ''
      };
    } else {
      this.state = {
        volume: props.volume,
        name: props.volume.name,
        description: props.volume.description,
        isLoaded: true,
        deleteModalIsOpen: false,
        isDLMEnabled: (props.volume.dlm && props.volume.dlm.enabled) || false,
        isDLMAddressAuto:
          props.volume.dlm && props.volume.dlm.rpc_addr_selector ? false : true,
        DLMAddress:
          (props.volume.dlm && props.volume.dlm.rpc_addr_selector) || ''
      };
    }

    this.submit = this.submit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteCurrentVolume = this.deleteCurrentVolume.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  closeModal() {
    if (this.props.volumeDeletionState !== ACTION_IN_PROGRESS) {
      this.setState({ deleteModalIsOpen: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.isLoaded && !isEmpty(nextProps.volume)) {
      this.setState({
        name: nextProps.volume.name,
        description: nextProps.volume.description,
        isLoaded: true
      });
    }
  }

  componentWillUnmount() {
    this.props.deactivateGlobalOverlay();
    this.props.resetGlobalOverlayText();
  }

  volumeNameHasError() {
    const { name } = this.state;
    const noSpaceRegex = /^\S*$/;
    const alphaNumericWithAtLeastOneLetterRegex = /\w*[a-zA-Z_-]\w*/;

    if (name.length === 0) {
      return null;
    }

    if (!name.match(noSpaceRegex)) {
      return 'Volume Name cannot contain space.';
    } else if (name.length > 64) {
      return 'Volume Name must contain at most 64 characters.';
    } else if (!name.match(alphaNumericWithAtLeastOneLetterRegex)) {
      return 'Volume Name must contain at least one letter.';
    } else {
      return null;
    }
  }

  volumeDescriptionHasError() {
    if (this.state.description.length > 1000) {
      return 'Volume Description must contain at most 1000 characters.';
    } else {
      return null;
    }
  }

  DLMAddressHasError() {
    if (this.state.DLMAddress.length === 0) {
      return null;
    }

    const isCIDR = cidrRegex({ exact: true }).test(this.state.DLMAddress);
    if (isCIDR) {
      return null;
    } else {
      return 'RPC Address Selector is not valid';
    }
  }

  canSubmit() {
    const isVolumeDLMValid = this.state.isDLMAddressAuto
      ? true
      : this.state.DLMAddress.length > 0 && !this.DLMAddressHasError();

    return (
      this.state.name.length &&
      !this.volumeNameHasError() &&
      !this.volumeDescriptionHasError() &&
      (this.state.isDLMEnabled === false || isVolumeDLMValid)
    );
  }

  submit() {
    if (!isEmpty(this.props.volume)) {
      const payload = {
        ...this.props.volume,
        ...{
          name: this.state.name,
          description: this.state.description,
          dlm: {
            enabled: this.state.isDLMEnabled
          }
        }
      };

      if (this.state.isDLMEnabled && !this.state.isDLMAddressAuto) {
        payload.dlm.rpc_addr_selector = this.state.DLMAddress;
      }

      delete payload._links;
      delete payload.volume_config_groups;

      this.props.setGlobalOverlayText('Editing volume...');
      this.props.activateGlobalOverlay();
      this.props.editVolume(payload);
    }
  }

  deleteCurrentVolume() {
    if (!isEmpty(this.state.volume)) {
      this.props.setGlobalOverlayText('Deleting volume...');
      this.props.activateGlobalOverlay();
      this.closeModal();
      this.props.deleteVolume(this.state.volume.id);
    }
  }

  handleChange(fieldName) {
    return (event, checked) => {
      this.setState(state => {
        return { ...state, [fieldName]: checked };
      });
    };
  }

  render() {
    const inputLabelPropsStyle = {
      style: { fontSize: '1.5rem', fontWeight: '100' },
      shrink: true
    };

    const inputPropsStyle = { style: { fontSize: '1.5rem' } };
    const helperTextProps = { style: { fontSize: '1rem' } };

    const isEditing = this.props.volumeEditionState === ACTION_IN_PROGRESS;
    const canSubmit = this.canSubmit();

    const isDeletingVolume =
      this.props.volumeDeletionState === ACTION_IN_PROGRESS;

    const nbOfConnectors =
      this.state.volume && this.state.volume.volume_config_groups
        ? this.state.volume.volume_config_groups.reduce((prev, cg) => {
            const count =
              cg.connectors && cg.connectors ? cg.connectors.length : 0;
            return prev + count;
          }, 0)
        : 0;

    return (
      <React.Fragment>
        <TabContent>
          <FormContent
            style={{ width: window.innerWidth > 1600 ? '50%' : '75%' }}
          >
            <TextField
              style={{ marginBottom: '10px' }}
              label="Volume Name"
              placeholder="Volume1"
              InputProps={inputPropsStyle}
              InputLabelProps={inputLabelPropsStyle}
              FormHelperTextProps={helperTextProps}
              value={this.state.name || ''}
              error={this.volumeNameHasError() !== null}
              helperText={this.volumeNameHasError()}
              onChange={e => this.setState({ name: e.target.value })}
              fullWidth
              data-cy="edit_volume_name"
            />
            <TextField
              style={{ marginBottom: '10px' }}
              label="Volume Description"
              placeholder="This is the description of the volume."
              InputProps={inputPropsStyle}
              InputLabelProps={inputLabelPropsStyle}
              FormHelperTextProps={helperTextProps}
              value={this.state.description || ''}
              error={this.volumeDescriptionHasError() !== null}
              helperText={this.volumeDescriptionHasError()}
              onChange={e => this.setState({ description: e.target.value })}
              rows="3"
              multiline
              fullWidth
              data-cy="edit_volume_description"
            />
            <div style={{ marginTop: '10px' }}>
              <VolumeLabel isTitle>
                DLM{' '}
                <HelpPopover
                  content={
                    <SimpleTips
                      title="DLM"
                      content={`The Distributed Lock Manager (DLM) is a feature of SOFS connectors that coordinates operations over files, directories and quotas.`}
                    />
                  }
                />
              </VolumeLabel>
            </div>

            <InformationLine>
              <VolumeLabel>Enabled</VolumeLabel>
              <Grid component="label" container alignItems="center" spacing={8}>
                <Grid item style={{ width: '40px' }}>
                  Off
                </Grid>
                <Grid item>
                  {/* Do not allow disabled DLM if you have connectors */}
                  {nbOfConnectors > 0 && this.state.isDLMEnabled ? (
                    <Tooltip
                      title={
                        'Disabling the DLM is not supported on a volume that has attached connectors'
                      }
                      classes={{ tooltip: this.props.classes.tooltip }}
                    >
                      <Switch checked={this.state.isDLMEnabled} />
                    </Tooltip>
                  ) : (
                    <Switch
                      checked={this.state.isDLMEnabled}
                      onChange={this.handleChange('isDLMEnabled')}
                      name="isDLMEnabled"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  )}
                </Grid>
                <Grid item>On</Grid>
              </Grid>
            </InformationLine>

            {this.state.isDLMEnabled ? (
              <InformationLine>
                <VolumeLabel>
                  RPC Address Selector <HelpPopover content={<DLMTips />} />
                </VolumeLabel>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={8}
                >
                  <Tooltip
                    title={'Manual'}
                    classes={{ tooltip: this.props.classes.tooltip }}
                  >
                    <Grid item style={{ width: '40px' }}>
                      Man
                    </Grid>
                  </Tooltip>
                  <Grid item>
                    <Switch
                      checked={this.state.isDLMAddressAuto}
                      onChange={this.handleChange('isDLMAddressAuto')}
                      name="isDLMAddressAuto"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item>Auto</Grid>
                  {this.state.isDLMAddressAuto ? null : (
                    <TextField
                      style={{ marginLeft: '20px' }}
                      placeholder="10.0.0.0/24"
                      InputProps={inputPropsStyle}
                      InputLabelProps={inputLabelPropsStyle}
                      FormHelperTextProps={helperTextProps}
                      value={this.state.DLMAddress || ''}
                      onChange={e =>
                        this.setState({ DLMAddress: e.target.value })
                      }
                      error={this.DLMAddressHasError() !== null}
                      helperText={this.DLMAddressHasError()}
                    />
                  )}
                </Grid>
              </InformationLine>
            ) : null}
          </FormContent>
          <div
            style={{
              height: '40px',
              display: 'flex',
              width: '100%',
              marginTop: '10px',
              justifyContent: 'space-between'
            }}
          >
            <DeleteButton
              type="button"
              canSubmit={true}
              disabled={isDeletingVolume}
              onClick={() => this.setState({ deleteModalIsOpen: true })}
              data-cy="open_delete_volume_modal"
            >
              Delete
              <i
                className="fa fa-trash fa-lg"
                style={{ margin: '5px 0 5px 10px' }}
              />
            </DeleteButton>
            <div style={{ display: 'flex' }}>
              <CancelButton
                style={{ marginRight: '20px' }}
                onClick={() =>
                  this.props.history.push(
                    `/storages/file/${this.state.volume.id}`
                  )
                }
              >
                Discard Changes
              </CancelButton>
              <Button
                canSubmit={!isEditing && canSubmit}
                disabled={isEditing || canSubmit === false}
                onClick={this.submit}
                data-cy="edit_volume"
              >
                Save
                {isEditing ? (
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa fa-cog fa-spin fa-fw"
                  />
                ) : (
                  <i
                    style={{ marginLeft: '10px' }}
                    className="fa fa-floppy-o fa-lg"
                  />
                )}
              </Button>
            </div>
          </div>
        </TabContent>
        <Modal
          isOpen={this.state.deleteModalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          style={{
            content: {
              position: 'absolute',
              top: '25%',
              left: '35%',
              width: '30%',
              height: '350px'
            }
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <i className="fa fa-exclamation-triangle fa-3x" />
          </div>
          <div style={{ textAlign: 'justify' }}>
            <span style={{ fontWeight: 900 }}>
              This operation can not be rolled back.{' '}
            </span>
            Deleting a volume will not delete its data from the RING, data{' '}
            <span style={{ fontWeight: 900 }}>must be</span> deleted manually
            before this operation.
          </div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            Do you really want to delete {this.state.volume.name}?
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CancelButton
              style={{ padding: '10px 10px', margin: '0 10px' }}
              onClick={this.closeModal}
              disabled={isDeletingVolume}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              style={{ padding: '10px 10px', margin: '0 10px' }}
              canSubmit={!isDeletingVolume}
              disabled={isDeletingVolume}
              onClick={this.deleteCurrentVolume}
              data-cy="delete_volume"
            >
              Yes, delete
              {isDeletingVolume ? (
                <i
                  style={{ marginLeft: '5px' }}
                  className="fa fa-cog fa-spin fa-fw"
                />
              ) : null}
            </DeleteButton>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const TabContent = styled.div`
  background-color: #201d1d;
  padding: 20px;
`;

const FormContent = styled.div`
  display: 'flex';
  flex-direction: 'column';
`;

const CancelButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #000;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: #3c3c3c;
  }
`;

const disabledColor = '#979797';

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#ef3340' : disabledColor)};
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#d81e1e' : disabledColor)};
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#2b65ad' : disabledColor)};
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#205aa5' : disabledColor)};
  }
`;

const mapStateToProps = state => ({
  volumeEditionState: getVolumeEditionState(state),
  volumeDeletionState: getVolumeDeletionState(state)
});

const mapDispatchToProps = dispatch => {
  return {
    editVolume: volume => dispatch(editVolumeAction(volume)),
    deleteVolume: id => dispatch(deleteVolumeAction(id)),
    activateGlobalOverlay: () => dispatch(activateGlobalOverlay()),
    deactivateGlobalOverlay: () => dispatch(deactivateGlobalOverlay()),
    setGlobalOverlayText: text => dispatch(setGlobalOverlayText(text)),
    resetGlobalOverlayText: () => dispatch(resetGlobalOverlayText())
  };
};

/* MaterialUI styles */

const styles = theme => ({
  tooltip: {
    fontSize: 11
  }
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(EditVolume))
);
