/*
 * Overview Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import * as ACTIONS from './constants';

export function checkLDAP() {
  return {
    type: ACTIONS.CHECK_LDAP
  };
}

export function setCheckedLDAP() {
  return {
    type: ACTIONS.SET_CHECKED_LDAP
  };
}

export function setInvalidBasicAuth() {
  return {
    type: ACTIONS.SET_INVALID_BASIC_AUTH
  };
}

export function resetInvalidBasicAuth() {
  return {
    type: ACTIONS.RESET_INVALID_BASIC_AUTH
  };
}

export function checkAPIConnection() {
  return {
    type: ACTIONS.CHECK_API_CONNECTION
  };
}

export function apiConnectionSuccess(response) {
  return {
    type: ACTIONS.API_CONNECTION_SUCCESS,
    response
  };
}

export function apiConnectionNoError() {
  return {
    type: ACTIONS.API_CONNECTION_NO_ERROR
  };
}

export function apiConnectionError(error) {
  return {
    type: ACTIONS.API_CONNECTION_ERROR,
    error
  };
}

export function getVersion() {
  return {
    type: ACTIONS.GET_VERSION
  };
}

export function getInfraMetaData() {
  return {
    type: ACTIONS.GET_INFRA_METADATA
  };
}

export function setInfraMetaData(payload) {
  return {
    type: ACTIONS.SET_INFRA_METADATA,
    platformName: payload
  };
}

export function getVersionSuccess(version, release) {
  return {
    type: ACTIONS.GET_VERSION_SUCCESS,
    version,
    release
  };
}

/**
 * Verify user access rights actions
 */
export const userAccessConfigs = () => ({
  type: ACTIONS.USER_ACCESS_CONFIGS
});

export const getUserAccessConfigsSuccess = adminAccess => ({
  type: ACTIONS.GET_ACCESS_CONFIGS_SUCCESS,
  adminAccess
});

export function activateGlobalOverlay() {
  return {
    type: ACTIONS.ACTIVATE_GLOBAL_OVERLAY
  };
}

export function deactivateGlobalOverlay() {
  return {
    type: ACTIONS.DEACTIVATE_GLOBAL_OVERLAY
  };
}

export function setGlobalOverlayText(text) {
  return {
    type: ACTIONS.SET_GLOBAL_OVERLAY_TEXT,
    text
  };
}

export function resetGlobalOverlayText() {
  return {
    type: ACTIONS.RESET_GLOBAL_OVERLAY_TEXT
  };
}

export function editInfraMetadataAction(infraMetadata) {
  return {
    type: ACTIONS.EDIT_INFRA_METADATA,
    payload: infraMetadata
  };
}

export function setApiRequestNoAction() {
  return {
    type: ACTIONS.SET_API_REQUEST_NO_ACTION
  };
}

export function setApiRequestError() {
  return {
    type: ACTIONS.SET_API_REQUEST_ERROR
  };
}

export function setApiRequestErrorMsg(apiRequestError) {
  return {
    type: ACTIONS.SET_API_REQUEST_ERROR_MSG,
    apiRequestError
  };
}
