import React, { Component } from 'react';
import { timeRangeOptions, CHART_PANEL_IDS } from '../constants';
import GrafanaPanel from './GrafanaPanel';
import SelectBox from './common/Select';
import SectionTitle from './ui/SectionTitle';
import SimpleTips from '../tips/SimpleTips';
import { buildChartURL } from '../utils/grafanaFrameSrcgenerator';

export default class SingleServerPerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartoptions: timeRangeOptions,
      selectedChartOption: timeRangeOptions[1]
    };
  }

  onChartOptionChange(selectedOption) {
    this.setState({
      selectedChartOption: selectedOption
    });
  }

  render() {
    const { chartoptions, selectedChartOption } = this.state;
    const { server } = this.props;

    const serverNodesOperationsUrl = buildChartURL(
      CHART_PANEL_IDS.OPERATIONS,
      selectedChartOption.value,
      { HOST_IP: server, RING: 'All' }
    );

    const serverNodesLatencyUrl = buildChartURL(
      CHART_PANEL_IDS.LATENCY,
      selectedChartOption.value,
      { HOST_IP: server, RING: 'All' }
    );

    const serverNodesBandwidthUrl = buildChartURL(
      CHART_PANEL_IDS.BANDWIDTH,
      selectedChartOption.value,
      { HOST_IP: server, RING: 'All' }
    );

    const singleServerLoadAvgUrl = buildChartURL(
      CHART_PANEL_IDS.LOAD_AVERAGE,
      selectedChartOption.value,
      { SPECIFIC_HOST: server.name, RING: 'All' }
    );

    const singleServerCPUUrl = buildChartURL(
      CHART_PANEL_IDS.CPU,
      selectedChartOption.value,
      { SPECIFIC_HOST: server.name, RING: 'All' }
    );

    const singleServerMemoryUrl = buildChartURL(
      CHART_PANEL_IDS.MEMORY,
      selectedChartOption.value,
      { SPECIFIC_HOST: server.name, RING: 'All' }
    );

    const singleServerSwapUrl = buildChartURL(
      CHART_PANEL_IDS.SWAP,
      selectedChartOption.value,
      { SPECIFIC_HOST: server.name, RING: 'All' }
    );

    const isStorageServer =
      server && server.roles && server.roles.includes('STORAGE');

    return (
      <div className="performance-tab">
        <div>
          <SelectBox
            options={chartoptions}
            name="chart_options"
            value={selectedChartOption}
            onChange={this.onChartOptionChange.bind(this)}
          />
        </div>
        <div className="vertical-scroll-wrapper">
          {isStorageServer && (
            <React.Fragment>
              <SectionTitle>Storage Performance</SectionTitle>
              <GrafanaPanel
                iFrameSrc={serverNodesOperationsUrl}
                panelTitle="Operations Per Sec"
                popOverTemplate={
                  <SimpleTips
                    title="Operations Per Sec"
                    content={`Number per second of all GET, PUT, and DELETE operations at the Storage level
                    for the time period selected.`}
                  />
                }
              />
              <GrafanaPanel
                iFrameSrc={serverNodesLatencyUrl}
                panelTitle="Latency"
                popOverTemplate={
                  <SimpleTips
                    title="Latency"
                    content={`Latency of all GET, PUT, and DELETE operations at the RING level
                for the time period selected.`}
                  />
                }
              />
              <GrafanaPanel
                iFrameSrc={serverNodesBandwidthUrl}
                panelTitle="Bandwidth"
                popOverTemplate={
                  <SimpleTips
                    title="Bandwidth"
                    content={`Bandwidth of all GET and PUT operations at the RING level for the time period selected.`}
                  />
                }
              />
            </React.Fragment>
          )}
          <SectionTitle>System Performance</SectionTitle>

          <GrafanaPanel
            iFrameSrc={singleServerLoadAvgUrl}
            panelTitle="Load Average"
            popOverTemplate={
              <SimpleTips
                title="Load Average"
                content={`The Load Average metric reports a count of the total number of processes using
                or waiting for the CPU at any point in time.`}
              />
            }
          />
          <GrafanaPanel
            iFrameSrc={singleServerCPUUrl}
            panelTitle="CPU"
            popOverTemplate={
              <SimpleTips
                title="CPU"
                content={`The CPU metric reports the fraction of work done by CPU in real time.`}
              />
            }
          />
          <GrafanaPanel
            iFrameSrc={singleServerMemoryUrl}
            panelTitle="Memory"
            popOverTemplate={
              <SimpleTips
                title="Memory"
                content={`The Memory metric reports the percentage of system memory being consumed over time.`}
              />
            }
          />
          <GrafanaPanel
            iFrameSrc={singleServerSwapUrl}
            panelTitle="Swap"
            popOverTemplate={
              <SimpleTips
                title="Swap"
                content={`The Swap metric reports the ratio of disk space substituted for RAM memory.`}
              />
            }
          />
        </div>
      </div>
    );
  }
}
