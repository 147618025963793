import styled from 'styled-components';
import * as cssVariables from './variables';

const Form = styled.form`
  margin: 30px 0 0 0;

  label {
    font-weight: lighter;
    width: 30%;
  }

  input {
    display: inline-block;
    height: 40px;
    width: 100%;
    color: #999;
    background-color: ${cssVariables.formInputBck};
    border-radius: 4px;
    border: 2px solid #222;
  }

  textarea {
    display: inline-block;
    width: 100%;
    color: #999;
    background-color: ${cssVariables.formInputBck};
    border: none;
    border-radius: 4px;
  }

  .reserved_ip {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #444;
    span {
      font-size: 0.6em;
      margin-right: 5px;
    }
  }

  .checkbox_group {
    display: inline-block;
  }

  input[type='textarea'] {
    height: 100px;
  }

  .checkbox-display {
    label {
      width: 50px;
    }
    .form--content {
      width: 40px;
      input {
        height: 20px;
      }
    }
    width: 30%;
  }

  //// SELECT STYLE

  /* Reset Select */

  select {
    /*
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    */
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: ${cssVariables.formInputBck};
    background-image: none;
  }

  /* Custom Select */

  .select {
    position: relative;
    display: block;
    width: 100%;
    height: 3em;
    line-height: 3;
    background: ${cssVariables.formInputBck};
    overflow: hidden;
    border-radius: 2px;
  }

  select {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 0 0.5em;
    color: #fff;
    cursor: pointer;
  }

  select::-ms-expand {
    display: none;
  }

  /* Arrow */

  .select::after {
    content: '\\25BC';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0.2em 1em;
    color: ${cssVariables.formInputBck};
    background: #171b1d;
    pointer-events: none;
  }

  /* Transition */

  .select:hover::after {
    color: #fff;
  }

  .select::after {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
`;

const FormGroup = styled.div`
  margin: 0 0 30px 0;
  label {
    float: left;
  }
`;

const FormContent = styled.div`
  position: relative;
  width: 70%;
`;

const FormError = styled.div`
  position: absolute;
  bottom: -22px;
  left: 0;
  margin-left: 10px;
  font-size: 0.8em;
  color: ${cssVariables.red};
`;

const FormActions = styled.div`
  margin-top: 40px;

  &.form--actions--twoside {
    button.right {
      margin-left: 10px;
    }
  }
`;

export { Form, FormGroup, FormContent, FormError, FormActions };
