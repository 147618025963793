import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';

import * as cssVariables from '../components/ui/variables';
import SearchBar from '../components/SearchBar';
import ZonesAdd from './ZonesAdd';
import Zone from './ZonesListItem';
import HelpPopover from '../components/common/HelpPopover';
import SimpleTips from '../tips/SimpleTips';
import { sortByPropertyOrder, getSortOrderObject } from '../utils/helpers';
import {
  Table,
  THead,
  Th,
  TBody,
  Tr,
  NoDataRow
} from '../components/common/Table';
import {
  PanelContainer,
  VerticalItemsContainer,
  PanelTools
} from '../components/ui/AllPagesItems';
import SortIcon from '../components/common/SortIcon';
import { ASC, KEY_DESCRIPTION, KEY_LOCATION, KEY_NAME } from '../constants';

const PageTitle = styled.span`
  font-weight: ${cssVariables.bold};
  font-size: ${cssVariables.fontSizeLarge};
`;

class AllZones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      selectedSort: {
        key: KEY_NAME,
        order: ASC
      }
    };
  }

  onSearchUpdate(term) {
    this.setState({ searchString: term });
  }
  searchRows = memoizeOne((zones, searchString) => {
    let key = searchString.toLowerCase();

    return zones.reduce((resultZones, zone) => {
      let zoneName = zone.name.toLowerCase();
      let zoneLocation = zone.location.toLowerCase();
      let zoneDescription = zone.description.toLowerCase();
      if (zoneName.indexOf(key) >= 0) {
        resultZones.push(zone);
      } else if (zoneLocation.indexOf(key) >= 0) {
        resultZones.push(zone);
      } else if (zoneDescription.indexOf(key) >= 0) {
        resultZones.push(zone);
      }
      return resultZones;
    }, []);
  });

  onSortRows(key) {
    const { selectedSort } = this.state;
    let sortOrderObj = getSortOrderObject(key, selectedSort);
    this.setState({
      selectedSort: sortOrderObj
    });
  }

  sortRows = memoizeOne((zones, sortKey, sortOrder = ASC) => {
    return sortByPropertyOrder(zones, sortKey, sortOrder);
  });

  render() {
    const { selectedSort, searchString } = this.state;
    const { servers, adminAccess, match, zones } = this.props;

    const sortedZones = this.sortRows(
      this.searchRows(zones, searchString),
      selectedSort.key,
      selectedSort.order
    );

    const ZoneRow = () => {
      let tableRows = sortedZones.map(zone => (
        <Zone
          key={`ZONEID_${zone.id}`}
          zone={zone}
          name={zone.name}
          servers={servers}
          adminAccess={adminAccess}
        />
      ));
      if (tableRows.length === 0) {
        tableRows = <NoDataRow colSpan={adminAccess ? 4 : 3} />;
      }

      return tableRows;
    };

    return (
      <PanelContainer>
        <VerticalItemsContainer>
          <div
            style={{
              display: 'flex',
              marginBottom: '15px'
            }}
          >
            <PageTitle>
              ZONES
              <HelpPopover
                content={
                  <SimpleTips
                    title="ZONES"
                    content={`Zones are failure domains, which are areas of a network impacted when a key device or service experiences problems (e.g., racks, datacenters, or any logical grouping to which RING nodes or connectors are assigned).`}
                  />
                }
              />
            </PageTitle>
          </div>
          <PanelTools>
            <SearchBar
              onSearchChange={this.onSearchUpdate.bind(this)}
              placeholder="zone1"
            />
            {adminAccess ? <ZonesAdd /> : null}
          </PanelTools>
          <Table>
            <THead>
              <Tr>
                <Th>
                  Name
                  <SortIcon
                    onSort={() => this.onSortRows(KEY_NAME)}
                    selected={selectedSort.key === KEY_NAME}
                    selectedOrder={selectedSort.order}
                  />
                </Th>
                <Th>
                  Location
                  <SortIcon
                    onSort={() => this.onSortRows(KEY_LOCATION)}
                    selected={selectedSort.key === KEY_LOCATION}
                    selectedOrder={selectedSort.order}
                  />
                </Th>
                <Th>
                  Description
                  <SortIcon
                    onSort={() => this.onSortRows(KEY_DESCRIPTION)}
                    selected={selectedSort.key === KEY_DESCRIPTION}
                    selectedOrder={selectedSort.order}
                  />
                </Th>
                {adminAccess ? <Th width="20" /> : null}
              </Tr>
            </THead>
            <TBody>
              <Switch>
                <Route path={`${match.url}/:id`} component={ZoneRow} />
                <Route path={`${match.url}`} component={ZoneRow} />
              </Switch>
            </TBody>
          </Table>
        </VerticalItemsContainer>
      </PanelContainer>
    );
  }
}

AllZones.propTypes = {
  servers: PropTypes.array,
  zones: PropTypes.array,
  adminAccess: PropTypes.bool
};

AllZones.defaultProps = {
  servers: [],
  zones: [],
  adminAccess: false
};

const mapStateToProps = state => ({
  servers: state.get('servers').get('list'),
  zones: state.get('zones').get('list'),
  adminAccess: state.get('overview').get('adminAccess')
});

export default withRouter(connect(mapStateToProps)(AllZones));
