import React, { Component } from 'react';
import { connect } from 'react-redux';
import Online from '../components/SingleStorageOnline';
import GlobalHealthDonut from '../components/SingleStorageGlobalHealth';
import ServiceListChartTable from '../components/SingleStorageListChartTable';
import GrafanaPanel from '../components/GrafanaPanel';
import SingleStorageResponseCodeTips from '../tips/SingleStorageResponseCodeTips';
import { getFrameUrlofS3 } from '../utils/grafanaFrameSrcgenerator';
import {
  getStorageAvailabilityAction,
  getServiceListDataAction,
  getGlobalHealthDataAction,
  selectStorageAvailabilityProgress,
  selectStorageAvailability,
  selectGlobalHealth,
  selectServiceList
} from '../ducks/s3';
import { IFRAME_VARS } from '../constants';
import config from '../config';

class SingleS3ServiceTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFetchStorageAvailability: false,
      onlineSelectValue: 7
    };

    this.onOnlineFilterChange = this.onOnlineFilterChange.bind(this);
  }

  componentDidMount() {
    this.pollData();
    this.poll = setInterval(() => this.pollData(), config.longPollingInterval);
  }

  componentWillUnmount() {
    clearInterval(this.poll);
    this.poll = null;
  }

  pollData() {
    this.props.getServicesList();
    this.props.getGlobalHealth();
    const { storage } = this.props;
    if (storage && storage.id) {
      this.props.getStorageAvailability(
        storage.id,
        this.state.onlineSelectValue
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { storage } = nextProps;
    if (!this.state.hasFetchStorageAvailability && storage && storage.id) {
      this.props.getStorageAvailability(
        storage.id,
        this.state.onlineSelectValue
      );
      this.setState({ hasFetchStorageAvailability: true });
    }
  }

  onOnlineFilterChange(selectedOption) {
    const { storage } = this.props;
    this.setState({
      onlineSelectValue: selectedOption.value
    });
    this.props.getStorageAvailability(storage.id, selectedOption.value);
  }

  render() {
    const {
      activeServiceList,
      activeGlobalHealth,
      storage,
      storageAvailability
    } = this.props;

    const GlobalHealthProps = {
      activeGlobalHealth,
      storage
    };

    const serviceProps = {
      storageAvailability
    };
    const serviceList = { activeServiceList, storage };

    const s3ResponseCodeUrl = getFrameUrlofS3(
      config.s3MonitoringCharts.service.responseCodes,
      null,
      IFRAME_VARS.AUTO_REFRESH
    );

    return (
      <div>
        <div style={{ marginBottom: '20px' }}>
          <div className="row">
            <div className="col-sm-4">
              {!this.props.storageAvailabilityInProgress && (
                <Online
                  {...serviceProps}
                  onOnlineFilterChange={this.onOnlineFilterChange}
                />
              )}
            </div>
            <div className="col-sm-4">
              <GlobalHealthDonut {...GlobalHealthProps} />
            </div>
            <div className="col-sm-4">
              <ServiceListChartTable {...serviceList} />
            </div>
          </div>
        </div>
        <GrafanaPanel
          iFrameSrc={s3ResponseCodeUrl}
          panelTitle="Response Code"
          popOverTemplate={<SingleStorageResponseCodeTips />}
        />
      </div>
    );
  }
}

SingleS3ServiceTab.defaultProps = {
  storage: {},
  storageAvailabilityInProgress: false,
  activeGlobalHealth: {},
  activeServiceList: {},
  storageAvailability: {}
};

const mapStateToProps = state => ({
  storageAvailabilityInProgress: selectStorageAvailabilityProgress(state),
  activeGlobalHealth: selectGlobalHealth(state),
  activeServiceList: selectServiceList(state),
  storageAvailability: selectStorageAvailability(state)
});

const mapDispatchToProps = dispatch => {
  return {
    getStorageAvailability: (storageId, fromTime) => {
      dispatch(getStorageAvailabilityAction(storageId, fromTime));
    },
    getServicesList: () => {
      dispatch(getServiceListDataAction());
    },
    getGlobalHealth: () => {
      dispatch(getGlobalHealthDataAction());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleS3ServiceTab);
