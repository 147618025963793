import React, { Component } from 'react';

import { prettifyBytes } from '../utils/helpers';
import { Table, THead, Th, TBody, Tr, Td } from './common/Table';
import { SectionHeader, SectionTitle } from './ui/AllPagesItems';
import { TableMessage } from './ui/Table';

export default class TopTenBucketInChartTable extends Component {
  render() {
    const { activeTopTenBucketIN } = this.props;
    let tableContent;

    if (Object.keys(activeTopTenBucketIN).length) {
      tableContent = (
        <Table>
          <THead>
            <Tr>
              <Th>Bucket</Th>
              <Th>Transferred Data</Th>
            </Tr>
          </THead>
          <TBody>
            {activeTopTenBucketIN
              .sort((a, b) => b.traffic.value - a.traffic.value)
              .map(hit => (
                <Tr key={hit.key}>
                  <Td>{hit.key}</Td>
                  <Td>
                    {prettifyBytes(hit.traffic.value).value}{' '}
                    {prettifyBytes(hit.traffic.value).unit}
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      );
    } else if (activeTopTenBucketIN.length === 0) {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> No data
          available.
        </TableMessage>
      );
    } else {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" /> Loading
          content...
        </TableMessage>
      );
    }

    return (
      <div>
        <SectionHeader>
          <SectionTitle>Inbound Bandwidth</SectionTitle>
        </SectionHeader>
        {tableContent}
      </div>
    );
  }
}
