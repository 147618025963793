import React, { Component } from 'react';

import { createArrayFromKey } from '../utils/helpers';
import EditServer from '../containers/SingleServerEdit';
import HelpPopover from './common/HelpPopover';
import BlockTitle from './ui/BlockTitle';
import ServerInfoTips from '../tips/ServerInfoTips';
import { InformationLine, InformationLabel, InformationLink } from './ui/Tab';
import { filterServerRoles } from '../utils/helpers';
import { KEY_NAME } from '../constants';

class SingleServerInformations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editableDataRolesArray: ['S3']
    };
  }

  render() {
    const { server, zones, volumes, configGroups, adminAccess } = this.props;
    const dataZonesArray = createArrayFromKey(zones, KEY_NAME);
    const serverRoles = filterServerRoles(server.roles);
    const serverHardware = server.hardware || {};

    const volumesLink = volumes.map((v, idx) => (
      <InformationLink key={idx} to={`/storages/file/${v.id}/information`}>
        {v.name}
      </InformationLink>
    ));
    const configGroupsLink = configGroups.map((cg, idx) => {
      return (
        <InformationLink
          key={idx}
          to={`/storages/file/${cg.volume}/${cg.id}/edit`}
        >
          {cg.name}
        </InformationLink>
      );
    });

    return (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <div>
            <BlockTitle>Server Information</BlockTitle>
            <HelpPopover content={<ServerInfoTips />} />
          </div>
        </div>
        <InformationLine>
          <InformationLabel>Name</InformationLabel>
          {server.name}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Data IP Address</InformationLabel>
          {server.data_ip_address}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Management IP Address</InformationLabel>
          {server.management_ip_address}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Zone</InformationLabel>
          {server.zone && server.zone.name}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Model</InformationLabel>
          {serverHardware.server_type}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Type</InformationLabel>
          {server.server_type}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Roles</InformationLabel>
          {serverRoles.join(', ').replace('CIFS', 'SMB')}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Description</InformationLabel>
          {server.description}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Volumes</InformationLabel>
          {volumesLink}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Volume Configuration Groups</InformationLabel>
          {configGroupsLink}
        </InformationLine>

        {adminAccess && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <EditServer
              dataZonesArray={dataZonesArray}
              dataEditableRolesArray={this.state.editableDataRolesArray}
              dataActivesRolesArray={serverRoles}
              dataNodeArray={server}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SingleServerInformations;
