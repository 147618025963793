export const API_PREFIX = '/api/v0.1/';
export const LOADING = 'LOADING';

export const CHECK_LDAP = 'CHECK_LDAP';
export const SET_CHECKED_LDAP = 'SET_CHECKED_LDAP';

export const SET_INVALID_BASIC_AUTH = 'SET_INVALID_BASIC_AUTH';
export const RESET_INVALID_BASIC_AUTH = 'RESET_INVALID_BASIC_AUTH';

export const CHECK_API_CONNECTION = 'CHECK_API_CONNECTION';
export const API_CONNECTION_SUCCESS = 'API_CONNECTION_SUCCESS';
export const API_CONNECTION_ERROR = 'API_CONNECTION_ERROR';
export const API_CONNECTION_NO_ERROR = 'API_CONNECTION_NO_ERROR';

export const GET_VERSION = 'GET_VERSION';
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';

export const GET_INFRA_METADATA = 'GET_INFRA_METADATA';
export const SET_INFRA_METADATA = 'SET_INFRA_METADATA';
export const EDIT_INFRA_METADATA = 'EDIT_INFRA_METADATA';

// ZONES CONSTANTS

export const SEARCH_ZONE = 'SEARCH_ZONE';

export const ADD_ZONE = 'ADD_ZONE';
export const ADD_ZONE_ERROR = 'ADD_ZONE_ERROR';

export const DELETE_ZONE = 'DELETE_ZONE';
export const DELETE_ZONE_ERROR = 'DELETE_ZONE_ERROR';

export const EDIT_ZONE = 'EDIT_ZONE';
export const EDIT_ZONE_SUCCESS = 'EDIT_ZONE_SUCCESS';
export const EDIT_ZONE_ERROR = 'EDIT_ZONE_ERROR';

/** Components that will render to right side of the overview screen **/

/* Storage service status */

export const STATUS_NOMINAL = 'NOMINAL';
export const STATUS_DEGRADED = 'DEGRADED';
export const STATUS_UNAVAILABLE = 'UNAVAILABLE';

/* User access configurations */

export const USER_ACCESS_CONFIGS = 'USER_ACCESS_CONFIGS';
export const GET_ACCESS_CONFIGS_SUCCESS = 'GET_ACCESS_CONFIGS_SUCCESS';
export const PATCH = 'PATCH';
export const PUT = 'PUT';

// VOLUMES CONSTANTS
export const SINGLE_VOLUME_PANEL = 'SINGLE_VOLUME_PANEL';

export const SET_ERROR_MSG = 'SET_ERROR_MSG';

export const SET_PAGE_ACCESS_NO_ACTION = 'SET_PAGE_ACCESS_NO_ACTION';
export const SET_PAGE_ACCESS_ERROR = 'SET_PAGE_ACCESS_ERROR';

export const ACTIVATE_GLOBAL_OVERLAY = 'ACTIVATE_GLOBAL_OVERLAY';
export const DEACTIVATE_GLOBAL_OVERLAY = 'DEACTIVATE_GLOBAL_OVERLAY';

export const SET_GLOBAL_OVERLAY_TEXT = 'SET_GLOBAL_OVERLAY_TEXT';
export const RESET_GLOBAL_OVERLAY_TEXT = 'RESET_GLOBAL_OVERLAY_TEXT';

export const SET_API_REQUEST_IN_PROGRESS =
  'SET_API_REQUEST_IN_PROGRESS';
export const SET_API_REQUEST_NO_ACTION = 'SET_API_REQUEST_NO_ACTION';
export const SET_API_REQUEST_ERROR = 'SET_API_REQUEST_ERROR';
export const SET_API_REQUEST_ERROR_MSG = 'SET_API_REQUEST_ERROR_MSG';
