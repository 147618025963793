import React from 'react';
import styled, { css } from 'styled-components';
import * as cssVariables from '../components/ui/variables';

export const Tab = ({ items, children }) => (
  <TabContainer>
    <TabBar>
      {items.map((item, index) => (
        <TabItem
          key={index}
          onClick={item.onClick}
          selected={item.selected}
          data-cy={item.title.toLowerCase().replace(' ', '-')}
        >
          <TabItemTitle selected={item.selected}>{item.title}</TabItemTitle>
        </TabItem>
      ))}
    </TabBar>
    <TabContent>{children}</TabContent>
  </TabContainer>
);

const TabContainer = styled.div`
  margin: 15px 0;
`;

const TabBar = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  flex-basis: 25%;
  flex-shrink: 1;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  ${props =>
    props.selected &&
    css`
      background-color: ${cssVariables.brdrClr};
    `}
`;

const TabItemTitle = styled.p`
  margin: 0;
  color: ${cssVariables.infoIcnClr};
  font-size: 16px;
  text-transform: capitalize;
  ${props =>
    props.selected &&
    css`
      color: ${cssVariables.fontClr};
    `}
`;

const TabContent = styled.div`
  margin: 0;
  background-color: ${cssVariables.tableHoverBck};
  padding: 30px 40px;
`;
