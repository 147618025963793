import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import Select from 'react-select';
import * as cssVariables from '../ui/variables';
import imgFilterArrow from '../../assets/images/global-icons/filter-arrow.svg';
import imgStatusAll from '../../assets/images/dashboard-icons/all.png';
export default class SelectBox extends Component {
  render() {
    let { options, onChange, name, value, type } = this.props;

    let newListOptions = ListOption;

    newListOptions.defaultProps = {
      currValue: value
    };

    // Note: inputRenderer={() => null} is work-around to fix the white
    // box on focus that appear after the migration to React 16.3
    // cf. https://github.com/JedWatson/react-select/issues/2370
    return (
      <SelectContainer>
        <Select
          name={name}
          value={value}
          options={options}
          onChange={onChange}
          searchable={false}
          clearable={false}
          inputRenderer={() => null}
          className="select-status"
          valueComponent={type === 'state' ? StateValue : TimeValue}
          optionComponent={newListOptions}
        />
      </SelectContainer>
    );
  }
}

export class ListOption extends Component {
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option);
  }

  handleMouseEnter() {
    this.props.onFocus(this.props.option);
  }

  handleMouseMove() {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option);
  }

  render() {
    return (
      <SelectList
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseEnter={this.handleMouseEnter.bind(this)}
        onMouseMove={this.handleMouseMove.bind(this)}
        title={this.props.option.title}
      >
        <SelectListItem
          selected={
            this.props.isSelected ||
            this.props.currValue.value === this.props.option.value
          }
        >
          {this.props.children}
        </SelectListItem>
      </SelectList>
    );
  }
}

class TimeValue extends Component {
  render() {
    return (
      <div title={this.props.value.title}>
        <SelectValue>{this.props.children}</SelectValue>
      </div>
    );
  }
}

class StateValue extends Component {
  render() {
    return (
      <div title={this.props.value.title}>
        <SelectValue>
          <Status status={this.props.value.label.toLowerCase()} />
          <span>{this.props.children}</span>
        </SelectValue>
      </div>
    );
  }
}

SelectBox.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  type: PropTypes.string
};

SelectBox.defaultProps = {
  options: [],
  name: 'Select'
};

const SelectList = styled.div`
  color: ${cssVariables.serviceBlkTitlClr};
  background-color: ${cssVariables.panelSlctBg};
  overflow: hidden;
  width: auto;
  cursor: pointer;
`;

const SelectListItem = styled.div`
  font-size: 10px;
  padding: 5px 10px;
  text-transform: uppercase;
  height: 25px;
  ${props =>
    props.selected &&
    css`
      color: ${cssVariables.mainBlue};
      font-weight: bold;
    `}
  &:hover {
    ${props =>
      !props.selected &&
      css`
        color: ${cssVariables.fontClr};
      `}
  }
`;
const SelectValue = styled.div`
  color: ${cssVariables.fontClr} !important;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  height: 36px;
  align-items: center;
  padding: 0 5px;
`;

const Status = styled.span`
  height: 15px;
  width: 15px;
  border-radius: 30px;
  display: block;
  float: left;
  margin-right: 5px;
  ${props => {
    switch (props.status) {
      case 'all':
        return css`
          width: 30px;
          background: url(${imgStatusAll}) no-repeat;
        `;
      case 'warning':
        return css`
          background-color: ${cssVariables.orng};
        `;
      case 'critical':
        return css`
          background-color: ${cssVariables.red};
        `;
      case 'empty':
        return css`
          background-color: ${cssVariables.infoIcnClr};
        `;
      default:
        return css`
          background-color: ${cssVariables.grn};
        `;
    }
  }};
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .select-status {
    .Select-control {
      background-color: transparent;
      border: none;
      cursor: pointer;
      min-width: 75px;

      .Select-arrow-zone {
        padding: 0;
        position: relative;
        width: 15px;
        .Select-arrow {
          border: none;
          width: 7px;
          height: 4px;
          display: inline-block;
          background: url(${imgFilterArrow}) no-repeat;
          position: absolute;
          top: 17px;
          right: 5px;
        }
      }
      &:hover {
        box-shadow: none;
      }
    }

    .Select-menu-outer {
      background-color: ${cssVariables.panelSlctBg};
      border-color: transparent;
      border: none;
      border-radius: 0;
      .Select-menu {
        overflow: hidden;
      }
    }
  }
`;
