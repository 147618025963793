import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { callApi, callApiPost } from '../utils/callApi';
import { setInvalidBasicAuth } from '../actions/overview';
import {
  ACTION_ERROR,
  ACTION_IN_PROGRESS,
  ACTION_SUCCESS,
  NO_ACTION
} from '../constants';

// Actions
const GET_VOLUMES_DATA = 'GET_VOLUMES_DATA';
const GET_VOLUMES_DATA_SUCCESS = 'GET_VOLUMES_DATA_SUCCESS';

const GET_VOLUME_CONNECTORS = 'GET_VOLUME_CONNECTORS';
const GET_VOLUME_CONNECTORS_SUCCESS = 'GET_VOLUME_CONNECTORS_SUCCESS';

const GET_VOLUME_CONFIG_GROUP = 'GET_VOLUME_CONFIG_GROUP';
const GET_VOLUME_CONFIG_GROUP_SUCCESS = 'GET_VOLUME_CONFIG_GROUP_SUCCESS';

const GET_VOLUMES_CONNECTORS = 'GET_VOLUMES_CONNECTORS';
const GET_VOLUMES_CONNECTORS_SUCCESS = 'GET_VOLUMES_CONNECTORS_SUCCESS';

const CREATE_VOLUME = 'CREATE_VOLUME';
const EDIT_VOLUME = 'EDIT_VOLUME';
const DELETE_VOLUME = 'DELETE_VOLUME';

const ADD_VOLUME_IN_VOLUMES = 'ADD_VOLUME_IN_VOLUMES';
const UPDATE_VOLUME_IN_VOLUMES = 'UPDATE_VOLUME_IN_VOLUMES';
const REMOVE_VOLUME_IN_VOLUMES = 'REMOVE_VOLUME_IN_VOLUMES';

const SET_VOLUME_CREATION_IN_PROGRESS = 'SET_VOLUME_CREATION_IN_PROGRESS';
const SET_VOLUME_CREATION_SUCCESS = 'SET_VOLUME_CREATION_SUCCESS';
const SET_VOLUME_CREATION_NO_ACTION = 'SET_VOLUME_CREATION_NO_ACTION';
const SET_VOLUME_CREATION_ERROR = 'SET_VOLUME_CREATION_ERROR';
const SET_VOLUME_CREATION_ERROR_MSG = 'SET_VOLUME_CREATION_ERROR_MSG';

const SET_VOLUME_EDITION_IN_PROGRESS = 'SET_VOLUME_EDITION_IN_PROGRESS';
const SET_VOLUME_EDITION_SUCCESS = 'SET_VOLUME_EDITION_SUCCESS';
const SET_VOLUME_EDITION_NO_ACTION = 'SET_VOLUME_EDITION_NO_ACTION';
const SET_VOLUME_EDITION_ERROR = 'SET_VOLUME_EDITION_ERROR';
const SET_VOLUME_EDITION_ERROR_MSG = 'SET_VOLUME_EDITION_ERROR_MSG';

const SET_VOLUME_DELETION_IN_PROGRESS = 'SET_VOLUME_DELETION_IN_PROGRESS';
const SET_VOLUME_DELETION_SUCCESS = 'SET_VOLUME_DELETION_SUCCESS';
const SET_VOLUME_DELETION_NO_ACTION = 'SET_VOLUME_DELETION_NO_ACTION';
const SET_VOLUME_DELETION_ERROR = 'SET_VOLUME_DELETION_ERROR';
const SET_VOLUME_DELETION_ERROR_MSG = 'SET_VOLUME_DELETION_ERROR_MSG';

const CREATE_CONFIG_GROUP = 'CREATE_CONFIG_GROUP';
const EDIT_CONFIG_GROUP = 'EDIT_CONFIG_GROUP';
const GET_CONFIG_GROUP = 'GET_CONFIG_GROUP';
const SET_CONFIG_GROUP = 'SET_CONFIG_GROUP';
const RESET_CONFIG_GROUP = 'RESET_CONFIG_GROUP';
const DELETE_CONFIG_GROUP = 'DELETE_CONFIG_GROUP';

const GET_CONFIG_GROUPS = 'GET_CONFIG_GROUPS';
const SET_CONFIG_GROUPS = 'SET_CONFIG_GROUPS';

const SET_CG_CREATION_IN_PROGRESS = 'SET_CG_CREATION_IN_PROGRESS';
const SET_CG_CREATION_SUCCESS = 'SET_CG_CREATION_SUCCESS';
const SET_CG_CREATION_NO_ACTION = 'SET_CG_CREATION_NO_ACTION';
const SET_CG_CREATION_ERROR = 'SET_CG_CREATION_ERROR';
const SET_CG_CREATION_ERROR_MSG = 'SET_CG_CREATION_ERROR_MSG';

const SET_CG_EDIT_IN_PROGRESS = 'SET_CG_EDIT_IN_PROGRESS';
const SET_CG_EDIT_SUCCESS = 'SET_CG_EDIT_SUCCESS';
const SET_CG_EDIT_NO_ACTION = 'SET_CG_EDIT_NO_ACTION';
const SET_CG_EDIT_ERROR = 'SET_CG_EDIT_ERROR';
const SET_CG_EDIT_ERROR_MSG = 'SET_CG_EDIT_ERROR_MSG';

const SET_CG_DELETION_IN_PROGRESS = 'SET_CG_DELETION_IN_PROGRESS';
const SET_CG_DELETION_SUCCESS = 'SET_CG_DELETION_SUCCESS';
const SET_CG_DELETION_NO_ACTION = 'SET_CG_DELETION_NO_ACTION';
const SET_CG_DELETION_ERROR = 'SET_CG_DELETION_ERROR';
const SET_CG_DELETION_ERROR_MSG = 'SET_CG_DELETION_ERROR_MSG';

// Reducer
const defaultState = fromJS({
  volumeCreationState: NO_ACTION,
  volumeCreationError: '',
  volumeEditionState: NO_ACTION,
  volumeEditionError: '',
  volumeDeletionState: NO_ACTION,
  volumeDeletionError: '',
  cgCreationState: NO_ACTION,
  cgCreationError: '',
  cgEditState: NO_ACTION,
  cgEditError: '',
  cgDeletionState: NO_ACTION
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case GET_VOLUMES_DATA_SUCCESS:
      return state.set('list', action.volumes);
    case GET_VOLUME_CONNECTORS_SUCCESS:
      return state.set('activeVolumeConnectors', action.connectors);
    case GET_VOLUME_CONFIG_GROUP_SUCCESS:
      return state.set('activeVolumeConfigGroup', action.configGroup);
    case GET_VOLUMES_CONNECTORS_SUCCESS:
      return state.set('volumesConnectors', action.volumesConnectors);
    case SET_VOLUME_CREATION_IN_PROGRESS:
      return state.set('volumeCreationState', ACTION_IN_PROGRESS);
    case SET_VOLUME_CREATION_SUCCESS:
      return state
        .set('volumeCreationState', ACTION_SUCCESS)
        .set('newVolumeId', action.newVolumeId);
    case SET_VOLUME_CREATION_NO_ACTION:
      return state
        .set('volumeCreationState', NO_ACTION)
        .set('newVolumeId', null);
    case SET_VOLUME_CREATION_ERROR:
      return state.set('volumeCreationState', ACTION_ERROR);
    case SET_VOLUME_CREATION_ERROR_MSG:
      return state.set('volumeCreationError', action.errorMsg);
    case SET_VOLUME_EDITION_IN_PROGRESS:
      return state.set('volumeEditionState', ACTION_IN_PROGRESS);
    case SET_VOLUME_EDITION_SUCCESS:
      return state.set('volumeEditionState', ACTION_SUCCESS);
    case SET_VOLUME_EDITION_NO_ACTION:
      return state.set('volumeEditionState', NO_ACTION);
    case SET_VOLUME_EDITION_ERROR:
      return state.set('volumeEditionState', ACTION_ERROR);
    case SET_VOLUME_EDITION_ERROR_MSG:
      return state.set('volumeEditionError', action.errorMsg);
    case ADD_VOLUME_IN_VOLUMES: {
      if (action.volume) {
        let volumes = state.get('list');
        volumes = [...volumes, action.volume];
        return state.set('list', volumes);
      }
      return state;
    }
    case UPDATE_VOLUME_IN_VOLUMES: {
      if (action.volume) {
        let volumes = state.get('list');
        volumes = volumes.filter(volume => volume.id !== action.volume.id);
        volumes = [...volumes, action.volume];
        return state.set('list', volumes);
      }
      return state;
    }
    case REMOVE_VOLUME_IN_VOLUMES: {
      if (action.id) {
        let volumes = state.get('list');
        volumes = volumes.filter(volume => volume.id !== action.id);
        return state.set('list', volumes);
      }
      return state;
    }
    case SET_VOLUME_DELETION_IN_PROGRESS:
      return state.set('volumeDeletionState', ACTION_IN_PROGRESS);
    case SET_VOLUME_DELETION_SUCCESS:
      return state.set('volumeDeletionState', ACTION_SUCCESS);
    case SET_VOLUME_DELETION_NO_ACTION:
      return state.set('volumeDeletionState', NO_ACTION);
    case SET_VOLUME_DELETION_ERROR:
      return state.set('volumeDeletionState', ACTION_ERROR);
    case SET_VOLUME_DELETION_ERROR_MSG:
      return state.set('volumeDeletionError', action.errorMsg);
    case SET_CG_CREATION_IN_PROGRESS:
      return state.set('cgCreationState', ACTION_IN_PROGRESS);
    case SET_CG_CREATION_SUCCESS:
      return state.set('cgCreationState', ACTION_SUCCESS);
    case SET_CG_CREATION_NO_ACTION:
      return state.set('cgCreationState', NO_ACTION);
    case SET_CG_CREATION_ERROR:
      return state.set('cgCreationState', ACTION_ERROR);
    case SET_CG_CREATION_ERROR_MSG:
      return state.set('cgCreationError', action.errorMsg);
    case SET_CONFIG_GROUPS:
      return state.set('configGroups', action.configGroups);
    case SET_CONFIG_GROUP:
      return state.set('activeConfigGroup', action.activeConfigGroup);
    case RESET_CONFIG_GROUP:
      return state.set('activeConfigGroup', null);
    case SET_CG_EDIT_IN_PROGRESS:
      return state.set('cgEditState', ACTION_IN_PROGRESS);
    case SET_CG_EDIT_SUCCESS:
      return state.set('cgEditState', ACTION_SUCCESS);
    case SET_CG_EDIT_NO_ACTION:
      return state.set('cgEditState', NO_ACTION);
    case SET_CG_EDIT_ERROR:
      return state.set('cgEditState', ACTION_ERROR);
    case SET_CG_EDIT_ERROR_MSG:
      return state.set('cgEditError', action.errorMsg);
    case SET_CG_DELETION_IN_PROGRESS:
      return state.set('cgDeletionState', ACTION_IN_PROGRESS);
    case SET_CG_DELETION_SUCCESS:
      return state.set('cgDeletionState', ACTION_SUCCESS);
    case SET_CG_DELETION_NO_ACTION:
      return state.set('cgDeletionState', NO_ACTION);
    case SET_CG_DELETION_ERROR:
      return state.set('cgDeletionState', ACTION_ERROR);
    case SET_CG_DELETION_ERROR_MSG:
      return state.set('cgDeletionError', action.errorMsg);
    default:
      return state;
  }
}

// Actions Creators
/* Get volumes Action */
function getVolumesDataAction() {
  return {
    type: GET_VOLUMES_DATA
  };
}

function getVolumesDataSuccessAction(volumes) {
  return {
    type: GET_VOLUMES_DATA_SUCCESS,
    volumes
  };
}

/* Get Volumes Connectors List */
function getVolumeConnectorsDataAction(volumeID) {
  return {
    type: GET_VOLUME_CONNECTORS,
    volumeID
  };
}

function getVolumeConnectorsSuccessAction(connectors) {
  return {
    type: GET_VOLUME_CONNECTORS_SUCCESS,
    connectors
  };
}

/* Get Volumes Config Group List */
function getVolumeConfigGroupDataAction(volumeID) {
  return {
    type: GET_VOLUME_CONFIG_GROUP,
    volumeID
  };
}

function getVolumeConfigGroupSuccessAction(configGroup) {
  return {
    type: GET_VOLUME_CONFIG_GROUP_SUCCESS,
    configGroup
  };
}

function getVolumesConnectorsAction() {
  return {
    type: GET_VOLUMES_CONNECTORS
  };
}

function getVolumesConnectorsSuccessAction(volumesConnectors) {
  return {
    type: GET_VOLUMES_CONNECTORS_SUCCESS,
    volumesConnectors
  };
}

function createVolumeAction(volume) {
  return {
    type: CREATE_VOLUME,
    volume
  };
}

function editVolumeAction(volume) {
  return {
    type: EDIT_VOLUME,
    volume
  };
}

function deleteVolumeAction(id) {
  return {
    type: DELETE_VOLUME,
    id
  };
}

function updateVolumeInVolumesAction(volume, oldVolumeId) {
  return {
    type: UPDATE_VOLUME_IN_VOLUMES,
    volume,
    oldVolumeId
  };
}

function addVolumeInVolumesAction(volume) {
  return {
    type: ADD_VOLUME_IN_VOLUMES,
    volume
  };
}

function removeVolumeInVolumesAction(id) {
  return {
    type: REMOVE_VOLUME_IN_VOLUMES,
    id
  };
}

function setVolumeCreationInProgressAction() {
  return {
    type: SET_VOLUME_CREATION_IN_PROGRESS
  };
}

function setVolumeCreationSuccessAction(newVolumeId) {
  return {
    type: SET_VOLUME_CREATION_SUCCESS,
    newVolumeId
  };
}

function setVolumeCreationNoActionAction() {
  return {
    type: SET_VOLUME_CREATION_NO_ACTION
  };
}

function setVolumeCreationErrorAction() {
  return {
    type: SET_VOLUME_CREATION_ERROR
  };
}

function setVolumeCreationErrorMsgAction(errorMsg) {
  return {
    type: SET_VOLUME_CREATION_ERROR_MSG,
    errorMsg
  };
}

function setVolumeEditionInProgressAction() {
  return {
    type: SET_VOLUME_EDITION_IN_PROGRESS
  };
}

function setVolumeEditionSuccessAction() {
  return {
    type: SET_VOLUME_EDITION_SUCCESS
  };
}

function setVolumeEditionNoActionAction() {
  return {
    type: SET_VOLUME_EDITION_NO_ACTION
  };
}

function setVolumeEditionErrorAction() {
  return {
    type: SET_VOLUME_EDITION_ERROR
  };
}

function setVolumeEditionErrorMsgAction(errorMsg) {
  return {
    type: SET_VOLUME_EDITION_ERROR_MSG,
    errorMsg
  };
}

function setVolumeDeletionInProgressAction() {
  return {
    type: SET_VOLUME_DELETION_IN_PROGRESS
  };
}

function setVolumeDeletionSuccessAction() {
  return {
    type: SET_VOLUME_DELETION_SUCCESS
  };
}

function setVolumeDeletionNoActionAction() {
  return {
    type: SET_VOLUME_DELETION_NO_ACTION
  };
}

function setVolumeDeletionErrorAction() {
  return {
    type: SET_VOLUME_DELETION_ERROR
  };
}

function setVolumeDeletionErrorMsgAction(errorMsg) {
  return {
    type: SET_VOLUME_DELETION_ERROR_MSG,
    errorMsg
  };
}

// Config Group
function getConfigGroupAction(id) {
  return {
    type: GET_CONFIG_GROUP,
    id
  };
}

function setActiveConfigGroupAction(activeConfigGroup) {
  return {
    type: SET_CONFIG_GROUP,
    activeConfigGroup
  };
}

function createConfigGroupAction(configGroup) {
  return {
    type: CREATE_CONFIG_GROUP,
    configGroup
  };
}

function editConfigGroupAction(id, configGroup) {
  return {
    type: EDIT_CONFIG_GROUP,
    id,
    payload: configGroup
  };
}

function resetActiveConfigGroupAction() {
  return {
    type: RESET_CONFIG_GROUP
  };
}

function setCGEditInProgressAction() {
  return {
    type: SET_CG_EDIT_IN_PROGRESS
  };
}

function setCGEditSuccessAction() {
  return {
    type: SET_CG_EDIT_SUCCESS
  };
}

function setCGEditNoActionAction() {
  return {
    type: SET_CG_EDIT_NO_ACTION
  };
}

function setCGEditErrorAction() {
  return {
    type: SET_CG_EDIT_ERROR
  };
}

function setCGEditErrorMsgAction(errorMsg) {
  return {
    type: SET_CG_EDIT_ERROR_MSG,
    errorMsg
  };
}

function setCGCreationInProgressAction() {
  return {
    type: SET_CG_CREATION_IN_PROGRESS
  };
}

function setCGCreationSuccessAction() {
  return {
    type: SET_CG_CREATION_SUCCESS
  };
}

function setCGCreationNoActionAction() {
  return {
    type: SET_CG_CREATION_NO_ACTION
  };
}

function setCGCreationErrorAction() {
  return {
    type: SET_CG_CREATION_ERROR
  };
}

function setCGCreationErrorMsgAction(errorMsg) {
  return {
    type: SET_CG_CREATION_ERROR_MSG,
    errorMsg
  };
}

function deleteConfigGroupAction(id) {
  return {
    type: DELETE_CONFIG_GROUP,
    id
  };
}

function setCGDeletionInProgressAction() {
  return {
    type: SET_CG_DELETION_IN_PROGRESS
  };
}

function setCGDeletionSuccessAction() {
  return {
    type: SET_CG_DELETION_SUCCESS
  };
}

function setCGDeletionNoActionAction() {
  return {
    type: SET_CG_DELETION_NO_ACTION
  };
}

function setCGDeletionErrorAction() {
  return {
    type: SET_CG_DELETION_ERROR
  };
}

function setCGDeletionErrorMsgAction(errorMsg) {
  return {
    type: SET_CG_DELETION_ERROR_MSG,
    errorMsg
  };
}

function getConfigGroupsAction() {
  return {
    type: GET_CONFIG_GROUPS
  };
}

function setConfigGroupsAction(configGroups) {
  return {
    type: SET_CONFIG_GROUPS,
    configGroups
  };
}

// Sagas

function* createVolume(action) {
  let requestURL = 'volumes';
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Content-Encoding': 'gzip'
  });

  try {
    yield put(setVolumeCreationInProgressAction());
    const newBody = {
      ...action.volume,
      declared_version: '9.2.0.0'
    };
    const data = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(newBody)
    });

    const volume = data && data._items && data._items[0];
    yield put(addVolumeInVolumesAction(volume));
    yield put(getVolumesDataAction());

    yield put(setVolumeCreationSuccessAction(volume.id));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(setVolumeCreationErrorMsgAction(error));
      yield put(setVolumeCreationErrorAction());
    }
  }
}

function* editVolume(action) {
  let requestURL = `volumes/${action.volume.id}`;
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Content-Encoding': 'gzip'
  });

  try {
    const volumeId = action.volume.id;
    const volume = action.volume;
    delete volume.id;
    yield put(setVolumeEditionInProgressAction());
    const data = yield call(callApiPost, requestURL, {
      method: 'PUT',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(action.volume)
    });

    const newVolume = data && data._items && data._items[0];
    yield put(updateVolumeInVolumesAction(newVolume, volumeId));
    yield put(getVolumesDataAction());
    yield put(setVolumeEditionSuccessAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(setVolumeEditionErrorMsgAction(error));
      yield put(setVolumeEditionErrorAction());
    }
  }
}

function* deleteVolume(action) {
  let requestURL = `volumes/${action.id}`;
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Content-Encoding': 'gzip'
  });

  try {
    yield put(setVolumeDeletionInProgressAction());
    yield call(callApiPost, requestURL, {
      method: 'DELETE',
      mode: 'cors',
      headers: header
    });

    yield put(removeVolumeInVolumesAction(action.id));
    yield put(getVolumesDataAction());
    yield put(setVolumeDeletionSuccessAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(setVolumeDeletionErrorMsgAction(error));
      yield put(setVolumeDeletionErrorAction());
    }
  }
}

function* fetchVolumesData() {
  let requestURL = 'volumes/?embed=volume_config_groups&limit=500';

  let responseItems = [];

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items;

    do {
      if (response._links.hasOwnProperty('next')) {
        requestURL = `volumes/?${response._links.next.split('?')[1]}`;

        response = yield call(callApi, requestURL, {
          method: 'GET',
          mode: 'cors'
        });

        responseItems = responseItems.concat(response._items);
      }
    } while (response._links.hasOwnProperty('next'));

    yield put(getVolumesDataSuccessAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* getVolumesConnectors() {
  let requestURL = 'volume_connectors/?limit=1000';
  let responseItems = [];

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items;

    yield put(getVolumesConnectorsSuccessAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* getVolumeConnectors(action) {
  let requestURL = `volume_connectors/?volume_id=${action.volumeID}`;

  let responseItems = [];

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items;

    yield put(getVolumeConnectorsSuccessAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* getVolumeConfigGroup(action) {
  let requestURL = `volume_config_groups/?embed=connectors&volume=${action.volumeID}`;

  let responseItems = [];

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items;

    yield put(getVolumeConfigGroupSuccessAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* createConfigGroup(action) {
  let requestURL = 'volume_config_groups';
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Content-Encoding': 'gzip'
  });

  try {
    yield put(setCGCreationInProgressAction());
    yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(action.configGroup)
    });

    yield call(fetchVolumesData);
    yield call(getConfigGroups);
    yield put(getVolumesDataAction());
    yield put(setCGCreationSuccessAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(setCGCreationErrorMsgAction(error));
      yield put(setCGCreationErrorAction());
    }
  }
}

function* editConfigGroup(action) {
  let requestURL = `volume_config_groups/${action.id}`;
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Content-Encoding': 'gzip'
  });

  try {
    yield put(setCGEditInProgressAction());
    yield call(callApiPost, requestURL, {
      method: 'PUT',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(action.payload)
    });

    yield call(
      getVolumeConfigGroup.bind(null, { volumeID: action.payload.volume })
    );
    yield call(fetchVolumesData);
    yield call(getConfigGroups);
    yield put(setCGEditSuccessAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(setCGEditErrorMsgAction(error));
      yield put(setCGEditErrorAction());
    }
  }
}

function* getConfigGroups() {
  let requestURL = 'volume_config_groups/?embed=connectors&limit=500';

  let responseItems = [];

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });
    responseItems = response._items;
    yield put(setConfigGroupsAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* getConfigGroup(action) {
  let requestURL = `volume_config_groups/${action.id}`;
  let responseItems = [];

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items[0];

    yield put(setActiveConfigGroupAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* deleteConfigGroup(action) {
  let requestURL = `volume_config_groups/${action.id}`;

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Content-Encoding': 'gzip'
  });

  try {
    yield put(setCGDeletionInProgressAction());
    yield call(callApiPost, requestURL, {
      method: 'DELETE',
      mode: 'cors',
      headers: header
    });

    yield call(fetchVolumesData);
    yield call(getConfigGroups);
    yield put(setCGDeletionSuccessAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(setCGDeletionErrorMsgAction(error));
      yield put(setCGDeletionErrorAction());
    }
  }
}

function* volumesSaga() {
  yield all([
    takeEvery(CREATE_VOLUME, createVolume),
    takeEvery(EDIT_VOLUME, editVolume),
    takeEvery(DELETE_VOLUME, deleteVolume),
    takeEvery(GET_VOLUMES_DATA, fetchVolumesData),
    takeLeading(GET_VOLUME_CONNECTORS, getVolumeConnectors),
    takeEvery(GET_VOLUMES_CONNECTORS, getVolumesConnectors),
    takeEvery(GET_VOLUME_CONFIG_GROUP, getVolumeConfigGroup),
    takeEvery(CREATE_CONFIG_GROUP, createConfigGroup),
    takeEvery(GET_CONFIG_GROUP, getConfigGroup),
    takeEvery(EDIT_CONFIG_GROUP, editConfigGroup),
    takeEvery(DELETE_CONFIG_GROUP, deleteConfigGroup),
    takeLeading(GET_CONFIG_GROUPS, getConfigGroups)
  ]);
}

// Selectors
function selectVolumesState(state) {
  return state.get('volumes');
}

function selectAllVolumesState(state) {
  return selectVolumesState(state).get('list');
}

function getVolumeFromUrlSelector(state, props) {
  const volumes = selectAllVolumesState(state) || [];

  if (props && props.match && props.match.params && props.match.params.id) {
    return (
      volumes.find(
        volume => volume.id === parseInt(props.match.params.id, 10)
      ) || {}
    );
  } else {
    return {};
  }
}

function getVolumesConnectorsSelector(state) {
  return selectVolumesState(state).get('volumesConnectors');
}

function getNewVolumeId(state) {
  return selectVolumesState(state).get('newVolumeId');
}

function getVolumeCreationState(state) {
  return selectVolumesState(state).get('volumeCreationState');
}

function getVolumeCreationError(state) {
  return selectVolumesState(state).get('volumeCreationError');
}

function getVolumeEditionState(state) {
  return selectVolumesState(state).get('volumeEditionState');
}

function getVolumeEditionError(state) {
  return selectVolumesState(state).get('volumeEditionError');
}

function getVolumeDeletionState(state) {
  return selectVolumesState(state).get('volumeDeletionState');
}

function getVolumeDeletionError(state) {
  return selectVolumesState(state).get('volumeDeletionError');
}

function getConfigGroupsSelector(state) {
  return selectVolumesState(state).get('configGroups');
}

function getConfigGroupFromUrl(state, props) {
  const configGroups = getConfigGroupsSelector(state) || [];

  if (
    props &&
    props.match &&
    props.match.params &&
    props.match.params.configGroupId
  ) {
    return (
      configGroups.find(
        cg => cg.id === parseInt(props.match.params.configGroupId, 10)
      ) || {}
    );
  } else {
    return {};
  }
}

function getConfigGroupCreationState(state) {
  return selectVolumesState(state).get('cgCreationState');
}

function getConfigGroupEditState(state) {
  return selectVolumesState(state).get('cgEditState');
}

function getConfigGroupDeletionState(state) {
  return selectVolumesState(state).get('cgDeletionState');
}

function getConfigGroupCreationError(state) {
  return selectVolumesState(state).get('cgCreationError');
}

function getConfigGroupEditError(state) {
  return selectVolumesState(state).get('cgEditError');
}

function getConfigGroupDeletionError(state) {
  return selectVolumesState(state).get('cgDeletionError');
}

export {
  getVolumesDataAction,
  getVolumesDataSuccessAction,
  getVolumeConnectorsDataAction,
  getVolumeConnectorsSuccessAction,
  getVolumeConfigGroupDataAction,
  getVolumeConfigGroupSuccessAction,
  getVolumesConnectorsAction,
  getVolumesConnectorsSuccessAction,
  createVolumeAction,
  editVolumeAction,
  deleteVolumeAction,
  updateVolumeInVolumesAction,
  addVolumeInVolumesAction,
  removeVolumeInVolumesAction,
  setVolumeCreationInProgressAction,
  setVolumeCreationSuccessAction,
  setVolumeCreationNoActionAction,
  setVolumeCreationErrorAction,
  setVolumeCreationErrorMsgAction,
  setVolumeEditionNoActionAction,
  setVolumeEditionErrorAction,
  setVolumeEditionErrorMsgAction,
  setVolumeDeletionInProgressAction,
  setVolumeDeletionSuccessAction,
  setVolumeDeletionNoActionAction,
  setVolumeDeletionErrorAction,
  setVolumeDeletionErrorMsgAction,
  getConfigGroupAction,
  setActiveConfigGroupAction,
  createConfigGroupAction,
  editConfigGroupAction,
  resetActiveConfigGroupAction,
  setCGEditInProgressAction,
  setCGEditSuccessAction,
  setCGEditNoActionAction,
  setCGEditErrorAction,
  setCGEditErrorMsgAction,
  setCGCreationInProgressAction,
  setCGCreationSuccessAction,
  setCGCreationNoActionAction,
  setCGCreationErrorAction,
  setCGCreationErrorMsgAction,
  deleteConfigGroupAction,
  setCGDeletionInProgressAction,
  setCGDeletionSuccessAction,
  setCGDeletionNoActionAction,
  setCGDeletionErrorAction,
  setCGDeletionErrorMsgAction,
  getConfigGroupsAction,
  setConfigGroupsAction,
  fetchVolumesData,
  getVolumeConfigGroup,
  volumesSaga,
  selectAllVolumesState,
  getVolumeFromUrlSelector,
  getVolumesConnectorsSelector,
  getNewVolumeId,
  getVolumeCreationState,
  getVolumeCreationError,
  getVolumeEditionState,
  getVolumeEditionError,
  getVolumeDeletionState,
  getVolumeDeletionError,
  getConfigGroupsSelector,
  getConfigGroupFromUrl,
  getConfigGroupCreationState,
  getConfigGroupEditState,
  getConfigGroupDeletionState,
  getConfigGroupCreationError,
  getConfigGroupEditError,
  getConfigGroupDeletionError
};
