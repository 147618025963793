import styled from 'styled-components';
import { Link } from 'react-router-dom';

const InformationLine = styled.div`
  display: flex;
  color: #a4acb4;
  font-size: 0.9em;
  margin-bottom: 10px;
`;

const InformationLabel = styled.div`
  width: 250px;
  min-width: 250px;
`;

const InformationLink = styled(Link)`
  color: #a4acb4;
  text-decoration: underline;
`;

const TabContent = styled.div`
  background-color: #201d1d;
  padding: 20px;
`;

export { TabContent, InformationLine, InformationLink, InformationLabel };
