import { all, fork } from 'redux-saga/effects';

import globalSaga from './global';
import overViewSaga from './overview';
import loginSaga from './login';
import { serversSaga } from '../ducks/servers';
import { topServersSaga } from '../ducks/topServers';
import { disksSaga } from '../ducks/disks';
import { topDisksSaga } from '../ducks/topDisks';
import { zonesSaga } from '../ducks/zones';
import { volumesSaga } from '../ducks/volumes';
import { ringsSaga } from '../ducks/rings';
import { s3Saga } from '../ducks/s3';

export default function* rootSaga() {
  return yield all([
    fork(globalSaga),
    fork(loginSaga),
    fork(overViewSaga),
    fork(serversSaga),
    fork(topServersSaga),
    fork(disksSaga),
    fork(topDisksSaga),
    fork(zonesSaga),
    fork(volumesSaga),
    fork(ringsSaga),
    fork(s3Saga)
  ]);
}
