import React from 'react';
import styled from 'styled-components';
import * as cssVariables from './ui/variables';

const ApiErrorContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ApiErrorMessage = styled.h4`
  font-size: 24px;
  color: ${cssVariables.infoIcnSlctClr};
  margin-top: 10px;
`;

export default function ApiError() {
  return (
    <ApiErrorContainer>
      <img
        src={require('../assets/images/global-icons/api-error-icon.png')}
        alt="API-Error"
      />
      <ApiErrorMessage>{"Can't connect to Scality API!"}</ApiErrorMessage>
    </ApiErrorContainer>
  );
}
