import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CPUIcon(props) {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
      <title>CPU</title>
      <defs>
        <path
          id="gkowa"
          d="M666.62 340.98h4.74c.34 0 .6.26.6.6v4.74c0 .34-.26.6-.6.6h-4.74a.59.59 0 0 1-.6-.6v-4.74c0-.32.28-.6.6-.6z"
        />
        <path
          id="gkowb"
          d="M675.2 340.5h2.66v1.2h-2.66v1.7h2.66v1.2h-2.66v1.7h2.66v1.2h-2.66v2.06c0 .34-.26.6-.6.6h-2.1v2.7h-1.2v-2.7h-1.7v2.7h-1.2v-2.7h-1.7v2.7h-1.2v-2.7h-2.1a.59.59 0 0 1-.6-.6v-2.06h-2.66v-1.2h2.66v-1.7h-2.66v-1.2h2.66v-1.7h-2.66v-1.2h2.66v-2.16c0-.34.26-.6.6-.6h2.1v-2.6h1.2v2.6h1.7v-2.6h1.2v2.6h1.7v-2.6h1.2v2.62h2.1c.34 0 .6.26.6.6zm-1.2-1.54h-10v10h10z"
        />
      </defs>
      <g>
        <g transform="translate(-660 -335)">
          <g>
            <use fill="#fff" xlinkHref="#gkowa" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#gkowb" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
