import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import { getGrafanaDiskPageLink } from '../utils/grafanaFrameSrcgenerator';
import { prettifyBytes, getDiskUsages } from '../utils/helpers';
import {
  DiskWrapper,
  DiskLegendWrapper,
  DiskLegend,
  DiskGauge,
  CapacityUsed,
  ColorLegend
} from '../components/common/DiskInfo';
import { InlineBreadcrumbItem, InlineStatus } from '../components/InlineStatus';
import Tooltip from '../components/Tooltip';
import Breadcrumb from '../components/Breadcrumb';
import { Tab } from '../components/Tab';
import SingleDiskPerformance from '../components/SingleDiskPerformance';
import { LinkOverlayIcon } from '../components/ui/AllPagesItems';
import monitoringIcon from '../assets/images/nav/stats-icon.png';
import {
  ContentContainer,
  PageHeader,
  BreadcrumbStatusContainer
} from '../components/ui/PageStructure';
import { TooltipOverlay } from '../components/ui/AllPagesItems';

import {
  selectAllDisksState,
  selectDiskServerSelector,
  selectDiskFromUrlSelector
} from '../ducks/disks';

export class SingleDisk extends Component {
  onTabClick(tab) {
    this.props.history.push(`${this.props.match.url}/${tab}`);
  }

  render() {
    const { disk, diskServer, adminAccess, match, location } = this.props;
    const exactPath = location.pathname;

    const prettyDiskTotal = prettifyBytes(disk.diskspace_total),
      prettyDiskStored = prettifyBytes(disk.diskspace_stored),
      prettyCapacityUsed = prettifyBytes(disk.diskspace_used),
      prettyCapacityAvailable = prettifyBytes(disk.diskspace_available);

    const diskName = (disk && disk.name) || '';

    const percentages = getDiskUsages(
      prettyCapacityUsed,
      prettyDiskStored,
      prettyDiskTotal
    );

    const diskGrafanaPage = getGrafanaDiskPageLink(disk.server, disk.name);

    const tabs = [
      {
        selected: exactPath.endsWith('performance'),
        title: 'Performance',
        onClick: () => this.onTabClick('performance')
      }
    ];

    return (
      <ContentContainer>
        <PageHeader>
          <BreadcrumbStatusContainer>
            <Breadcrumb
              routes={[
                { name: 'SERVERS', link: '/servers' },
                {
                  name: diskServer && diskServer.name ? diskServer.name : '',
                  customName: diskServer ? (
                    <InlineBreadcrumbItem>
                      {diskServer.name}
                      <InlineStatus
                        status={diskServer.status}
                        text={diskServer.state && diskServer.state.join(', ')}
                      />
                    </InlineBreadcrumbItem>
                  ) : null,
                  link: `/servers/${(disk.server && disk.server.id) || ''}`
                },
                {
                  name: diskName || '',
                  customName: diskName ? (
                    <InlineBreadcrumbItem>
                      {diskName}
                      <InlineStatus
                        status={disk.status}
                        text={disk.state ? disk.state.join(', ') : null}
                      />
                    </InlineBreadcrumbItem>
                  ) : null
                }
              ]}
            />
          </BreadcrumbStatusContainer>
          {adminAccess && (
            <LinkOverlayIcon
              icon={monitoringIcon}
              link={diskGrafanaPage}
              overlayText="Advanced Monitoring"
            />
          )}
        </PageHeader>
        <div>
          {/* FIXME Patrick Ear : Used styled-components later */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ColorLegend wrapperStyle={{ paddingTop: '4px' }} />
              <div style={{ width: '500px' }}>
                <Tooltip
                  placement="top"
                  overlay={
                    <TooltipOverlay>{`Stored: ${percentages.actual_stored}% | Used: ${percentages.used}%`}</TooltipOverlay>
                  }
                >
                  <DiskWrapper>
                    <DiskGauge>
                      <CapacityUsed
                        width={percentages.actual_stored + '%'}
                        stored
                      />
                      <CapacityUsed
                        width={
                          percentages.used - percentages.actual_stored + '%'
                        }
                        used
                      />
                      <CapacityUsed width={100 - percentages.used + '%'} />
                    </DiskGauge>
                    <DiskLegendWrapper>
                      <DiskLegend>
                        {prettyDiskStored.value}
                        {prettyDiskStored.unit} stored -{' '}
                        {prettyCapacityUsed.value}
                        {prettyCapacityUsed.unit} used
                      </DiskLegend>
                      <DiskLegend>
                        {prettyCapacityAvailable.value}
                        {prettyCapacityAvailable.unit} Free -{' '}
                        {prettyDiskTotal.value}
                        {prettyDiskTotal.unit} Total
                      </DiskLegend>
                    </DiskLegendWrapper>
                  </DiskWrapper>
                </Tooltip>
              </div>
            </div>
          </div>
          <Tab items={tabs}>
            <Switch>
              <Route
                path={`${match.url}/performance`}
                render={() => <SingleDiskPerformance disk={disk} />}
              />
              <Redirect from={`${match.url}`} to={`${match.url}/performance`} />
            </Switch>
          </Tab>
        </div>
      </ContentContainer>
    );
  }
}

SingleDisk.propTypes = {
  disks: PropTypes.array,
  disk: PropTypes.object
};

SingleDisk.defaultProps = {
  disks: [],
  disk: {}
};

const mapStateToProps = (state, ownProps) => ({
  disks: selectAllDisksState(state),
  disk: selectDiskFromUrlSelector(state, ownProps),
  diskServer: selectDiskServerSelector(state, ownProps),
  adminAccess: state.get('overview').get('adminAccess')
});

export default withRouter(connect(mapStateToProps)(SingleDisk));
