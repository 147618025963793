import _ from 'lodash';
import moment from 'moment';
import config from '../config';

import { PATCH, PUT } from '../actions/overview/constants';
import * as CONSTANTS from '../constants.js';
import { ASC, DESC } from '../constants';

/** Scale a number of bytes to make it human-readable.
 *
 * @param {number} num - a number of bytes
 * @param {number} decimals - the number of digits to keep
 * @returns {Object.<number, string>} the readable value and its associated unit
 */
const prettifyBytes = (num, decimals = 2) => {
  const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const abs_num = Math.abs(num);

  if (abs_num < 1) {
    return { value: Number(Number(num).toFixed(decimals)), unit: UNITS[0] };
  }
  const nb_digits = Math.log10(abs_num);
  const exponent = Math.min(Math.floor(nb_digits / 3), UNITS.length - 1);
  const n = Number((num / 1000 ** exponent).toFixed(decimals));

  return { value: n, unit: UNITS[exponent] };
};

function commaSeparateByThousands(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getPrettyScale(value) {
  if (value < 1000000) {
    return { value: commaSeparateByThousands(value), unit: null };
  } else if (value < 1000000000) {
    return {
      value: commaSeparateByThousands((value / 1000000).toFixed(2)),
      unit: 'Million'
    };
  } else if (value < 1000000000000) {
    return {
      value: commaSeparateByThousands((value / 1000000000).toFixed(2)),
      unit: 'Billion'
    };
  } else {
    return {
      value: commaSeparateByThousands((value / 1000000000000).toFixed(2)),
      unit: 'Trillion'
    };
  }
}

function getMonitoringMainPageLink() {
  return config.api.host + config.globalDashboardLink;
}

function getOldSupPageLink() {
  return config.oldSuperVisorHost;
}

function getCloudMonitoringLink() {
  return config.cloudMonitoringLink;
}

function getOldSupRingPageLink(ringId) {
  return config.oldSuperVisorHost + ringId;
}

function getOldSupHardwareLink(nodeId) {
  return config.oldSuperVisorHost + 'hardware/' + nodeId;
}

function getMB(value) {
  let valueInMb = 0;

  if (value.unit === 'MB') {
    valueInMb = value.value;
  } else if (value.unit === 'GB') {
    valueInMb = value.value * 1000;
  } else if (value.unit === 'TB') {
    valueInMb = value.value * 1000 * 1000;
  } else if (value.unit === 'PB') {
    valueInMb = value.value * 1000 * 1000 * 1000;
  }

  return Math.floor(valueInMb);
}

function getPercentage(value, base) {
  if (_.isNil(value) || _.isNil(base)) {
    return 0;
  }

  return value <= 0 ? value : parseFloat((value / base) * 100).toFixed(2);
}

function getDiskUsages(used, stored, total) {
  let usedMb = getMB(used),
    storedMB = getMB(stored),
    totalMb = getMB(total);

  return {
    used: getPercentage(usedMb, totalMb),
    stored: getPercentage(storedMB, usedMb),
    actual_stored: getPercentage(storedMB, totalMb)
  };
}

function sortByPropertyOrder(data, key, order) {
  const newArray = [...data];

  if (!order) {
    order = ASC;
  }

  if (newArray && newArray.length != null) {
    return _.orderBy(
      newArray,
      [
        item =>
          typeof item[key] === 'string' ? item[key].toLowerCase() : item[key]
      ],
      order
    );
  } else {
    return [];
  }
}

function sortByRoles(data, order) {
  const newArray = [...data];
  newArray.sort(function(a, b) {
    return order === ASC
      ? a.roles.length - b.roles.length
      : b.roles.length - a.roles.length;
  });
  return newArray;
}

function compareHardware(hardwareA, hardwareB) {
  const typeA = hardwareA.server_type ? hardwareA.server_type : '';
  const typeB = hardwareB.server_type ? hardwareB.server_type : '';
  return typeA.toLowerCase().localeCompare(typeB.toLowerCase());
}

function sortByHardwareServerType(data, order) {
  const newArray = [...data];
  newArray.sort((a, b) => compareHardware(a.hardware, b.hardware));

  if (order === DESC) newArray.reverse();
  return newArray;
}

function compareZone(zoneA, zoneB) {
  return zoneA.name.toLowerCase().localeCompare(zoneB.name.toLowerCase());
}

function sortByZoneName(data, order) {
  const newArray = [...data];

  newArray.sort(function(a, b) {
    return order === ASC
      ? compareZone(a.zone, b.zone)
      : compareZone(b.zone, a.zone);
  });
  return newArray;
}

function sortByCapacity(data, order) {
  const newArray = [...data];

  const capacities = newArray.reduce((acc, server) => {
    if (server.hardware && server.hardware.disks) {
      acc[server.id] = server.hardware.disks.reduce((total, disk) => {
        return total + disk.capacity;
      }, 0);
    } else {
      acc[server.id] = 0;
    }
    return acc;
  }, {});

  newArray.sort(function(a, b) {
    return order === ASC
      ? capacities[a.id] - capacities[b.id]
      : capacities[b.id] - capacities[a.id];
  });
  return newArray;
}

function daysToYears(days) {
  if (days >= 365) {
    const years = Math.floor(days / 365);

    return {
      value: years,
      unit: years === 1 ? 'YEAR' : 'YEARS'
    };
  } else {
    return {
      value: days,
      unit: days <= 1 ? 'DAY' : 'DAYS'
    };
  }
}

/**
 * Check if any pattern from a given list is found at least once in a string.
 * Note that the search is case-insensitive.
 *
 * @param {Array} patterns
 * array of patterns to check
 * @param {string} text
 * string to match the patterns against
 */
function matchPatterns(patterns, text) {
  for (let pattern of patterns) {
    var regex = new RegExp(pattern, 'i');
    if (regex.test(text)) {
      return true;
    }
  }
  return false;
}

/**
 * Create a new array of element from specific key/array.
 *
 * TODO(gd): remove usage of this function, since `Array.from` is sufficient.
 *
 * @param {*} array
 * @param {*} key
 */
function createArrayFromKey(array, key) {
  return Array.from(array, member => member[key]);
}

function checkSpecialCharaters(entry) {
  const acceptOnlyThisCharacters = /^[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*$/;
  return acceptOnlyThisCharacters.test(entry);
}

function timeRange(now, value) {
  let timeRange;
  if (value === '1h') {
    timeRange = moment(now)
      .subtract(1, 'hours')
      .valueOf();
  } else if (value === '6h') {
    timeRange = moment(now)
      .subtract(6, 'hours')
      .valueOf();
  } else if (value === '24h') {
    timeRange = moment(now)
      .subtract(24, 'hours')
      .valueOf();
  } else if (value === '7d' || value === '1w') {
    timeRange = moment(now)
      .subtract(7, 'days')
      .valueOf();
  }
  return timeRange;
}

function userAccessibilityByAccessMethods(allowed_methods) {
  return (
    allowed_methods &&
    allowed_methods.indexOf(PATCH) !== -1 &&
    allowed_methods.indexOf(PUT) !== -1
  );
}

function getSortOrderObject(key, selectedSortOrder) {
  let defaultObj = { key: '', order: '' };
  let sortOrderObj = selectedSortOrder
    ? _.cloneDeep(selectedSortOrder)
    : defaultObj;
  if (selectedSortOrder && selectedSortOrder.key) {
    if (selectedSortOrder.key === key) {
      // Same key selected, just switch the order
      sortOrderObj.key = key;
      sortOrderObj.order = selectedSortOrder.order === DESC ? ASC : DESC;
    } else {
      // Change to the selected key with default ASC order
      sortOrderObj.key = key;
      sortOrderObj.order = ASC;
    }
  } else {
    // Change to the selected key with default ASC order
    sortOrderObj.key = key;
    sortOrderObj.order = ASC;
  }
  return sortOrderObj;
}

function getDateRangeS3(from = 0, length = 450) {
  const now = moment().valueOf();
  const start = now - from * 1000;
  const stop = start - length * 1000;
  return { start, stop };
}

/**
 * TODO Patrick Ear Add documentation & test !!!
 */
const compareByObjectValue = (key, order = ASC) => {
  return (x, y) => {
    if (!x.hasOwnProperty(key) || !y.hasOwnProperty(key)) {
      return 0;
    }
    const a = typeof x[key] === 'string' ? x[key].toLowerCase() : x[key];
    const b = typeof y[key] === 'string' ? y[key].toLowerCase() : y[key];

    let comparison = 0;
    if (a > b) {
      comparison = 1;
    }
    if (a < b) {
      comparison = -1;
    }
    return order === DESC ? comparison * -1 : comparison;
  };
};

/**
 * IPv4 and IPv6 address validity checks (using Regular Expressions...)
 */

/* IPv4 */

// Match            250-255 |  200-249  |  100-199  |  0-99
const IPv4Field = '(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])';
// Four fields, each separated by a dot.
const IPv4Full = `(?:(?:${IPv4Field}\\.){3}${IPv4Field})`;
const IPv4Regex = new RegExp(`^${IPv4Full}$`);

/** Check if the input string `str` is a valid IPv4
 *
 * Note that this function only accept IP addresses formatted as quad-dotted
 * decimal notation and that leader zeros are not allowed.
 *
 * Reference:
 * - Regular Expressions Cookbook, 2nd Edition, §8.16
 */
const isIPv4 = str => IPv4Regex.test(str);

/* IPv6 */

// Match a group of one to four hex digit.
const IPv6Field = '(?:[A-F0-9]{1,4})';
// Match an optional group of one to four hex digit.
const optIPv6Field = '(?:[A-F0-9]{0,4})';
// Standard form (non-compressed): eight fields, each separated by a colon.
// e.g: "2001:0db8:85a3:0000:0000:8a2e:0370:7334"
const IPv6Std = `(?:${IPv6Field}:){7}${IPv6Field}`;
// Mixed form (non-compressed): six fields, each separated by a colon + IPv4.
// e.g: "1762:0:0:0:0:B03:127.32.67.15"
const IPv6Mix = `(?:${IPv6Field}:){6}${IPv4Full}`;
// Compressed standard form.
// e.g: "::" or "::1" or "ffff::" or "2001:db8:85a3::8a2e:370:7334"
const IPv6StdComp =
  // Use lookahead to be sure that we have at most seven ":".
  `(?=(?:${optIPv6Field}:){0,7}${optIPv6Field}$)` +
  // At most one "::" with 0 to 7 fields (in total) placed around the "::".
  `(?:(?:${IPv6Field}:){1,7}|:)(?:(?::${IPv6Field}){1,7}|:)` +
  // Handle leading/trailing "::".
  `|(?:${IPv6Field}:){7}:|:(?::${IPv6Field}){7}`;
// Compressed mixed form.
// e.g: "::ffff:192.0.2.128" or "1762::B03:127.32.67.15"
const IPv6MixComp =
  '(?:' +
  // Use lookahead to be sure that we have at most six ":".
  `(?=(?:${optIPv6Field}:){0,6}${IPv4Full}$)` +
  // At most one "::" with 0 to 6 fields (in total) placed around the "::".
  `(?:(?:${IPv6Field}:){1,5}|:)(?:(?::${IPv6Field}){1,5}:|:)` +
  // Handle leading "::".
  `|::(?:${IPv6Field}:){5}` +
  // Full IPv4 after the compressed part.
  `)${IPv4Full}`;
// All alternatives: standard, standard compressed, mixed and mixed compressed.
const IPv6All = [IPv6Std, IPv6StdComp, IPv6Mix, IPv6MixComp]
  .map(regex => `(?:${regex})`)
  .join('|');
const IPv6Regex = new RegExp(`^(?:${IPv6All})$`, 'i');

/** Check if the input string `str` is a valid IPv6
 *
 * This function supports the following formats:
 * - the standard form: eight fields, each separated by a colon.
 * => "2001:0db8:85a3:0000:0000:8a2e:0370:7334"
 * - the mixed form: six fields, each separated by a colon + an IPv4
 * => "1762:0:0:0:0:B03:127.32.67.15"
 *
 * Compressed versions (one or more consecutive groups containing only zeros may
 * be replaced with a single empty group (::), this substitution can only be
 * applied once) of these formats are also supported:
 * - the standard form: "::", "::1", "1::", "2001:db8:85a3::8a2e:370:7334", …
 * - the mixed form: "::ffff:192.0.2.128", "1762::B03:127.32.67.15", …
 *
 * Note that we don't enforce the recommendations of the RFC 5952 (leading zeros
 * are allowed, uppercase is accepted, …)
 *
 * Reference:
 * - Regular Expressions Cookbook, 2nd Edition, §8.17
 */
const isIPv6 = str => IPv6Regex.test(str);

const validateIPaddress = str => isIPv4(str) || isIPv6(str);

/**
 * Basic "unique" filter, to use along with `Array.filter`.
 * @param {*} value
 * @param {Number} index
 * @param {Array} array
 */
const filterUnique = (value, index, array) => array.indexOf(value) === index;

/**
 * Extract username from the base64 hash
 */
function extractUsername(hash) {
  const decryptedHash = atob(hash);
  const splits = decryptedHash.split(':');

  return splits.length > 1 ? splits[0] : null;
}

// Return the list of supported protocols for the given volume.
function getProtocolsFromVolume(volume) {
  const protos = _.map(volume.volume_config_groups, 'protocol');
  protos.sort();
  return _.sortedUniq(protos);
}

/**
 * Check that a string defines a valid "host" for an NFS export.
 * @param {String} str
 */
const isValidNetworkIPDomain = str => {
  // If matches the IP format, then it must be IPv4, and can either be a single
  // IP address or a network defined with a netmask after the address.
  // A netmask can be provided as a single value (0 - 255), or as a full IPv4.
  const IPv4Netmask = `(?:${IPv4Full}|${IPv4Field})`;
  const IPv4NetworkRegex = new RegExp(`^${IPv4Full}/${IPv4Netmask}$`);

  // Our implementation also supports wildcards in IP addresses.
  const IPv4FieldOrWildcard = `(?:${IPv4Field}|\\*)`;
  const IPv4FullWithWildcard = `(?:${IPv4FieldOrWildcard}\\.){3}${IPv4FieldOrWildcard}`;
  const IPv4WildcardRegex = new RegExp(`^${IPv4FullWithWildcard}$`);

  // Domain names are defined of a series of domain labels separated by periods
  // RFC 952 states a domain name sould be a "text string [...] drawn from the
  // alphabet (A-Z), digits (0-9), minus sign (-), and period (.)". It is also
  // stated that domain names are case-insensitive.
  // RFC 1034 revises that statement, specifying a label should be at most 63
  // bytes long, and the total domain name at most 255 bytes long.
  // These two RFCs define a label as neither starting nor ending with a minus
  // sign, but RFC 1034 now allows a label to begin with a numeric character.
  // Our implementation allows one to use the "*" wildcard inside a label.

  const LabelChar = '[a-z0-9\\*]';
  const DomainLabel = `(?:${LabelChar}(?:(?:${LabelChar}|\\-){0,61}${LabelChar})?)`;
  const DomainName = `(?:${DomainLabel}\\.)*${DomainLabel}`;
  const DomainNameRegex = new RegExp(`^${DomainName}$`, 'i');
  // A complete domain name cannot be a simple number
  const DomainNameGuard = /^[0-9]+$/;
  return (
    IPv4WildcardRegex.test(str) ||
    IPv4NetworkRegex.test(str) ||
    (DomainNameRegex.test(str) &&
      str.length < 256 &&
      !DomainNameGuard.test(str))
  );
};

/**
 * Limit a string to a maximum number of characters, using an ellipsis as the
 * last kept character if the string had to be truncated.
 * @param {*} str the string to parse
 * @param {*} max_chars the maximum number of UTF-16 code units accepted in
 *  the string
 */
const shortenString = (str, max_chars = 10) => {
  if (!str || str.length <= max_chars) {
    return str;
  }
  return `${str.slice(0, max_chars - 1)}…`;
};

const filterServerRoles = array => {
  let serverRoles = [];
  if (!array || (array && array.length < 1)) {
    return [];
  }

  const roleName = {
    SPROXYD: 'Simple REST',
    STORAGE: 'Storage',
    NFS: 'SOFS/NFS',
    CIFS: 'SOFS/SMB',
    SOFS: 'SOFS/LocalFS',
    CDMI: 'SOFS/CDMI',
    SUPERVISOR: 'Supervisor',
    S3: 'S3',
    S3_MD: 'S3 MD',
    S3_WSB: 'S3 WSB'
  };

  serverRoles = array
    .filter(role => roleName[role])
    .map(role => {
      if (role === 'SOFS') {
        if (array.find(r => ['NFS', 'CIFS', 'CDMI'].includes(r))) {
          return null;
        }
      }
      return roleName[role];
    })
    .filter(role => role !== null)
    .sort();

  return serverRoles;
};

/**
 * Map of keys to look for in a Server's `server_type` in order to detect its
 * vendor.
 * Note that since `getBrandNameFromServerType` iterates over this map's keys,
 * order here will imply precedence in the image choice (first is stronger).
 */
const mapOfHardwareKeys = {
  DELL: ['DELL'],
  CISCO: ['CISCO'],
  HPE: ['HPE', 'HP'],
  VM: ['VIRTUAL MACHINE', 'VM']
};

/**
 * Extract a "brand" from a given Server type (free text).
 *
 * @param {string} server_type
 */
function getBrandNameFromServerType(server_type) {
  if (typeof server_type === 'string') {
    const serverTypesUppercase = server_type.toUpperCase();
    const serverTypesSplit = serverTypesUppercase.split(' ');

    if (serverTypesUppercase.includes('VIRTUAL MACHINE')) {
      return 'VM';
    }

    for (let brandName in mapOfHardwareKeys) {
      let brandPatterns = mapOfHardwareKeys[brandName];

      for (let i = 0; i < brandPatterns.length; ++i) {
        if (serverTypesSplit.includes(brandPatterns[i])) {
          return brandName;
        }
      }
    }
  }

  return '';
}

/**
 * Abstract differences between Volume.status and S3.cluster_health.
 */
function computeCSSClasses(status) {
  let statusClass, errorMsg;

  switch (status) {
    case CONSTANTS.STATUS_OK:
    case CONSTANTS.STATUS_NOMINAL:
      statusClass = '';
      break;
    case CONSTANTS.STATUS_DEGRADED:
    case CONSTANTS.STATUS_WARNING:
      statusClass = 'warning';
      break;
    case CONSTANTS.STATUS_UNAVAILABLE:
    case CONSTANTS.STATUS_CRITICAL:
      statusClass = 'critical';
      break;
    case CONSTANTS.STATUS_NOT_DEPLOYED:
      statusClass = 'not-deployed';
      errorMsg = 'Not deployed';
      break;
    case CONSTANTS.STATUS_ERROR:
      statusClass = 'error';
      errorMsg = 'Monitoring error';
      break;
    case CONSTANTS.STATUS_EMPTY:
    case CONSTANTS.STATUS_NO_CONNECTOR:
      statusClass = 'empty';
      break;
    default:
      statusClass = '';
      break;
  }

  return { errorMsg, statusClass };
}

export {
  getDiskUsages,
  getOldSupRingPageLink,
  getOldSupHardwareLink,
  getOldSupPageLink,
  getMonitoringMainPageLink,
  getCloudMonitoringLink,
  prettifyBytes,
  daysToYears,
  getPrettyScale,
  getPercentage,
  commaSeparateByThousands,
  validateIPaddress,
  createArrayFromKey,
  matchPatterns,
  checkSpecialCharaters,
  timeRange,
  userAccessibilityByAccessMethods,
  sortByRoles,
  sortByCapacity,
  sortByZoneName,
  compareByObjectValue,
  sortByPropertyOrder,
  getSortOrderObject,
  getDateRangeS3,
  isIPv4,
  isIPv6,
  filterUnique,
  extractUsername,
  getProtocolsFromVolume,
  isValidNetworkIPDomain,
  shortenString,
  filterServerRoles,
  getBrandNameFromServerType,
  computeCSSClasses,
  compareZone,
  compareHardware,
  sortByHardwareServerType
};
