import { takeLatest, put, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { unauthorized } from '../../actions/global';
import {
  REQUEST_FAILED,
  UNAUTHORIZED,
  HASH_KEY
} from '../../actions/global/constants';

export function* handleRequestError({ failedResponse: { status }, pathname }) {
  if (status === UNAUTHORIZED) {
    localStorage.removeItem(HASH_KEY);
    yield put(push('/login'));
    yield put(unauthorized(pathname));
  } else {
    yield put(push('/connection-error'));
  }
}

export default function* globalSaga() {
  yield all([takeLatest(REQUEST_FAILED, handleRequestError)]);
}
