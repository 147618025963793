import React from 'react';
import styled from 'styled-components';

import { Formik, Form } from 'formik';
import _ from 'lodash';

// formValidator might be needed later

const disabledColor = '#979797';
const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#2b65ad' : disabledColor)};
  border-radius: 3px;
  font-size: 18px;
  padding: 6px 20px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#205aa5' : disabledColor)};
  }
  margin: 0 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const FormField = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 6px 12px;
  height: 40px;
  background-color: #0e0b0b;
  border-radius: 0;
  color: #768290;
  border: 2px solid #222;
`;

const FormSelect = styled.select`
  width: 100%;
  background-color: #0e0b0b;
  border: 2px solid #222;
  display: inline-block;
  line-height: 1.5em;
  padding: 6px 12px;

  /* reset */

  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, #0e0b0b 50%),
    linear-gradient(135deg, #0e0b0b 50%, transparent 50%),
    linear-gradient(to right, #1c1a1a, #1c1a1a);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
`;

const FormOption = styled.option`
  width: 100%;
  padding: 6px 12px;
  height: 40px;
  background-color: #0e0b0b;
  border-radius: 0;
  color: #768290;
  border: 2px solid #222;
`;

const FormLabel = styled.label`
  width: 35%;
  font-family: Work Sans, sans-serif;
  font-weight: normal;
`;

const CreateServer = props => {
  const { zones, onSubmit } = props;

  const submit = values => {
    const newServer = {
      name: values.name,
      management_ip_address: values.managementIpAddress,
      zone: values.zone,
      server_type: values.serverType,
      description: values.description
    };
    onSubmit(newServer);
  };

  const validationSchema = {};

  const initialValues = {
    name: '',
    managementIpAddress: '',
    zone: (zones && zones[0]) || '',
    serverType: '',
    description: ''
  };

  const clickCancel = () => {
    props.parentServerMethodCloseModal();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {props => {
        const {
          values,
          errors,
          dirty,
          handleChange,
          handleSubmit,
          handleReset
        } = props;

        const canSubmit = dirty && _.isEmpty(errors);
        return (
          <Form onReset={handleReset} onSubmit={handleSubmit}>
            <FormContainer>
              <FormSection>
                <FormField>
                  <FormLabel>Name</FormLabel>
                  <FormInput
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Management IP Address</FormLabel>
                  <FormInput
                    name="managementIpAddress"
                    value={values.managementIpAddress}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Zone</FormLabel>
                  <FormSelect
                    name="zone"
                    value={values.zone}
                    onChange={handleChange}
                  >
                    {zones.map(zoneOption => (
                      <FormOption value={zoneOption} key={zoneOption}>
                        {zoneOption}
                      </FormOption>
                    ))}
                  </FormSelect>
                </FormField>
                <FormField>
                  <FormLabel>Type</FormLabel>
                  <FormInput
                    name="serverType"
                    value={values.serverType}
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <FormLabel>Description</FormLabel>
                  <FormInput
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </FormField>
              </FormSection>
              <FormActionSection>
                <Button type="button" onClick={() => clickCancel()}>
                  Cancel
                </Button>
                <Button canSubmit={canSubmit} disabled={!canSubmit}>
                  Save
                </Button>
              </FormActionSection>
            </FormContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateServer;
