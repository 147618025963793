import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import memoizeOne from 'memoize-one';
import { withRouter } from 'react-router-dom';
import HelpPopover from '../components/common/HelpPopover';
import BlockTitle from '../components/ui/BlockTitle';
import SingleServerDiskInfoTips from '../tips/SingleServerDiskInfoTips';
import {
  STATUS_OK,
  STATUS_WARNING,
  STATUS_CRITICAL,
  statusFilterOptions,
  ASC,
  KEY_NAME,
  KEY_STATUS,
  KEY_DISKSPACE_USED,
  itemsPerPageOptions,
} from '../constants';

import {
  DiskWrapper,
  DiskGauge,
  CapacityUsed,
  DiskLegendWrapper,
  DiskLegend,
  ColorLegend
} from './common/DiskInfo';

import { prettifyBytes, getDiskUsages } from '../utils/helpers';

import Pagination from './Pagination';
import { Table, THead, Th, TBody, Tr, Td, NoDataRow } from './common/Table';
import { getFilteredItems } from '../utils/dataTableHelpers';
import SelectBox from './common/Select';
import Tooltip from './Tooltip';
import { sortByPropertyOrder, getSortOrderObject } from '../utils/helpers';
import SortIcon from '../components/common/SortIcon';
import StatusDisplay from './StatusDisplay';
import { TooltipOverlay } from '../components/ui/AllPagesItems';

class SingleServerDisk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: statusFilterOptions,
      filter: statusFilterOptions[0],
      itemsPerPageOptions,
      itemsPerPageOption: itemsPerPageOptions[0],
      activePage: 1,
      selectedSort: {
        key: KEY_NAME,
        order: ASC
      }
    };
    this.onTableRowClick = this.onTableRowClick.bind(this);
  }

  setStateForNode(server) {
    let defaultStatusFilter = STATUS_OK;

    if (server.diskcount_status_critical > 0) {
      defaultStatusFilter = STATUS_CRITICAL;
    } else if (server.diskcount_status_warning > 0) {
      defaultStatusFilter = STATUS_WARNING;
    } else if (server.diskcount_status_ok > 0) {
      defaultStatusFilter = STATUS_OK;
    }

    this.setState({
      filter: {
        value: defaultStatusFilter,
        label: defaultStatusFilter
      }
    });
  }

  componentDidMount() {
    const { server } = this.props;
    if (!isEmpty(server)) {
      this.setStateForNode(server);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { server } = nextProps;
    if (!isEmpty(server)) {
      this.setStateForNode(server);
    }
  }

  onTableRowClick(disk) {
    this.props.history.push(
      `/servers/${this.props.server.id}/disks/${disk.id}`
    );
  }

  onFilterOptionChange(value) {
    this.setState({ filter: value, activePage: 1 });
  }

  onPaginationChange(page) {
    this.setState({ activePage: page });
  }

  sortRows = memoizeOne((disks, sortKey, sortOrder = ASC) => {
    return sortByPropertyOrder(disks, sortKey, sortOrder);
  });

  onSortRows(key) {
    const { selectedSort } = this.state;
    const sortKey = key ? key : KEY_NAME;
    this.setState({
      selectedSort: getSortOrderObject(sortKey, selectedSort)
    });
  }

  onItemsPerPageOptionChange(itemsPerPageOption) {
    this.setState({ itemsPerPageOption });
  }

  render() {
    const { serverDisks } = this.props;
    const {
      filter,
      filters,
      itemsPerPageOption,
      itemsPerPageOptions,
      activePage,
      selectedSort
    } = this.state;
    const pagerFirstItemIndex = activePage * itemsPerPageOption.value - itemsPerPageOption.value;
    const pagerLastItemIndex = activePage * itemsPerPageOption.value - 1;

    let filteredServerDisks = this.sortRows(
      getFilteredItems(serverDisks, filter.value),
      selectedSort.key,
      selectedSort.order
    );
    let tableRows;

    // FIXME Patrick Ear : This logic is only here because we do not have real datatable
    if (serverDisks.length === 0) {
      tableRows = <NoDataRow />;
    } else if (filteredServerDisks.length === 0) {
      tableRows = <NoDataRow text="No data selected." />;
    } else {
      tableRows = filteredServerDisks.map((singleDisk, index) => {
        if (index >= pagerFirstItemIndex && index <= pagerLastItemIndex) {
          return (
            <Tablerow
              onTableRowClick={this.onTableRowClick.bind(this)}
              key={`single_disk_${index}`}
              singleDisk={singleDisk}
            />
          );
        }
        return null;
      });
    }

    return (
      <div>
        <div>
          <BlockTitle>Disk Information</BlockTitle>
          <HelpPopover content={<SingleServerDiskInfoTips />} />
        </div>
        <SelectBox
          options={filters}
          name="disks-filter"
          type="state"
          value={filter}
          onChange={this.onFilterOptionChange.bind(this)}
        />
        <Table>
          <THead>
            <Tr>
              <Th>
                Name
                <SortIcon
                  onSort={() => this.onSortRows(KEY_NAME)}
                  selected={selectedSort.key === KEY_NAME}
                  selectedOrder={selectedSort.order}
                />
              </Th>
              <Th>
                Status
                <SortIcon
                  onSort={() => this.onSortRows(KEY_STATUS)}
                  selected={selectedSort.key === KEY_STATUS}
                  selectedOrder={selectedSort.order}
                />
              </Th>
              <Th>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <span>
                    Usage
                    <SortIcon
                      onSort={() => this.onSortRows(KEY_DISKSPACE_USED)}
                      selected={selectedSort.key === KEY_DISKSPACE_USED}
                      selectedOrder={selectedSort.order}
                    />
                  </span>
                  <ColorLegend />
                </div>
              </Th>
            </Tr>
          </THead>
          <TBody>{tableRows}</TBody>
        </Table>
        <SelectBox
          options={itemsPerPageOptions}
          name="single_server_disk_options"
          value={itemsPerPageOption}
          onChange={this.onItemsPerPageOptionChange.bind(this)}
        />
        {filteredServerDisks.length > itemsPerPageOption.value ? (
          <Pagination
            totalItems={filteredServerDisks.length}
            itemsPerPage={itemsPerPageOption.value}
            activePage={activePage}
            switchPage={this.onPaginationChange.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export class Tablerow extends Component {
  render() {
    const { singleDisk, onTableRowClick } = this.props;

    const prettyDiskTotal = prettifyBytes(singleDisk.diskspace_total),
      prettyDiskStored = prettifyBytes(singleDisk.diskspace_stored),
      prettyCapacityUsed = prettifyBytes(singleDisk.diskspace_used),
      prettyCapacityAvailable = prettifyBytes(singleDisk.diskspace_available);

    const percentages = getDiskUsages(
      prettyCapacityUsed,
      prettyDiskStored,
      prettyDiskTotal
    );

    let _diskName = singleDisk.name.substring(0, 20);
    return (
      <Tr onClick={() => onTableRowClick(singleDisk)} hoverable>
        <Td>
          <Tooltip
            placement="top"
            overlay={<TooltipOverlay>{singleDisk.name}</TooltipOverlay>}
          >
            <span>{_diskName}</span>
          </Tooltip>
        </Td>
        <Td>
          <StatusDisplay
            status={singleDisk.status}
            statusState={singleDisk.state}
          />
        </Td>
        <Td>
          <Tooltip
            placement="top"
            overlay={
              <TooltipOverlay>{`Stored: ${percentages.actual_stored}% | Used: ${percentages.used}%`}</TooltipOverlay>
            }
          >
            <DiskWrapper>
              <DiskGauge>
                <CapacityUsed width={percentages.actual_stored + '%'} stored />
                <CapacityUsed
                  width={percentages.used - percentages.actual_stored + '%'}
                  used
                />
                <CapacityUsed width={100 - percentages.used + '%'} />
              </DiskGauge>
              <DiskLegendWrapper>
                <DiskLegend>
                  {prettyDiskStored.value}
                  {prettyDiskStored.unit} stored - {prettyCapacityUsed.value}
                  {prettyCapacityUsed.unit} used
                </DiskLegend>
                <DiskLegend>
                  {prettyCapacityAvailable.value}
                  {prettyCapacityAvailable.unit} Free - {prettyDiskTotal.value}
                  {prettyDiskTotal.unit} Total
                </DiskLegend>
              </DiskLegendWrapper>
            </DiskWrapper>
          </Tooltip>
        </Td>
      </Tr>
    );
  }
}

export default withRouter(SingleServerDisk);
