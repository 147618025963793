import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import { createSelector } from 'reselect';

import Tooltip from '../components/Tooltip';
import {
  STATUS_WARNING,
  STATUS_CRITICAL,
  STATUS_NO_CONNECTOR,
  ASC,
  ACTION_SUCCESS,
  statusFilterOptions,
  KEY_NAME,
  KEY_STATUS,
  KEY_BACKEND_RING,
  KEY_DATA_REPLICATION_LONG_NAME
} from '../constants';

import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import SelectBox from '../components/common/Select';
import {
  Table,
  THead,
  Th,
  TBody,
  Tr,
  Td,
  NoDataRow
} from '../components/common/Table';
import { getFilteredItems } from '../utils/dataTableHelpers';
import {
  sortByPropertyOrder,
  getSortOrderObject,
  getProtocolsFromVolume
} from '../utils/helpers';
import {
  setVolumeCreationNoActionAction,
  setVolumeDeletionNoActionAction,
  selectAllVolumesState,
  getVolumeCreationState,
  getVolumeDeletionState
} from '../ducks/volumes';
import {
  PanelContainer,
  VerticalItemsContainer,
  PanelTools
} from '../components/ui/AllPagesItems';
import Breadcrumb from '../components/Breadcrumb';
import SortIcon from '../components/common/SortIcon';
import { TooltipOverlay } from '../components/ui/AllPagesItems';

class AllVolumes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: statusFilterOptions,
      filter: statusFilterOptions[0],
      searchString: '',
      itemsPerPage: 10,
      activePage: 1,
      selectedSort: {
        key: KEY_NAME,
        order: ASC
      }
    };

    this.onTableRowClick = this.onTableRowClick.bind(this);
  }

  componentDidMount() {
    const { volumesFilter, volumeDeletionState } = this.props;

    if (volumesFilter) {
      this.setState({
        filter: { value: volumesFilter, label: volumesFilter }
      });
    }

    if (volumeDeletionState === ACTION_SUCCESS) {
      this.notify('Volume successfully deleted');
      this.props.resetVolumeDeletion();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { volumeDeletionState } = nextProps;

    if (volumeDeletionState === ACTION_SUCCESS) {
      this.notify('Volume successfully deleted');
      this.props.resetVolumeDeletion();
    }
  }

  notify(message) {
    toast.success(<div style={{ textAlign: 'center' }}>{message}</div>, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true
    });
  }

  onSortRows(key) {
    let sortOrderObj = getSortOrderObject(key, this.state.selectedSort);
    this.setState({
      selectedSort: sortOrderObj
    });
  }

  sortRows = memoizeOne((volumes, sortKey, sortOrder = ASC) => {
    return sortByPropertyOrder(volumes, sortKey, sortOrder);
  });

  onTableRowClick(volume) {
    this.props.history.push('/storages/file/' + volume.id);
  }

  onFilterOptionChange(value) {
    this.setState({
      filter: value,
      activePage: 1,
      selectedSort: { key: KEY_NAME, order: ASC }
    });
  }

  searchRows = memoizeOne((volumes, key) => {
    return volumes.reduce((resultVolumes, volume) => {
      let volumeName = volume.name.toLowerCase();

      if (volumeName.indexOf(key) >= 0) {
        resultVolumes.push(volume);
      }

      return resultVolumes;
    }, []);
  });

  onSearchUpdate(term) {
    this.setState({ searchString: term.toLowerCase() });
  }

  onPaginationChange(page) {
    this.setState({ activePage: page });
  }

  render() {
    const _this = this;

    const {
      filter,
      filters,
      itemsPerPage,
      activePage,
      selectedSort,
      searchString
    } = this.state;

    const { adminAccess, volumes } = this.props;

    const pagerFirstItemIndex = activePage * itemsPerPage - itemsPerPage;
    const pagerLastItemIndex = activePage * itemsPerPage - 1;

    // FIXME Patrick Ear : This logic is only here because we do not have real datatable
    let filteredVolumes = this.sortRows(
      getFilteredItems(this.searchRows(volumes, searchString), filter.value),
      selectedSort.key,
      selectedSort.order
    );

    let tableRows = null;
    if (volumes.length === 0) {
      tableRows = <NoDataRow colSpan={5} text="No Volume" />;
    } else if (filteredVolumes.length === 0) {
      tableRows = <NoDataRow colSpan={5} text="No data selected." />;
    } else {
      tableRows = filteredVolumes.map(function(singleVolume, index) {
        const node = { singleVolume };

        if (index >= pagerFirstItemIndex && index <= pagerLastItemIndex) {
          return (
            <Tablerow
              onTableRowClick={_this.onTableRowClick.bind(_this)}
              key={`node_row_${index}`}
              {...node}
              volumes={volumes}
            />
          );
        }
        return null;
      });
    }

    return (
      <PanelContainer>
        <VerticalItemsContainer>
          <div
            style={{
              display: 'flex',
              marginBottom: '15px'
            }}
          >
            <Breadcrumb routes={[{ name: 'VOLUMES' }]} />
          </div>
          <PanelTools>
            <SearchBar
              onSearchChange={this.onSearchUpdate.bind(this)}
              placeholder="volume1"
              cyId="volumes-list-search-input"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SelectBox
                options={filters}
                name="volumes-filter"
                type="state"
                value={filter}
                onChange={this.onFilterOptionChange.bind(this)}
              />
              <div style={{ display: 'flex' }}>
                {adminAccess ? (
                  <LinkButton
                    to="/storages/file/create"
                    data-cy="link-create-volume"
                  >
                    <i
                      className="fa fa-plus"
                      style={{ verticalAlign: 'middle' }}
                    />
                  </LinkButton>
                ) : null}
              </div>
            </div>
          </PanelTools>
          <Table>
            <THead>
              <Tr>
                <Th>
                  Name
                  <SortIcon
                    onSort={() => this.onSortRows(KEY_NAME)}
                    selected={selectedSort.key === KEY_NAME}
                    selectedOrder={selectedSort.order}
                  />
                </Th>
                <Th>
                  Status
                  <SortIcon
                    onSort={() => this.onSortRows(KEY_STATUS)}
                    selected={selectedSort.key === KEY_STATUS}
                    selectedOrder={selectedSort.order}
                  />
                </Th>
                <Th>
                  Backend RINGs
                  <SortIcon
                    onSort={() => this.onSortRows(KEY_BACKEND_RING)}
                    selected={selectedSort.key === KEY_BACKEND_RING}
                    selectedOrder={selectedSort.order}
                  />
                </Th>
                <Th>
                  Data Protection Level
                  <SortIcon
                    onSort={() => this.onSortRows(KEY_DATA_REPLICATION_LONG_NAME)}
                    selected={selectedSort.key === KEY_DATA_REPLICATION_LONG_NAME}
                    selectedOrder={selectedSort.order}
                  />
                </Th>
                <Th>Supported Protocols</Th>
              </Tr>
            </THead>
            <TBody>{tableRows}</TBody>
          </Table>
          {filteredVolumes.length > itemsPerPage ? (
            <Pagination
              totalItems={filteredVolumes.length}
              itemsPerPage={itemsPerPage}
              activePage={activePage}
              switchPage={this.onPaginationChange.bind(this)}
            />
          ) : null}
        </VerticalItemsContainer>
      </PanelContainer>
    );
  }
}

const Tablerow = props => {
  const { singleVolume, onTableRowClick, volumes } = props;

  const style = { fontSize: '1.1em', color: '#21ac4d' };
  // FIXME Patrick Ear Need to externalize in new component
  switch (singleVolume.status) {
    case STATUS_WARNING:
      style.color = '#ff8f1c';
      break;
    case STATUS_CRITICAL:
      style.color = '#ef3340';
      break;
    case STATUS_NO_CONNECTOR:
      style.color = '#76828f';
      break;
    default:
      style.color = '#21ac4d';
  }

  let dataReplicationName =
    singleVolume && singleVolume.data_replication
      ? singleVolume.data_replication.long_name
      : '';

  const protocolName = {
    cifs: 'SMB',
    nfs: 'NFS',
    localfs: 'LocalFS',
    cdmi: 'CDMI'
  };

  let protocols = getProtocolsFromVolume(singleVolume).map(
    protocol => protocolName[protocol]
  );
  let protocolStr = protocols.join(', ');

  return (
    <Tr
      onClick={() => onTableRowClick(singleVolume, volumes)}
      hoverable
      data-cy={singleVolume.name}
    >
      <Td>
        <Tooltip
          placement="top"
          overlay={<TooltipOverlay>{singleVolume.name}</TooltipOverlay>}
        >
          <span>{singleVolume.name}</span>
        </Tooltip>
      </Td>
      <Td style={style}>
        <i className="fa fa-circle" />
      </Td>
      <Td>{singleVolume.backendRing}</Td>
      <Td>{dataReplicationName}</Td>
      <Td className="protocol">{protocolStr}</Td>
    </Tr>
  );
};

const LinkButton = styled(Link)`
  font-family: 'Work Sans', sans-serif;
  background-color: #2b65ad;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;
`;

Tablerow.propTypes = {
  singleVolume: PropTypes.object
};

Tablerow.defaultProps = {
  singleVolume: {}
};

AllVolumes.propTypes = {
  volumes: PropTypes.array,
  adminAccess: PropTypes.bool
};

AllVolumes.defaultProps = {
  volumes: [],
  adminAccess: false
};

const mapStateToProps = state => ({
  volumes: getVolumesWithRingType(state),
  adminAccess: state.get('overview').get('adminAccess'),
  volumeCreationState: getVolumeCreationState(state),
  volumeDeletionState: getVolumeDeletionState(state)
});

const getVolumesWithRingType = createSelector([selectAllVolumesState], volumes => {
  if (volumes) {
    return volumes.map(singleVolume => {
      return {
        ...singleVolume,
        backendRing: `${singleVolume.data_ring} - ${singleVolume.metadata_ring}`
      };
    });
  } else {
    return [];
  }
});

const mapDispatchToProps = dispatch => {
  return {
    resetVolumeCreation: () => dispatch(setVolumeCreationNoActionAction()),
    resetVolumeDeletion: () => dispatch(setVolumeDeletionNoActionAction())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllVolumes)
);
