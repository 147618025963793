import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';

import { getProtocolsFromVolume } from '../utils/helpers';
import BlockTitle from './ui/BlockTitle';
import HelpPopover from './common/HelpPopover';
import { InformationLine, InformationLabel } from './ui/Tab';
import VolumeInfosTips from '../tips/VolumeInfosTips';

class Information extends React.Component {
  protectionLevel(replication) {
    const { long_name, type, cos } = replication;
    switch (type) {
      case 'ARC':
        return (
          <div>
            {long_name}
            <span>{` (COS${cos} for small objects)`}</span>
          </div>
        );
      case 'COS':
        return <div>{long_name}</div>;
      default:
        return <div />;
    }
  }

  render() {
    const { volume, volumeProtectionLevel, adminAccess } = this.props;

    const protocolName = {
      cifs: 'SMB',
      nfs: 'NFS',
      localfs: 'LocalFS',
      cdmi: 'CDMI'
    };

    let dlm = volume.dlm && volume.dlm.enabled ? 'Enabled' : 'Disabled';
    if (volume.dlm && volume.dlm.rpc_addr_selector) {
      dlm = dlm + ` (${volume.dlm.rpc_addr_selector})`;
    }

    let types = getProtocolsFromVolume(volume)
      .filter(type => type != null)
      .map(protocol => protocolName[protocol]);

    return isEmpty(volume) ? (
      // FIXME Patrick Ear:  Change it SVG later
      <div style={{ textAlign: 'center' }}>Loading...</div>
    ) : (
      <div>
        <div style={{ marginBottom: '10px' }}>
          <BlockTitle>Volume Information</BlockTitle>
          <HelpPopover content={<VolumeInfosTips />} />
        </div>

        <InformationLine>
          <InformationLabel>Name</InformationLabel>
          {volume.name}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Description</InformationLabel>
          {volume.description}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Protocols</InformationLabel>
          {types.join(', ')}
        </InformationLine>
        <InformationLine>
          <InformationLabel>DATA RING</InformationLabel>
          {volume.data_ring}
        </InformationLine>
        <InformationLine>
          <InformationLabel>DATA RING Protection Level</InformationLabel>
          {this.protectionLevel((volume && volume.data_replication) || '')}
        </InformationLine>
        <InformationLine>
          <InformationLabel />
          {volumeProtectionLevel && volumeProtectionLevel.description
            ? volumeProtectionLevel.description
            : ''}
        </InformationLine>
        <InformationLine>
          <InformationLabel>META RING</InformationLabel>
          {volume.metadata_ring}
        </InformationLine>
        <InformationLine>
          <InformationLabel>META RING Protection Level</InformationLabel>
          {this.protectionLevel((volume && volume.metadata_replication) || '')}
        </InformationLine>
        <InformationLine>
          <InformationLabel>Device ID</InformationLabel>
          {volume.device_id}
        </InformationLine>
        <InformationLine>
          <InformationLabel>DLM</InformationLabel>
          {dlm}
        </InformationLine>
        {adminAccess ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() =>
                this.props.history.push(`/storages/file/${volume.id}/edit`)
              }
              data-cy="edit"
            >
              Edit
              <i
                className="fa fa-edit fa-lg"
                style={{ margin: '5px 0 5px 10px' }}
              />
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #2b65ad;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: #205aa5;
  }
`;

export default withRouter(Information);
