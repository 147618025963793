import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';

import HelpPopover from './common/HelpPopover';
import SingleStorageGlobalHealthTips from '../tips/SingleStorageGlobalHealthTips';
import { SectionHeader, SectionTitle } from './ui/AllPagesItems';
import { TableMessage } from './ui/Table';

export default class GlobalHealthDonut extends Component {
  render() {
    const { activeGlobalHealth } = this.props;
    let tableContent;

    const chartOptions = {
      responsive: false,
      maintainAspectRatio: true,
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontSize: 8,
          fontColor: '#000'
        }
      },
      layout: {
        padding: {
          left: 60,
          right: 0,
          top: 10,
          bottom: 15
        }
      },
      cutoutPercentage: 70
    };

    if (Object.keys(activeGlobalHealth).length) {
      const healthMapping = {
        true: { title: 'UP', color: 'rgba(126, 178, 108, 1)' },
        false: { title: 'DOWN', color: 'rgba(234, 184, 57, 1)' }
      };

      let dataInfo = { data: [], titles: [], colors: [] };
      activeGlobalHealth.reduce((acc, bucket) => {
        acc.data.push(bucket.doc_count);
        const { title, color } = healthMapping[bucket.key_as_string];
        acc.titles.push(title);
        acc.colors.push(color);
        return acc;
      }, dataInfo);

      const chartData = {
        responsive: false,
        datasets: [
          {
            data: dataInfo.data,
            backgroundColor: dataInfo.colors,
            borderWidth: 0,
            borderColor: '#201d1d'
          }
        ],
        labels: dataInfo.titles
      };

      tableContent = (
        <Doughnut
          data={chartData}
          width={170}
          height={170}
          options={chartOptions}
        />
      );
    } else if (activeGlobalHealth.length === 0) {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> No data
          available.
        </TableMessage>
      );
    } else {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" /> Loading
          content...
        </TableMessage>
      );
    }

    return (
      <div>
        <SectionHeader>
          <SectionTitle>Global Health</SectionTitle>
          <HelpPopover content={<SingleStorageGlobalHealthTips />} />
        </SectionHeader>
        {tableContent}
      </div>
    );
  }
}
