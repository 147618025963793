import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import {
  FormFieldRender,
  FormFieldTextAreaRender
} from '../components/common/FormFieldRender';
import { serverManagementFormValidation } from '../utils/formValidator';
import { ButtonOk, ButtonKo, ButtonM } from '../components/ui/Button';
import { Form, FormGroup, FormContent, FormActions } from '../components/ui/Form';

let AddServer = props => {
  const { handleSubmit, submitting, zones, singleNode } = props;

  function clickRemove() {
    props.parentServerMethod(singleNode.id);
  }

  function clickCancel() {
    props.parentServerMethodCloseModal();
  }

  return (
    <Form className="form-wrapper" onSubmit={handleSubmit}>
      <FormGroup className="form-group clearfix">
        <label>Name</label>
        <span>
          <b>{singleNode.name}</b>
        </span>
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <label>Management IP Address</label>
        <span>
          <b>{singleNode.management_ip_address}</b>
        </span>
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <label>Zone</label>
        <FormContent className="left form--content">
          <div className="select">
            <Field name="zone" component="select">
              <option value="">Select a zone...</option>
              {zones.map(zoneOption => (
                <option value={zoneOption} key={zoneOption}>
                  {zoneOption}
                </option>
              ))}
            </Field>
          </div>
        </FormContent>
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <Field
          name="server_type"
          type="text"
          component={FormFieldRender}
          label="Type"
        />
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <Field
          name="description"
          component={FormFieldTextAreaRender}
          row="5"
          label="Description"
        />
      </FormGroup>
      <FormActions className="clearfix form--actions form--actions--twoside clearfix">
        <ButtonKo
          type="button"
          className="left btn btn--ko"
          onClick={() => {
            clickRemove(singleNode.id);
          }}
        >
          <span>
            <i className="fa fa-trash" />
          </span>
        </ButtonKo>
        <ButtonOk
          type="submit"
          className="right btn btn--ok"
          disabled={submitting}
        >
          Save
        </ButtonOk>
        <ButtonM
          type="button"
          className="right btn btn--m"
          onClick={() => {
            clickCancel();
          }}
        >
          Cancel
        </ButtonM>
      </FormActions>
    </Form>
  );
};

export default reduxForm({
  form: 'EditServer',
  fields: ['zone', 'server_type', 'description'],
  validate: serverManagementFormValidation
})(AddServer);
