import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';

import { FormFieldRender } from '../components/common/FormFieldRender';
import Tooltip from '../components/Tooltip';
import { zoneManagementFormValidation } from '../utils/formValidator';
import { TooltipOverlay } from '../components/ui/AllPagesItems';
import { ButtonOk, ButtonKo, ButtonM } from '../components/ui/Button';
import { Form, FormGroup, FormActions } from '../components/ui/Form';

const EditZoneForm = props => {
  const { handleSubmit, submitting, zone, deleteZoneAvailability} = props;

  function clickRemove() {
    props.parentZoneMethod(zone.name);
  }

  function clickCancel() {
    props.parentZoneMethodCloseModal();
  }

  let deleteButton = null;

  if (zone.name !== 'default' && deleteZoneAvailability) {
    deleteButton = (
      <ButtonKo
        type="button"
        className="btn btn--ko"
        onClick={() => {
          clickRemove(zone.name);
        }}
      >
        <span>
          <i className="fa fa-trash" />
        </span>
      </ButtonKo>
    );
  } else if (zone.name === 'default' && deleteZoneAvailability) {
    deleteButton = (
      <Tooltip
        placement="top"
        overlay={
          <TooltipOverlay>You can not delete default zone</TooltipOverlay>
        }
      >
        <ButtonKo type="button" className="btn btn--ko" disabled>
          <span>
            <i className="fa fa-trash" />
          </span>
        </ButtonKo>
      </Tooltip>
    );
  } else {
    deleteButton = (
      <Tooltip
        placement="top"
        overlay={
          <TooltipOverlay>
            You can not delete a zone with linked servers
          </TooltipOverlay>
        }
      >
        <ButtonKo type="button" className="left btn btn--ko" disabled>
          <span>
            <i className="fa fa-trash" />
          </span>
        </ButtonKo>
      </Tooltip>
    );
  }

  return (
    <Form className="form-wrapper" onSubmit={handleSubmit}>
      <FormGroup className="form-group clearfix">
        <div>
          <label>Name</label>
          <span>
            <b>{zone.name}</b>
          </span>
        </div>
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <Field
          name="location"
          type="text"
          component={FormFieldRender}
          label="Location"
        />
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <Field
          name="description"
          type="text"
          component={FormFieldRender}
          label="Description"
        />
      </FormGroup>
      <FormActions className="clearfix form--actions form--actions--twoside">
        {deleteButton}
        <ButtonOk
          type="submit"
          className="right btn btn--ok"
          disabled={submitting}
        >
          Save
        </ButtonOk>
        <ButtonM
          type="button"
          className="right btn btn--m"
          onClick={() => {
            clickCancel();
          }}
        >
          Cancel
        </ButtonM>
      </FormActions>
    </Form>
  );
};

export default reduxForm({
  form: 'EditZone',
  fields: ['name', 'location', 'description'],
  validate: zoneManagementFormValidation
})(EditZoneForm);
