import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import {
  Table,
  THead,
  Th,
  TBody,
  Tr,
  Td,
  NoDataRow
} from '../components/common/Table';
import {
  activateGlobalOverlay,
  deactivateGlobalOverlay,
  setGlobalOverlayText,
  resetGlobalOverlayText
} from '../actions/overview';
import {
  setVolumeCreationNoActionAction,
  getVolumesConnectorsAction,
  getVolumeFromUrlSelector,
  getVolumesConnectorsSelector,
  getVolumeCreationState,
  createConfigGroupAction,
  getConfigGroupCreationState
} from '../ducks/volumes';
import {
  ContentContainer,
  Header,
  Breadcrumb as BreadcrumbStyle
} from '../components/ui/PageStructure';
import {
  ACTION_SUCCESS,
  ACTION_IN_PROGRESS,
  SFUSED_UPPER_BOUND_PORT,
  SFUSED_LOWER_BOUND_PORT
} from '../constants';
import HelpPopover from '../components/common/HelpPopover';
import { isValidNetworkIPDomain } from '../utils/helpers';
import SelectableConnectorsTable from '../components/SelectableConnectorsTable';
import Breadcrumb from '../components/Breadcrumb';
import SimpleTips from '../tips/SimpleTips';
import CGNFSSharesTips from '../tips/CGNFSSharesTips';
import { InlineBreadcrumbItem, InlineStatus } from '../components/InlineStatus';

// FIXME Patrick Ear: This component begin to be very complex
// Might need to think how to cut down the logic
class CreateConfigGroup extends Component {
  constructor() {
    super();

    this.state = {
      groupName: '',
      shareList: [],

      availConnectors: [],
      linkConnectors: [],

      availableConnectors: [],

      selectedAvailConnectors: [],
      selectedlinkConnectors: [],

      linkedConnectors: [],

      selectedConnector: null,
      selectedOption: null,
      sharePath: '',
      network: '',

      expertMode: '',
      anonuid: null,
      anongid: null,

      editShareIndex: -1,
      isLoaded: false,
      isDefaultValueSet: false
    };

    this.moveConnAvailToLinked = this.moveConnAvailToLinked.bind(this);
    this.moveConnLinkedToAvail = this.moveConnLinkedToAvail.bind(this);
  }

  componentDidMount() {
    const {
      volume,
      volumeCreationState,
      isAdmin,
      resetVolumeCreation,
      getVolumesConnectors,
      history
    } = this.props;

    if (!isAdmin) {
      if (!isEmpty(volume) && volume.id) {
        history.push(`/storages/file/${volume.id}/config-group`);
      } else {
        history.push('/storages/file/');
      }
    }

    if (volumeCreationState === ACTION_SUCCESS) {
      this.setState({ isNewVolume: true });
      this.notify();
      resetVolumeCreation();
    }
    getVolumesConnectors();
  }

  // Rare case where componentWillReceiveProps is useful
  componentWillReceiveProps(nextProps) {
    const {
      volume,
      volumesConnectors,
      cgCreationState,
      volumeCreationState,
      isAdmin,
      history
    } = nextProps;

    if (volumeCreationState === ACTION_SUCCESS) {
      this.notify();
      this.props.resetVolumeCreation();
    }

    if (cgCreationState === ACTION_SUCCESS) {
      // Redirect
      if (volume && volume.id) {
        history.push(`/storages/file/${volume.id}/config-group`);
      } else {
        history.push('/storages/file/');
      }
    }

    if (!isAdmin) {
      if (volume && volume.id) {
        history.push(`/storages/file/${volume.id}/config-group`);
      } else {
        history.push('/storages/file/');
      }
    }

    if (!this.state.isLoaded && volumesConnectors.length > 0) {
      this.setState({
        availableConnectors: volumesConnectors.filter(connector => {
          const connectorPortSplit =
            connector.address && connector.address.split(':');
          const connectorPort =
            (connectorPortSplit && connectorPortSplit[1]) || '-1';
          return (
            connector.detached === true &&
            connectorPort >= SFUSED_LOWER_BOUND_PORT &&
            connectorPort <= SFUSED_UPPER_BOUND_PORT
          );
        }),
        isLoaded: true
      });
    }
    this.setState({ volume });
  }

  componentWillUnmount() {
    this.props.deactivateGlobalOverlay();
    this.props.resetGlobalOverlayText();
  }

  notify() {
    toast.success(
      <div style={{ textAlign: 'center' }}>Volume successfully created</div>,
      {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true
      }
    );
  }

  moveConnAvailToLinked() {
    this.setState(prevState => {
      return {
        availableConnectors: prevState.availableConnectors.filter(
          ac => !prevState.selectedAvailConnectors.find(sac => sac.id === ac.id)
        ),
        linkConnectors: [
          ...prevState.linkConnectors,
          ...prevState.selectedAvailConnectors
        ],
        selectedAvailConnectors: []
      };
    });
  }

  moveConnLinkedToAvail() {
    this.setState(prevState => {
      return {
        linkConnectors: prevState.linkConnectors.filter(
          lc => !prevState.selectedlinkConnectors.find(slc => slc.id === lc.id)
        ),
        availableConnectors: [
          ...prevState.availableConnectors,
          ...prevState.selectedlinkConnectors
        ],
        selectedlinkConnectors: []
      };
    });
  }

  onSelectConnector(e) {
    let newState = { selectedConnector: e.target.value };
    const { volume } = this.props;
    this.setState(prevState => {
      /**
       * Set default value
       */
      if (!prevState.isDefaultValueSet || prevState.shareList.length === 0) {
        let groupName = `ConfigGroup1`;
        let sharePath = '/volume/';

        if (!isEmpty(volume)) {
          groupName = `${volume.name}-${e.target.value}`;
          sharePath = `/volume/${volume.name}`.toLowerCase();
        }

        newState = {
          ...prevState,
          ...newState,
          groupName: groupName,
          selectedOption: 'default',
          sharePath: sharePath,
          network: '*',
          isDefaultValueSet: true
        };
      }

      /**
       * Reset the shares
       */
      if (['localfs', 'cifs', 'cdmi'].includes(newState.selectedConnector)) {
        newState = {
          ...prevState,
          ...newState,
          shareList: [],
          selectedOption: null,
          sharePath: '',
          network: '',
          isDefaultValueSet: true
        };
      }
      return newState;
    });
  }

  onSelectOptions(e) {
    this.setState({ selectedOption: e.target.value });
  }

  onConnectorCheckboxChange(selectedConnectorsState) {
    return (connectors, checked) => {
      if (checked) {
        this.setState(prevState => {
          let newSelectedConnectorsState = [
            ...prevState[selectedConnectorsState],
            ...connectors
          ];
          newSelectedConnectorsState = newSelectedConnectorsState.filter(
            (value, idx, array) => {
              return idx === array.findIndex(v => value.id === v.id);
            }
          );

          return {
            [selectedConnectorsState]: newSelectedConnectorsState
          };
        });
      } else {
        this.setState(prevState => {
          const connectorIds = connectors.map(c => c.id);
          return {
            [selectedConnectorsState]: prevState[
              selectedConnectorsState
            ].filter(connector => !connectorIds.includes(connector.id))
          };
        });
      }
    };
  }

  submit(e) {
    e.preventDefault();

    const {
      shareList,
      linkConnectors,
      selectedConnector,
      groupName
    } = this.state;
    const { volume } = this.props;

    const shareResult = shareList.reduce((prev, currShare) => {
      let config = {};
      switch (currShare.option) {
        case 'default':
          config = { access: 'rw' };
          break;
        case 'allow_root_access':
          config = { no_root_squash: null };
          break;
        case 'map_all_users':
          config = {
            access: 'rw',
            all_squash: null,
            anonuid: parseInt(currShare.anonuid, 10),
            anongid: parseInt(currShare.anongid, 10)
          };
          break;
        case 'read_only':
          config = { access: 'ro' };
          break;
        case 'expert':
          config = currShare.expertMode;
          break;
        default:
          config = { access: 'rw' };
      }

      const shareWithSamePathIndex = prev.findIndex(
        share => share.path === currShare.sharePath
      );

      if (shareWithSamePathIndex === -1) {
        return [
          ...prev,
          {
            path: currShare.sharePath,
            hosts: currShare.network.split(',').map(ip => {
              return currShare.option === 'expert'
                ? {
                    name: ip,
                    options_raw: config
                  }
                : {
                    name: ip,
                    options: config
                  };
            })
          }
        ];
      } else {
        let shareWithSamePath = prev[shareWithSamePathIndex];
        shareWithSamePath.hosts = [
          ...shareWithSamePath.hosts,
          ...currShare.network.split(',').map(ip => {
            return currShare.option === 'expert'
              ? {
                  name: ip,
                  options_raw: config
                }
              : {
                  name: ip,
                  options: config
                };
          })
        ];

        prev[shareWithSamePathIndex] = shareWithSamePath;
        return prev;
      }
    }, []);

    if (volume) {
      let connectorsId = linkConnectors.map(c => c.id);
      let result = {
        connectors: connectorsId,
        protocol: selectedConnector,
        volume: volume.id,
        name: groupName
      };

      if (selectedConnector === 'nfs') {
        result.config = {
          shares: shareResult
        };
      }

      this.props.setGlobalOverlayText('Creating configuration group...');
      this.props.activateGlobalOverlay();
      this.props.createConfigGroup(result);
    }
  }

  removeShare(idx) {
    if (this.state.editShareIndex === -1) {
      this.setState(prevState => {
        return {
          shareList: prevState.shareList.filter(
            (share, shareIdx) => shareIdx !== idx
          )
        };
      });
    }
  }

  enterEditShareMode(idx) {
    this.setState(prevState => {
      const selectedShare = prevState.shareList[idx];

      const shareToEdit = {
        sharePath: selectedShare.sharePath,
        network: selectedShare.network,
        selectedOption: selectedShare.option,
        expertMode: selectedShare.expertMode,
        anonuid: selectedShare.anonuid,
        anongid: selectedShare.anongid
      };

      return {
        ...shareToEdit,
        editShareIndex: idx
      };
    });
  }

  exitEditShareMode() {
    this.setState({
      editShareIndex: -1,
      sharePath: '',
      network: '',
      expertMode: '',
      selectedOption: null,
      anonuid: null,
      anongid: null
    });
  }

  updateShare() {
    this.setState(prevState => {
      const newShareList = [...prevState.shareList];
      newShareList[prevState.editShareIndex] = {
        sharePath: prevState.sharePath,
        network: prevState.network,
        option: prevState.selectedOption,
        expertMode: prevState.expertMode,
        anonuid: prevState.anonuid,
        anongid: prevState.anongid
      };

      return {
        shareList: newShareList,
        editShareIndex: -1,
        sharePath: '',
        network: '',
        expertMode: '',
        selectedOption: null,
        anonuid: null,
        anongid: null
      };
    });
  }

  canUpdateShare() {
    const {
      shareList,
      sharePath,
      network,
      selectedOption,
      expertMode,
      anonuid,
      anongid,
      editShareIndex
    } = this.state;

    const duplicateCurrentShareIndex = shareList.findIndex(
      share => share.sharePath === sharePath && share.network === network
    );

    if (
      duplicateCurrentShareIndex !== -1 &&
      editShareIndex !== duplicateCurrentShareIndex
    ) {
      return false;
    }

    if (selectedOption === 'expert' && expertMode.length === 0) {
      return false;
    }

    if (
      selectedOption === 'map_all_users' &&
      (anonuid === null || anonuid === '' || anongid === null || anongid === '')
    ) {
      return false;
    }

    return (
      sharePath.length > 0 &&
      network.length > 0 &&
      selectedOption !== null &&
      !this.anonuidHasError() &&
      !this.anongidHasError() &&
      !this.clientNetworkIpHasError()
    );
  }

  canAddShare() {
    const {
      shareList,
      sharePath,
      network,
      selectedOption,
      expertMode,
      anonuid,
      anongid
    } = this.state;

    const duplicateCurrentShare = shareList.find(
      share => share.sharePath === sharePath && share.network === network
    );

    if (duplicateCurrentShare) {
      return false;
    }

    if (selectedOption === 'expert' && expertMode.length === 0) {
      return false;
    }

    if (
      selectedOption === 'map_all_users' &&
      (anonuid === null || anongid === null)
    ) {
      return false;
    }

    return (
      sharePath.length > 0 &&
      network.length > 0 &&
      selectedOption !== null &&
      !this.sharePathHasError() &&
      !this.clientNetworkIpHasError() &&
      !this.anonuidHasError() &&
      !this.anongidHasError()
    );
  }

  addShare(e) {
    e.preventDefault();
    this.setState(prevState => {
      return {
        shareList: [
          ...prevState.shareList,
          {
            sharePath: prevState.sharePath,
            network: prevState.network,
            option: prevState.selectedOption,
            expertMode: prevState.expertMode,
            anonuid: prevState.anonuid,
            anongid: prevState.anongid
          }
        ],
        sharePath: '',
        network: '',
        expertMode: '',
        selectedOption: null,
        anonuid: null,
        anongid: null
      };
    });
  }

  cgNameHasError() {
    const { groupName } = this.state;
    const noSpaceRegex = /^\S*$/;
    const alphaNumericWithAtLeastOneLetterRegex = /\w*[a-zA-Z_-]\w*/;

    if (groupName.length === 0) {
      return null;
    }

    if (!groupName.match(noSpaceRegex)) {
      return 'Configuration Group Name cannot contain space';
    } else if (groupName.length > 64) {
      return 'Configuration Group Name must contain at most 64 characters.';
    } else if (!groupName.match(alphaNumericWithAtLeastOneLetterRegex)) {
      return 'Configuration Group Name with number must have a character';
    } else {
      return null;
    }
  }

  sharePathHasError() {
    const { sharePath } = this.state;
    const regex = /^\/[\S]*$/;

    if (sharePath.length === 0) {
      return null;
    }

    if (!sharePath.match(regex)) {
      return 'Share Path should be a valid Unix path';
    } else {
      return null;
    }
  }

  clientNetworkIpHasError() {
    const { network } = this.state;

    if (network.length === 0) {
      return null;
    }

    if (!isValidNetworkIPDomain(network)) {
      return 'Client Network / IP should be a valid domain or IP';
    } else {
      return null;
    }
  }

  anonuidHasError() {
    const { anonuid } = this.state;
    const numberRegex = /^\d+$/;

    if (!anonuid || anonuid.length === 0) {
      return null;
    }

    if (!anonuid.match(numberRegex)) {
      return 'Anonuid must be a positive number';
    } else {
      return null;
    }
  }

  anongidHasError() {
    const { anongid } = this.state;
    const numberRegex = /^\d+$/;

    if (!anongid || anongid.length === 0) {
      return null;
    }

    if (!anongid.match(numberRegex)) {
      return 'Anongid must be a positive number';
    } else {
      return null;
    }
  }

  canSubmit() {
    if (
      this.state.selectedConnector === 'nfs' &&
      this.state.linkConnectors.length > 0 &&
      this.state.shareList.length === 0
    ) {
      return false;
    }

    return (
      this.state.groupName.length !== 0 &&
      this.state.selectedConnector !== null &&
      !this.cgNameHasError() &&
      !this.sharePathHasError() &&
      !this.clientNetworkIpHasError() &&
      !this.anonuidHasError() &&
      !this.anongidHasError()
    );
  }

  render() {
    const {
      shareList,
      editShareIndex,
      selectedAvailConnectors,
      selectedlinkConnectors
    } = this.state;
    const { volume } = this.props;
    const options = [
      { key: 'default', value: 'default', label: 'Default' },
      {
        key: 'allow_root_access',
        value: 'allow_root_access',
        label: 'Allow Root Access'
      },
      { key: 'map_all_users', value: 'map_all_users', label: 'Map All Users' },
      { key: 'read_only', value: 'read_only', label: 'Read-Only' },
      { key: 'expert', value: 'expert', label: 'Expert' }
    ];

    let tableRows = null;
    if (shareList.length === 0) {
      tableRows = <NoDataRow colSpan={4} text="No share created." />;
    } else {
      tableRows = shareList.map((row, idx) => {
        const optionName = options.find(option => option.key === row.option);

        const optionsDefinition = {
          default: 'rw',
          allow_root_access: 'no_root_squash',
          map_all_users: `rw,all_squash,anonuid=${row.anonuid},anongid=${row.anongid}`,
          read_only: 'ro',
          expert: row.expertMode
        };

        return (
          <Tr
            key={idx}
            style={{ color: editShareIndex === idx ? '#90caf9' : null }}
          >
            <Td fontSize="0.8em" color="white">
              {row.sharePath || ''}
            </Td>
            <Td fontSize="0.8em" color="white">
              {row.network || ''}
            </Td>
            <Td fontSize="0.8em" color="white">
              {`${optionName ? optionName.label : ''} (${
                optionsDefinition[row.option]
              })` || ''}
            </Td>
            <Td
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
              fontSize="0.8em"
              color="white"
            >
              <i
                className="fa fa-edit fa-2x hover-icon"
                style={{ marginRight: '10px' }}
                onClick={this.enterEditShareMode.bind(this, idx)}
              />
              <i
                className="fa fa-trash-o fa-2x hover-delete"
                style={{
                  cursor: editShareIndex === -1 ? null : 'not-allowed'
                }}
                onClick={this.removeShare.bind(this, idx)}
              />
            </Td>
          </Tr>
        );
      });
    }

    const connectorTypes = [
      { key: 'nfs', value: 'nfs', label: 'NFS' },
      { key: 'localfs', value: 'localfs', label: 'LOCALFS' },
      {
        key: 'cifs',
        value: 'cifs',
        label: 'SMB'
      },
      {
        key: 'cdmi',
        value: 'cdmi',
        label: 'CDMI'
      }
    ];

    const style = {
      bodyItems: {
        display: 'flex',
        flexDirection: 'column'
      },
      contentTitle: {
        textTransform: 'capitalize',
        fontSize: '16px',
        display: 'inline-block',
        paddingLeft: '10px',
        borderLeft: '2px solid #fff'
      },
      container: {
        marginBottom: '25px'
      },
      textField: { margin: '0 5px' },
      submitButtonContainer: {
        height: '40px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
      }
    };

    const inputLabelPropsStyle = {
      style: { fontSize: '1.5rem', fontWeight: '100' },
      shrink: true
    };
    const inputPropsStyle = { style: { fontSize: '1.5rem' } };
    const helperTextProps = { style: { fontSize: '1rem' } };

    const canAddShare = this.canAddShare();
    const canUpdateShare = this.canUpdateShare();
    const canSubmit = this.canSubmit();

    const cgNameHasError = this.cgNameHasError();
    const sharePathHasError = this.sharePathHasError();
    const clientNetworkIpHasError = this.clientNetworkIpHasError();
    const anonuidHasError = this.anonuidHasError();
    const anongidHasError = this.anongidHasError();

    const isCreatingConfigGroup =
      this.props.cgCreationState === ACTION_IN_PROGRESS;
    let isSwitchToAssoDisabled = selectedAvailConnectors.length === 0;
    let isSwitchToAvailDisabled = selectedlinkConnectors.length === 0;

    return (
      <ContentContainer>
        <Header>
          <BreadcrumbStyle>
            <Breadcrumb
              routes={[
                { name: 'VOLUMES', link: '/storages/file' },
                {
                  name: volume ? volume.name : '',
                  customName: volume ? (
                    <InlineBreadcrumbItem>
                      {volume.name}
                      <InlineStatus
                        status={volume.status}
                        text={volume.status}
                      />
                    </InlineBreadcrumbItem>
                  ) : null,
                  link:
                    volume && volume.id
                      ? `/storages/file/${volume.id}/config-group`
                      : '/storages/file/'
                },
                {
                  name: 'Create Configuration Group'
                }
              ]}
            />
          </BreadcrumbStyle>
        </Header>
        <BodyContainer>
          <div>
            <div style={style.bodyItems}>
              <Container>
                <ContentTitle>Configuration Group Properties</ContentTitle>
                <HelpPopover
                  content={
                    <SimpleTips
                      title="Configuration Group Properties"
                      content={`A configuration group requires a protocol (NFS/SMB/LocalFS/CDMI) and a name. Then, select one or more connectors associate to the configuration group.
    To associate or remove connectors, tick the connector box and click the right arrow to associate it to the configuration group, or click the left arrow to disassociate it.`}
                    />
                  }
                />
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      width:
                        this.state.selectedConnector !== null ? '66%' : '33%',
                      marginTop: '15px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginRight: '10px'
                      }}
                    >
                      <TextField
                        style={style.textField}
                        label="Configuration Group Protocol"
                        InputProps={inputPropsStyle}
                        InputLabelProps={inputLabelPropsStyle}
                        select
                        value={this.state.selectedConnector || ''}
                        onChange={this.onSelectConnector.bind(this)}
                        fullWidth
                        data-cy="select-config-group-protocol"
                      >
                        {connectorTypes.map(connectorType => {
                          return (
                            <MenuItem
                              key={connectorType.key}
                              value={connectorType.value}
                              data-cy={
                                'select-config-group-protocol-item_' +
                                connectorType.label
                              }
                            >
                              {connectorType.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                      {this.state.selectedConnector === 'cifs' ||
                      this.state.selectedConnector === 'cdmi' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            fontSize: '10px',
                            paddingLeft: '5px',
                            marginTop: '5px'
                          }}
                        >
                          {`⚠️ Manual configuration needed, Please follow the `}
                          <Link
                            to="/documentation"
                            style={{
                              fontWeight: 900,
                              textDecoration: 'underline'
                            }}
                          >
                            Operation Guide
                          </Link>
                        </div>
                      ) : null}
                    </div>
                    {this.state.selectedConnector !== null ? (
                      <TextField
                        style={style.textField}
                        label="Configuration Group Name"
                        placeholder="ConfigGroup1"
                        InputProps={inputPropsStyle}
                        InputLabelProps={inputLabelPropsStyle}
                        FormHelperTextProps={helperTextProps}
                        error={cgNameHasError !== null}
                        helperText={cgNameHasError}
                        value={this.state.groupName || ''}
                        onChange={e =>
                          this.setState({ groupName: e.target.value })
                        }
                        fullWidth
                        data-cy="textfield-config-group-name"
                      />
                    ) : null}
                  </div>
                </div>
              </Container>

              {this.state.selectedConnector === 'nfs' ? (
                <div>
                  <div style={style.container}>
                    <div style={style.contentTitle}>NFS Shares</div>
                    <HelpPopover content={<CGNFSSharesTips />} />
                    <div style={{ marginTop: '15px' }}>
                      <Table>
                        <THead>
                          <Tr>
                            <Th width="200" fontSize="0.9em">
                              Share Path
                            </Th>
                            <Th fontSize="0.9em">Client Network / IP</Th>
                            <Th fontSize="0.9em">Options</Th>
                            <Th width="70" />
                          </Tr>
                        </THead>
                        <TBody>{tableRows}</TBody>
                      </Table>
                    </div>
                    <div style={style.container}>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '10px',
                          marginTop: '15px'
                        }}
                      >
                        <TextField
                          style={style.textField}
                          label="Share Path"
                          placeholder={
                            '/volume/' +
                            (volume.name ? volume.name.toLowerCase() : '')
                          }
                          value={this.state.sharePath}
                          InputProps={inputPropsStyle}
                          InputLabelProps={inputLabelPropsStyle}
                          FormHelperTextProps={helperTextProps}
                          error={sharePathHasError !== null}
                          helperText={sharePathHasError}
                          onChange={e =>
                            this.setState({ sharePath: e.target.value })
                          }
                          fullWidth
                        />
                        <TextField
                          style={style.textField}
                          label="Client Network / IP"
                          placeholder="10.0.0.1"
                          value={this.state.network}
                          InputProps={inputPropsStyle}
                          InputLabelProps={inputLabelPropsStyle}
                          FormHelperTextProps={helperTextProps}
                          error={clientNetworkIpHasError !== null}
                          helperText={clientNetworkIpHasError}
                          onChange={e =>
                            this.setState({ network: e.target.value })
                          }
                          fullWidth
                        />
                        <TextField
                          style={style.textField}
                          label="Options"
                          placeholder="Select Option"
                          InputProps={inputPropsStyle}
                          InputLabelProps={inputLabelPropsStyle}
                          select
                          value={this.state.selectedOption || ''}
                          onChange={this.onSelectOptions.bind(this)}
                          fullWidth
                        >
                          {options.map(option => {
                            return (
                              <MenuItem key={option.key} value={option.value}>
                                {option.label}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <div
                          style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                          {editShareIndex === -1 ? (
                            <Button
                              canSubmit={canAddShare}
                              disabled={!canAddShare}
                              onClick={this.addShare.bind(this)}
                              style={{ padding: '7px 10px' }}
                              data-cy="nfs-shares-add-button"
                            >
                              Add
                              <span
                                className="fa fa-plus"
                                style={{
                                  marginLeft: '8px',
                                  verticalAlign: 'middle'
                                }}
                              />
                            </Button>
                          ) : (
                            <React.Fragment>
                              <CancelButton
                                style={{
                                  padding: '7px 10px',
                                  marginRight: '10px'
                                }}
                                onClick={this.exitEditShareMode.bind(this)}
                              >
                                Cancel
                              </CancelButton>
                              <Button
                                canSubmit={canUpdateShare}
                                disabled={!canUpdateShare}
                                onClick={this.updateShare.bind(this)}
                                style={{ padding: '7px 10px' }}
                              >
                                Update
                                <span
                                  className="fa fa-edit fa-lg"
                                  style={{
                                    marginLeft: '8px',
                                    verticalAlign: 'middle'
                                  }}
                                />
                              </Button>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      {this.state.selectedOption === 'expert' ? (
                        <div style={{ display: 'flex' }}>
                          <div style={{ minWidth: '400px', maxWidth: '600px' }}>
                            <TextField
                              style={style.textField}
                              label="Expert Options"
                              placeholder="ro,all_squash,anonuid=,anongid="
                              value={this.state.expertMode || ''}
                              InputProps={inputPropsStyle}
                              InputLabelProps={inputLabelPropsStyle}
                              onChange={e =>
                                this.setState({ expertMode: e.target.value })
                              }
                              fullWidth
                            />
                          </div>
                        </div>
                      ) : null}
                      {this.state.selectedOption === 'map_all_users' ? (
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              display: 'flex',
                              minWidth: '600px',
                              maxWidth: '1000px'
                            }}
                          >
                            <TextField
                              style={style.textField}
                              label="Anonuid"
                              placeholder="65534"
                              value={
                                this.state.anonuid === null
                                  ? ''
                                  : this.state.anonuid
                              }
                              InputProps={inputPropsStyle}
                              InputLabelProps={inputLabelPropsStyle}
                              FormHelperTextProps={helperTextProps}
                              error={anonuidHasError !== null}
                              helperText={anonuidHasError}
                              onChange={e => {
                                this.setState({ anonuid: e.target.value });
                              }}
                              fullWidth
                            />
                            <TextField
                              style={style.textField}
                              label="Anongid"
                              placeholder="65534"
                              value={
                                this.state.anongid == null
                                  ? ''
                                  : this.state.anongid
                              }
                              InputProps={inputPropsStyle}
                              InputLabelProps={inputLabelPropsStyle}
                              FormHelperTextProps={helperTextProps}
                              error={anongidHasError !== null}
                              helperText={anongidHasError}
                              onChange={e => {
                                this.setState({ anongid: e.target.value });
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.selectedConnector !== null ? (
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '47%' }}>
                    <div style={style.contentTitle}>Unused connectors</div>
                    <div style={{ marginTop: '15px' }}>
                      <SelectableConnectorsTable
                        style={style}
                        connectors={this.state.availableConnectors}
                        selectedConnectors={this.state.selectedAvailConnectors}
                        onCheckboxChange={this.onConnectorCheckboxChange(
                          'selectedAvailConnectors'
                        )}
                        canSelect={true}
                        restrictDeadConnectors
                        emptyTableText="No Associated Connectors"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  >
                    <Button
                      canSubmit={!isSwitchToAssoDisabled}
                      disabled={isSwitchToAssoDisabled}
                      style={{ padding: '7px 10px', marginBottom: '5px' }}
                      onClick={this.moveConnAvailToLinked}
                      data-cy="config-group-connector-add-button"
                    >
                      <span
                        className="fa fa-angle-right fa-lg"
                        style={{
                          verticalAlign: 'middle'
                        }}
                      />
                    </Button>
                    <Button
                      canSubmit={!isSwitchToAvailDisabled}
                      disabled={isSwitchToAvailDisabled}
                      style={{ padding: '7px 10px' }}
                      onClick={this.moveConnLinkedToAvail}
                    >
                      <span
                        className="fa fa-angle-left fa-lg"
                        style={{
                          verticalAlign: 'middle'
                        }}
                      />
                    </Button>
                  </div>
                  <div style={{ width: '47%' }}>
                    <div style={style.contentTitle}>Associated Connectors</div>
                    <div style={{ marginTop: '15px' }}>
                      <SelectableConnectorsTable
                        style={style}
                        connectors={this.state.linkConnectors}
                        selectedConnectors={this.state.selectedlinkConnectors}
                        canSelect={true}
                        onCheckboxChange={this.onConnectorCheckboxChange(
                          'selectedlinkConnectors'
                        )}
                        emptyTableText="No Associated Connectors"
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div style={style.submitButtonContainer}>
                <CancelButton
                  style={{ marginRight: '20px' }}
                  onClick={() => {
                    let path = '/storages/file/';
                    if (volume && volume.id) {
                      path = `/storages/file/${volume.id}/config-group`;
                    }
                    this.props.history.push(path);
                  }}
                >
                  Cancel
                </CancelButton>
                <Button
                  canSubmit={canSubmit && !isCreatingConfigGroup}
                  disabled={!canSubmit || isCreatingConfigGroup}
                  onClick={this.submit.bind(this)}
                  data-cy="config-group-create-button"
                >
                  Create
                  {isCreatingConfigGroup ? (
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa fa-cog fa-spin fa-fw"
                    />
                  ) : (
                    <i
                      style={{ marginLeft: '10px' }}
                      className="fa fa-plus-circle fa-lg"
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </BodyContainer>
      </ContentContainer>
    );
  }
}

const Container = styled.div`
  margin-bottom: 20px;
`;

const ContentTitle = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  display: inline-block;
  padding-left: 10px;
  border-left: 2px solid #fff;
`;

const disabledColor = '#979797';
const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#2b65ad' : disabledColor)};
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#205aa5' : disabledColor)};
  }
`;

const CancelButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #000;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: #3c3c3c;
  }
`;

const BodyContainer = styled.div`
  background-color: #201d1d;
  padding: 20px;
`;

const mapStateToProps = (state, ownProps) => ({
  volume: makeGetVolumeFromUrl(state, ownProps),
  isAdmin: state.get('overview').get('adminAccess'),
  volumesConnectors: getVolumesConnectorsSelector(state),
  cgCreationState: getConfigGroupCreationState(state),
  volumeCreationState: getVolumeCreationState(state)
});

const mapDispatchToProps = dispatch => {
  return {
    getVolumesConnectors: () => dispatch(getVolumesConnectorsAction()),
    createConfigGroup: newConfigGroup => {
      dispatch(createConfigGroupAction(newConfigGroup));
    },
    resetVolumeCreation: () => dispatch(setVolumeCreationNoActionAction()),
    activateGlobalOverlay: () => dispatch(activateGlobalOverlay()),
    deactivateGlobalOverlay: () => dispatch(deactivateGlobalOverlay()),
    setGlobalOverlayText: text => dispatch(setGlobalOverlayText(text)),
    resetGlobalOverlayText: () => dispatch(resetGlobalOverlayText())
  };
};

CreateConfigGroup.propTypes = {
  volume: Proptypes.object,
  isAdmin: Proptypes.bool,
  volumesConnectors: Proptypes.array
};

CreateConfigGroup.defaultProps = {
  volume: {},
  isAdmin: false,
  volumesConnectors: []
};

const makeGetVolumeFromUrl = createSelector(
  getVolumeFromUrlSelector,
  volume => volume
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateConfigGroup)
);
