import React, { Component } from 'react';
import { Table, THead, Th, TBody, Tr, Td } from './common/Table';
import { SectionHeader, SectionTitle } from './ui/AllPagesItems';
import { TableMessage } from './ui/Table';

export default class TopTenBucketOPSChartTable extends Component {
  render() {
    const { activeTopTenBucketOPS } = this.props;
    let tableContent;

    if (Object.keys(activeTopTenBucketOPS).length) {
      tableContent = (
        <Table>
          <THead>
            <Tr>
              <Th>Bucket</Th>
              <Th>Number of Operations</Th>
            </Tr>
          </THead>
          <TBody>
            {activeTopTenBucketOPS
              .sort((a, b) => b.doc_count - a.doc_count)
              .map(hit => (
                <Tr key={hit.key}>
                  <Td>{hit.key}</Td>
                  <Td>{hit.doc_count}</Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      );
    } else if (activeTopTenBucketOPS.length === 0) {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> No data
          available.
        </TableMessage>
      );
    } else {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" /> Loading
          content...
        </TableMessage>
      );
    }

    return (
      <div>
        <SectionHeader>
          <SectionTitle>Operations</SectionTitle>
        </SectionHeader>
        {tableContent}
      </div>
    );
  }
}
