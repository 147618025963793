import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Tooltip from './Tooltip';

import { Tr, Td } from './common/Table';
import { CircleStatus } from './CircleStatus';
import { TooltipOverlay } from './ui/AllPagesItems';
import { StatusText } from './ui/Table';

class ConfigGroupTableList extends Component {
  render() {
    const { singleConfigGroup } = this.props;

    let connectors = singleConfigGroup.connectors;
    const res = connectors
      ? connectors.reduce((obj, v) => {
          obj[v.status] = (obj[v.status] || 0) + 1;
          return obj;
        }, {})
      : {};

    const nbShares =
      singleConfigGroup &&
      singleConfigGroup.config &&
      singleConfigGroup.config.shares &&
      singleConfigGroup.config.shares.reduce((prevCount, currShare) => {
        return prevCount + currShare.hosts.length;
      }, 0);

    let protocol = '';
    switch (singleConfigGroup.protocol) {
      case 'cifs':
        protocol = 'SMB';
        break;
      case 'localfs':
        protocol = 'LocalFS';
        break;
      case 'nfs':
        protocol = 'NFS';
        break;
      case 'cdmi':
        protocol = 'CDMI';
        break;
      default:
        protocol = '';
    }

    return (
      <Tr
        onClick={() => {
          this.props.history.push(
            `/storages/file/${this.props.volume.id}/${singleConfigGroup.id}/edit`
          );
        }}
        hoverable
        data-cy={'config-group-list-item-' + singleConfigGroup.name}
      >
        <Td>
          <Tooltip
            placement="top"
            overlay={<TooltipOverlay>{singleConfigGroup.name}</TooltipOverlay>}
          >
            <span>{singleConfigGroup.name}</span>
          </Tooltip>
        </Td>
        <Td>
          <CircleStatus status={singleConfigGroup.status} />
        </Td>
        <Td>{protocol || ''}</Td>
        {/**
          FIXME Patrick Ear Can be greatly improve, it's very difficult to understand this all the possible case
          A reduce on the array should help to add the / correctly
          */}
        <Td>
          {res.OK ? (
            <span className="status_text OK">
              {res.OK} <span>OK</span>
            </span>
          ) : null}

          {res.OK && res.WARNING ? ' / ' : null}

          {res.WARNING ? (
            <StatusText className="status_text WARNING">
              {res.WARNING} <span>WARNING</span>
            </StatusText>
          ) : null}

          {res.WARNING && res.CRITICAL ? ' / ' : null}

          {res.OK && res.CRITICAL && !res.WARNING ? ' / ' : null}

          {res.CRITICAL ? (
            <StatusText className="status_text ERROR">
              {res.CRITICAL} <span>OFFLINE</span>
            </StatusText>
          ) : null}
        </Td>
        <Td>{nbShares || '0'}</Td>
      </Tr>
    );
  }
}

export default withRouter(ConfigGroupTableList);
