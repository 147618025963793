import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import AllDisks from './AllDisks';
import AllServers from './AllServers';
import AllVolumes from './AllVolumes';
import CreateVolume from './CreateVolume';
import CreateConfigGroup from './CreateConfigGroup';
import EditConfigGroup from './EditConfigGroup';
import RingStats from './RingStats';
import SingleDisk from './SingleDisk';
import SingleServer from './SingleServer';
import SingleS3Service from './SingleS3Service';
import SingleVolume from './SingleVolume';
import AllZones from './AllZones';

const RightPanelContainer = styled.div`
  width: calc(100% - 480px);
  height: calc(100vh - 56px);
  overflow-x: auto;
`;

export class RightPanel extends Component {
  render() {
    const { adminAccess } = this.props;
    return (
      <RightPanelContainer data-cy="right-panel">
        <Switch>
          {/* FIXME Patrick Ear :
            We will to have a real loading page to fix this issue.
            We should not hard code DATA
            */}
          <Redirect exact from="/" to="/rings/DATA" />
          <Redirect exact from="/rings" to="/rings/DATA" />
          <Route path="/rings/:id" component={RingStats} />
          <AdminRoute
            isAdmin={adminAccess}
            path="/storages/file/create"
            redirectRoute="/storages/file"
            component={CreateVolume}
          />
          <Route
            path="/storages/file/:id/:configGroupId/edit"
            component={EditConfigGroup}
          />
          <AdminRoute
            isAdmin={adminAccess}
            path="/storages/file/:id/config-group/create"
            redirectRoute="/storages/file/:id/config-group"
            component={CreateConfigGroup}
          />
          <Route path="/storages/file/:id" component={SingleVolume} />
          <Route path="/storages/file" component={AllVolumes} />
          <Route path={`/zones/new`} component={AllZones} />
          <Route path={`/zones`} component={AllZones} />
          <Route path="/servers/new" component={AllServers} />
          <Route
            path="/servers/:serverId/disks/:diskId"
            component={SingleDisk}
          />
          <Route path="/servers/:id" component={SingleServer} />
          <Route path="/servers" component={AllServers} />
          <Route path="/disks" component={AllDisks} />
          <Route path="/storages/object/:id" component={SingleS3Service} />
          <Redirect to="/rings/META" />
        </Switch>
      </RightPanelContainer>
    );
  }
}

class AdminRoute extends Component {
  render() {
    let { isAdmin, redirectRoute, component: Component, ...rest } = this.props;
    const arrayOfKeys = Object.keys(this.props.computedMatch.params);

    const redirectRouteComputed = arrayOfKeys.reduce((prev, key) => {
      return prev.replace(
        `/:${key}/`,
        `/${this.props.computedMatch.params[key]}/`
      );
    }, redirectRoute);

    if (isAdmin) {
      return <Route {...rest} render={props => <Component {...props} />} />;
    } else {
      // TODO Patrick Ear: We should dispatch error here
      return <Redirect to={redirectRouteComputed} />;
    }
  }
}

RightPanel.propTypes = {
  adminAccess: PropTypes.bool
};

RightPanel.defaultProps = {
  adminAccess: false
};

const mapStateToProps = state => ({
  adminAccess: state.get('overview').get('adminAccess')
});

export default withRouter(connect(mapStateToProps)(RightPanel));
