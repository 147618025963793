import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import { InlineBreadcrumbItem, InlineStatus } from '../components/InlineStatus';
import Breadcrumb from '../components/Breadcrumb';
import { Tab } from '../components/Tab';
import SingleS3ServiceTab from './SingleS3ServiceTab';
import SingleS3UsageTab from './SingleS3UsageTab';
import SingleS3PerformanceTab from './SingleS3PerformanceTab';
import {
  ContentContainer,
  PageHeader,
  BreadcrumbStatusContainer
} from '../components/ui/PageStructure';
import monitoringIcon from '../assets/images/nav/stats-icon.png';
import { LinkOverlayIcon } from '../components/ui/AllPagesItems';
import { selectS3FromUrl } from '../ducks/s3';

export class SingleStorageService extends Component {
  onTabClick(tab) {
    this.props.history.push(`${this.props.match.url}/${tab}`);
  }

  render() {
    const { match, storage, location } = this.props;
    const exactPath = location.pathname;
    const storageServiceGrafanaPage = '/stats/dashboard/db/s3-healthchecks';

    const tabs = [
      {
        selected: exactPath.endsWith('service'),
        title: 'Service',
        onClick: () => this.onTabClick('service')
      },
      {
        selected: exactPath.endsWith('usage'),
        title: 'Usage',
        onClick: () => this.onTabClick('usage')
      },
      {
        selected: exactPath.endsWith('performance'),
        title: 'Performance',
        onClick: () => this.onTabClick('performance')
      }
    ];

    return (
      <ContentContainer>
        <PageHeader>
          <BreadcrumbStatusContainer>
            <Breadcrumb
              routes={[
                { name: 'S3 SERVICE' },
                {
                  name: storage.endpoint || '',
                  customName:
                    storage && storage.endpoint ? (
                      <InlineBreadcrumbItem>
                        {storage.endpoint}
                        <InlineStatus
                          status={storage.cluster_health}
                          text={storage.cluster_health}
                        />
                      </InlineBreadcrumbItem>
                    ) : null
                }
              ]}
            />
          </BreadcrumbStatusContainer>
          <LinkOverlayIcon
            icon={monitoringIcon}
            link={storageServiceGrafanaPage}
            overlayText="Advanced Monitoring"
          />
        </PageHeader>
        <Tab items={tabs}>
          <Switch>
            <Route
              path={`${match.url}/service`}
              render={() => <SingleS3ServiceTab storage={storage} />}
            />
            <Route path={`${match.url}/usage`} component={SingleS3UsageTab} />
            <Route
              path={`${match.url}/performance`}
              component={SingleS3PerformanceTab}
            />
            <Redirect from={`${match.url}`} to={`${match.url}/service`} />
          </Switch>
        </Tab>
      </ContentContainer>
    );
  }
}

SingleStorageService.propTypes = {
  storage: PropTypes.object
};

SingleStorageService.defaultProps = {
  storage: {}
};

const makeGetS3FromUrl = createSelector(selectS3FromUrl, storage => storage);

const mapStateToProps = (state, ownProps) => ({
  storage: makeGetS3FromUrl(state, ownProps)
});

export default withRouter(connect(mapStateToProps)(SingleStorageService));
