import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import * as cssVariables from '../components/ui/variables';
import { Modal } from '../components/ui/Modal';
import { createServerAction } from '../ducks/servers';
import HelpPopover from '../components/common/HelpPopover';
import ServerAddTips from '../tips/ServerAddTips';

import AddServerForm from './ServerAddForm';

const CreateServerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CreateServerHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-bottom: 20px;
  justify-content: center;
`;

class AddServer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: this.props.location.pathname.endsWith('new'),
      destroyTooltipOnHide: true
    };

    this.alertAddServer = this.alertAddServer.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ showModal: nextProps.location.pathname.endsWith('new') });
  }

  handleCloseModal() {
    this.props.history.push('/servers');
  }

  alertAddServer(values) {
    this.props.addServer(values);
    this.handleCloseModal();
  }

  cancelEdit = () => {
    this.handleCloseModal();
  };

  render() {
    const modalStyle = {
      content: {
        position: 'absolute',
        top: '10%',
        left: '30%',
        border: '0 !important',
        backgroundColor: `${cssVariables.drkBg} !important`,
        overflow: 'auto',
        borderRadius: '6px',
        outline: 'none',
        padding: '40px 15px 25px 45px !important',
        width: '680px',
        maxHeight: '550px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    };

    return (
      <div style={{ display: 'flex' }}>
        <LinkButton to="/servers/new">
          <span className="fa fa-plus" style={{ verticalAlign: 'middle' }} />
        </LinkButton>
        <Modal
          isOpen={this.state.showModal}
          ariaHideApp={true}
          onRequestClose={this.handleCloseModal}
          style={modalStyle}
        >
          <CreateServerContainer>
            <CreateServerHeader>
              Create Server
              <HelpPopover content={<ServerAddTips />} />
            </CreateServerHeader>
            <AddServerForm
              onSubmit={this.alertAddServer}
              ips={this.props.dataIPAddressArray}
              zones={this.props.dataZonesArray}
              roles={this.props.dataRolesArray}
              parentServerMethodCloseModal={this.cancelEdit}
            />
          </CreateServerContainer>
        </Modal>
      </div>
    );
  }
}

const LinkButton = styled(Link)`
  font-family: 'Work Sans', sans-serif;
  background-color: #2b65ad;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;
`;

AddServer.propTypes = {
  addServer: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    addServer: server => {
      dispatch(createServerAction(server));
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AddServer));
