import React from 'react';
import { HelpPopupContainer, HelpPopupTitle, HelpPopupContent } from './Tips';

const RingNetCapacityTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`RING Net Capacity`}</HelpPopupTitle>
    <HelpPopupContent>
      {`The Net Used Capacity represents the amount of space occupied by data objects stored on the RING
    excluding any overhead due to copies (replicas or coding parts) or system data.`}
    </HelpPopupContent>
    <HelpPopupContent>
      {`The Estimated Net Available Capacity (otherwise known as "unique"
    or "usable" storage, as opposed to "raw") is computed dynamically every day.
    Relevant only if more than 5% of storage is "used".`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default RingNetCapacityTips;
