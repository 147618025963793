import styled from 'styled-components';

export default styled.h2`
  text-transform: capitalize;
  font-size: 18px;
  text-align: center;
  margin: 30px 0px 20px;
  display: table;
  white-space: nowrap;
  width: 100%;

  &:before,
  &:after {
    border-top: 1px solid #eee;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.5em;
    width: 45%;
  }

  &:before {
    right: 1.5%;
  }
  &:after {
    left: 1.5%;
  }
`;
