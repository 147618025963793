import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ol,
  Li
} from './Tips';

const RingForcastTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`RING Forecasts`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Forecasts are predictions based on existing data. Their accuracy requires a system
    to be in production for at least a few days. The Growth metric represents the estimated
    RING usage variation over a period of time (e.g.: +30 TB/month).`}
      <HelpPopupContent>
        {`The Days to 80% Used metric represents the number of remaining days,
based on the actual rate of data growth, before the 80% full capacity threshold is reached.
If this value is:`}
        <Ol>
          <Li>
            {`< 100 days, the RING global status changes to WARNING.
      A "LOW STORAGE" state is added, indicating that new disks or nodes are required.`}
          </Li>
          <Li>
            {`< 60 days, the RING global status changes to CRITICAL,
      and a "CRITICAL LOW STORAGE" state is added.
      Contacting Scality Customer Support is strongly advised.`}
          </Li>
          <Li>{`N/A, there is not enough data to compute this metric.`}</Li>
        </Ol>
      </HelpPopupContent>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default RingForcastTips;
