import styled from 'styled-components';
import * as cssVariables from './variables';

export const StorageContainer = styled.div`
  display: inline-block;
  margin-right: 2%;
  margin-bottom: 4%;
  width: 15%;
  background: ${cssVariables.panelSlctBg};
  border-radius: 0 0 4px 4px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-top: 2px solid
    ${props => {
      switch (props.status) {
        case 'warning':
          return cssVariables.orng;
        case 'empty':
        case 'not-deployed':
          return cssVariables.darkGrey;
        case 'critical':
        case 'error':
          return cssVariables.red;
        default:
          return cssVariables.grn;
      }
    }};

  &:before {
    content: '';
    width: 36px;
    height: 24px;
    position: absolute;
    top: 17px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 2%;
  }
`;

export const StorageContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 13px;
  font-weight: ${cssVariables.semiBold};
  color: ${cssVariables.serviceBlkTitlClr};
  margin: 47px auto 0;
  width: 88%;
`;

export const EllipsisTitle = styled.span`
  word-wrap: break-word;
`;

export const ErrorMessage = styled.span`
  color: ${cssVariables.red};
  font-size: 10px;
`;

export const Separator = styled.span`
  display: inline-block;
  height: 60px;
  border-right: 1px solid ${cssVariables.darkGrey};
  margin: 0 10px;
`;
