import { STATUS_ALL } from '../constants';

// FIXME Patrick Ear : Maybe the first step to the creation of DataTable Component
const getFilteredItems = (items, filterValue) => {
  return filterValue === STATUS_ALL
    ? [...items]
    : items.filter(item => filterValue === item.status);
};

export { getFilteredItems };
