const floatStringsValueChecker = (valueA, valueB) => {
  return parseFloat(valueB) - parseFloat(valueA)
}

const objectValueCheckerCreator = (checker) => {
  return (objectA, objectB) => checker(objectA.value, objectB.value)
}

export {
  floatStringsValueChecker,
  objectValueCheckerCreator
}