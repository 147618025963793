import normalizeUrl from 'normalize-url';

import { API_PREFIX } from '../../actions/overview/constants';
import config from '../../config';
import request from '../request';
import LDAPService from '../LDAPService';

let hash;

function setHash(authHash) {
  hash = authHash;
}

const LDAPRequest = (url, options = {}) => {
  const preparedUrl = normalizeUrl(`${config.api.host}${API_PREFIX}${url}`);
  let header = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json'
  };
  options.headers = header;

  return request(preparedUrl, options);
};

function callApi(url, options = {}) {
  const preparedUrl = normalizeUrl(`${config.api.host}${API_PREFIX}${url}`);

  let header = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json'
  };

  if (!LDAPService.getLDAP()) {
    header.Authorization = 'Basic ' + hash;
  }

  options.headers = header;

  return request(preparedUrl, options);
}

// Force 'Application/json' Content-Type header to post request
// For each post request in saga, just import callApiPost
function callApiPost(url, options = {}) {
  const preparedUrl = normalizeUrl(`${config.api.host}${API_PREFIX}${url}`);

  let header = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  if (!LDAPService.getLDAP()) {
    header.Authorization = 'Basic ' + hash;
  }

  options.headers = header;

  return request(preparedUrl, options);
}

export { callApi, callApiPost, setHash, LDAPRequest };
