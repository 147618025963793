import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as cssVariables from '../components/ui/variables';
import closeIconWhite from '../assets/images/global-icons/close-icon-white.png';
import { Modal } from '../components/ui/Modal';
import scalityLogo from '../assets/images/branding.svg';

const VersionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  .copy-rights {
    text-align: center;
    padding-bottom: 20px;
    font-size: 12px;
    color: ${cssVariables.infoIcnSlctClr};
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  .close-btn {
    width: 45px;
    height: 45px;
    background-image: url(${closeIconWhite});
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    right: 0;
    top: 0;
    :hover {
      cursor: pointer;
    }
  }
  .scality-logo {
    width: 260px;
  }
  .about-title {
    font-size: 24px;
    color: ${cssVariables.serviceBlkTitlClr};
    margin-bottom: 20px;
  }
  .content-label {
    font-size: 12px;
    color: ${cssVariables.infoIcnSlctClr};
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .content {
    font-size: 12px;
    color: ${cssVariables.infoIcnSlctClr};
    margin-bottom: 0;
    text-transform: uppercase;
  }
}
`;

export class AboutPopup extends React.Component {
  closePopup() {
    this.props.cancel();
  }

  render() {
    const { isAboutPopupOpen, version, release } = this.props;
    let brandImage;
    const URL = window.location.protocol + '//' + window.location.host;
    if (process.env.NODE_ENV === 'development') {
      brandImage = scalityLogo;
    } else {
      brandImage = URL + '/assets/images/branding.svg';
    }

    const style = {
      content: {
        position: 'absolute',
        top: '25%',
        left: '35%',
        border: '0 !important',
        backgroundColor: `${cssVariables.tableHoverBck} !important`,
        overflow: 'auto',
        borderRadius: '6px',
        outline: 'none',
        padding: '50px !important',
        width: '680px',
        height: '300px',
        display: 'flex'
      }
    };

    const d = new Date();
    const year = d.getFullYear();

    return (
      <Modal
        isOpen={isAboutPopupOpen}
        onRequestClose={() => this.closePopup()}
        style={style}
      >
        <VersionContainer>
          <ContentWrapper>
            <div onClick={() => this.closePopup()} className="close-btn" />

            <div className="col-md-7">
              <img src={brandImage} alt="Scality" className="scality-logo" />
            </div>
            <div className="col-md-5">
              <h5 className="about-title">Scality Supervisor</h5>
              <div className="row">
                <div className="col-md-4">
                  <p className="content-label">Version</p>
                </div>
                <div className="col-md-8">
                  <p className="content">{version}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <p className="content-label">Release</p>
                </div>
                <div className="col-md-8">
                  <p className="content">{release}</p>
                </div>
              </div>
            </div>
          </ContentWrapper>
          <p className="copy-rights">
            Copyright 2009-{year} Scality. All Rights Reserved.
          </p>
        </VersionContainer>
      </Modal>
    );
  }
}

AboutPopup.propTypes = {
  version: PropTypes.string,
  release: PropTypes.string
};

AboutPopup.defaultProps = {
  version: '<unknown>',
  release: '<unknown>'
};

const mapStateToProps = state => ({
  version: state.get('overview').get('version'),
  release: state.get('overview').get('release')
});

export default connect(mapStateToProps)(AboutPopup);
