import React from 'react';
import bowser from 'bowser';
import styled from 'styled-components';
import { Modal } from '../components/ui/Modal';

const OKButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #000;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px;
  outline: none;

  &:hover {
    background-color: #3c3c3c;
  }
`;

class BrowserWarning extends React.Component {
  state = {
    displayWarning: false
  };

  componentDidMount() {
    const browser = bowser.getParser(window.navigator.userAgent);
    const browserInfo = browser.getBrowser();

    if (
      browserInfo.name !== 'Chrome' &&
      browserInfo.name !== 'Firefox' &&
      !JSON.parse(localStorage.getItem('hideWarning'))
    ) {
      this.setState({ displayWarning: true });
    }
  }

  closeModal = () => {
    this.setState({ displayWarning: false });
    localStorage.setItem('hideWarning', true);
  };

  render() {
    return (
      <Modal
        isOpen={this.state.displayWarning}
        onRequestClose={this.closeModal}
        ariaHideApp={false}
        style={{
          content: {
            position: 'absolute',
            top: '35%',
            left: '35%',
            width: '30%',
            height: '230px'
          }
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <i className="fa fa-exclamation-triangle fa-3x" />
        </div>
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          Browser not supported, Scality Supervisor optimised for Firefox and
          Chrome
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px'
          }}
        >
          <OKButton onClick={this.closeModal}>OK</OKButton>
        </div>
      </Modal>
    );
  }
}

export default BrowserWarning;
