import { validateIPaddress, checkSpecialCharaters } from './helpers';
import { KEY_NAME } from '../constants';

export const s3ManagementFormValidation = values => {
  const errors = {};

  const FQDNregex = /^((?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i;
  // eslint-disable-next-line
  const URLregex = /^((http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)$/i;

  let endpoint = values.get('endpoint');
  if (!endpoint) {
    errors.endpoint = 'Endpoint is required';
  } else if (endpoint.length > 255) {
    errors.endpoint = 'Endpoint max length should be 255';
  } else if (!(FQDNregex.test(endpoint) || validateIPaddress(endpoint))) {
    errors.endpoint = 'Invalid FQDN or IP';
  }

  if (!values.get('description')) {
    //errors.description = 'Description is required';
  } else if (values.get('description').length > 100) {
    errors.description = 'Description max length should be 100';
  }

  if (!values.get('accountUrl')) {
    errors.accountUrl = 'S3 Console URL is required';
  } else if (values.get('accountUrl').length > 1000) {
    errors.accountUrl = 'S3 Console URL max length should be 1000';
  } else if (!URLregex.exec(values.get('accountUrl'))) {
    errors.accountUrl = 'Invalid URL';
  }

  if (!values.get('browserUrl')) {
    errors.browserUrl = 'S3 Browser URL is required';
  } else if (values.get('browserUrl').length > 1000) {
    errors.browserUrl = 'S3 Browser URL max length should be 1000';
  } else if (!URLregex.exec(values.get('browserUrl'))) {
    errors.browserUrl = 'Invalid URL';
  }

  return errors;
};

export const serverManagementFormValidation = (values, props) => {
  const errors = {};

  const { ips } = props;
  const nameValue = values.get(KEY_NAME);
  const IPValue = values.get('management_ip_address');

  if (!nameValue) {
    errors.name = 'Required';
  } else if (!checkSpecialCharaters(nameValue)) {
    errors.name = 'Special characters and spaces not allowed';
  } else if (nameValue.length > 128) {
    errors.name = 'Must be 128 characters or less';
  }

  if (!IPValue) {
    errors.management_ip_address = 'Required';
  } else if (!validateIPaddress(IPValue)) {
    errors.management_ip_address = 'Must be a valid IP';
  } else if (ips.indexOf(IPValue) !== -1) {
    errors.management_ip_address = 'This IP is already used';
  }

  if (!values.get('description')) {
  } else if (values.get('description').length > 256) {
    errors.description = 'Must be 256 characters or less';
  }

  return errors;
};

export const zoneManagementFormValidation = values => {
  const errors = {};
  if (!values.get(KEY_NAME)) {
    errors.name = 'Required';
  } else if (values.get(KEY_NAME).length > 12) {
    errors.name = 'Must be 12 characters or less';
  }
  if (!values.get('location')) {
  } else if (values.get('location').length > 128) {
    errors.location = 'Must be 128 characters or less';
  }
  if (!values.get('description')) {
  } else if (values.get('description').length > 128) {
    errors.description = 'Must be 128 characters or less';
  }
  return errors;
};
