import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import cidrRegex from 'cidr-regex';
import isEmpty from 'lodash/isEmpty';

import { InformationLine, InformationLabel } from '../components/ui/Tab';
import DLMTips from '../tips/DLMTips';
import SimpleTips from '../tips/SimpleTips';
import {
  activateGlobalOverlay,
  deactivateGlobalOverlay,
  setGlobalOverlayText,
  resetGlobalOverlayText
} from '../actions/overview';
import {
  createVolumeAction,
  setVolumeCreationNoActionAction,
  selectAllVolumesState,
  getVolumeCreationState,
  getVolumeCreationError,
  getNewVolumeId
} from '../ducks/volumes';
import {
  selectRingsList
} from '../ducks/rings';
import {
  ContentContainer,
  Header,
  Breadcrumb as BreadcrumbStyle
} from '../components/ui/PageStructure';
import { ACTION_SUCCESS, ACTION_IN_PROGRESS, ASC } from '../constants';
import PieChart from '../components/PieChart';
import {
  getPercentage,
  prettifyBytes,
  compareByObjectValue
} from '../utils/helpers';
import HelpPopover from '../components/common/HelpPopover';
import Breadcrumb from '../components/Breadcrumb';
import VolumeCreateTips from '../tips/VolumeCreateTips';

class CreateVolume extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: 'volume1',
      description: '',
      deviceID: '',
      pairOfRings: [],
      selectedPair: '',
      selectedProtectionIdx: -1,
      pairOfProtections: null,
      isLoaded: false,
      isDLMEnabled: false,
      isDLMAddressAuto: true,
      DLMAddress: ''
    };

    this.onSelectProtection = this.onSelectProtection.bind(this);
  }

  componentDidMount() {
    const { rings = [] } = this.props;

    if (!this.state.isLoaded && rings.length > 0) {
      const {
        pairOfRings,
        selectedPair,
        selectedProtectionIdx,
        pairOfProtections
      } = this.genInitialState(rings);

      this.setState({
        pairOfRings,
        selectedPair,
        selectedProtectionIdx,
        pairOfProtections,
        isLoaded: true
      });
    }
  }

  // FIXME Patrick Ear : Should think how to move elsewhere
  // componentWillReceiveProps will be deprecated soon
  componentWillReceiveProps(nextProps) {
    const { volumeCreationState, newVolumeId, rings = [] } = nextProps;

    if (
      rings !== this.props.rings &&
      !this.state.isLoaded &&
      rings.length > 0
    ) {
      const {
        pairOfRings,
        selectedPair,
        selectedProtectionIdx,
        pairOfProtections
      } = this.genInitialState(rings);

      this.setState({
        pairOfRings,
        selectedPair,
        selectedProtectionIdx,
        pairOfProtections,
        isLoaded: true
      });
    }

    // FIXME Patrick Ear : This logic should be remove when the link
    // between redux and react-router is fixed
    if (volumeCreationState === ACTION_SUCCESS) {
      if (newVolumeId) {
        this.props.history.push(
          `/storages/file/${newVolumeId}/config-group/create`
        );
      } else {
        this.props.history.push('/storages/file');
      }
    }
  }

  componentWillUnmount() {
    this.props.deactivateGlobalOverlay();
    this.props.resetGlobalOverlayText();
  }

  genInitialState(rings) {
    const pairOfRings = this.createPairOfRing(rings);
    let selectedPair = '';
    let selectedProtectionIdx = -1;
    let pairOfProtections = null;
    if (pairOfRings.length === 1) {
      selectedPair = pairOfRings[0].key;
      const newPairOfRing = pairOfRings.find(pair => pair.key === selectedPair);
      if (
        newPairOfRing &&
        newPairOfRing.data &&
        newPairOfRing.data.protections
      ) {
        pairOfProtections = this.createPairOfProtection(
          newPairOfRing.data.protections
        );
        if (pairOfProtections.length === 1) {
          selectedProtectionIdx = pairOfProtections[0].value;
        }
      }
    }

    return {
      pairOfRings,
      selectedPair,
      selectedProtectionIdx,
      pairOfProtections
    };
  }

  createPairOfRing(rings) {
    const metaRings = rings.filter(ring => ring.type === 'META');
    const dataRings = rings.filter(ring => ring.type === 'DATA');

    return dataRings
      .reduce((prev, currData) => {
        const pair = metaRings.map(currMeta => {
          return {
            meta: currMeta,
            data: currData,
            key: `${currData.id}:${currMeta.id}`,
            label: `${currData.name} + ${currMeta.name}`
          };
        });

        return [...prev, ...pair];
      }, [])
      .sort(compareByObjectValue('label', ASC));
  }

  createPairOfProtection(protections) {
    if (protections) {
      let protectionObject = protections.map((protection, idx) => {
        /**
         * The protection id will help to sort and define if it's a FastTrack
         * If the protectionId < 100 -> FastTrack
         * otherwise it's a Custom
         */
        return {
          key: protection.name,
          label:
            protection.id > 100
              ? `⚠️  ${protection.long_name}`
              : protection.long_name,
          id: protection.id,
          value: idx
        };
      });

      return protectionObject;
    } else {
      return [];
    }
  }

  ringChanged(e) {
    const { pairOfRings } = this.state;
    const selectedPair = e.target.value;
    if (pairOfRings) {
      const newPairOfRing = pairOfRings.find(pair => pair.key === selectedPair);
      if (
        newPairOfRing &&
        newPairOfRing.data &&
        newPairOfRing.data.protections
      ) {
        const protections = this.createPairOfProtection(
          newPairOfRing.data.protections
        );

        this.setState(prevState => {
          const newState = {
            selectedPair,
            pairOfProtections: protections
          };

          if (prevState.selectedPair !== selectedPair) {
            newState.selectedProtectionIdx = '';
          }
          return newState;
        });
      }
    }
  }

  onSelectProtection(e) {
    this.setState({ selectedProtectionIdx: e.target.value });
  }

  canSubmit() {
    const {
      name,
      selectedPair,
      selectedProtectionIdx,
      isDLMAddressAuto,
      DLMAddress
    } = this.state;

    const isVolumeDLMValid = isDLMAddressAuto
      ? true
      : DLMAddress.length && !this.DLMAddressHasError();

    return (
      name.length !== 0 &&
      selectedPair !== '' &&
      selectedProtectionIdx !== -1 &&
      !this.volumeNameHasError() &&
      !this.volumeDescriptionHasError() &&
      !this.volumeDeviceIDHasError() &&
      (this.state.isDLMEnabled === false || isVolumeDLMValid)
    );
  }

  submit(e) {
    e.preventDefault();

    if (this.canSubmit()) {
      const {
        pairOfRings,
        selectedPair,
        name,
        description,
        deviceID,
        selectedProtectionIdx,
        isDLMEnabled,
        isDLMAddressAuto,
        DLMAddress
      } = this.state;

      const pairOfRing = pairOfRings.find(pair => pair.key === selectedPair);

      const protection = pairOfRing.data.protections[selectedProtectionIdx];
      if (pairOfRing) {
        const value = {
          name: name,
          description: description,
          data_ring: pairOfRing.data.id,
          data_replication: protection.name,
          metadata_ring: pairOfRing.meta.id,
          metadata_replication: '4+',
          dlm: {
            enabled: isDLMEnabled
          }
        };

        if (isDLMEnabled && !isDLMAddressAuto) {
          value.dlm.rpc_addr_selector = DLMAddress;
        }
        if (deviceID !== '') {
          value.device_id = parseInt(deviceID, 10);
        }
        this.props.setGlobalOverlayText('Creating volume...');
        this.props.activateGlobalOverlay();
        this.props.createVolume(value);
      }
    }
  }

  volumeNameHasError() {
    const { name } = this.state;
    const noSpaceRegex = /^\S*$/;
    const alphaNumericWithAtLeastOneLetterRegex = /\w*[a-zA-Z_-]\w*/;

    if (name.length === 0) {
      return null;
    }

    if (!name.match(noSpaceRegex)) {
      return 'Volume Name cannot contain space.';
    } else if (name.length > 64) {
      return 'Volume Name must contain at most 64 characters.';
    } else if (!name.match(alphaNumericWithAtLeastOneLetterRegex)) {
      return 'Volume Name must contain at least one letter.';
    } else if (name.indexOf('+') !== -1) {
      return 'Volume Name cannot contain plus.';
    } else {
      return null;
    }
  }

  volumeDescriptionHasError() {
    if (this.state.description.length > 1000) {
      return 'Volume Description must contain at most 1000 characters.';
    } else {
      return null;
    }
  }

  volumeDeviceIDHasError() {
    const { deviceID } = this.state;
    const { volumes } = this.props;

    const deviceIDs =
      (volumes &&
        volumes.map(volume => {
          return volume.device_id;
        })) ||
      [];

    if (deviceID !== '') {
      const device_id = parseInt(deviceID, 10);
      const integerRegex = /^[1-9]+[0-9]*$/;

      if (deviceID !== '0' && !deviceID.match(integerRegex)) {
        return 'Device ID must be an integer.';
      } else if (device_id < 1 || device_id > 2147483647) {
        return 'Device ID must be between 1 and 2147483647.';
      } else if (deviceIDs.indexOf(device_id) !== -1) {
        return 'Device ID already exists.';
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  DLMAddressHasError() {
    if (this.state.DLMAddress.length === 0) {
      return null;
    }

    const isCIDR = cidrRegex({ exact: true }).test(this.state.DLMAddress);
    if (isCIDR) {
      return null;
    } else {
      return 'RPC Address Selector is not valid';
    }
  }

  handleChange(fieldName) {
    return (event, checked) => {
      this.setState(state => {
        return { ...state, [fieldName]: checked };
      });
    };
  }

  render() {
    const {
      pairOfRings,
      selectedPair,
      selectedProtectionIdx,
      pairOfProtections
    } = this.state;

    const style = {
      bodyContainer: {
        backgroundColor: '#201d1d',
        padding: '20px'
      },
      separator: {
        borderRight: '1px solid #464f56',
        marginLeft: '10px',
        marginRight: '9px'
      },
      contentTitle: {
        textTransform: 'capitalize',
        fontSize: '16px',
        display: 'inline-block',
        paddingLeft: '10px',
        borderLeft: '2px solid #fff'
      },
      ringsContainer: {
        width: '33%',
        margin: '0 15px 0 5px'
      },
      volumeInfoTitle: {
        textAlign: 'center',
        marginBottom: '10px',
        fontSize: '13px'
      },
      select: { margin: '0 5px' },
      submitButtonContainer: {
        height: '40px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
      },
      dataRingContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'
      },
      flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      dataRingValue: {
        fontSize: '18px',
        color: '#a4acb4'
      },
      dataRingUnit: {
        fontSize: '14px',
        color: '#a4acb4',
        marginLeft: '5px'
      },
      dataRingLegend: {
        textTransform: 'uppercase',
        fontSize: '10px',
        color: '#76828f',
        fontWeight: 500
      }
    };

    const pairOfRing = pairOfRings.find(pair => pair.key === selectedPair);
    const selectedDataRing =
      pairOfRing && pairOfRing.data ? pairOfRing.data : {};

    let usedPercentageValue = 'N/A';
    let pieChartData = [];
    let spaceAvailable = { value: 0, unit: 'GB' };

    if (!isEmpty(selectedDataRing)) {
      spaceAvailable = prettifyBytes(selectedDataRing.diskspace_available);

      if (selectedDataRing.diskspace_total > 0) {
        usedPercentageValue = Math.floor(
          getPercentage(
            selectedDataRing.diskspace_used,
            selectedDataRing.diskspace_total
          )
        );

        pieChartData = [
          {
            value: usedPercentageValue,
            name: `${usedPercentageValue}%`,
            hoverTitle: 'Used'
          },
          {
            value: 100 - usedPercentageValue,
            name: `${100 - usedPercentageValue}%`,
            hoverTitle: 'Available'
          }
        ];
      } else {
        pieChartData = [
          {
            value: 100,
            name: 'N/A',
            hoverTitle: 'N/A'
          }
        ];
      }
    }

    let estAvailable = { value: 0, unit: 'GB' };
    let protectionData = {};
    if (pairOfRing && pairOfRing.data && pairOfRing.data.protections) {
      protectionData = pairOfRing.data.protections[selectedProtectionIdx] || {};

      if (!isEmpty(protectionData)) {
        estAvailable = prettifyBytes(
          pairOfRing.data.diskspace_available / protectionData.overhead
        );
      }
    }

    const inputLabelPropsStyle = {
      style: { fontSize: '1.5rem', fontWeight: '100' },
      shrink: true
    };
    const inputPropsStyle = { style: { fontSize: '1.5rem' } };
    const helperTextProps = { style: { fontSize: '1rem' } };

    const canSubmit = this.canSubmit();
    const isCreatingVolume =
      this.props.volumeCreationState === ACTION_IN_PROGRESS;

    return (
      <ContentContainer>
        <Header>
          <BreadcrumbStyle>
            <Breadcrumb
              routes={[
                { name: 'VOLUMES', link: '/storages/file' },
                { name: 'Create New Volume' }
              ]}
            />
          </BreadcrumbStyle>
        </Header>
        <div style={style.bodyContainer}>
          <div style={style.contentTitle}>Create New Volume</div>
          <HelpPopover content={<VolumeCreateTips />} />
          <form>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <div style={{ width: '33%', marginRight: '10px' }}>
                <div style={style.volumeInfoTitle}>Volume Properties</div>
                <TextField
                  label="Volume Name"
                  placeholder="volume1"
                  style={{ marginBottom: '10px' }}
                  InputLabelProps={inputLabelPropsStyle}
                  InputProps={{ ...inputPropsStyle, 'data-cy': 'volume_name' }}
                  FormHelperTextProps={helperTextProps}
                  value={this.state.name}
                  onChange={e => this.setState({ name: e.target.value })}
                  error={this.volumeNameHasError() !== null}
                  helperText={this.volumeNameHasError()}
                  fullWidth
                />
                <TextField
                  label="Volume Description - Optional"
                  style={{ marginBottom: '10px' }}
                  InputProps={inputPropsStyle}
                  InputLabelProps={inputLabelPropsStyle}
                  FormHelperTextProps={helperTextProps}
                  onChange={e => this.setState({ description: e.target.value })}
                  error={this.volumeDescriptionHasError() !== null}
                  helperText={this.volumeDescriptionHasError()}
                  rows="3"
                  multiline
                  fullWidth
                />
                <TextField
                  label="Device ID - Optional"
                  InputProps={inputPropsStyle}
                  InputLabelProps={inputLabelPropsStyle}
                  FormHelperTextProps={helperTextProps}
                  onChange={e => this.setState({ deviceID: e.target.value })}
                  error={this.volumeDeviceIDHasError() !== null}
                  helperText={this.volumeDeviceIDHasError()}
                  fullWidth
                />
              </div>
              <div style={style.separator} />
              <div style={style.ringsContainer}>
                <div style={style.volumeInfoTitle}>Backend RINGs</div>
                <TextField
                  select
                  style={style.select}
                  label="Select Backend RINGs"
                  InputProps={inputPropsStyle}
                  InputLabelProps={inputLabelPropsStyle}
                  value={selectedPair && selectedPair ? selectedPair : ''}
                  onChange={this.ringChanged.bind(this)}
                  fullWidth
                  data-cy="select-rings"
                >
                  {pairOfRings.map(option => {
                    return (
                      <MenuItem
                        key={option.key}
                        value={option.key}
                        data-cy="select-rings-item"
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
                {!isEmpty(selectedDataRing) ? (
                  <div style={{ marginTop: '5px' }}>
                    <div
                      style={{
                        marginLeft: '5px',
                        fontSize: '12px',
                        color: '#919ba5'
                      }}
                    >
                      Current State of the RING used as Data
                    </div>
                    <div style={style.dataRingContainer}>
                      <div style={style.flexColumn}>
                        <div style={{ textAlign: 'center' }}>
                          {selectedDataRing.name}
                        </div>
                        <PieChart data={pieChartData} />
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginTop: '20px'
                        }}
                      >
                        <div style={style.flexColumn}>
                          <div>
                            <span style={style.dataRingValue}>
                              {spaceAvailable.value}
                            </span>
                            <span style={style.dataRingUnit}>
                              {spaceAvailable.unit}
                            </span>
                          </div>
                          <div style={style.dataRingLegend}>AVAILABLE</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div style={style.separator} />
              <div style={style.ringsContainer}>
                <div style={style.volumeInfoTitle}>Protection Level</div>
                <TextField
                  select
                  style={style.select}
                  label="Select Protection Level"
                  InputProps={inputPropsStyle}
                  InputLabelProps={inputLabelPropsStyle}
                  value={selectedProtectionIdx}
                  onChange={this.onSelectProtection}
                  fullWidth
                  disabled={!selectedPair}
                  data-cy="select-protection"
                >
                  {pairOfProtections ? (
                    pairOfProtections
                      .sort((a, b) => a.id - b.id)
                      .map(option => {
                        return (
                          <MenuItem
                            key={option.key}
                            value={option.value}
                            data-cy={`select-protection-item_${option.key.replace(
                              ',',
                              'COS'
                            )}`}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })
                  ) : (
                    <MenuItem />
                  )}
                </TextField>
                {selectedProtectionIdx !== -1 ? (
                  <div>
                    <div>
                      <div
                        style={{
                          fontSize: '40px',
                          color: '#a4acb4',
                          marginLeft: '5px',
                          textAlign: 'center'
                        }}
                      >
                        {`${estAvailable.value} ${estAvailable.unit}`}
                      </div>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '15px',
                          color: '#76828f',
                          textAlign: 'center',
                          marginTop: '-10px'
                        }}
                      >
                        EST. AVAILABLE
                      </div>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                      <div
                        style={{
                          margin: '5px 0',
                          color: '#a4acb4',
                          fontSize: '14px'
                        }}
                      >
                        {protectionData.description}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div style={{ marginTop: '20px' }}>
                  <div style={style.volumeInfoTitle}>
                    Distributed Lock Manager{' '}
                    <HelpPopover
                      content={
                        <SimpleTips
                          title="DLM"
                          content={`The Distributed Lock Manager (DLM) is a feature of SOFS connectors that coordinates operations over files, directories and quotas.`}
                        />
                      }
                    />
                  </div>
                  <InformationLine style={{ flexDirection: 'column' }}>
                    <VolumeLabel>Enabled</VolumeLabel>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={8}
                    >
                      <Grid item style={{ width: '40px' }}>
                        Off
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={this.state.isDLMEnabled}
                          onChange={this.handleChange('isDLMEnabled')}
                          name="isDLMEnabled"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </Grid>
                      <Grid item>On</Grid>
                    </Grid>
                  </InformationLine>

                  {this.state.isDLMEnabled ? (
                    <InformationLine style={{ flexDirection: 'column' }}>
                      <VolumeLabel>
                        RPC Address Selector{' '}
                        <HelpPopover content={<DLMTips />} />
                      </VolumeLabel>
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={8}
                      >
                        <Tooltip
                          title={'Manual'}
                          classes={{ tooltip: this.props.classes.tooltip }}
                        >
                          <Grid item style={{ width: '40px' }}>
                            Man
                          </Grid>
                        </Tooltip>
                        <Grid item>
                          <Switch
                            checked={this.state.isDLMAddressAuto}
                            onChange={this.handleChange('isDLMAddressAuto')}
                            name="isDLMAddressAuto"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </Grid>
                        <Grid item>Auto</Grid>
                        {this.state.isDLMAddressAuto ? null : (
                          <TextField
                            placeholder="10.0.0.0/24"
                            InputProps={inputPropsStyle}
                            InputLabelProps={inputLabelPropsStyle}
                            FormHelperTextProps={helperTextProps}
                            value={this.state.DLMAddress || ''}
                            onChange={e =>
                              this.setState({ DLMAddress: e.target.value })
                            }
                            error={this.DLMAddressHasError() !== null}
                            helperText={this.DLMAddressHasError()}
                          />
                        )}
                      </Grid>
                    </InformationLine>
                  ) : null}
                </div>
              </div>
            </div>
            <div style={style.submitButtonContainer}>
              <CancelButton
                style={{ marginRight: '20px' }}
                onClick={() => this.props.history.push('/storages/file')}
              >
                Cancel
              </CancelButton>
              <Button
                canSubmit={canSubmit && !isCreatingVolume}
                disabled={!canSubmit || isCreatingVolume}
                onClick={this.submit.bind(this)}
                data-cy="submit"
              >
                Create
                {isCreatingVolume ? (
                  <i
                    style={{ marginLeft: '5px' }}
                    className="fa fa-cog fa-spin fa-fw"
                  />
                ) : (
                  <i
                    style={{ marginLeft: '10px' }}
                    className="fa fa-plus-circle fa-lg"
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </ContentContainer>
    );
  }
}

const CancelButton = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #000;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: #3c3c3c;
  }
`;

const disabledColor = '#979797';

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#2b65ad' : disabledColor)};
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#205aa5' : disabledColor)};
  }
`;

const VolumeLabel = styled(InformationLabel)`
  display: flex;
  align-items: center;
  color: #a4acb4;
  ${({ isTitle }) =>
    !isTitle
      ? css`
          font-size: 12px;
        `
      : null};
`;

const mapDispatchToProps = dispatch => {
  return {
    createVolume: newVolume => dispatch(createVolumeAction(newVolume)),
    resetVolumeCreation: () => dispatch(setVolumeCreationNoActionAction()),
    activateGlobalOverlay: () => dispatch(activateGlobalOverlay()),
    deactivateGlobalOverlay: () => dispatch(deactivateGlobalOverlay()),
    setGlobalOverlayText: text => dispatch(setGlobalOverlayText(text)),
    resetGlobalOverlayText: () => dispatch(resetGlobalOverlayText())
  };
};

/**
 * FIXME We may want to wait until everything is loaded before displaying
 * anything, it will simplify the code and avoid a lot of issue
 */
const mapStateToProps = state => ({
  rings: selectRingsList(state),
  volumeCreationState: getVolumeCreationState(state),
  volumeCreationError: getVolumeCreationError(state),
  newVolumeId: getNewVolumeId(state),
  volumes: selectAllVolumesState(state)
});

const styles = theme => ({
  tooltip: {
    fontSize: 11
  }
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(CreateVolume))
);
