import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const RingCodingObjectChartTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`RING Erasure Coding Objects`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Number of Erasure Coding Objects:`}
      <Ul>
        <Li>
          <b>{`ARC Objects`}</b>
          {`: Number of erasure coded objects (for each erasure coded object there are a number of ARC
      data and
      ARC coding objects, depending on the protection level, e.g., 9+3, 8+4, and the object size)`}
        </Li>
        <Li>
          <b>{`ARC Data`}</b>
          {`: Number of ARC Data Objects`}
        </Li>
        <Li>
          <b>{`ARC Coding`}</b>
          {`: Number of ARC Coding (=parity) Objects`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default RingCodingObjectChartTips;
