import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const ServerInfoTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>Server Information</HelpPopupTitle>
    <HelpPopupContent>
      <Ul>
        <Li>
          <b>Name:</b> hostname or IP address
        </Li>
        <Li>
          <b>Data IP Address:</b> IP address for data traffic
        </Li>
        <Li>
          <b>Management IP Address:</b> IP address for management traffic
        </Li>

        <Li>
          <b>Zone:</b> server location (zone or logical failure domain)
        </Li>
        <Li>
          <b>Model:</b> detected hardware model of the server
        </Li>
        <Li>
          <b>Type (optional):</b> text field into which static information about
          type/brand/virtual can be input (manually or from the description
          file)
        </Li>
        <Li>
          <b>Roles:</b> defined role(s) from the description file (Storage,
          Supervisor, NFS connector, SMB Connector, LFS connectors (fuse),
          Simple Rest (sproxyd) and S3)
        </Li>
        <Li>
          <b>Description (optional):</b> text field into which information
          pertinent to the server can be input
        </Li>
        <Li>
          <b>Volumes:</b> link to server's volumes
        </Li>
        <Li>
          <b>Volume Configuration Groups:</b> link to server's volume
          configuration groups.
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default ServerInfoTips;
