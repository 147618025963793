import 'whatwg-fetch';
import config from '../config';
import { LAST_CALL_API_TIME, HASH_KEY } from '../actions/global/constants';
/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export default function request(url, options) {
  if (!(url.includes('?') || url.endsWith('/'))) url += '/';

  const checkLastCallAPITime = !!localStorage.getItem(LAST_CALL_API_TIME);
  const currentCallAPITime = new Date();

  if (checkLastCallAPITime) {
    const lastCallAPITime = new Date(localStorage.getItem(LAST_CALL_API_TIME));
    const intervalCallAPITime =
      currentCallAPITime.getTime() - lastCallAPITime.getTime();

    if (intervalCallAPITime > config.loginExpirationInterval) {
      localStorage.removeItem(HASH_KEY);
      // We can't easily connect this function with React Router
      // That's why we use window.location to force the refresh.
      if (window.location.pathname !== '/gui/login') {
        window.location.href = '/gui/login';
      }
    }
  }
  localStorage.setItem(LAST_CALL_API_TIME, currentCallAPITime);

  return new Promise((resolve, reject) => {
    let isError500 = false;
    fetch(url, options)
      .then((res) => {
        const { status } = res;
        if (status === 500) {
          isError500 = true;
        }

        // Delete Response
        if (status === 204) {
          resolve({});
        }

        // Credential issue
        if (status === 401) {
          // FIXME Patrick Ear : Need improvement after discution with the back-end
          // on how to handle error message
          reject({
            status,
            message:
              'Could not verify your access level for that URL. You have to login with proper credentials',
          });
        }

        if (status === 404) {
          reject({
            status,
            message: 'URL not found.',
          });
        }

        if (status === 405) {
          reject({
            status,
            message: 'The method is not allowed for the requested URL.',
          });
        }

        if (status === 409) {
          reject({
            status,
            message: 'Connector currently locked by another request',
          });
        }

        return res.json();
      })
      .then((res) => {
        return isError500 || res.errors ? reject(res) : resolve(res);
      })
      .catch((error) => {
        return reject({
          errorMsg: error.message,
        });
      });
  });
}
