import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import { IFRAME_VARS } from '../constants';
import { getGrafanaRingPageLink } from '../utils/grafanaFrameSrcgenerator';
import { getOldSupRingPageLink } from '../utils/helpers';
import Capacity from '../components/RingStatsCapacity';
import RingStatsNodes from '../containers/RingStatsNodes';
import RingStatsObjects from '../components/RingStatsObjects';
import RingStatsPerformance from '../components/RingStatsPerformance';

import { InlineBreadcrumbItem, InlineStatus } from '../components/InlineStatus';
import Breadcrumb from '../components/Breadcrumb';
import { Tab } from '../components/Tab';
import { MonitoringAndOperationLink } from '../components/ui/AllPagesItems';
import {
  ContentContainer,
  PageHeader,
  BreadcrumbStatusContainer
} from '../components/ui/PageStructure';
import { getRingFromUrlId } from '../ducks/rings';

export class RingStats extends Component {
  onTabClick(tab) {
    const { history, match } = this.props;
    history.push(`${match.url}/${tab}`);
  }

  render() {
    const { ring, location, match } = this.props;
    const exactPath = location.pathname;
    const capacityProps = { ring };
    let ringPage = null;
    let ringGrafanaPage = null;
    const ringState = ring.state ? ring.state.join(', ') : null;

    if (!_.isEmpty(ring)) {
      ringPage = getOldSupRingPageLink(ring.id);
      ringGrafanaPage = getGrafanaRingPageLink(
        ring.id,
        IFRAME_VARS.AUTO_REFRESH
      );
    }

    const tabs = [
      {
        selected: exactPath.endsWith('storage'),
        title: 'Storage',
        onClick: () => this.onTabClick('storage')
      },
      {
        selected: exactPath.endsWith('nodes'),
        title: 'Nodes',
        onClick: () => this.onTabClick('nodes')
      },
      {
        selected: exactPath.endsWith('objects'),
        title: 'Objects',
        onClick: () => this.onTabClick('objects')
      },
      {
        selected: exactPath.endsWith('performance'),
        title: 'Performance',
        onClick: () => this.onTabClick('performance')
      }
    ];

    return (
      <ContentContainer>
        <PageHeader>
          <BreadcrumbStatusContainer>
            <Breadcrumb
              routes={[
                { name: 'RINGS' },
                {
                  name: ring.id ? ring.id : 'N/A',
                  customName: ring.id ? (
                    <InlineBreadcrumbItem>
                      {ring.id}
                      <InlineStatus status={ring.status} text={ringState} />
                    </InlineBreadcrumbItem>
                  ) : null
                }
              ]}
            />
          </BreadcrumbStatusContainer>
          <MonitoringAndOperationLink
            monitoringLink={ringGrafanaPage}
            operationLink={ringPage}
          />
        </PageHeader>
        <Tab items={tabs}>
          <Switch>
            <Route
              path={`${match.url}/storage`}
              render={() => <Capacity {...capacityProps} />}
            />
            <Route path={`${match.url}/nodes`} component={RingStatsNodes} />
            <Route
              path={`${match.url}/objects`}
              render={() => <RingStatsObjects ring={ring} />}
            />
            <Route
              path={`${match.url}/performance`}
              render={() => <RingStatsPerformance ring={ring} />}
            />
            <Redirect from={`${match.url}`} to={`${match.url}/storage`} />
          </Switch>
        </Tab>
      </ContentContainer>
    );
  }
}

RingStats.propTypes = {
  rings: PropTypes.array
};

RingStats.defaultProps = {
  rings: [],
  adminAccess: false
};

const mapStateToProps = (state, ownProps) => ({
  ring: getRingFromUrlId(state, ownProps)
});

export default withRouter(connect(mapStateToProps)(RingStats));
