import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';

import RingLabelSelectedPointImg from '../assets/images/dashboard-icons/ring-stat-selected-point-bg.png';
import RingLabelPointImg from '../assets/images/dashboard-icons/ring-stat-point-bg.png';
import BlockTitle from '../components/ui/BlockTitle';
import HelpPopover from '../components/common/HelpPopover';
import RingIcon from '../components/common/icons/Ring';
import Tooltip from '../components/Tooltip';
import ScalityRingTips from '../tips/ScalityRingTips';
import {
  sortByPropertyOrder,
  prettifyBytes,
  getPercentage,
  shortenString
} from '../utils/helpers';
import { selectRingsList } from '../ducks/rings';

export const SORT_BY_CAPACITY = 'diskspace_total';

// TODO(gd): extract into common PanelHolder component
const RingsPanel = styled.section`
  min-height: 285px;
  margin-bottom: 0 !important;
  padding: 0 10px 10px;
  border-radius: 6px;
  border: 1px solid transparent;
  ${({ selected }) =>
    selected && {
      background: '#0e0e0e',
      borderColor: '#211d1d'
    }} & .fa-question-circle {
    color: #76828f;
    margin-left: 5px;
    cursor: pointer;
  }
`;

const RingsHolder = styled.div`
  position: relative;
  margin: 36px 0;
`;

const RingRow = styled.div`
  position: relative;
  margin-bottom: ${({ selected }) => (selected ? '-38px' : '-31px')};
  display: inline-block;
  width: 100%;
  cursor: pointer;
`;

const RingImageHolder = styled.div`
  position: relative;
  width: 62%;
  max-width: 300px;
  float: left;
`;

const RingLabelHolder = styled.div`
  position: relative;
  width: 38%;
  max-width: 170px;
  float: right;
  height: 60px;
  padding: 11px 6px;
  display: flex;
  flex-direction: row;
  margin-top: ${({ selected }) => (selected ? '25px' : '18px')};
  border-radius: 4px;
  background: ${({ selected }) => (selected ? '#fff' : '#1b1d1f')};
  box-shadow: 1px 2px 6px -2px #000;
  &:before {
    content: '';
    width: 14px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -9px;
    background: url(${({ selected }) =>
        selected ? RingLabelSelectedPointImg : RingLabelPointImg})
      no-repeat;
  }
`;

// FIXME(gd): split into multiple parts
const RingCapacity = styled.div`
  position: relative;
  min-width: 44px;
  margin-left: 2px;
  text-align: center;

  .value-holder {
    margin: 0;
    color: #a4acb4;
    line-height: 1;
    display: inline-flex;

    & > * {
      display: inline-block;
    }

    .value {
      margin-right: 2px;
      font-size: 20px;
    }

    .value-unit {
      text-transform: uppercase;
      font-size: 14px;
      margin: auto 0 1px;
    }
  }

  .total-text {
    color: #a4acb4;
    text-transform: uppercase;
    font-weight: $semi-bold;
    font-size: 12px;
    margin: 0;

    &.not-available {
      margin-top: 11px;
    }
  }
`;

const RingLabel = styled.div`
  position: relative;
  margin: 4px 0 0 15px;
  flex-grow: 1;

  &:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -7px;
    width: 1px;
    height: 40px;
    background: ${props => (props.selected ? '#1b1d1f' : '#a4acb4')};
    opacity: 0.1;
  }
`;

const RingName = styled.div`
  font-size: 14px;
  font-weight: semi-bold;
  margin-bottom: 0;
  line-height: 1;
  ${props => props.selected && { color: '#1b1d1f' }};
`;

const RingStatus = styled.div`
  margin-top: 3px;
  font-size: ${({ status }) => (status === 'ok' ? '12px' : '8px')};
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ status }) => {
    switch (status) {
      case 'ok':
        return '#21ac4d';
      case 'warning':
        return '#ff8f1c';
      case 'critical':
        return '#ef3340';
      default:
        return '#21ac4d';
    }
  }};
`;

const PercentNumber = styled.span`
  display: block;
  position: absolute;
  top: ${props => (props.behind ? '54%' : '55%')};
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  color: #201d1d;
  font-size: 18px;
  letter-spacing: -2px;
`;

export class Rings extends Component {
  onSelectRing(selectedRing) {
    const match = matchPath(this.props.history.location.pathname, {
      path: '/rings/:id'
    });
    const nextPath =
      match && match.params && match.params.id
        ? this.props.history.location.pathname.replace(
            match.params.id,
            selectedRing.id
          )
        : `/rings/${selectedRing.id}`;
    this.props.history.push(nextPath);
  }

  getPanelRings(rings, ringUrlId) {
    const ringsCount = rings.length;

    return rings.map((ring, ringIndex) => {
      const ringStatus = ring.status.toLowerCase();

      const ringStyle = {
        zIndex: ringsCount - ringIndex
      };

      let diskUsage = 0;

      if (ring.diskspace_total > 0) {
        diskUsage = Math.floor(
          getPercentage(ring.diskspace_used, ring.diskspace_total)
        );
      }

      const total = prettifyBytes(ring.diskspace_total);

      const isSelected = ring.id === ringUrlId;

      const ringName = shortenString(ring.name, 8);

      return (
        <RingRow
          key={`scality_ring_${ringIndex}`}
          style={ringStyle}
          selected={isSelected}
          onClick={this.onSelectRing.bind(this, ring)}
        >
          <div className="data clearfix">
            <RingImageHolder className="ring-wrapper pull-left">
              <RingIcon
                custom_id={ring.name}
                selected={isSelected}
                state={ringStatus}
                style={{
                  maxWidth: '100%',
                  maxHeight: isSelected ? '100px' : '92px'
                }}
              />
              {isSelected ? (
                <PercentNumber>{diskUsage + ' %'}</PercentNumber>
              ) : (
                <PercentNumber behind>{diskUsage + ' %'}</PercentNumber>
              )}
            </RingImageHolder>
            <RingLabelHolder selected={isSelected} className="pull-right">
              {/*<div className="inner-holder clearfix">*/}
              <RingCapacity className="ring-status">
                {total.value > 0 ? (
                  <div>
                    <p className="value-holder">
                      <span className="value">{total.value}</span>
                      <span className="value-unit">{total.unit}</span>
                    </p>
                    <p className="total-text">total</p>
                  </div>
                ) : (
                  <p className="total-text not-available">N/A</p>
                )}
              </RingCapacity>
              <RingLabel selected={isSelected}>
                <Tooltip overlay={<span>{ring.name}</span>}>
                  <RingName selected={isSelected}>{ringName}</RingName>
                </Tooltip>
                <RingStatus status={ringStatus}>{ring.status}</RingStatus>
              </RingLabel>
              {/*</div>*/}
            </RingLabelHolder>
          </div>
        </RingRow>
      );
    });
  }

  render() {
    const { rings, match, location, history } = this.props;
    const ring = rings.find(ring => ring.id === match.params.id);

    const isOnRingsPage = location.pathname.startsWith('/rings');
    if (rings.length > 0 && !ring && isOnRingsPage) {
      // The URL does not contain a valid RingId
      history.push(`/rings/${rings[0].id}`);
    }

    const sortedRing = sortByPropertyOrder(rings, SORT_BY_CAPACITY).reverse();
    const scalityRings = this.getPanelRings(sortedRing, match.params.id);

    return (
      <RingsPanel selected={isOnRingsPage}>
        <BlockTitle>The Scality RINGs</BlockTitle>
        <HelpPopover content={<ScalityRingTips />} />
        <RingsHolder className="ring-holder">{scalityRings}</RingsHolder>
      </RingsPanel>
    );
  }
}

Rings.propTypes = {
  rings: PropTypes.array
};

Rings.defaultProps = {
  rings: []
};

const mapStateToProps = state => ({
  rings: selectRingsList(state)
});

export default withRouter(connect(mapStateToProps)(Rings));
