import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const VolumeInformationTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`Volume Information`}</HelpPopupTitle>
    <HelpPopupContent>
      <Ul>
        <Li>
          <b>{`Name:`}</b>
          {` Volume name`}
        </Li>
        <Li>
          <b>{`Description (optional):`}</b>
          {` Volume description`}
        </Li>
        <Li>
          <b>{`Protocols:`}</b>
          {` Supported protocols for the volume (NFS/SMB/LocalFS/CDMI)`}
        </Li>
        <Li>
          <b>{`DATA RING:`}</b>
          {` Name of the RING used for the volume's data`}
        </Li>
        <Li>
          <b>{`DATA RING Protection Level:`}</b>
          {` Protection level configured for the data (can't be changed)`}
        </Li>
        <Li>
          <b>{`META RING:`}</b>
          {` Name of the RING used for the volume's metadata`}
        </Li>
        <Li>
          <b>{`META RING Protection Level:`}</b>
          {` Protection level configured for the metadata (can't be changed)`}
        </Li>
        <Li>
          <b>{`Device ID:`}</b>
          {` Volume's unique ID`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default VolumeInformationTips;
