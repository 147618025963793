import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import BlockTitle from '../components/ui/BlockTitle';
import HelpPopover from '../components/common/HelpPopover';
import DisksPerformanceTips from '../tips/DisksPerformanceTips';
import SelectBox from '../components/common/Select';
import SectionTitle from '../components/ui/SectionTitle';
import { SimpleTable } from '../components/SimpleTable';

import {
  getDisksRelocationAction,
  getAverageSSDReadAction,
  getAverageSSDWriteAction,
  getAverageReadAction,
  getAverageWriteAction,
  selectAverageSSDReadState,
  selectAverageSSDWriteState,
  selectAverageReadState,
  selectAverageWriteState
} from '../ducks/disks';
import {
  getTopDisksSSDReadAction,
  getTopDisksSSDWriteAction,
  getTopDisksReadAction,
  getTopDisksWriteAction,
  selectTopDisksSSDRead,
  selectTopDisksWrite,
  selectTopDisksRead,
} from '../ducks/topDisks';
import {
  getTopServersSSDReadAction,
  getTopServersSSDWriteAction,
  getTopServersReadAction,
  getTopServersWriteAction,
  selectTopServersSSDRead,
  selectTopServersSSDWrite,
  selectTopServersRead,
  selectTopServersWrite
} from '../ducks/topServers';

const options = [
  { value: '1h', label: '1 hour' },
  { value: '6h', label: '6 hours' },
  { value: '24h', label: '24 hours' },
  { value: '7d', label: '7 days' }
];

const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px 30px;
`;

const topDisksTablecolumns = [
  { name: 'Name', key: 'diskName', linkKey: 'diskLink' },
  { name: 'Server', key: 'serverName', linkKey: 'serverLink' },
  {
    name: 'Relocation',
    key: 'relocation',
    type: 'boolean',
    tooltip: 'Running'
  },
  { name: 'Latency (ms)', key: 'value' }
];

const topServersTablecolumns = [
  { name: 'Server', key: 'serverName', linkKey: 'serverLink' },
  { name: 'Latency (ms)', key: 'value' }
];

class AllDisksPerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      performanceTimeFrame: options,
      selectedPerformanceTimeFrame: options[0]
    };
  }

  componentDidMount() {
    this.fetchDisks(this.state.selectedPerformanceTimeFrame.value);
  }

  onPerformanceFilterChange(selectedOption) {
    this.setState({
      selectedPerformanceTimeFrame: selectedOption
    });
    this.fetchDisks(selectedOption.value);
  }

  fetchDisks(timeFrame) {
    this.props.getDisksRelocation();
    this.props.getTopDisksSSDRead(timeFrame);
    this.props.getTopDisksSSDWrite(timeFrame);
    this.props.getTopDisksRead(timeFrame);
    this.props.getTopDisksWrite(timeFrame);
    this.props.getTopServersSSDRead(timeFrame);
    this.props.getTopServersSSDWrite(timeFrame);
    this.props.getTopServersRead(timeFrame);
    this.props.getTopServersWrite(timeFrame);
    this.props.getAverageSSDRead(timeFrame);
    this.props.getAverageSSDWrite(timeFrame);
    this.props.getAverageRead(timeFrame);
    this.props.getAverageWrite(timeFrame);
  }

  render() {
    return (
      <div>
        <div>
          <BlockTitle>Disks Top-10 Highest Latencies</BlockTitle>
          <HelpPopover content={<DisksPerformanceTips />} />
          <SelectBox
            options={this.state.performanceTimeFrame}
            name="performance_options"
            value={this.state.selectedPerformanceTimeFrame}
            onChange={this.onPerformanceFilterChange.bind(this)}
          />
        </div>
        <SectionTitle>Top-10 Individual Disks Performance</SectionTitle>
        <TableContainer>
          <SimpleTable
            title={'Disks HDD GET'}
            subtitle={`Average : ${this.props.averageRead} ms`}
            columns={topDisksTablecolumns}
            data={this.props.topDisksRead}
          />
          <SimpleTable
            title={'Disks HDD PUT'}
            subtitle={`Average : ${this.props.averageWrite} ms`}
            columns={topDisksTablecolumns}
            data={this.props.topDisksWrite}
          />
        </TableContainer>
        <TableContainer>
          <SimpleTable
            title={'Disks SSD GET'}
            subtitle={`Average : ${this.props.averageSSDRead} ms`}
            columns={topDisksTablecolumns}
            data={this.props.topDisksSSDRead}
          />
          <SimpleTable
            title={'Disks SSD PUT'}
            subtitle={`Average : ${this.props.averageSSDWrite} ms`}
            columns={topDisksTablecolumns}
            data={this.props.topDisksSSDWrite}
          />
        </TableContainer>
        <SectionTitle>Top-10 By Server Disks Performance</SectionTitle>
        <TableContainer>
          <SimpleTable
            title={'Servers HDD GET'}
            subtitle={`Average : ${this.props.averageRead} ms`}
            columns={topServersTablecolumns}
            data={this.props.topServersRead}
          />
          <SimpleTable
            title={'Servers HDD PUT'}
            subtitle={`Average : ${this.props.averageWrite} ms`}
            columns={topServersTablecolumns}
            data={this.props.topServersWrite}
          />
        </TableContainer>
        <TableContainer>
          <SimpleTable
            title={'Servers SSD GET'}
            subtitle={`Average : ${this.props.averageSSDRead} ms`}
            columns={topServersTablecolumns}
            data={this.props.topServersSSDRead}
          />
          <SimpleTable
            title={'Servers SSD PUT'}
            subtitle={`Average : ${this.props.averageSSDWrite} ms`}
            columns={topServersTablecolumns}
            data={this.props.topServersSSDWrite}
          />
        </TableContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  topDisksSSDRead: selectTopDisksSSDRead(state),
  topDisksSSDWrite: selectTopDisksWrite(state),
  topDisksRead: selectTopDisksRead(state),
  topDisksWrite: selectTopDisksWrite(state),
  topServersSSDRead: selectTopServersSSDRead(state),
  topServersSSDWrite: selectTopServersSSDWrite(state),
  topServersRead: selectTopServersRead(state),
  topServersWrite: selectTopServersWrite(state),
  averageSSDRead: selectAverageSSDReadState(state),
  averageSSDWrite: selectAverageSSDWriteState(state),
  averageRead: selectAverageReadState(state),
  averageWrite: selectAverageWriteState(state)
});

const mapDispatchToProps = dispatch => {
  return {
    getTopDisksSSDRead: timeFrame => {
      dispatch(getTopDisksSSDReadAction(timeFrame));
    },
    getTopDisksSSDWrite: timeFrame => {
      dispatch(getTopDisksSSDWriteAction(timeFrame));
    },
    getTopDisksWrite: timeFrame => {
      dispatch(getTopDisksWriteAction(timeFrame));
    },
    getTopDisksRead: timeFrame => {
      dispatch(getTopDisksReadAction(timeFrame));
    },
    getTopServersSSDRead: timeFrame => {
      dispatch(getTopServersSSDReadAction(timeFrame));
    },
    getTopServersSSDWrite: timeFrame => {
      dispatch(getTopServersSSDWriteAction(timeFrame));
    },
    getTopServersWrite: timeFrame => {
      dispatch(getTopServersWriteAction(timeFrame));
    },
    getTopServersRead: timeFrame => {
      dispatch(getTopServersReadAction(timeFrame));
    },
    getAverageSSDRead: timeFrame => {
      dispatch(getAverageSSDReadAction(timeFrame));
    },
    getAverageSSDWrite: timeFrame => {
      dispatch(getAverageSSDWriteAction(timeFrame));
    },
    getAverageWrite: timeFrame => {
      dispatch(getAverageWriteAction(timeFrame));
    },
    getAverageRead: timeFrame => {
      dispatch(getAverageReadAction(timeFrame));
    },
    getDisksRelocation: () => {
      dispatch(getDisksRelocationAction());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllDisksPerformance);
