import React, { Component } from 'react';
import SelectBox from '../components/common/Select';
import GrafanaPanel from '../components/GrafanaPanel';
import SimpleTips from '../tips/SimpleTips';
import { IFRAME_VARS } from '../constants';
import config from '../config';
import { getFrameUrlofS3 } from '../utils/grafanaFrameSrcgenerator';

export default class SingleS3PerformanceTab extends Component {
  constructor(props) {
    super(props);

    const options = [
      { value: '1h', label: '1 hour' },
      { value: '6h', label: '6 hours' },
      { value: '24h', label: '24 hours' },
      { value: '1w', label: '1 week' }
    ];

    this.state = {
      performanceFilterOptions: options,
      selectedPerformanceFilter: options[1]
    };
  }

  onPerformanceFilterChange(selectedOption) {
    this.setState({
      selectedPerformanceFilter: selectedOption
    });
  }

  render() {
    const { performanceFilterOptions, selectedPerformanceFilter } = this.state;

    const s3OpsUrl = getFrameUrlofS3(
      config.s3MonitoringCharts.performance.operations,
      selectedPerformanceFilter.value,
      IFRAME_VARS.AUTO_REFRESH
    );

    const s3LatencyUrl = getFrameUrlofS3(
      config.s3MonitoringCharts.performance.latency,
      selectedPerformanceFilter.value,
      IFRAME_VARS.AUTO_REFRESH
    );

    const s3BandwidthUrl = getFrameUrlofS3(
      config.s3MonitoringCharts.performance.bandwidth,
      selectedPerformanceFilter.value,
      IFRAME_VARS.AUTO_REFRESH
    );

    return (
      <div className="tab-container single-storage-performance-tab">
        <div>
          <SelectBox
            options={performanceFilterOptions}
            name="performance_options"
            value={selectedPerformanceFilter}
            onChange={this.onPerformanceFilterChange.bind(this)}
          />
        </div>
        <div>
          <GrafanaPanel
            iFrameSrc={s3OpsUrl}
            panelTitle="Operations per Sec"
            popOverTemplate={
              <SimpleTips
                title="S3 Operations per Sec"
                content={`S3 service Operations per Second per Operation Type (HEAD, GET, PUT, DELETE and All)`}
              />
            }
          />
          <GrafanaPanel
            iFrameSrc={s3LatencyUrl}
            panelTitle="Latency"
            popOverTemplate={
              <SimpleTips
                title="S3 Latency"
                content={`S3 service Latencies per Operation Type (HEAD, GET, PUT, DELETE)`}
              />
            }
          />
          <GrafanaPanel
            iFrameSrc={s3BandwidthUrl}
            panelTitle="Bandwidth"
            popOverTemplate={
              <SimpleTips
                title="S3 Bandwidth"
                content={`S3 Service Inbound (PUT) and Outbound (GET) Bandwidth`}
              />
            }
          />
        </div>
      </div>
    );
  }
}
