import React from 'react';
import styled, { css } from 'styled-components';

/**
 * The Table Components is exactly the same as
 * default HTML table.
 * All his children are have also the same behavior
 */
const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const THead = styled.thead``;

const Th = styled.th`
  min-width: ${props => props.minWidth || null};
  max-width: ${props => props.maxWidth || null};
  padding: ${props => props.padding || '10px'};
  border-bottom: 1px solid #221d1d;
  background-color: #130e0e;
  font-size: ${({ fontSize }) => fontSize || '0.8em'};
  color: ${({ color }) => color || '#fff'};
`;

const TBody = styled.tbody``;

const Tr = styled.tr`
  height: ${props => props.height || '38px'};
  color: ${({ color }) => color || '#c3c3c3'};

  &:nth-child(odd) {
    background-color: #1f1a1a;
  }
  &:nth-child(even) {
    background-color: #221d1d;
  }

  ${props =>
    props.hoverable &&
    css`
      :hover {
        background-color: #120e0e;
        cursor: pointer;
        color: white;
      }
    `};
`;

const Td = styled.td`
  padding: ${props => props.padding || '5px 10px'};
  color: ${({ color }) => color || null};
  font-size: ${({ fontSize }) => fontSize || '0.7em'};
`;

// FIXME Patrick Ear : Do know how exactly do the center
// correctly across the project
const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

// FIXME Patrick Ear : Need externalize this element
// because center an element is something we do regulary
const CenterItems = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
`;

/**
 * NoDataRow should be use as a placeholder when your
 * Table does not have data
 * @param {{ colSpan: number, text: string }} props
 * `colSpan` should be the same as the number of column
 * your table have
 *
 * `text` allow you to replace the default text displayed
 */
const NoDataRow = props => (
  <Tr>
    <Td colSpan={props.colSpan || 3}>
      <div style={styleCenter}>
        <i
          className="fa fa-exclamation-circle fa-1x fa-fw"
          aria-hidden="true"
        />
        {props.text || 'No data available.'}
      </div>
    </Td>
  </Tr>
);

/**
 * LoadingRow should be use as a placeholder when your
 * Table data is loading
 * @param {{ colSpan: number, text: string }} props
 * `colSpan` should be the same as the number of column
 * your table have
 *
 * `text` allow you to replace the default text displayed
 */
const LoadingRow = props => (
  <Tr>
    <Td colSpan={props.colSpan || 3}>
      <div style={styleCenter}>
        <i
          className="fa fa-circle-o-notch fa-spin fa-1x fa-fw"
          aria-hidden="true"
        />
        {props.text || 'Loading content...'}
      </div>
    </Td>
  </Tr>
);

export { Table, THead, Th, TBody, Tr, Td, NoDataRow, CenterItems, LoadingRow };
