import config from '../config';

import { IFRAME_VARS } from '../constants';

const {
  DASHBOARD_BEATS,
  DASHBOARD_SOLO,
  DASHBOARD_OPERATIONS,
  DASHBOARD_DISK_STATS,
  RING,
  DISK,
  HOST,
  FROM,
  TO,
  PANEL_ID,
  ORG_ID,
  SPECIFIC_HOST,
  BEATS_HOST,
  HOST_IP,
  CONNECTOR,
  READ_OPS_VAR,
  WRITE_OPS_VAR,
  READ_OPS,
  WRITE_OPS,
  BW_READ_OPS,
  BW_WRITE_OPS,
  AUTO_REFRESH,
  DARK_THEME
} = IFRAME_VARS;

function serverToHostIPList(server) {
  if (server) {
    const ip_list = [server.data_ip_address];
    if (server.management_ip_address !== server.data_ip_address) {
      ip_list.push(server.management_ip_address);
    }
    return ip_list.map(ip => `${HOST_IP}${ip}`).join('');
  }
  return '';
}

function getGrafanaFrameUrl(ringId, chartPanelId, fromValue, autoRefresh) {
  return (
    config.grafanaLink +
    DASHBOARD_SOLO +
    RING +
    ringId +
    HOST +
    FROM +
    fromValue +
    TO +
    PANEL_ID +
    chartPanelId +
    ORG_ID +
    DARK_THEME +
    autoRefresh
  );
}

function getGrafanaRingPageLink(ringId, autoRefresh) {
  return (
    config.grafanaLink +
    DASHBOARD_OPERATIONS +
    RING +
    ringId +
    HOST +
    ORG_ID +
    DARK_THEME +
    autoRefresh
  );
}

function getGrafanaNodePageLink(nodeName, autoRefresh) {
  return (
    config.grafanaLink +
    DASHBOARD_BEATS +
    BEATS_HOST +
    nodeName +
    ORG_ID +
    DARK_THEME +
    autoRefresh
  );
}

// FIXME Patrick Ear : It's just really dirty...
function getGrafanaDiskPageLink(server, diskId) {
  return (
    config.grafanaLink +
    DASHBOARD_DISK_STATS +
    ORG_ID +
    serverToHostIPList(server) +
    '&var-SERVICE=bizio&var-LAYER=bizobj' +
    DISK +
    DARK_THEME +
    diskId
  );
}

function getFrameUrlOfNode(nodeName, chartPanelId, fromValue, autoRefresh) {
  return (
    config.grafanaLink +
    DASHBOARD_SOLO +
    PANEL_ID +
    chartPanelId +
    FROM +
    fromValue +
    TO +
    SPECIFIC_HOST +
    nodeName +
    ORG_ID +
    DARK_THEME +
    autoRefresh
  );
}

function getFrameUrlOfDisk(
  diskName,
  chartPanelId,
  fromValue,
  autoRefresh,
  server
) {
  return (
    config.grafanaLink +
    DASHBOARD_SOLO +
    PANEL_ID +
    chartPanelId +
    FROM +
    fromValue +
    TO +
    serverToHostIPList(server) +
    DISK +
    diskName +
    ORG_ID +
    DARK_THEME +
    autoRefresh
  );
}

function getFrameUrlofS3(panelName, fromValue, autoRefresh) {
  if (fromValue) {
    return (
      config.grafanaLink +
      DASHBOARD_SOLO +
      PANEL_ID +
      panelName +
      FROM +
      fromValue +
      TO +
      DARK_THEME +
      autoRefresh
    );
  } else {
    return (
      config.grafanaLink +
      DASHBOARD_SOLO +
      PANEL_ID +
      panelName +
      DARK_THEME +
      autoRefresh
    );
  }
}

function getFrameUrlOfRing(ringId, panelName, autoRefresh, fromValue) {
  return (
    config.grafanaLink +
    DASHBOARD_SOLO +
    PANEL_ID +
    panelName +
    RING +
    ringId +
    DARK_THEME +
    autoRefresh +
    `${fromValue ? FROM + fromValue + TO : ''}`
  );
}

function getFrameUrlOfVolume(
  chartPanelId,
  fromValue,
  autoRefresh,
  connectors,
  chartType
) {
  const connectorsAddress = connectors.map(x => x.address);
  let read_ops, write_ops;

  switch (chartType.TYPE) {
    case 'bandwidth':
      read_ops = READ_OPS_VAR + BW_READ_OPS.join(READ_OPS_VAR);
      write_ops = WRITE_OPS_VAR + BW_WRITE_OPS.join(WRITE_OPS_VAR);
      break;
    case 'noBandwidth':
      read_ops = READ_OPS_VAR + READ_OPS.join(READ_OPS_VAR);
      write_ops = WRITE_OPS_VAR + WRITE_OPS.join(WRITE_OPS_VAR);
      break;
    default:
      break;
  }

  const buildConnectorsParams = connectorsAddress
    ? CONNECTOR + connectorsAddress.join(CONNECTOR)
    : '';
  return (
    config.grafanaLink +
    DASHBOARD_SOLO +
    PANEL_ID +
    chartPanelId +
    buildConnectorsParams +
    FROM +
    fromValue +
    TO +
    ORG_ID +
    read_ops +
    write_ops +
    DARK_THEME +
    autoRefresh
  );
}

// FIXME(gd): add documentation for this helper
function buildChartURL(panelID, from, { autoRefresh = true, ...extras }) {
  const baseURL = config.grafanaLink + DASHBOARD_SOLO + PANEL_ID + panelID;
  let timeParams = FROM + from + TO;
  if (autoRefresh) {
    timeParams += AUTO_REFRESH;
  }
  const extraParams = Object.entries(extras).reduce((acc, [key, value]) => {
    let varKey = IFRAME_VARS[key];
    if (varKey !== undefined) {
      if (varKey === HOST_IP) {
        return acc + serverToHostIPList(value);
      }
      return acc + varKey + value;
    }
    return acc;
  }, '');

  return baseURL + timeParams + extraParams + DARK_THEME;
}

export {
  buildChartURL,
  getGrafanaFrameUrl,
  getGrafanaRingPageLink,
  getFrameUrlOfNode,
  getFrameUrlOfDisk,
  getGrafanaNodePageLink,
  getFrameUrlofS3,
  getFrameUrlOfRing,
  getFrameUrlOfVolume,
  getGrafanaDiskPageLink
};
