import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import config from '../config';
import {
  getVolumesConnectorsAction,
  setVolumeCreationNoActionAction,
  setVolumeEditionNoActionAction,
  setVolumeDeletionNoActionAction,
  getVolumeCreationState,
  getVolumeEditionState,
  getVolumeDeletionState,
  getVolumeFromUrlSelector,
  getConfigGroupsAction,
  setCGCreationNoActionAction,
  setCGEditNoActionAction,
  setCGDeletionNoActionAction,
  getConfigGroupCreationState,
  getConfigGroupEditState,
  getConfigGroupDeletionState,
  getConfigGroupsSelector
} from '../ducks/volumes';
import {
  selectRingsList
} from '../ducks/rings';
import Information from '../components/SingleVolumeInformation';
import ConfigsGroups from '../components/SingleVolumeConfigGroup';
import Performances from '../components/SingleVolumePerformances';
import EditVolume from './SingleVolumeEdit';
import { ACTION_SUCCESS } from '../constants';
import { InlineBreadcrumbItem, InlineStatus } from '../components/InlineStatus';
import Breadcrumb from '../components/Breadcrumb';
import { Tab } from '../components/Tab';
import { MonitoringAndOperationLink } from '../components/ui/AllPagesItems';
import {
  ContentContainer,
  PageHeader,
  BreadcrumbStatusContainer
} from '../components/ui/PageStructure';

const propTypes = {
  volume: PropTypes.object,
  volumeConfigGroups: PropTypes.array,
  volumeRing: PropTypes.object,
  volumeConnectors: PropTypes.array,
  adminAccess: PropTypes.bool
};

const defaultProps = {
  volume: {},
  volumeConfigGroups: [],
  volumeRing: {},
  volumeConnectors: [],
  adminAccess: false
};

class SingleVolume extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      cgCreationState,
      volumeCreationState,
      cgEditState,
      cgDeletionState,
      volumeEditionState
    } = this.props;

    this.pollData();
    this.poll = setInterval(() => this.pollData(), config.longPollingInterval);

    if (cgCreationState === ACTION_SUCCESS) {
      this.notify('Configuration Group successfully created');
      this.props.resetCGCreation();
    }

    if (volumeCreationState === ACTION_SUCCESS) {
      this.notify('Volume successfully created');
      this.props.resetVolumeCreation();
    }

    if (cgEditState === ACTION_SUCCESS) {
      this.notify('Configuration Group successfully updated');
      this.props.resetCGEdit();
    }

    if (cgDeletionState === ACTION_SUCCESS) {
      this.notify('Configuration Group successfully deleted');
      this.props.resetCGDeletion();
    }

    if (volumeEditionState === ACTION_SUCCESS) {
      this.notify('Volume successfully edited');
      this.props.resetVolumeEdition();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { volume, volumeEditionState, volumeDeletionState } = nextProps;

    if (volumeEditionState === ACTION_SUCCESS) {
      if (this.props.location.pathname.endsWith('/edit')) {
        this.props.history.push(`/storages/file/${volume.id}`);
      } else {
        this.notify('Volume successfully edited');
        this.props.resetVolumeEdition();
      }
    }

    if (volumeDeletionState === ACTION_SUCCESS) {
      this.props.history.push(`/storages/file/`);
    }
  }

  componentWillUnmount() {
    clearInterval(this.poll);
    this.poll = null;
  }

  pollData() {
    this.props.getConfigGroups();
    this.props.getVolumesConnectors();
  }

  notify(message) {
    toast.success(<div style={{ textAlign: 'center' }}>{message}</div>, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true
    });
  }

  goToAllVolumes() {
    this.props.history.push('/storages/file');
  }

  onTabClick(tab) {
    this.props.history.push(`${this.props.match.url}/${tab}`);
  }

  getVolumeOperationUrl(volume) {
    let operationUrl =
      config.oldSuperVisorHost + `volumes/${volume.supv2_id}/edit`;

    if (process.env.NODE_ENV === 'development') {
      operationUrl = config.devUrl + operationUrl;
    }

    return operationUrl;
  }

  // The link is hardcode and will be change later
  // cf. Charles comment on RING-27905
  getVolumeMonitoringUrl() {
    let monitoringUrl = '/stats/dashboard/db/connectors-stats';
    if (process.env.NODE_ENV === 'development') {
      monitoringUrl = config.devUrl + monitoringUrl;
    }
    return monitoringUrl;
  }

  render() {
    const {
      volume,
      volumeConfigGroups,
      volumeRing,
      volumeConnectors,
      adminAccess,
      match,
      location
    } = this.props;
    const exactPath = location.pathname;

    const volumeProtectionLevel =
      volumeRing &&
      volumeRing.protections &&
      volumeRing.protections.find(prot => {
        const volumeProt = volume.data_replication;

        if (
          prot.arc_coding === volumeProt.arc_coding &&
          prot.arc_data === volumeProt.arc_data &&
          prot.cos === volumeProt.cos
        ) {
          return true;
        }
        return false;
      });

    const tabs = [
      {
        selected:
          exactPath.endsWith('information') || exactPath.endsWith('edit'),
        title: 'Information',
        onClick: () => this.onTabClick('information')
      },
      {
        selected: exactPath.endsWith('config-group'),
        title: 'Configuration Groups',
        onClick: () => this.onTabClick('config-group')
      },
      {
        selected: exactPath.endsWith('performance'),
        title: 'Performance',
        onClick: () => this.onTabClick('performance')
      }
    ];
    return (
      <ContentContainer>
        <PageHeader>
          <BreadcrumbStatusContainer>
            <Breadcrumb
              routes={[
                { name: 'VOLUMES', link: '/storages/file' },
                {
                  name: volume.name || '',
                  customName:
                    volume && volume.name ? (
                      <InlineBreadcrumbItem>
                        {volume.name}
                        <InlineStatus
                          status={volume.status}
                          text={volume.status}
                        />
                      </InlineBreadcrumbItem>
                    ) : null
                }
              ]}
            />
          </BreadcrumbStatusContainer>
          {adminAccess && (
            <MonitoringAndOperationLink
              monitoringLink={this.getVolumeMonitoringUrl()}
              operationLink={this.getVolumeOperationUrl(volume)}
            />
          )}
        </PageHeader>
        <Tab items={tabs}>
          <Switch>
            <Route
              path={`${match.url}/edit`}
              render={() => <EditVolume volume={volume} />}
            />
            <Route
              path={`${match.url}/information`}
              render={() => (
                <Information
                  volume={volume}
                  volumeProtectionLevel={volumeProtectionLevel}
                  adminAccess={adminAccess}
                />
              )}
            />
            <Route
              path={`${match.url}/performance`}
              render={() => (
                <Performances
                  activeVolume={volume}
                  volumeConnectors={volumeConnectors}
                />
              )}
            />
            <Route
              path={`${match.url}/config-group`}
              render={() => (
                <ConfigsGroups
                  adminAccess={adminAccess}
                  activeVolume={volume}
                  activeVolumeConfigGroup={volume.volume_config_groups || []}
                  configGroups={volumeConfigGroups}
                />
              )}
            />
            <Redirect from={`${match.url}`} to={`${match.url}/information`} />
          </Switch>
        </Tab>
      </ContentContainer>
    );
  }
}

SingleVolume.propTypes = propTypes;
SingleVolume.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => ({
  volume: makeGetVolumeFromUrl(state, ownProps),
  volumeConfigGroups: makeGetVolumeCGFromUrl(state, ownProps),
  volumeRing: makeGetVolumeRing(state, ownProps),
  volumeConnectors: makeGetVolumeConnectors(state, ownProps),
  adminAccess: state.get('overview').get('adminAccess'),
  volumeCreationState: getVolumeCreationState(state),
  cgCreationState: getConfigGroupCreationState(state),
  cgEditState: getConfigGroupEditState(state),
  cgDeletionState: getConfigGroupDeletionState(state),
  volumeEditionState: getVolumeEditionState(state),
  volumeDeletionState: getVolumeDeletionState(state)
});

const mapDispatchToProps = dispatch => {
  return {
    getVolumesConnectors: () => dispatch(getVolumesConnectorsAction()),
    getConfigGroups: () => dispatch(getConfigGroupsAction()),
    resetVolumeCreation: () => dispatch(setVolumeCreationNoActionAction()),
    resetCGCreation: () => dispatch(setCGCreationNoActionAction()),
    resetCGEdit: () => dispatch(setCGEditNoActionAction()),
    resetCGDeletion: () => dispatch(setCGDeletionNoActionAction()),
    resetVolumeEdition: () => dispatch(setVolumeEditionNoActionAction()),
    resetVolumeDeletion: () => dispatch(setVolumeDeletionNoActionAction())
  };
};

const makeGetVolumeFromUrl = createSelector(getVolumeFromUrlSelector, volume => volume);

const getVolumeCGFromUrl = (state, props) => {
  const configGroups = getConfigGroupsSelector(state) || [];

  if (props && props.match && props.match.params && props.match.params.id) {
    const cgs = configGroups.filter(
      cg => cg.volume === parseInt(props.match.params.id, 10)
    );
    return cgs;
  }
  return [];
};

const makeGetVolumeCGFromUrl = createSelector(getVolumeCGFromUrl, cg => cg);

const makeGetVolumeRing = createSelector(
  [getVolumeFromUrlSelector, selectRingsList],
  (volume, rings) => {
    if (!isEmpty(volume) && rings && rings.length > 0) {
      return rings.find(ring => ring.id === volume.data_ring) || {};
    }
    return {};
  }
);

const makeGetVolumeConnectors = createSelector(
  [getVolumeFromUrlSelector, getVolumeCGFromUrl],
  (volume, configGroups) => {
    if (!isEmpty(volume) && configGroups) {
      return configGroups.reduce((prev, cg) => [...prev, ...cg.connectors], []);
    }
    return [];
  }
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SingleVolume)
);
