import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { callApi, callApiPost } from '../utils/callApi';
import { fromJS } from 'immutable';
import { setInvalidBasicAuth } from '../actions/overview';

// Actions
const GET_ZONES = 'GET_ZONES';
const SET_ZONES = 'SET_ZONES';
const CREATE_ZONE = 'CREATE_ZONE';
const DELETE_ZONE = 'DELETE_ZONE';
const EDIT_ZONE = 'EDIT_ZONE';

// Reducer
const defaultState = fromJS({
  list: undefined
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_ZONES:
      return state.set('list', action.payload);
    default:
      return state;
  }
}

// Action Creators

export function getZonesAction() {
  return {
    type: GET_ZONES
  };
}

export function setZonesAction(zones) {
  return {
    type: SET_ZONES,
    payload: zones
  };
}

export function createZoneAction(zone) {
  return {
    type: CREATE_ZONE,
    payload: zone
  };
}

export function deleteZoneAction(id) {
  return {
    type: DELETE_ZONE,
    payload: id
  };
}

export function editZoneAction(zone, id) {
  return {
    type: EDIT_ZONE,
    payload: { zone, id }
  };
}

// Sagas

export function* getZones() {
  let requestURL = 'server_zones/?limit=99';

  try {
    let responseItems = [];

    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items;

    do {
      if (response._links.hasOwnProperty('next')) {
        requestURL = `server_zones/?${response._links.next.split('?')[1]}`;

        response = yield call(callApi, requestURL, {
          method: 'GET',
          mode: 'cors'
        });

        responseItems = responseItems.concat(response._items);
      }
    } while (response._links.hasOwnProperty('next'));

    yield put(setZonesAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

export function* createZone({ payload }) {
  let requestURL = '/server_zones/';

  try {
    yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload)
    });

    yield put(getZonesAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

export function* deleteZone({ payload }) {
  let requestURL = '/server_zones/' + payload;

  try {
    yield call(callApiPost, requestURL, {
      method: 'DELETE',
      mode: 'cors'
    });
    yield put(getZonesAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

export function* editZone({ payload }) {
  const { id, zone } = payload;
  let requestURL = '/server_zones/' + id;

  try {
    yield call(callApiPost, requestURL, {
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(zone)
    });
    yield put(getZonesAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

export function* zonesSaga() {
  yield takeLatest(GET_ZONES, getZones);
  yield takeEvery(CREATE_ZONE, createZone);
  yield takeEvery(DELETE_ZONE, deleteZone);
  yield takeEvery(EDIT_ZONE, editZone);
}
