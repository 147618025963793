import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonOk, ButtonM } from '../components/ui/Button';
import { Modal } from '../components/ui/Modal';

import { deleteServerAction, editServerAction } from '../ducks/servers';

import EditServerForm from './SingleServerEditForm';

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: #2b65ad;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;

  &:hover {
    background-color: #205aa5;
  }
`;

class EditServer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: this.props.location.pathname.endsWith('edit'),
      showModalDeleteServer: false,
      deleteName: ''
    };
    this.alertEditServer = this.alertEditServer.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalDelete = this.handleCloseModalDelete.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { location } = nextProps;

    this.setState({ showModal: location.pathname.endsWith('edit') });
  }

  handleCloseModal() {
    let url = this.props.match.url.replace('/edit', '/information');
    this.props.history.push(url);
  }

  handleOpenModal() {
    let url = this.props.match.url.replace('/information', '/edit');
    this.props.history.push(url);
  }

  alertEditServer(values) {
    const serverID = this.props.dataNodeArray.id;
    this.props.editServer(values, serverID);
    this.handleCloseModal();
  }

  removeServerValid = () => {
    const value = this.state.deleteName;
    this.props.deleteServer(value);

    this.setState({
      showModal: false,
      showModalDeleteZone: false,
      deleteName: ''
    });
    this.props.history.push('/servers');
  };

  handleCloseModalDelete() {
    this.setState({ showModalDeleteServer: false });
  }

  removeServer = value => {
    this.setState({ showModalDeleteServer: true, deleteName: value });
  };

  cancelEdit = () => {
    this.handleCloseModal();
  };

  render() {
    const { dataNodeArray } = this.props;
    const defaultForValue = {
      zone:
        (dataNodeArray && dataNodeArray.zone && dataNodeArray.zone.name) || '',
      server_type: dataNodeArray.server_type,
      description: dataNodeArray.description
    };
    return (
      <div>
        <Button onClick={this.handleOpenModal}>
          Edit
          <i
            className="fa fa-edit fa-lg"
            style={{ margin: '5px 0 5px 10px' }}
          />
        </Button>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          ariaHideApp={true}
          onRequestClose={this.handleCloseModal}
          className="ReactModal__Content_Server"
        >
          <h3>Edit Server</h3>
          <div className="modal--content clearfix">
            <EditServerForm
              initialValues={defaultForValue}
              onSubmit={this.alertEditServer}
              parentServerMethod={this.removeServer}
              ips={this.props.dataIPAddressArray}
              zones={this.props.dataZonesArray}
              editableRoles={this.props.dataEditableRolesArray}
              activatedRoles={this.props.dataActivesRolesArray}
              singleNode={this.props.dataNodeArray}
              parentServerMethodCloseModal={this.cancelEdit}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showModalDeleteServer}
          ariaHideApp={false}
          className="ReactModal__Content_Prompt_Confirm"
        >
          <div className="alert--text centerAlign">
            <h4>
              <b>{dataNodeArray.name}</b>
            </h4>
            You are going to delete this server permanently
          </div>
          <div className="centerAlign">
            <ButtonM
              className="btn btn--m"
              onClick={this.handleCloseModalDelete}
            >
              Cancel
            </ButtonM>
            <ButtonOk className="btn btn--ok" onClick={this.removeServerValid}>
              Delete
            </ButtonOk>
          </div>
        </Modal>
      </div>
    );
  }
}

EditServer.propTypes = {
  deleteServer: PropTypes.func.isRequired,
  editServer: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    deleteServer: server => {
      dispatch(deleteServerAction(server));
    },
    editServer: (server, name) => {
      dispatch(editServerAction(server, name));
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(EditServer));
