import { call, put, takeEvery, all } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import { callApi, callApiPost } from '../utils/callApi';
import {
  setInvalidBasicAuth,
  setApiRequestErrorMsg,
  setApiRequestError
} from '../actions/overview';
import { getDateRangeS3, timeRange } from '../utils/helpers';
import { DESC } from '../constants';

// Actions
const UPDATE_S3_MANAGEMENT = 'UPDATE_S3_MANAGEMENT';
const UPDATE_S3_MANAGEMENT_SUCCESS = 'UPDATE_S3_MANAGEMENT_SUCCESS';
const UPDATE_S3_MANAGEMENT_FAILED = 'UPDATE_S3_MANAGEMENT_FAILED';

const GET_S3_STORAGE_SERVICES = 'GET_S3_STORAGE_SERVICES';
const GET_S3_STORAGE_SERVICES_IN_PROGRESS =
  'GET_S3_STORAGE_SERVICES_IN_PROGRESS';
const GET_S3_STORAGE_SERVICES_SUCCESS = 'GET_S3_STORAGE_SERVICES_SUCCESS';
const GET_S3_STORAGE_SERVICES_FAILED = 'GET_S3_STORAGE_SERVICES_FAILED';

const GET_STORAGE_AVAILABILITY = 'GET_STORAGE_AVAILABILITY';
const GET_STORAGE_AVAILABILITY_IN_PROGRESS =
  'GET_STORAGE_AVAILABILITY_IN_PROGRESS';
const GET_STORAGE_AVAILABILITY_SUCCESS = 'GET_STORAGE_AVAILABILITY_SUCCESS';
const GET_STORAGE_AVAILABILITY_FAILED = 'GET_STORAGE_AVAILABILITY_FAILED';

const GET_SERVICES_LIST_DATA = 'GET_SERVICES_LIST_DATA';
const GET_SERVICES_LIST_DATA_SUCCESS = 'GET_SERVICES_LIST_DATA_SUCCESS';

const GET_TOP_TEN_OPS_DATA = 'GET_TOP_TEN_OPS_DATA';
const GET_TOP_TEN_OPS_DATA_SUCCESS = 'GET_TOP_TEN_OPS_DATA_SUCCESS';

const GET_TOP_TEN_IN_DATA = 'GET_TOP_TEN_IN_DATA';
const GET_TOP_TEN_IN_DATA_SUCCESS = 'GET_TOP_TEN_IN_DATA_SUCCESS';

const GET_TOP_TEN_OUT_DATA = 'GET_TOP_TEN_OUT_DATA';
const GET_TOP_TEN_OUT_DATA_SUCCESS = 'GET_TOP_TEN_OUT_DATA_SUCCESS';

const GET_GLOBAL_HEALTH_DATA = 'GET_GLOBAL_HEALTH_DATA';
const GET_GLOBAL_HEALTH_DATA_SUCCESS = 'GET_GLOBAL_HEALTH_DATA_SUCCESS';

// Reducer
const defaultState = fromJS({});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case GET_S3_STORAGE_SERVICES_IN_PROGRESS:
      return state.set('s3storageInProgress', true);
    case GET_S3_STORAGE_SERVICES_SUCCESS:
      return state
        .set('s3storages', action.s3storages)
        .set('s3storageInProgress', false);
    case GET_S3_STORAGE_SERVICES_FAILED:
      return state
        .set('s3storageError', true)
        .set('s3storageInProgress', false);
    case GET_STORAGE_AVAILABILITY_SUCCESS:
      return state
        .set('storageAvailability', action.availability)
        .set('storageAvailabilityInProgress', false);
    case GET_STORAGE_AVAILABILITY_FAILED:
      return state.set('storageAvailabilityInProgress', false);
    case GET_SERVICES_LIST_DATA_SUCCESS:
      return state.set('servicesList', action.servicesList);
    case GET_TOP_TEN_OPS_DATA_SUCCESS:
      return state.set('topTenBucketOPS', action.topTenBucketOPS);
    case GET_TOP_TEN_IN_DATA_SUCCESS:
      return state.set('topTenBucketIN', action.topTenBucketIN);
    case GET_TOP_TEN_OUT_DATA_SUCCESS:
      return state.set('topTenBucketOUT', action.topTenBucketOUT);
    case GET_GLOBAL_HEALTH_DATA_SUCCESS:
      return state.set('globalHealth', action.globalHealth);
    default:
      return state;
  }
}

// Action Creators
function getS3StorageServicesAction() {
  return {
    type: GET_S3_STORAGE_SERVICES
  };
}

function getS3StorageServicesInProgressAction() {
  return {
    type: GET_S3_STORAGE_SERVICES_IN_PROGRESS
  };
}

function getS3StorageServicesSuccessAction(s3storages) {
  return {
    type: GET_S3_STORAGE_SERVICES_SUCCESS,
    s3storages
  };
}

function getS3StorageServicesFailedAction() {
  return {
    type: GET_S3_STORAGE_SERVICES_FAILED
  };
}

/* S3 Management Action */
function updateS3ManagementAction(data, storageId) {
  return {
    type: UPDATE_S3_MANAGEMENT,
    data,
    storageId
  };
}

function updateS3ManagementSuccessAction() {
  return {
    type: UPDATE_S3_MANAGEMENT_SUCCESS
  };
}

function updateS3ManagementFailedAction() {
  return {
    type: UPDATE_S3_MANAGEMENT_FAILED
  };
}

/* Get storage availability */
function getStorageAvailabilityAction(storageId, from) {
  return {
    type: GET_STORAGE_AVAILABILITY,
    storageId,
    from
  };
}

function getStorageAvailabilityInProgressAction() {
  return {
    type: GET_STORAGE_AVAILABILITY_IN_PROGRESS
  };
}

function getStorageAvailabilitySuccessAction(availability) {
  return {
    type: GET_STORAGE_AVAILABILITY_SUCCESS,
    availability
  };
}

function getStorageAvailabilityFailedAction(error) {
  return {
    type: GET_STORAGE_AVAILABILITY_FAILED,
    error
  };
}

/* Service request actions */
function getServiceListDataAction() {
  return {
    type: GET_SERVICES_LIST_DATA
  };
}

function getServiceListSuccessAction(servicesList) {
  return {
    type: GET_SERVICES_LIST_DATA_SUCCESS,
    servicesList
  };
}

/* Top ten bucket OPS request actions */
function getTopTenBucketOPSDataAction(value) {
  return {
    type: GET_TOP_TEN_OPS_DATA,
    value
  };
}

function getTopTenBucketOPSDataSuccessAction(topTenBucketOPS) {
  return {
    type: GET_TOP_TEN_OPS_DATA_SUCCESS,
    topTenBucketOPS
  };
}

/* Top ten bucket IN request actions */
function getTopTenBucketINDataAction(value) {
  return {
    type: GET_TOP_TEN_IN_DATA,
    value
  };
}

function getTopTenBucketINDataSuccessAction(topTenBucketIN) {
  return {
    type: GET_TOP_TEN_IN_DATA_SUCCESS,
    topTenBucketIN
  };
}

/* Top ten bucket Out request actions */
function getTopTenBucketOUTDataAction(value) {
  return {
    type: GET_TOP_TEN_OUT_DATA,
    value
  };
}

function getTopTenBucketOUTDataSuccessAction(topTenBucketOUT) {
  return {
    type: GET_TOP_TEN_OUT_DATA_SUCCESS,
    topTenBucketOUT
  };
}

/* Global health request actions */
function getGlobalHealthDataAction() {
  return {
    type: GET_GLOBAL_HEALTH_DATA
  };
}

function getGlobalHealthDataSuccessAction(globalHealth) {
  return {
    type: GET_GLOBAL_HEALTH_DATA_SUCCESS,
    globalHealth
  };
}

// Sagas
function* getS3StoragesServicesData() {
  yield put(getS3StorageServicesInProgressAction());

  let requestURL = 's3_clusters';

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    const responseItems = response._items;
    yield put(getS3StorageServicesSuccessAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(getS3StorageServicesFailedAction());
    }
  }
}

function* updateS3ManagementData(action) {
  let requestURL = 's3_clusters/';
  if (action.storageId !== undefined) {
    requestURL += `${action.storageId}`;
  }

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  let formData = action.data;
  let payload = {
    endpoint: (formData && formData.endpoint) || '',
    identisee_url: (formData && formData.accountUrl) || '',
    s3browser_url: (formData && formData.browserUrl) || '',
    name: (formData && formData.description) || ''
  };

  try {
    let response = yield call(callApiPost, requestURL, {
      method: action.storageId === undefined ? 'POST' : 'PUT',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(payload)
    });

    yield put(updateS3ManagementSuccessAction());
    yield put(getS3StorageServicesSuccessAction(response._items));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(updateS3ManagementFailedAction());
    }
  }
}

function* getStorageAvailability(action) {
  yield put(getStorageAvailabilityInProgressAction());

  const now = moment().toISOString();
  const to = moment()
    .subtract(action.from, 'days')
    .toISOString();

  let requestURL = `s3_clusters/${action.storageId}/availability/?start_date=${to}&end_date=${now}`;

  let responseItems = {};

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response;

    yield put(getStorageAvailabilitySuccessAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(getStorageAvailabilityFailedAction(error));
    }
  }
}

/**
 * GET Service list
 * -> Request: GET
 */
function* getServicesListData() {
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  let query = {
    size: 500,
    query: {
      bool: {
        filter: {
          range: {
            '@timestamp': {
              gte: getDateRangeS3().stop,
              lte: getDateRangeS3().start,
              format: 'epoch_millis'
            }
          }
        }
      }
    },
    sort: {
      '@timestamp': {
        order: DESC,
        unmapped_type: 'boolean'
      }
    },
    stored_fields: ['*', '_source'],
    script_fields: {}
  };
  let requestURL = 'es_proxy/scality-s3-group-health/_search';

  try {
    let response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    /**
     * Get the list of services with the most recent timestamp
     */
    const result = _.chain(response.hits.hits)
      .groupBy('_source.groupName')
      .values()
      .map(service => _.maxBy(service, '_source.@timestamp'))
      .value();

    yield put(getServiceListSuccessAction(result));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
    if (error && error.status >= 500 && error.status < 600) {
      yield put(
        setApiRequestErrorMsg({
          message: 'Unable to fetch Storages Services information.'
        })
      );
      yield put(setApiRequestError());
    }
  }
}

/**
 * Top ten bucket
 * GET OPS
 * -> Request: GET
 */
function* getTopTenBucketOPSData(value) {
  let ActiveRangeSelection = value.value.value;
  let now = moment().valueOf();

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  let query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              '@timestamp': {
                gte: timeRange(now, ActiveRangeSelection),
                lte: now,
                format: 'epoch_millis'
              }
            }
          },
          {
            query_string: {
              analyze_wildcard: true,
              query: 'service.keyword:s3 AND NOT httpCode:404'
            }
          }
        ]
      }
    },
    aggs: {
      datas: {
        terms: {
          field: 'bucketName.keyword',
          size: 10,
          order: { _count: DESC },
          min_doc_count: 1
        },
        aggs: {}
      }
    }
  };
  let requestURL = 'es_proxy/scality-s3-metrics/_search';

  try {
    let response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    yield put(
      getTopTenBucketOPSDataSuccessAction(response.aggregations.datas.buckets)
    );
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(getTopTenBucketOPSDataSuccessAction([]));
    }
  }
}

/**
 * Top ten bucket
 * GET IN Bound
 * -> Request: GET
 */
function* getTopTenBucketINData(value) {
  let ActiveRangeSelection = value.value.value;
  let now = moment().valueOf();

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  let query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              '@timestamp': {
                gte: timeRange(now, ActiveRangeSelection),
                lte: now,
                format: 'epoch_millis'
              }
            }
          },
          {
            query_string: {
              query:
                'service.keyword:s3 AND httpMethod:put AND NOT httpCode:404'
            }
          }
        ]
      }
    },
    aggs: {
      datas: {
        terms: {
          field: 'bucketName.keyword',
          size: 10,
          order: { traffic: DESC },
          min_doc_count: 1
        },
        aggs: {
          traffic: {
            sum: { field: 'contentLength' }
          }
        }
      }
    }
  };
  let requestURL = 'es_proxy/scality-s3-metrics/_search';

  try {
    let response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    yield put(
      getTopTenBucketINDataSuccessAction(response.aggregations.datas.buckets)
    );
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(getTopTenBucketINDataSuccessAction([]));
    }
  }
}

/**
 * Top ten bucket
 * GET OUT Bound
 * -> Request: GET
 */
function* getTopTenBucketOUTData(value) {
  let ActiveRangeSelection = value.value.value;
  let now = moment().valueOf();

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  let query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              '@timestamp': {
                gte: timeRange(now, ActiveRangeSelection),
                lte: now,
                format: 'epoch_millis'
              }
            }
          },
          {
            query_string: {
              query:
                'service.keyword:s3 AND httpMethod:get AND NOT httpCode:404'
            }
          }
        ]
      }
    },
    aggs: {
      datas: {
        terms: {
          field: 'bucketName.keyword',
          size: 10,
          order: { traffic: DESC },
          min_doc_count: 1
        },
        aggs: {
          traffic: {
            sum: { field: 'contentLength' }
          }
        }
      }
    }
  };
  let requestURL = 'es_proxy/scality-s3-metrics/_search';

  try {
    let response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    yield put(
      getTopTenBucketOUTDataSuccessAction(response.aggregations.datas.buckets)
    );
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    } else {
      yield put(getTopTenBucketOUTDataSuccessAction([]));
    }
  }
}

/**
 * Global health
 * GET Global health state
 * -> Request: GET
 */
function* getGlobalHealthData() {
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  let query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              '@timestamp': {
                gte: getDateRangeS3().stop,
                lte: getDateRangeS3().start,
                format: 'epoch_millis'
              }
            }
          }
        ]
      }
    },
    aggs: {
      health: {
        terms: {
          field: 'healthcheck'
        },
        aggs: {
          agg_by_timestamp: {
            terms: {
              field: '@timestamp',
              size: 500,
              min_doc_count: 1
            }
          }
        }
      }
    }
  };
  let requestURL = 'es_proxy/scality-stats-s3/_search';

  try {
    let response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    /**
     * This logic is here to get the lastest timestamp
     */
    const mostRecentHealthData = _.chain(response.aggregations.health.buckets)
      .reduce((prev, bucket) => {
        return [...prev, _.maxBy(bucket.agg_by_timestamp.buckets, 'key')];
      }, [])
      .maxBy('key')
      .value();

    /**
     * Use `mostRecentHealthData` to create GlobalHealth latest data
     */
    const result = _.chain(response.aggregations.health.buckets)
      .reduce((prev, bucket, list) => {
        const mostRecentBucket = bucket.agg_by_timestamp.buckets.find(
          b => b.key === mostRecentHealthData.key
        );
        if (mostRecentBucket) {
          return [
            ...prev,
            {
              key: bucket.key,
              key_as_string: bucket.key_as_string,
              doc_count: mostRecentBucket.doc_count
            }
          ];
        } else {
          return prev;
        }
      }, [])
      .value();

    yield put(getGlobalHealthDataSuccessAction(result));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* s3Saga() {
  yield all([
    takeEvery(UPDATE_S3_MANAGEMENT, updateS3ManagementData),
    takeEvery(GET_S3_STORAGE_SERVICES, getS3StoragesServicesData),
    takeEvery(GET_STORAGE_AVAILABILITY, getStorageAvailability),
    takeEvery(GET_SERVICES_LIST_DATA, getServicesListData),
    takeEvery(GET_TOP_TEN_OPS_DATA, getTopTenBucketOPSData),
    takeEvery(GET_TOP_TEN_IN_DATA, getTopTenBucketINData),
    takeEvery(GET_TOP_TEN_OUT_DATA, getTopTenBucketOUTData),
    takeEvery(GET_GLOBAL_HEALTH_DATA, getGlobalHealthData)
  ]);
}

// Selectors
function selectS3State(state) {
  return state.get('s3');
}

function selectS3Storages(state) {
  return selectS3State(state).get('s3storages');
}

function selectS3StorageInProgress(state) {
  return selectS3State(state).get('s3storageInProgress');
}

function selectS3FromUrl(state, props) {
  const storages = selectS3Storages(state) || [];

  if (props && props.match && props.match.params && props.match.params.id) {
    return (
      storages.find(
        storage => storage.id === parseInt(props.match.params.id, 10)
      ) || {}
    );
  } else {
    return {};
  }
}

function selectStorageAvailabilityProgress(state) {
  return selectS3State(state).get('storageAvailabilityInProgress');
}

function selectStorageAvailability(state) {
  return selectS3State(state).get('storageAvailability');
}

function selectGlobalHealth(state) {
  return selectS3State(state).get('globalHealth');
}

function selectServiceList(state) {
  return selectS3State(state).get('servicesList');
}

function selectTopTenBucketOPS(state) {
  return selectS3State(state).get('topTenBucketOPS');
}

function selectTopTenBucketIN(state) {
  return selectS3State(state).get('topTenBucketIN');
}

function selectTopTenBucketOUT(state) {
  return selectS3State(state).get('topTenBucketOUT');
}

export {
  getS3StorageServicesAction,
  getS3StorageServicesInProgressAction,
  getS3StorageServicesSuccessAction,
  getS3StorageServicesFailedAction,
  updateS3ManagementAction,
  updateS3ManagementSuccessAction,
  updateS3ManagementFailedAction,
  getStorageAvailabilityAction,
  getStorageAvailabilityInProgressAction,
  getStorageAvailabilitySuccessAction,
  getStorageAvailabilityFailedAction,
  getServiceListDataAction,
  getServiceListSuccessAction,
  getTopTenBucketOPSDataAction,
  getTopTenBucketOPSDataSuccessAction,
  getTopTenBucketINDataAction,
  getTopTenBucketINDataSuccessAction,
  getTopTenBucketOUTDataAction,
  getTopTenBucketOUTDataSuccessAction,
  getGlobalHealthDataAction,
  getGlobalHealthDataSuccessAction,
  s3Saga,
  selectS3Storages,
  selectS3StorageInProgress,
  selectS3FromUrl,
  selectStorageAvailabilityProgress,
  selectStorageAvailability,
  selectGlobalHealth,
  selectServiceList,
  selectTopTenBucketOPS,
  selectTopTenBucketIN,
  selectTopTenBucketOUT
};
