import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { CircleStatus } from './CircleStatus';
import { getStatusColor } from '../utils/status';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  text-transform: uppercase;

  > svg {
    margin-right: 10px;
  }
`;

const StatusDisplay = props => {
  const { status, statusState } = props;
  const color = getStatusColor(status);
  return (
    <Container color={color}>
      <CircleStatus status={status} />
      <>{statusState.join(' ')}</>
    </Container>
  );
};

StatusDisplay.propTypes = {
  status: Proptypes.string,
  statusState: Proptypes.arrayOf(Proptypes.string)
};

StatusDisplay.defaultProps = {};

export default StatusDisplay;
