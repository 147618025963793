import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const VolumeConfigGroupTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`Volume Configuration Groups`}</HelpPopupTitle>
    <HelpPopupContent>
      <Ul>
        <Li>
          {`To add a new configuration group, click on the plus ("+") button.`}
        </Li>
        <Li>
          {`To edit/delete a configuration group or see its details, click anywhere on the row.`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default VolumeConfigGroupTips;
