import React from 'react';
import { HelpPopupContainer, HelpPopupTitle, HelpPopupContent } from './Tips';

const HardwareStatusTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`Hardware Status`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Hardware Status conveys the status for the servers and disks in use by RING software and the Storage Services. Servers are grouped by zone (or failure domain), with the zone name displayed atop the list of servers.`}
    </HelpPopupContent>
    <HelpPopupContent>
      {`Clicking on any server name results in the display in the right panel of detailed information correlating to that server, whereas clicking on any status icon results in the filtering of the component list according to status.`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default HardwareStatusTips;
