import React, { Component } from 'react';
import styled from 'styled-components';
import * as cssVariables from './ui/variables';
import config from '../config';

/**
 * This component is pretty awful to read
 * However, there are not a lot of logic so it's still manageable to
 * keep it that way.
 * IMO, it's not worth to spend too much effort on it.
 */
const SupportContainer = styled.div`
  height: 100vh;
  overflow-x: hidden;
  padding: 76px 40px 20px 40px;

  .help-support {
    .header {
      h1 {
        padding-left: 10px;
        border-left: 2px solid #fff;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
      }
    }

    .help-body {
      display: flex;
      background-color: #221d1d;
      width: 100%;
      min-height: calc(100vh - 172px);
      height: 100%;
      margin-top: 20px;
      align-items: center;
      justify-content: center;

      .list-container {
        &.active {
          padding: 0;
          width: 30%;
          float: left;
          background: #130e0e;
          align-self: stretch;

          ol {
            margin-top: 30px;

            li {
              width: 100%;
              background: none;
              padding: 23px 30px;
              border-bottom: 1px solid #221d1d;
              margin: 0;

              &:last-child {
                border: none;
              }

              &.active {
                background-color: #221d1d;

                &:before {
                  background: #fff;
                  color: #464f56;
                }

                p {
                  color: #fff;
                }

                .right-arrow {
                  display: none;
                }
              }

              &:hover {
                background-color: ${cssVariables.panelSlctBg};
                &:before {
                  left: 30px;
                }

                p {
                  margin: 0 0 0 65px;
                }

                .right-arrow {
                  display: none;
                }
              }
            }
          }
        }

        ol {
          counter-reset: item;
          margin: 0;
          padding: 0;

          li {
            font-size: 16px;
            color: #fff;
            counter-increment: item;
            position: relative;
            padding: 18px 30px;
            background: #151313;
            width: 470px;
            margin: 0 auto 10px;

            &:before {
              content: counter(item);
              background: #221d1d;
              border-radius: 4px;
              color: #fff;
              text-align: center;
              width: 35px;
              height: 35px;
              position: absolute;
              top: 0;
              left: 30px;
              bottom: 0;
              margin: auto;
              font-size: 17px;
              font-weight: ${cssVariables.semiBold};
              line-height: 2;
            }

            &.active {
              background-color: ${cssVariables.bgClr};
              color: ${cssVariables.txtHighlight};

              .right-arrow {
                display: block;
              }
            }

            &:hover {
              background-color: #130e0e;
              color: ${cssVariables.txtHighlight};
              cursor: pointer;

              &:before {
                background: #fff;
                color: #464f56;
              }

              p {
                color: #fff;
              }

              .right-arrow {
                display: block;
              }
            }

            &:last-child {
              p {
                border-bottom: 0;
              }
            }

            p {
              font-weight: 400;
              position: relative;
              margin: 0 0 0 65px;
              color: #76828f;
            }

            .right-arrow {
              color: ${cssVariables.fontClr};
              font-size: 18px;
              display: none;
              position: absolute;
              right: 15px;
              top: 0;
              bottom: 0;
              margin: auto;
              color: #22272b;
              line-height: 1.3;
            }
          }
        }
      }

      .content-container {
        width: 70%;
        background-color: #221d1d;
        float: left;
        padding: 20px;
        align-self: stretch;

        .description {
          .title {
            font-size: 20px;
            font-weight: ${cssVariables.semiBold};
            text-transform: capitalize;
            margin-bottom: 30px;
          }

          .sub-title {
            color: #a4acb4;
            font-size: 12px;
            font-weight: ${cssVariables.semiBold};
            text-transform: uppercase;
            margin: 0 0 24px;
            line-height: 1;
          }

          .section-sub-title {
            font-size: 14px;
            text-transform: none;
            color: #fff;
            margin-bottom: 10px;
          }

          .text-block {
            margin-bottom: 30px;
          }

          p {
            font-size: 16px;
            line-height: 26px;
          }

          a {
            color: ${cssVariables.mainBlue};
            font-size: 20px;
            text-decoration: none;
            line-height: 1;

            &:hover {
              text-decoration: none;
            }

            &.contact {
              font-size: 16px;
              color: #fff;
            }
          }
          .support-table {
            margin-bottom: 0;
          }
          .inner-block {
            background: #130e0e;
            padding: 20px;
            margin-bottom: 38px;
            border-radius: 6px;
            &.code {
              padding: 23px 21px;

              p {
                font-size: 14px;
                color: #a4acb4;
                line-height: 22px;
                line-height: 1;

                &:last-child {
                  margin: 0;
                }
              }
            }
          }

          .contact-block-holder {
            margin-top: 15px;

            .flag-wrapper {
              margin: 4px 8px 0 0;
              float: left;
              width: 25px;
              height: 20px;

              img {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }

          .contact-block {
            margin-bottom: 45px;

            &.ftp-server {
              margin: 0;

              .sub-title {
                margin-bottom: 12px;
              }

              a {
                color: #00a3e0;
                font-size: 16px;
              }
            }

            .sub-title {
              margin: 0;
            }
          }

          .support-table {
            border: 1px solid #151313;
            thead {
              tr {
                th {
                  font-weight: 600;
                  font-size: 14px;
                  color: #fff;
                  padding: 20px 15px;
                  background-color: #130e0e;
                  border-bottom: 1px solid #221d1d;
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 15px;
                  border-top: none;
                  p {
                    color: #fff;
                    font-size: 13px;
                    line-height: 18px;
                  }
                }
              }
              tr:nth-child(odd) {
                background-color: #1f1a1a;
              }
              tr:nth-child(even) {
                background-color: #221d1d;
              }
            }
          }
        }
      }
    }
  }
`;

const SupportHeader = styled.h1`
  padding-left: 10px;
  border-left: 2px solid #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
`;

export default class Support extends Component {
  constructor() {
    super();

    this.state = {
      qID: 0
    };

    this.onQuestionClick = this.onQuestionClick.bind(this);
  }

  onQuestionClick(id) {
    this.setState({ qID: id });
  }

  render() {
    let { qID } = this.state;

    return (
      <SupportContainer>
        <div className="help-support">
          <SupportHeader>Support</SupportHeader>
          <div className="help-body">
            <div
              className={qID !== 0 ? 'list-container active' : 'list-container'}
            >
              <ol>
                <li
                  className={qID === 1 ? 'active' : null}
                  onClick={e => this.onQuestionClick(1)}
                >
                  <p>
                    Contacting Scality Support
                    <i className="fa fa-angle-right right-arrow" />
                  </p>
                </li>
                <li
                  className={qID === 2 ? 'active' : null}
                  onClick={e => this.onQuestionClick(2)}
                >
                  <p>
                    Scality Issue Prioritization{' '}
                    <i className="fa fa-angle-right right-arrow" />
                  </p>
                </li>
                <li
                  className={qID === 3 ? 'active' : null}
                  onClick={e => this.onQuestionClick(3)}
                >
                  <p>
                    Using sReport Tool{' '}
                    <i className="fa fa-angle-right right-arrow" />
                  </p>
                </li>
                <li
                  className={qID === 4 ? 'active' : null}
                  onClick={e => this.onQuestionClick(4)}
                >
                  <p>
                    FTP Server
                    <i className="fa fa-angle-right right-arrow" />
                  </p>
                </li>
              </ol>
            </div>
            {qID !== 0 ? (
              <div className="content-container">
                {qID === 1 ? (
                  <div className="description">
                    <h3 className="title">Contacting Scality Support</h3>
                    <div className="inner-block">
                      <p>To Open a Support Ticket, Please Sign in on </p>
                      <a
                        href={config.supportUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {config.supportUrl}
                      </a>
                    </div>
                    <div className="text-block">
                      <p>
                        For Priority 1 Critical Emergencies Only, After Opening
                        a Ticket Please Contact Our Dedicated Support Team
                        Through the On-Call Service:
                      </p>
                    </div>
                    <div className="row contact-block-holder">
                      <div className="contact-block col-sm-4">
                        <div className="col-sm-3 flag-wrapper">
                          <img
                            alt="flag AUSTRALIA"
                            src={require('../assets/images/flags/australia.png')}
                          />
                        </div>
                        <h4 className="sub-title">Australia & APAC</h4>
                        <a href="tel:+611300954703" className="contact">
                          +61 1300 954 703
                        </a>
                      </div>
                      <div className="contact-block col-sm-4">
                        <div className="col-sm-3 flag-wrapper">
                          <img
                            alt="flag US"
                            src={require('../assets/images/flags/us.png')}
                          />
                        </div>
                        <h4 className="sub-title">U.S.A. Toll Free</h4>
                        <a href="tel:+18003197090" className="contact">
                          +1 800 319 7090
                        </a>
                      </div>
                      <div className="contact-block col-sm-4">
                        <div className="col-sm-3 flag-wrapper">
                          <img
                            alt="flag UK"
                            src={require('../assets/images/flags/uk.png')}
                          />
                        </div>
                        <h4 className="sub-title">U.K. & International</h4>
                        <a href="tel:+442033181073" className="contact">
                          +44 2033 181 073
                        </a>
                      </div>
                      <div className="contact-block col-sm-4">
                        <div className="col-sm-3 flag-wrapper">
                          <img
                            alt="flag FR"
                            src={require('../assets/images/flags/france.png')}
                          />
                        </div>
                        <h4 className="sub-title">France</h4>
                        <a href="tel:+33184885570" className="contact">
                          +33 1 8488 5570
                        </a>
                      </div>
                      <div className="contact-block col-sm-4">
                        <div className="col-sm-3 flag-wrapper">
                          <img
                            alt="flag JAPAN"
                            src={require('../assets/images/flags/japan.png')}
                          />
                        </div>
                        <h4 className="sub-title">Japan</h4>
                        <a href="tel:+81345881408" className="contact">
                          +81 3 4588 1408
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
                {qID === 2 ? (
                  <div className="description">
                    <h3 className="title">Scality Issue Prioritization </h3>
                    <div className="text-block">
                      <p>
                        Assignment of an impact priority is required when
                        opening a case.  Please refer to the following
                        guidelines for assessing correct priority level:
                      </p>
                    </div>
                    <table className="support-table table">
                      <thead>
                        <tr>
                          <th className="col-sm-2">Priority</th>
                          <th className="col-sm-5">
                            Scality & Customer availability
                          </th>
                          <th className="col-sm-5">Examples</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p>P1 Major</p>
                          </td>
                          <td>
                            <p>
                              Resources dedicated 24x7x365 until a resolution or
                              workaround is in place.
                            </p>
                          </td>
                          <td>
                            <p>
                              Total loss or continuous instability of
                              mission-critical functionality. Inability to use a
                              feature or functionality that is currently relied
                              upon for mission-critical functionality
                            </p>
                            <p>Data loss or corruption</p>
                            <p>Security threat</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>P2 Moderate</p>
                          </td>
                          <td>
                            <p>
                              Resources available Monday through Friday during
                              local business hours until a resolution or
                              workaround is in place.
                            </p>
                          </td>
                          <td>
                            <p>
                              Issues that are impairing, but not causing a total
                              loss of mission-critical functionality.
                              Intermittent issues that are mission-critical
                              functionality.{' '}
                            </p>
                            <p>
                              Inability to deploy a feature that is not
                              currently relied upon for mission-critical
                              functionality.{' '}
                            </p>
                            <p>
                              Loss of redundancy of critical hardware component.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>P3 Minor</p>
                          </td>
                          <td>
                            <p>
                              Resources available Monday through Friday during
                              local business hours until a resolution or
                              workaround is in place.
                            </p>
                          </td>
                          <td>
                            <p>
                              Issues in the network or on the system that are
                              not causing impact to mission-critical
                              functionality.{' '}
                            </p>
                            <p>
                              Non-repeated issues that have impacted
                              mission-critical functionality but have since
                              recovered.{' '}
                            </p>
                            <p>
                              Issues seen in a test or pre-production
                              environment that would normally cause adverse
                              impact to a production network. Time sensitive
                              questions or information requests. Workaround in
                              place for Priority 1 and Priority 2 issues.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Service and/or Information Requests</p>
                          </td>
                          <td>
                            <p>
                              Resources available Monday through Friday during
                              local business hours
                            </p>
                          </td>
                          <td>
                            <p>
                              Questions related to use and operation including
                              basic instruction or assistance related to
                              functional errors. Does not apply to any hardware.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : null}
                {qID === 3 ? (
                  <div className="description">
                    <h3 className="title">Using sReport Tool</h3>
                    <div className="text-block">
                      <p>
                        The sreport tool gathers Scality related configuration
                        information and logs used for Scality support purposes.
                        It is run on demand only, does not make changes to any
                        component installed on the system, and can be safely run
                        on live systems (as all commands issued by the tool are
                        read-only).{' '}
                      </p>
                    </div>
                    <div className="text-block">
                      <p>
                        The Scality recommended Salt version 2016.3.6 or higher
                        is required for sreport.
                      </p>
                    </div>
                    <h4 className="section-sub-title">
                      When opening a support case please generate the sreport
                      file:
                    </h4>
                    <div className="inner-block code">
                      <p>{`sreport --salt ‘*’ --scality-only --customer <customer ID, often the FQDN e.g. scality.com>`}</p>
                    </div>
                    <h4 className="section-sub-title">
                      And if using the S3 connector also run:
                    </h4>
                    <div className="inner-block code">
                      <p>{`sreport --salt ‘*’ --scality-only --S3-metadata-status --customer <customer ID/FQDN>`}</p>
                    </div>
                    <div className="text-block">
                      <h4 className="sub-title">Note</h4>
                      <p>
                        Internet connection is required for automated upload of
                        file, available only in v7 sreport, or to send the
                        output archive to Scality or for FTP upload.{' '}
                      </p>
                    </div>
                  </div>
                ) : null}
                {qID === 4 ? (
                  <div className="description">
                    <h3 className="title">FTP Server</h3>
                    <div className="text-block">
                      <p>
                        When resolution of a technical issue requires sending
                        many files or large files from a test or production
                        site, uploading those files securely to the Scality ftp
                        server may be preferable to sending them via email or
                        attaching them to a support ticket.
                      </p>
                    </div>
                    <div className="row text-block">
                      <div className="contact-block ftp-server col-sm-3">
                        <h4 className="sub-title">server</h4>
                        <span className="contact">ftp.scality.com</span>
                      </div>
                      <div className="contact-block ftp-server col-sm-9">
                        <h4 className="sub-title">login</h4>
                        <p>
                          same credentials as used for{' '}
                          <a href={config.docs.base} className="contact">
                            {config.docs.base}
                          </a>
                        </p>
                      </div>
                    </div>
                    <h4 className="section-sub-title">
                      Using the command line from an sftp client, you can upload
                      files to the Scality FTP server directly from your system:
                    </h4>
                    <div className="inner-block code">
                      <p>
                        [root@remote.server.com ~]# sftp
                        julius.caesar@ftp.scality.com
                      </p>
                      <p>Connecting to ftp.scality.com...</p>
                      <p>julius.caesar@ftp.scality.com's password: xxxxxxx</p>
                      <br />
                      <p>&nbsp;&nbsp;&nbsp;&nbsp; sftp> put test.log</p>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Uploading install.log to
                        /home.13620807555525956879/julius.caesar/test.log
                      </p>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        test.log
                      </p>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        100%   18KB  17.7KB/s   00:00
                      </p>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp; sftp></p>
                    </div>
                    <p>
                      Any desktop ftp or sftp client can be used. The file(s)
                      are uploaded to the location associated with your Scality
                      username, and are accessible only to yourself and to
                      Scality administrators.{' '}
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </SupportContainer>
    );
  }
}
