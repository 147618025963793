import React from 'react';
import { HelpPopupContainer, HelpPopupTitle, HelpPopupContent } from './Tips';

const RingObjectsTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`RING Objects`}</HelpPopupTitle>
    <HelpPopupContent>
      {`The Objects metric reports the total number of objects on the RING.`}
    </HelpPopupContent>
    <HelpPopupContent>
      {`The Average Object Size metric computes the average size of objects stored on the RING.`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default RingObjectsTips;
