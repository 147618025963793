import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { createZoneAction } from '../ducks/zones';
import HelpPopover from '../components/common/HelpPopover';
import { Modal } from '../components/ui/Modal';
import SimpleTips from '../tips/SimpleTips';

import SimpleForm from './ZonesAddForm';

class AddZone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: this.props.location.pathname.endsWith('new')
    };
    this.alertAddZone = this.alertAddZone.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ showModal: nextProps.location.pathname.endsWith('new') });
  }

  handleCloseModal() {
    this.props.history.push('/zones');
  }

  cancelAdd = () => {
    this.handleCloseModal();
  };

  alertAddZone(values) {
    this.props.addZone(values);
    this.handleCloseModal();
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <LinkButton to="/zones/new">
          <span className="fa fa-plus" style={{ verticalAlign: 'middle' }} />
        </LinkButton>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          className="ReactModal__Content_Zone"
        >
          <h3>
            Add Zone
            <HelpPopover
              content={
                <SimpleTips
                  title="ZONES"
                  content={`Zones are failure domains, which are areas of a network impacted when a key device or service experiences problems (e.g., racks, datacenters, or any logical grouping to which RING nodes or connectors are assigned).`}
                />
              }
            />
          </h3>
          <div className="modal--content clearfix">
            <SimpleForm
              onSubmit={this.alertAddZone}
              parentZoneMethodCloseModal={this.cancelAdd}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const LinkButton = styled(Link)`
  font-family: 'Work Sans', sans-serif;
  background-color: #2b65ad;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;
`;

AddZone.propTypes = {
  addZone: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    addZone: zone => {
      dispatch(createZoneAction(zone));
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AddZone));
