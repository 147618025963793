import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ol,
  Ul,
  Li
} from './Tips';

const CGNfsSharesTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`NFS Shares`}</HelpPopupTitle>
    <HelpPopupContent>
      {`To create a share:`}
      <Ol>
        <Li>
          {`Provide a "Share Path": directory path starting with '/', if that
          directory does not exist it will be created - e.g.:
          /volume/Marketing-Backups/2018`}
        </Li>
        <Li>
          {`Provide a "Client Network / IP": host or network to which the share is
          being shared. It can be a single host (IP, hostname or fqdn),
          wildcards ('*' or '?') or IP networks - e.g.: 'myhost' or
          'host1.mydomain.com', '*', '10.0.0.*, '*.mydomain.com'.`}
        </Li>
        <Li>
          {`Select an "Option" from the following list:`}
          <Ul>
            <Li>{`Default (Read-Write)`}</Li>
            <Li>
              {`Allow Root Access (read-write with root access [also known as
              no_root_squash])`}
            </Li>
            <Li>{`Map All Users (Map all users to UID/GID)`}</Li>
            <Li>{`Read-Only (Read-Only)`}</Li>
            <Li>{`Expert (Expert mode, options can be tuned)`}</Li>
          </Ul>
        </Li>
      </Ol>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default CGNfsSharesTips;
