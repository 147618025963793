/**
 * ObjectStorage component, included in the StorageServices panel
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip from './Tooltip';
import { STATUS_NOT_DEPLOYED } from '../constants.js';

import { computeCSSClasses } from '../utils/helpers';

import * as StyledStorage from './ui/StorageContainer';
import * as StyledTooltip from './ui/Tooltip';

import imgCloudIcon from '../assets/images/dashboard-icons/cloud-icon.png';
import imgCloudIconWarning from '../assets/images/dashboard-icons/cloud-icon-warning.png';
import imgCloudIconCritical from '../assets/images/dashboard-icons/cloud-icon-critical.png';
import imgCloudIconInactive from '../assets/images/dashboard-icons/cloud-icon-inactive.png';

const ObjectStorage = props => {
  const { storage, openDetailPanel } = props;
  const clickable = storage.cluster_health !== STATUS_NOT_DEPLOYED;
  const { errorMsg, statusClass } = computeCSSClasses(storage.cluster_health);

  const ObjectStorageContainer = styled(StyledStorage.StorageContainer)`
    &:before {
      background: url(${props => {
          switch (props.status) {
            case 'warning':
              return imgCloudIconWarning;
            case 'error':
            case 'not-deployed':
              return imgCloudIconInactive;
            case 'critical':
              return imgCloudIconCritical;
            default:
              return imgCloudIcon;
          }
        }})
        no-repeat;
    }
  `;

  return (
    <Tooltip
      overlay={
        <StyledTooltip.StyledTooltipDiv>
          <h5>
            <b>Type: </b>
            S3
          </h5>
          <h5>
            <b>Endpoint:</b> {storage.endpoint}
          </h5>
        </StyledTooltip.StyledTooltipDiv>
      }
    >
      <ObjectStorageContainer
        status={statusClass}
        onClick={() => clickable && openDetailPanel(storage)}
      >
        <StyledStorage.StorageContent>
          <StyledStorage.EllipsisTitle>
            {storage.endpoint}
          </StyledStorage.EllipsisTitle>
          {errorMsg && (
            <StyledStorage.ErrorMessage>{errorMsg}</StyledStorage.ErrorMessage>
          )}
        </StyledStorage.StorageContent>
      </ObjectStorageContainer>
    </Tooltip>
  );
};

ObjectStorage.propTypes = {
  storage: PropTypes.shape({
    endpoint: PropTypes.string.isRequired,
    cluster_health: PropTypes.string
  }),
  openDetailPanel: PropTypes.func.isRequired
};

export default ObjectStorage;
