/**
 * Hardware tab components
 */

import React from 'react';
import PropTypes from 'prop-types';

import HardwareCommon from './Hardware';

function Hardware(props) {
  const { server } = props;

  return (
    <div>
      <HardwareCommon
        provided_model={server.server_type}
        {...server.hardware}
      />
    </div>
  );
}

Hardware.propTypes = {
  // TODO(gd): detail the required shape of a server object.
  server: PropTypes.shape({
    hardware: PropTypes.object.isRequired,
    server_type: PropTypes.string.isRequired
  }).isRequired
};

export default Hardware;
