import React, { Component } from 'react';
import SelectBox from './common/Select';
import GrafanaPanel from './GrafanaPanel';
import RingCodingObjectChartTips from '../tips/RingCodingObjectChartTips';
import RingTotalObjectChartTips from '../tips/RingTotalObjectChartTips';
import RingObjectToRebuildChartTips from '../tips/RingObjectToRebuildChartTips';

import { IFRAME_VARS, timeRangeOptions } from '../constants';
import config from '../config';
import { getFrameUrlOfRing } from '../utils/grafanaFrameSrcgenerator';

export default class RingStatsObjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: timeRangeOptions,
      selectedObjectChartOption: timeRangeOptions[1]
    };
  }

  onChartOptionChange(selectedOption) {
    this.setState({
      selectedObjectChartOption: selectedOption
    });
  }

  render() {
    const { ring } = this.props;
    const { chartOptions, selectedObjectChartOption } = this.state;

    const totalObjectsUrl = getFrameUrlOfRing(
      ring.id,
      config.ringCharts.objects.totalObjects,
      IFRAME_VARS.AUTO_REFRESH,
      selectedObjectChartOption.value
    );

    const codingObjectsUrl = getFrameUrlOfRing(
      ring.id,
      config.ringCharts.objects.codingObjects,
      IFRAME_VARS.AUTO_REFRESH,
      selectedObjectChartOption.value
    );

    const rebuildUrl = getFrameUrlOfRing(
      ring.id,
      config.ringCharts.objects.rebuildObjects,
      IFRAME_VARS.AUTO_REFRESH,
      selectedObjectChartOption.value
    );

    return (
      <div>
        <div>
          <SelectBox
            options={chartOptions}
            name="capacity_chart_options"
            value={selectedObjectChartOption}
            onChange={this.onChartOptionChange.bind(this)}
          />
        </div>
        <div className="vertical-scroll-wrapper">
          <GrafanaPanel
            iFrameSrc={rebuildUrl}
            panelTitle="Objects to Rebuild"
            popOverTemplate={<RingObjectToRebuildChartTips />}
          />
          <GrafanaPanel
            iFrameSrc={totalObjectsUrl}
            panelTitle="Total Objects"
            popOverTemplate={<RingTotalObjectChartTips />}
          />
          <GrafanaPanel
            iFrameSrc={codingObjectsUrl}
            panelTitle="Erasure Coding Objects"
            popOverTemplate={<RingCodingObjectChartTips />}
          />
        </div>
      </div>
    );
  }
}
