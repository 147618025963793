import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const ServerAddTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`Create Server`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Information pertinent to add a server:`}
      <Ul>
        <Li>
          <b>{`Name:`}</b>
          {` hostname or IP address`}
        </Li>
        <Li>
          <b>{`Management IP Address:`}</b>
          {`IP address for management traffic`}
        </Li>
        <Li>
          <b>{`Zone:`}</b>
          {` server location (zone or logical failure domain)`}
        </Li>
        <Li>
          <b>{`Type (optional):`}</b>
          {` text field into which information about type/brand/virtual can be input`}
        </Li>
        <Li>
          <b>{`Description (optional):`}</b>
          {` text field into which information pertinent to the server can be input`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default ServerAddTips;
