import styled from 'styled-components';

const Header = styled.div`
  margin-bottom: 15px;
  font-size: 18px;
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowHeader = styled.div`
  margin: 0 10px;
  i {
    color: grey;
  }
`;

const ContentContainer = styled.div`
  padding: 20px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BreadcrumbStatusContainer = styled.div`
  display: flex;
`;

export {
  ContentContainer,
  Header,
  Breadcrumb,
  ArrowHeader,
  PageHeader,
  BreadcrumbStatusContainer
};
