import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const S3ManagementTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`S3 Service`}</HelpPopupTitle>
    <HelpPopupContent>
      <Ul>
        <Li>
          <b>{`S3 Endpoint:`}</b>
          {`the FQDN endpoint used to reach the S3 service (could require DNS configuration), (e.g. s3.yourdomain.com)`}
        </Li>
        <Li>
          <b>{`Description:`}</b>
          {`free text field to provide whatever information is necessary about this S3 service`}
        </Li>
        <Li>
          <b>{`S3 Console URL:`}</b>
          {`from where actions are taken on Accounts, Users, Groups, Policies (also called Identisee), (e.g. http://s3.yourdomain.com/_/console/)`}
        </Li>
        <Li>
          <b>{`S3 Browser URL:`}</b>
          {`S3 Web client from where actions are taken on Objects, Versioning is enabled, ACLs are managed, (e.g. http://s3.yourdomain.com/_/s3browser/)`}
        </Li>
      </Ul>
      {`Note that when SSL certificates are set up 'http' can be replaced with 'https' in all of the above detailed URLs.`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default S3ManagementTips;
