import React from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
import monitoringIcon from '../../assets/images/nav/stats-icon.png';
import operationIcon from '../../assets/images/nav/config-icon.png';
import * as cssVariables from './variables';

const PanelContainer = styled.div`
  padding: 20px;
`;

const VerticalItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PanelTools = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SectionHeader = styled.div`
  margin-bottom: 15px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  display: inline-block;
  padding-left: 10px;
  border-left: 2px solid #fff;
`;

const ImgIcon = styled.div`
  background: url(${({ backgroundImg }) => backgroundImg || monitoringIcon})
    center no-repeat;
  width: 20px;
  height: 20px;
`;

const TooltipOverlay = styled.div`
  font-size: 10px;
  color: #fff;
  font-family: ${cssVariables.fontFamily};
  font-weight: ${cssVariables.semiBold};
`;

const LinkOverlayIcon = ({ icon, link, overlayText }) => {
  return (
    <Tooltip
      placement="top"
      overlay={<TooltipOverlay>{overlayText}</TooltipOverlay>}
    >
      <a target="_blank" href={link} rel="noopener noreferrer">
        <ImgIcon backgroundImg={icon} />
      </a>
    </Tooltip>
  );
};

const MonitoringAndOperationContainer = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  margin: ${({ right, left }) => {
    if (right) {
      return '0 15px 0 0';
    }
    if (left) {
      return '0 0 0 15px';
    } else {
      return '0';
    }
  }};
`;

const MonitoringAndOperationLink = ({ monitoringLink, operationLink }) => (
  <MonitoringAndOperationContainer>
    <IconContainer right>
      <LinkOverlayIcon
        icon={monitoringIcon}
        link={monitoringLink}
        overlayText="Advanced Monitoring"
      />
    </IconContainer>
    <IconContainer left>
      <LinkOverlayIcon
        icon={operationIcon}
        link={operationLink}
        overlayText="Monitoring"
      />
    </IconContainer>
  </MonitoringAndOperationContainer>
);

export {
  PanelContainer,
  VerticalItemsContainer,
  PanelTools,
  SectionHeader,
  SectionTitle,
  LinkOverlayIcon,
  MonitoringAndOperationLink,
  TooltipOverlay
};
