import React, { Component } from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { Search } from 'styled-icons/fa-solid/Search';

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
`;

const Input = styled.input`
  display: block;
  text-indent: 25px;
  height: 30px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #120e0e;
  background-color: #221d1d;
  font-size: 14px;
  line-height: 1.42857;
  color: #555;
`;

const SearchIcon = styled(Search)`
  position: absolute;
  top: 7px;
  left: 6px;
  color: #76828f;
`;

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      searchValue: ''
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  onSearchChange(e) {
    this.props.onSearchChange(e.target.value);
    this.setState({ searchValue: e.target.value });
  }

  render() {
    let { searchValue } = this.state;

    return (
      <SearchWrapper>
        <SearchIcon size={17} />
        <Input
          type="text"
          value={searchValue}
          onChange={this.onSearchChange}
          placeholder={this.props.placeholder || 'Search'}
          data-cy={this.props.cyId}
        />
      </SearchWrapper>
    );
  }
}

SearchBar.propTypes = {
  placeholder: Proptypes.string,
  onSearchChange: Proptypes.func
};

SearchBar.defaultProps = {
  placeholder: null,
  onSearchChange: () => {}
};

export default SearchBar;
