import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormFieldRender } from '../components/common/FormFieldRender';
import { zoneManagementFormValidation } from '../utils/formValidator';
import { ButtonOk, ButtonM } from '../components/ui/Button';
import { Form, FormGroup, FormActions } from '../components/ui/Form';

const AddZone = props => {
  const { handleSubmit, submitting } = props;

  function clickCancel() {
    props.parentZoneMethodCloseModal();
  }

  return (
    <Form className="form-wrapper" onSubmit={handleSubmit}>
      <FormGroup className="form-group clearfix">
        <Field
          name="name"
          type="text"
          component={FormFieldRender}
          label="Name"
        />
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <Field
          name="location"
          type="text"
          component={FormFieldRender}
          label="Location"
        />
      </FormGroup>
      <FormGroup className="form-group clearfix">
        <Field
          name="description"
          type="text"
          component={FormFieldRender}
          label="Description"
        />
      </FormGroup>
      <FormActions className="clearfix form--actions form--actions--twoside">
        <ButtonOk
          type="submit"
          className="right btn btn--ok"
          disabled={submitting}
        >
          Save
        </ButtonOk>
        <ButtonM
          type="button"
          className="right btn btn--m"
          onClick={() => {
            clickCancel();
          }}
        >
          Cancel
        </ButtonM>
      </FormActions>
    </Form>
  );
};

export default reduxForm({
  // A unique identifier for this form
  form: 'AddZone',
  fields: ['name', 'location', 'description'],
  validate: zoneManagementFormValidation
})(AddZone);
