export default {
  api: {
    host: ''
  },
  s3MonitoringCharts: {
    service: {
      health: '26',
      listOfServices: '25',
      responseCodes: '21'
    },
    usage: {
      operations: '19',
      inbound: '20',
      outbound: '27'
    },
    performance: {
      operations: '23',
      latency: '22',
      bandwidth: '24'
    }
  },
  singleDiskCharts: {
    performance: {
      operations: '16',
      latency: '15',
      bandwidth: '17'
    }
  },
  ringCharts: {
    nodes: {
      globalHealth: '28',
      nodeStatus: '29'
    },
    objects: {
      totalObjects: '30',
      codingObjects: '31',
      rebuildObjects: '32'
    }
  },
  singleVolumeCharts: {
    performance: {
      operations: '34',
      latency: '33',
      bandwidth: '35'
    }
  },
  s3Browser: 'http://ss3b.scality.com:7101/_/s3browser/',
  longPollingInterval: 60000,
  loginExpirationInterval: 900000,
  grafanaLink: '/stats/',
  globalDashboardLink: '/stats/d/home/1-ring-global-monitoring?from=now-3d&to=now&orgId=1&refresh=1m',
  oldSuperVisorHost: '/sup/Local/',
  cloudMonitoringLink: 'https://metrics-ui.scality.com',
  supportUrl: 'https://support.scality.com',
  rootPath: '/gui',
  devUrl: 'http://0.0.0.0',
  docs: {
    base: 'https://documentation.scality.com/RING/9.2.0/index.html',
    ring_version: '9.2.0',
    s3_version: '9.2.0',
    ring: {
      setup_and_installation_guide: {
        link:
          '/assets/pdf/RING_Installation.pdf',
        image: '/assets/images/RINGSetupAndInstalltionGuide_NOVERSION.png'
      },
      operations_guide: {
        link: '/assets/pdf/RING_Operation.pdf',
        image: '/assets/images/RINGOpsGuide_NOVERSION.png'
      },
      reference_manual: {
        link: '/assets/pdf/RING_Reference.pdf',
        image: '/assets/images/RINGRefGuide_NOVERSION.png'
      },
      handbook: {
        link:
          '/assets/pdf/RING_Supervisor.pdf',
        image: '/assets/images/RINGRefGuide_NOVERSION.png'
      },
      quick_start_guide: {
        link:
          '/assets/pdf/RING_Quickstart.pdf',
        image: '/assets/images/RINGRefGuide_NOVERSION.png'
      }
    },
    s3_connector: {
      setup_and_installation_guide: {
        link:
          '/assets/pdf/S3_Connector_Installation.pdf',
        image: '/assets/images/S3SetupAndInstallGuide_NOVERSION.png'
      },
      operations_guide: {
        link:
          '/assets/pdf/S3_Connector_Operation.pdf',
        image: '/assets/images/S3OpsGuide_NOVERSION.png'
      },
      reference_manual: {
        link:
          '/assets/pdf/S3_Connector_Reference.pdf',
        image: '/assets/images/S3RefGuide_NOVERSION.png'
      }
    }
  }
};
