import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import memoizeOne from 'memoize-one';

import {
  DiskWrapper,
  DiskLegendWrapper,
  DiskLegend,
  DiskGauge,
  CapacityUsed,
  ColorLegend
} from '../components/common/DiskInfo';
import Pagination from '../components/Pagination';
import SelectBox from '../components/common/Select';
import { ASC, DESC, statusFilterOptions, STATUS_ALL, KEY_NAME, KEY_STATUS, KEY_SERVER_NAME, itemsPerPageOptions } from '../constants';
import {
  Table,
  THead,
  Th,
  TBody,
  Tr,
  Td,
  NoDataRow
} from '../components/common/Table';
import SearchBar from '../components/SearchBar';
import {
  prettifyBytes,
  getDiskUsages,
  sortByPropertyOrder
} from '../utils/helpers';
import { getFilteredItems } from '../utils/dataTableHelpers';
import { PanelTools } from '../components/ui/AllPagesItems';
import Tooltip from '../components/Tooltip';
import SortIcon from '../components/common/SortIcon';
import { sortByStatus } from '../utils/status';
import StatusDisplay from '../components/StatusDisplay';
import { TooltipOverlay } from '../components/ui/AllPagesItems';

import { selectDisksWithServerNameSelector } from '../ducks/disks';

class AllDisks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsPerPageOptions,
      itemsPerPageOption: itemsPerPageOptions[0],
      activePage: 1,
      search: ''
    };

    this.onTableRowClick = this.onTableRowClick.bind(this);
  }

  sortDisks(disks, key, sortOrder = ASC) {
    return memoizeOne((disks, key, sortOrder = ASC) => {
      let sortedDisks = [...disks];

      if (key === 'diskspace_used_percent') {
        disks = disks.map(disk => {
          disk['diskspace_used_percent'] =
            disk.diskspace_total !== 0
              ? parseFloat(
                  ((disk.diskspace_used / disk.diskspace_total) * 100).toFixed(
                    2
                  )
                )
              : 0;
          return disk;
        });
      }

      if (key === KEY_STATUS) {
        return sortByStatus(sortedDisks, sortOrder);
      }
      return sortByPropertyOrder(sortedDisks, key, sortOrder);
    })(disks, key, sortOrder);
  }

  matchSearch(disks, term) {
    return memoizeOne((disks, term) => {
      const key = term.toLowerCase();

      return disks.filter(disks => {
        const serverName = disks.name.toLowerCase();
        return serverName.indexOf(key) >= 0;
      });
    })(disks, term);
  }

  onFilterOptionChange(option, location) {
    let queryString = location.search || '';
    queryString = queryString.substring(1);
    let params = qs.parse(queryString);
    let url = '/disks?';

    params.status = option.value;
    url = url + qs.stringify(params, { skipNulls: true });
    this.props.history.push(url);

    this.setState({ activePage: 1 });
  }

  onSearchUpdate(term) {
    this.setState({ search: term });
  }

  onPaginationChange(page) {
    this.setState({ activePage: page });
  }

  onTableRowClick(disk) {
    if (disk.server) {
      this.props.history.push(`/servers/${disk.server.id}/disks/${disk.id}`);
    }
  }

  onItemsPerPageOptionChange(itemsPerPageOption) {
    this.setState({ itemsPerPageOption });
  }

  static getDerivedStateFromProps(props) {
    const { location, history } = props;
    let queryString = location.search || '';
    queryString = queryString.substring(1);
    let params = qs.parse(queryString);
    if (!params.order || !params.sort) {
      //update route with sort by default
      params.order = ASC;
      params.sort = KEY_NAME;
      history.replace('/disks?' + qs.stringify(params, { skipNulls: true }));
    }
    return null;
  }

  redirectToSort(key, location) {
    let queryString = location.search || '';
    queryString = queryString.substring(1);
    let params = qs.parse(queryString);
    let url = '/disks?';

    if (key === params.sort) {
      if (params.order === ASC) {
        params.order = DESC;
      } else {
        params.order = ASC;
      }
    } else {
      params.order = ASC;
    }

    params.sort = key;
    url = url + qs.stringify(params, { skipNulls: true });

    this.props.history.push(url);
  }

  render() {
    const { itemsPerPageOptions, itemsPerPageOption, activePage, search } = this.state;
    const { location } = this.props;

    const disks = this.props.disksWithServerName;
    const pagerFirstItemIndex = activePage * itemsPerPageOption.value - itemsPerPageOption.value;
    const pagerLastItemIndex = activePage * itemsPerPageOption.value - 1;

    let queryString =
      location && location.search ? location.search.substring(1) : '';
    const queryParams = qs.parse(queryString) || {};
    const selectedSort = queryParams.sort || KEY_NAME;
    const selectedOrder = queryParams.order || ASC;

    const selectedFilter =
      statusFilterOptions.find(status => status.value === queryParams.status) ||
      statusFilterOptions[0];

    /**
     * Disks will be filter then sort
     * - Status
     * - Search
     * - Sort
     */
    let filteredDisks = getFilteredItems(
      disks,
      queryParams.status || STATUS_ALL
    );
    let matchSearchDisks = this.matchSearch(filteredDisks, search);
    let matchDisks = this.sortDisks(
      matchSearchDisks,
      selectedSort,
      selectedOrder
    );

    // FIXME Patrick Ear : This logic is only here because we do not have real datatable
    let tableRows;
    if (matchDisks.length === 0) {
      tableRows = <NoDataRow colSpan={6} />;
    } else if (matchDisks.length === 0) {
      tableRows = <NoDataRow colSpan={6} text="No data selected." />;
    } else {
      tableRows = matchDisks.map((singleDisk, index) => {
        if (index >= pagerFirstItemIndex && index <= pagerLastItemIndex) {
          return (
            <Tablerow
              onTableRowClick={this.onTableRowClick.bind(this)}
              key={`single_disk_${index}`}
              singleDisk={singleDisk}
            />
          );
        }
        return null;
      });
    }

    return (
      <div>
        <PanelTools>
          <SearchBar
            onSearchChange={this.onSearchUpdate.bind(this)}
            placeholder="disk1"
          />
          <SelectBox
            options={statusFilterOptions}
            name="disks-filter"
            type="state"
            value={selectedFilter}
            onChange={option => this.onFilterOptionChange(option, location)}
          />
        </PanelTools>
        <div>
          <Table>
            <THead>
              <Tr>
                <Th>
                  Name
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_NAME, location)}
                    selected={selectedSort === KEY_NAME}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th>
                  Server
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_SERVER_NAME, location)}
                    selected={selectedSort === KEY_SERVER_NAME}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th>
                  Status
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_STATUS, location)}
                    selected={selectedSort === KEY_STATUS}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th colSpan={3}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <span>
                      Usage
                      <SortIcon
                        onSort={() =>
                          this.redirectToSort(
                            'diskspace_used_percent',
                            location
                          )
                        }
                        selected={selectedSort === 'diskspace_used_percent'}
                        selectedOrder={selectedOrder}
                      />
                    </span>
                    <ColorLegend />
                  </div>
                </Th>
              </Tr>
            </THead>
            <TBody>{tableRows}</TBody>
          </Table>
        </div>
        <SelectBox
          options={itemsPerPageOptions}
          name="all_disks_options"
          value={itemsPerPageOption}
          onChange={this.onItemsPerPageOptionChange.bind(this)}
        />
        {matchDisks.length > itemsPerPageOption.value ? (
          <Pagination
            totalItems={matchDisks.length}
            itemsPerPage={itemsPerPageOption.value}
            activePage={activePage}
            switchPage={this.onPaginationChange.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}
export class Tablerow extends Component {
  render() {
    const { singleDisk, onTableRowClick } = this.props;

    const prettyDiskTotal = prettifyBytes(singleDisk.diskspace_total),
      prettyDiskStored = prettifyBytes(singleDisk.diskspace_stored),
      prettyCapacityUsed = prettifyBytes(singleDisk.diskspace_used),
      prettyCapacityAvailable = prettifyBytes(singleDisk.diskspace_available);

    const percentages = getDiskUsages(
      prettyCapacityUsed,
      prettyDiskStored,
      prettyDiskTotal
    );

    let _diskName = singleDisk.name.substring(0, 20);

    return (
      <Tr onClick={() => onTableRowClick(singleDisk)} hoverable>
        <Td>
          <Tooltip
            placement="top"
            overlay={<TooltipOverlay>{singleDisk.name}</TooltipOverlay>}
          >
            <span>{_diskName}</span>
          </Tooltip>
        </Td>
        <Td>{singleDisk.serverName || ''}</Td>
        <Td>
          <StatusDisplay
            status={singleDisk.status}
            statusState={singleDisk.state}
          />
        </Td>
        <Td colSpan={3}>
          <Tooltip
            placement="top"
            overlay={
              <TooltipOverlay>
                {`Stored: ${percentages.actual_stored}% | Used:
                 ${percentages.used}%`}
              </TooltipOverlay>
            }
          >
            <DiskWrapper>
              <DiskGauge>
                <CapacityUsed width={percentages.actual_stored + '%'} stored />
                <CapacityUsed
                  width={percentages.used - percentages.actual_stored + '%'}
                  used
                />
                <CapacityUsed width={100 - percentages.used + '%'} />
              </DiskGauge>
              <DiskLegendWrapper>
                <DiskLegend>
                  {prettyDiskStored.value}
                  {prettyDiskStored.unit} stored - {prettyCapacityUsed.value}
                  {prettyCapacityUsed.unit} used
                </DiskLegend>
                <DiskLegend>
                  {prettyCapacityAvailable.value}
                  {prettyCapacityAvailable.unit} Free - {prettyDiskTotal.value}
                  {prettyDiskTotal.unit} Total
                </DiskLegend>
              </DiskLegendWrapper>
            </DiskWrapper>
          </Tooltip>
        </Td>
      </Tr>
    );
  }
}

AllDisks.propTypes = {
  disksWithServerName: PropTypes.array
};

AllDisks.defaultProps = {
  disksWithServerName: []
};


const mapStateToProps = state => ({
  disksWithServerName: selectDisksWithServerNameSelector(state)
});

export default withRouter(connect(mapStateToProps)(AllDisks));
