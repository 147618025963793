import React from 'react';
import {
  Table,
  THead,
  Th,
  TBody,
  Tr,
  Td,
  NoDataRow,
  LoadingRow
} from './common/Table';

const NodeStatusDataTable = props => {
  let tableContent;

  const bgColorChooser = status => {
    return status === 'RUN'
      ? {}
      : { backgroundColor: '#ff8f1c', color: '#201d1d' };
  };

  if (props.nodeStatusRing) {
    if (props.nodeStatusRing.length !== 0) {
      tableContent = props.nodeStatusRing.map(hit =>
        hit[3].buckets.map(hi => (
          <Tr key={hit.key + hi.key}>
            <Td>{hit.key}</Td>
            <Td style={bgColorChooser(hi.key)}>{hi.key}</Td>
            <Td>{hi.doc_count}</Td>
          </Tr>
        ))
      );
    } else {
      tableContent = <NoDataRow />;
    }
  } else {
    tableContent = <LoadingRow />;
  }
  return (
    <div>
      <Table>
        <THead>
          <Tr>
            <Th>Address</Th>
            <Th>Status</Th>
            <Th>Count</Th>
          </Tr>
        </THead>
        <TBody>{tableContent}</TBody>
      </Table>
    </div>
  );
};

export { NodeStatusDataTable };
