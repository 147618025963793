import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { selectAllDisksState } from './disks';
import { callApi, callApiPost } from '../utils/callApi';
import { setInvalidBasicAuth } from '../actions/overview';
// Actions
const GET_SERVERS = 'GET_SERVERS';
const SET_SERVERS = 'SET_SERVERS';
const CREATE_SERVER = 'CREATE_SERVER';
const DELETE_SERVER = 'DELETE_SERVER';
const EDIT_SERVER = 'EDIT_SERVER';

// Reducer
const defaultState = fromJS({
  list: undefined
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_SERVERS:
      return state.set('list', action.payload);
    default:
      return state;
  }
}

// Action Creators
function getServersAction() {
  return {
    type: GET_SERVERS
  };
}

function setServersAction(servers) {
  return {
    type: SET_SERVERS,
    payload: servers
  };
}

function createServerAction(server) {
  return {
    type: CREATE_SERVER,
    payload: server
  };
}

function deleteServerAction(name) {
  return {
    type: DELETE_SERVER,
    payload: name
  };
}

function editServerAction(server, id) {
  return {
    type: EDIT_SERVER,
    payload: { server, id }
  };
}

// Sagas
function* getServers() {
  let requestURL = 'servers/?limit=1000&embed=hardware&embed=zone';
  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    let responseItems = response._items || [];

    while (response._links.hasOwnProperty('next')) {
      requestURL = `servers/?${response._links.next.split('?')[1]}`;

      response = yield call(callApi, requestURL, {
        method: 'GET',
        mode: 'cors'
      });

      responseItems = responseItems.concat(response._items);
    }
    yield put(setServersAction(responseItems));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* createServer({ payload }) {
  let requestURL = '/servers/';

  try {
    yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload)
    });

    yield put(getServersAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* deleteServer({ payload }) {
  let requestURL = '/servers/';
  let req = requestURL + payload;

  try {
    yield call(callApiPost, req, {
      method: 'DELETE',
      mode: 'cors'
    });
    yield put(getServersAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* editServer({ payload }) {
  const { server, id } = payload;

  const patchArray = [];
  for (let entry of server) {
    let valueResult;
    if (!entry[1]) {
      valueResult = '';
    } else {
      valueResult = entry[1];
    }
    const objectStructure = {
      field: entry[0],
      action: 'change',
      value: valueResult
    };
    patchArray.push(objectStructure);
  }

  let requestURL = `servers/${id}/?embed=hardware&embed=zone`;

  try {
    yield call(callApiPost, requestURL, {
      method: 'PATCH',
      mode: 'cors',
      body: JSON.stringify(patchArray)
    });

    yield put(getServersAction());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* serversSaga() {
  yield takeLatest(GET_SERVERS, getServers);
  yield takeEvery(CREATE_SERVER, createServer);
  yield takeEvery(DELETE_SERVER, deleteServer);
  yield takeEvery(EDIT_SERVER, editServer);
}

// Selectors
function selectServersState(state) {
  return state.get('servers');
}

function selectAllServersState(state) {
  return selectServersState(state).get('list');
}

function selectServerFromUrl(state, props) {
  const servers = selectAllServersState(state) || [];

  if (props && props.match && props.match.params && props.match.params.id) {
    return (
      servers.find(
        server => server.id === parseInt(props.match.params.id, 10)
      ) || {}
    );
  }
  return {};
}

const selectServerFromUrlSelector = createSelector(
  selectServerFromUrl,
  server => server
);

const selectServerDiskSelector = createSelector(
  [selectServerFromUrl, selectAllDisksState],
  (server, disks) => {
    if (!isEmpty(server) && disks) {
      return disks.filter(disk => {
        return disk && disk.server && disk.server.id === server.id;
      });
    }
    return [];
  }
);

export {
  getServersAction,
  setServersAction,
  createServerAction,
  deleteServerAction,
  editServerAction,
  createServer,
  deleteServer,
  editServer,
  serversSaga,
  selectServerFromUrlSelector,
  selectServerDiskSelector,
  selectAllServersState
};
