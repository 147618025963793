/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';

// Notice that we need redux-form/immutable for the boilerplate
// http://redux-form.com/6.2.0/examples/immutable/
import { reducer as reduxFormReducer } from 'redux-form/immutable';

import loginReducer from './login';
import overviewReducer from './overview';
import serversReducer from '../ducks/servers';
import topServersReducer from '../ducks/topServers';
import zonesReducer from '../ducks/zones';
import disksReducer from '../ducks/disks';
import topDisksReducer from '../ducks/topDisks';
import volumesReducer from '../ducks/volumes';
import ringsReducer from '../ducks/rings';
import s3Reducer from '../ducks/s3';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer() {
  return combineReducers({
    overview: overviewReducer,
    login: loginReducer,
    form: reduxFormReducer,
    servers: serversReducer,
    topServers: topServersReducer,
    zones: zonesReducer,
    disks: disksReducer,
    topDisks: topDisksReducer,
    volumes: volumesReducer,
    rings: ringsReducer,
    s3: s3Reducer
  });
}
