//variables

// Global
export const fontFamily = "'Work Sans', sans-serif";
export const fontSize = '16px';
export const fontSizeLarge = '18px';
export const lineHeight = 1.53;

// Font Weights
export const extraLight = 200;
export const light = 300;
export const regular = 400;
export const semiBold = 500;
export const bold = 700;

//base clrs
export const grn = '#21ac4d';
export const orng = '#ff8f1c';
export const red = '#ef3340';
export const drkBg = '#141414';
export const blu = '#00a3e0';
export const radientGrn = '#00b2a9';
export const radientPrpl = '#9152e1';
export const darkGrey = '#464f56';
export const black = '#0e0e0e';

// site clrs
export const txtHighlight = '#2aad8e';
export const fontClr = '#fff';
export const fontClrSecondry = '#f1f1f1';
export const infoIcnClr = '#76828f';
export const infoIcnSlctClr = '#76828f';
export const brdrClr = '#211d1d';
export const serviceBlkTitlClr = '#a4acb4';
export const darkBlue = '#003568';

// section clrs
export const panelBg = '#2b3237';
export const panelSlctBg = '#151313';
export const infoPopOvrSlctdBg = '#faf7f5';
export const bgClr = '#151313';
export const hdrBg = '#211d1d';

//table progressbar colors

export const pgBorderClr = '#24292e';
export const pgStoredClr = '#2b65ad';
export const pgUsedClr = '#184680';
export const pgAvailableClr = '#332c2c';
export const tableHoverBck = '#201d1d';

//border color
export const brdrClrSecondry = '#979797';
export const brdrClrLogin = '#191b1e';

//shadow color

export const shadowClrLogin = '#181b1e';
export const shadowClrHelpSupport = '#1a1d1f';

export const green1 = '#2bad8d';
export const formInputBck = '#0e0b0b';

export const mainBlue = '#2b65ad';
