import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import memoizeOne from 'memoize-one';

import { Link, withRouter } from 'react-router-dom';
import {
  ASC,
  KEY_CONFIG_SHARES_LENGTH,
  KEY_CONNECTORS_LENGTH,
  KEY_NAME,
  KEY_PROTOCOL,
  KEY_STATUS,
  statusFilterOptions
} from '../constants';
import Pagination from './Pagination';
import { getFilteredItems } from '../utils/dataTableHelpers';
import { sortByPropertyOrder, getSortOrderObject } from '../utils/helpers';
import { Table, THead, Th, TBody, Tr, NoDataRow } from './common/Table';
import HelpPopover from './common/HelpPopover';
import BlockTitle from './ui/BlockTitle';
import ConfigGroupTableList from './SingleVolumeConfigTableList';
import VolumeConfigGroupTips from '../tips/VolumeConfigGroupTips';
import SortIcon from '../components/common/SortIcon';

class ConfigsGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsPerPage: 10,
      activePage: 1,
      selectedSort: {
        key: KEY_NAME,
        order: ASC
      },
      filters: statusFilterOptions,
      filter: statusFilterOptions[0]
    };
  }

  onPaginationChange(page) {
    this.setState({ activePage: page });
  }

  onSortRows(key) {
    let sortOrderObj = getSortOrderObject(key, this.state.selectedSort);
    this.setState({
      selectedSort: sortOrderObj
    });
  }

  sortRows = memoizeOne((configGroups, sortKey, sortOrder = ASC) => {
    return sortByPropertyOrder(configGroups, sortKey, sortOrder);
  });

  render() {
    const { adminAccess, configGroups } = this.props;
    const { selectedSort, itemsPerPage, activePage, filter } = this.state;

    const pagerFirstItemIndex = activePage * itemsPerPage - itemsPerPage;
    const pagerLastItemIndex = activePage * itemsPerPage - 1;

    let filteredCG = this.sortRows(
      getFilteredItems(configGroups, filter.value),
      selectedSort.key,
      selectedSort.order
    );

    const ConfigGroupTableRows =
      configGroups.length !== 0 ? (
        filteredCG.map((singleConfigGroup, index) => {
          const configGroup = { singleConfigGroup };

          if (index >= pagerFirstItemIndex && index <= pagerLastItemIndex) {
            return (
              <ConfigGroupTableList
                key={`single_cg_${index}`}
                volume={this.props.activeVolume}
                {...configGroup}
              />
            );
          }
          return null;
        })
      ) : (
        <NoDataRow colSpan={6} />
      );

    return (
      <div className="performance-tab">
        <div style={{ marginBottom: '10px' }}>
          {adminAccess ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px'
              }}
            >
              <div>
                <BlockTitle>Volume Configuration Groups</BlockTitle>
                <HelpPopover content={<VolumeConfigGroupTips />} />
              </div>
              <LinkButton
                to={`/storages/file/${
                  this.props.activeVolume.id
                }/config-group/create`}
                data-cy="config-group-add-button"
              >
                <span className="fa fa-plus" />
              </LinkButton>
            </div>
          ) : (
            <div>
              <BlockTitle>Volume Configuration Groups</BlockTitle>
              <HelpPopover content={<VolumeConfigGroupTips />} />
            </div>
          )}
        </div>

        <Table>
          <THead>
            <Tr>
              <Th>
                Name
                <SortIcon
                  onSort={() => this.onSortRows(KEY_NAME)}
                  selected={selectedSort.key === KEY_NAME}
                  selectedOrder={selectedSort.order}
                />
              </Th>
              <Th>
                Status
                <SortIcon
                  onSort={() => this.onSortRows(KEY_STATUS)}
                  selected={selectedSort.key === KEY_STATUS}
                  selectedOrder={selectedSort.order}
                />
              </Th>
              <Th>
                Protocol
                <SortIcon
                  onSort={() => this.onSortRows(KEY_PROTOCOL)}
                  selected={selectedSort.key === KEY_PROTOCOL}
                  selectedOrder={selectedSort.order}
                />
              </Th>
              <Th>
                Connectors
                <SortIcon
                  onSort={() => this.onSortRows(KEY_CONNECTORS_LENGTH)}
                  selected={selectedSort.key === KEY_CONNECTORS_LENGTH}
                  selectedOrder={selectedSort.order}
                />
              </Th>
              <Th>
                Shares
                <SortIcon
                  onSort={() => this.onSortRows(KEY_CONFIG_SHARES_LENGTH)}
                  selected={selectedSort.key === KEY_CONFIG_SHARES_LENGTH}
                  selectedOrder={selectedSort.order}
                />
              </Th>
            </Tr>
          </THead>
          <TBody>{ConfigGroupTableRows}</TBody>
        </Table>

        {filteredCG.length > itemsPerPage ? (
          <Pagination
            totalItems={filteredCG.length}
            itemsPerPage={itemsPerPage}
            activePage={activePage}
            switchPage={this.onPaginationChange.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

const LinkButton = styled(Link)`
  font-family: 'Work Sans', sans-serif;
  background-color: #2b65ad;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px 10px;
  outline: none;
`;

ConfigsGroup.propTypes = {
  configGroups: PropTypes.array
};

ConfigsGroup.defaultProps = {
  configGroups: []
};

export default withRouter(ConfigsGroup);
