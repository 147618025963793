import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

export default class GrafanaFrame extends Component {
  constructor(props) {
    super(props);

    this.onIframeLoad = this.onIframeLoad.bind(this);

    this.state = {
      isIframeLoaded: false
    };
  }

  componentWillReceiveProps() {
    this.setState({
      isIframeLoaded: false
    });
  }

  onIframeLoad() {
    this.setState({
      isIframeLoaded: true
    });
  }

  render() {
    const { src, height } = this.props;
    const { isIframeLoaded } = this.state;

    return (
      <Wrapper>
        {isIframeLoaded ? null : <span />}

        <Iframe
          onLoad={this.onIframeLoad}
          title="grafana"
          src={src}
          width="100%"
          height={height}
          scrolling="no"
        />
      </Wrapper>
    );
  }
}

GrafanaFrame.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

GrafanaFrame.defaultProps = {
  width: '100%',
  height: '300'
};

const Wrapper = styled.div`
  position: relative;
`;

const Iframe = styled.iframe`
  display: block;
  border: none;
`;
