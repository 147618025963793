/**
 * --- /!\ THIS MODULE IS STUPID /!\ ---
 *
 * We need to clean up and sort these sagas by theme, not location in the code.
 * Please, don't add anymore stuff to it.
 */

import { call, put, takeEvery, takeLeading, all } from 'redux-saga/effects';
import * as actions from '../../actions/overview';
import * as ACTIONS from '../../actions/overview/constants';
import { callApi, callApiPost, LDAPRequest } from '../../utils/callApi';
import { userAccessibilityByAccessMethods } from '../../utils/helpers';
import LDAPService from '../../utils/LDAPService';

export function* checkLDAP() {
  let requestURL = 'config';

  try {
    yield call(LDAPRequest, requestURL);
    LDAPService.setLDAP(true);
    yield put(actions.setCheckedLDAP());
  } catch (error) {
    LDAPService.setLDAP(false);
    yield put(actions.setCheckedLDAP());
  }
}
/**
 * Check API connection
 **/

export function* checkAPIConnection() {
  const requestURL = '/';

  try {
    const response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });
    yield put(actions.apiConnectionSuccess(response));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(actions.setInvalidBasicAuth());
    }
  }
}

/**
 * Fetch RING version
 **/
export function* getVersion() {
  const requestURL = 'status/';

  try {
    const response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    // Full version: 7.4.0.0.r180214170724.bf209bc940
    const full_version = response.supapi_complete_version;

    // Version: 7.4.0.0
    const version = /^(?:\d+\.){3}\d+/g.exec(full_version)[0];

    // Full release: r180214170724.bf209bc940
    const full_release = /r\d+\.\w+$/g.exec(full_version)[0];

    // Partial release: R180214
    const partial_release = /^r\d{6}/g.exec(full_release)[0];

    yield put(actions.getVersionSuccess(version, partial_release));
  } catch (error) {
    // Who cares about errors ?
    // FIXME(gd): Handle errors uniformly
    if (error && error.status === 401) {
      yield put(actions.setInvalidBasicAuth());
    }
  }
}

export function* getInfraMetaData() {
  let requestURL = 'infra_metadata';
  try {
    let res = yield call(callApi, requestURL);

    if (res && res._items && res._items[0] && res._items[0].name != null) {
      yield put(actions.setInfraMetaData(res._items[0].name));
    }
  } catch (error) {
    if (error && error.status === 401) {
      yield put(actions.setInvalidBasicAuth());
    }
    if (error && error.status >= 500 && error.status < 600) {
      yield put(
        actions.setApiRequestErrorMsg({
          message: 'Unable to fetch Zones information.'
        })
      );
      yield put(actions.setApiRequestError());
    }
  }
}

/**
 * Get accessible Api methods for the user
 * @param action
 */
export function* getUserAccessConfigs() {
  try {
    let requestURL = '/config';
    let header = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
      'Content-Encoding': 'gzip'
    });
    let response = yield call(callApiPost, requestURL, {
      method: 'OPTIONS',
      mode: 'cors',
      headers: header
    });

    const { allowed_methods } = response;
    const adminAccess = userAccessibilityByAccessMethods(allowed_methods);
    yield put(actions.getUserAccessConfigsSuccess(adminAccess));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(actions.setInvalidBasicAuth());
    }
  }
}

export function* getAverage(timeFrame, type, operation, setAverage) {
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            query_string: {
              query: `operation:${operation} AND diskname:*${type}*`
            }
          },
          {
            range: {
              '@timestamp': {
                gte: `now-${timeFrame}`
              }
            }
          }
        ]
      }
    },
    aggs: {
      agg_by_range: {
        avg: {
          field: 'avg_ms'
        }
      }
    }
  };

  const requestURL = 'es_proxy/scality-stats-disk-*/_search';

  try {
    const response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });
    yield put(setAverage(response.aggregations.agg_by_range.value.toFixed(2)));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(actions.setInvalidBasicAuth());
    }
    if (error && error.status >= 500 && error.status < 600) {
      yield put(
        actions.setApiRequestErrorMsg({
          message: 'Unable to fetch Config Groups information.'
        })
      );
      yield put(actions.setApiRequestError());
    }
  }
}

export function* editInfraMedata({ payload }) {
  const requestURL = 'infra_metadata';

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const body = {
    name: payload
  };

  try {
    yield call(callApiPost, requestURL, {
      method: 'PUT',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(body)
    });
    yield call(getInfraMetaData);
  } catch (error) {
    if (error && error.status === 401) {
      yield put(actions.setInvalidBasicAuth());
    }
  }
}

export default function* overViewSaga() {
  yield all([
    takeEvery(ACTIONS.CHECK_LDAP, checkLDAP),
    takeLeading(ACTIONS.CHECK_API_CONNECTION, checkAPIConnection),
    takeLeading(ACTIONS.GET_VERSION, getVersion),
    takeEvery(ACTIONS.USER_ACCESS_CONFIGS, getUserAccessConfigs),
    takeEvery(ACTIONS.GET_INFRA_METADATA, getInfraMetaData),
    takeEvery(ACTIONS.EDIT_INFRA_METADATA, editInfraMedata)
  ]);
}
