class LDAPService {
  isLDAP = false;
  checked = false;

  setLDAP(value) {
    if (typeof value === 'boolean') {
      this.isLDAP = value;
      this.checked = true;
    }
  }

  resetLDAP() {
    this.isLDAP = false;
  }

  getLDAP() {
    return this.isLDAP;
  }

  getChecked() {
    return this.checked;
  }
}

const instance = new LDAPService();

export default instance;
