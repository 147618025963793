import React from 'react';
import moment from 'moment';
import Tooltip from './Tooltip';

import { Table, THead, Th, TBody, Tr, Td, NoDataRow } from './common/Table';
import {
  DiskWrapper,
  DiskLegendWrapper,
  DiskLegend,
  DiskGauge,
  CapacityUsed
} from '../components/common/DiskInfo';
import { prettifyBytes } from '../utils/helpers';
import { TooltipOverlay } from './ui/AllPagesItems';

const NodeTaskDataTable = props => (
  <Table>
    <THead>
      <Tr>
        <Th>Node Name</Th>
        <Th>Task Type</Th>
        <Th width="170px">Start Time</Th>
        <Th minWidth="300px">Progress</Th>
        <Th>Destination</Th>
      </Tr>
    </THead>
    <TBody>
      {props && props.data && props.data.length !== 0 ? (
        props.data.map((task, curr, i) => {
          const chunkRatio =
            task.tasks[0].nbchunkdone / task.tasks[0].nbtotalchunk;
          const chunkRoundPer = (chunkRatio * 100).toFixed(2);
          const prettyProcessed = prettifyBytes(task.tasks[0].size_done);
          const prettyTotal = prettifyBytes(task.tasks[0].est_size_total);

          return (
            <Tr key={`${task.node}_${i}`}>
              <Td>{task.node}</Td>
              <Td>{task.tasks[0].type}</Td>
              <Td>
                {moment
                  .unix(task.tasks[0].starting_time)
                  .format('YYYY MM DD [at] h:mm a UTC')}
              </Td>
              <Td>
                <Tooltip
                  placement="top"
                  overlay={
                    <TooltipOverlay>
                      {`Processed: ${chunkRoundPer}% | Remaining: ${100 -
                        chunkRoundPer}%`}
                    </TooltipOverlay>
                  }
                >
                  <DiskWrapper>
                    <DiskGauge>
                      <CapacityUsed width={chunkRoundPer + '%'} used />
                      <CapacityUsed width={100 - chunkRoundPer + '%'} />
                    </DiskGauge>
                    <DiskLegendWrapper>
                      <DiskLegend>
                        {`${task.tasks[0].nbchunkdone} processed - ${task.tasks[0].nbtotalchunk} total`}
                      </DiskLegend>
                      {task.tasks[0].est_size_total !== 0 ? (
                        <DiskLegend>
                          {`${prettyProcessed.value} ${prettyProcessed.unit}
                             / ${prettyTotal.value} ${prettyTotal.unit} (est)`}
                        </DiskLegend>
                      ) : null}
                    </DiskLegendWrapper>
                  </DiskWrapper>
                </Tooltip>
              </Td>
              <Td />
            </Tr>
          );
        })
      ) : (
        <NoDataRow colSpan={5} />
      )}
    </TBody>
  </Table>
);

export { NodeTaskDataTable };
