import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const SingleStorageUsageTopTenBucketTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`S3 Top-10 Buckets`}</HelpPopupTitle>
    <HelpPopupContent>
      {`The Top-10 Buckets tables present the names of the leading buckets over a selected time period with regard to:`}
      <Ul>
        <Li>
          <b>{`Operations`}</b>
          {`: Number of operations`}
        </Li>
        <Li>
          <b>{`Inbound Bandwidth`}</b>
          {`: Inbound transferred data`}
        </Li>
        <Li>
          <b>{`Outbound Bandwidth`}</b>
          {`: Outbound transferred data`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default SingleStorageUsageTopTenBucketTips;
