import React from 'react';
import PropTypes from 'prop-types';

const defaultColors = {
  top: 'white',
  inside: '#949698',
  outside: {
    light: '#C6C8CC',
    shadow: '#949698'
  }
};

const stateColors = {
  ok: { light: '#53A02E', shadow: '#3A7420' },
  warning: { light: '#DC900B', shadow: '#BB7C0C' },
  critical: { light: '#DE626A', shadow: '#C6545C' }
};

function Ring({ custom_id, selected, state, ...props }) {
  let colors = { ...defaultColors };
  const stateGradient = stateColors[state];
  if (stateGradient) {
    colors.outside = { ...stateGradient };
  }

  return (
    <svg
      width="772"
      height="314"
      viewBox="0 0 772 314"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M654.396 124.01C660.014 118.731 663 113.199 663 107.5C663 70.7731 538.983 41 386 41C233.017 41 109 70.7731 109 107.5C109 113.199 111.986 118.731 117.604 124.01C126.944 89.3767 243.54 62 386 62C528.46 62 645.056 89.3767 654.396 124.01Z"
          fill={colors.inside}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M386 256C588.136 256 752 202.274 752 136C752 69.7258 588.136 16 386 16C183.864 16 20 69.7258 20 136C20 202.274 183.864 256 386 256ZM386 173C538.983 173 663 143.451 663 107C663 70.5492 538.983 41 386 41C233.017 41 109 70.5492 109 107C109 143.451 233.017 173 386 173Z"
          fill={colors.top}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M751.771 140.179C744.887 202.861 583.76 253 386 253C188.108 253 26.8974 202.794 20.2155 140.053C20.0727 140.018 20 140 20 140C20 178.79 32.5 198.5 32.5 198.5L32.5311 198.517C62.3597 250.513 209.266 290 386 290C564.279 290 712.207 249.82 740.224 197.15L740.5 197C740.5 197 751.681 178.649 751.771 140.179Z"
          fill={colors.outside.light}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M751.771 140.289C751.923 138.865 752 137.436 752 136C752 69.7258 588.136 16 386 16C183.864 16 20 69.7258 20 136C20 137.356 20.0686 138.706 20.2046 140.051C20.069 140.017 20 140 20 140C20 178.79 32.5 198.5 32.5 198.5L32.5311 198.517C62.3597 250.513 209.266 290 386 290C564.279 290 712.207 249.82 740.224 197.15L740.5 197C740.5 197 751.659 178.684 751.771 140.289ZM386 173C538.983 173 663 143.451 663 107C663 104.674 662.495 102.376 661.509 100.111C662.344 102.214 662.771 104.345 662.771 106.5C662.771 112.199 659.785 117.731 654.168 123.01C644.827 88.3767 528.232 61 385.771 61C243.311 61 126.715 88.3767 117.375 123.01C114.618 120.419 112.494 117.767 111.047 115.064C127.739 147.711 244.475 173 386 173Z"
          fill="#000"
          fillOpacity={selected ? 0 : 0.45}
        />
      </g>
    </svg>
  );
}

Ring.propTypes = {
  custom_id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired
};

Ring.defaultProps = {
  custom_id: '',
  selected: false,
  state: ''
};

export default Ring;
