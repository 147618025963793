import { injectGlobal } from 'styled-components';
import * as cssVariables from './variables';

injectGlobal`
  body {
    font-family: ${cssVariables.fontFamily};
    font-size: ${cssVariables.fontSize};
    line-height: ${cssVariables.lineHeight};
    color: ${cssVariables.fontClr};
    background: ${cssVariables.bgClr};
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
  }

  a {
    cursor: pointer;
    color: ${cssVariables.fontClr};
    &:hover,
    &:focus {
      text-decoration: none;
      outline: 0;
      color: lighten(${cssVariables.fontClr}, 10%);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: ${cssVariables.regular};
  }

  .right {
    float: right;
    display: inline;
  }

  .left {
    float: left;
    display: inline;
  }

  .centerAlign {
    display: block;
    text-align: center;
    div,
    a,
    span,
    button {
      display: inline-block;
      margin: 5px 5px;
    }
  }

  .hover-delete {
    &:hover {
      cursor: pointer;
      color: red;
    }
  }

  .hover-icon {
    &:hover {
      cursor: pointer;
      color: #a5a5a5;
    }
  }
`;
