/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Header from '../components/Header';
import Overview from './Overview';
import APIConnectionError from '../components/ConnectionError';
import Documentation from '../components/Documentation';
import Login from './Login';
import Support from '../components/Support';
import { HASH_KEY } from '../actions/global/constants';
import LoadingOverlay from '../components/LoadingOverlay';
import { checkLDAP } from '../actions/overview';
import LDAPService from '../utils/LDAPService';
import Notification from './Notification';
import BrowserWarning from '../components/BrowserWarning';

const check = () => !!localStorage.getItem(HASH_KEY);

/**
 * URL convention to keep consistency across the app
 * For the future paths pleases use hyphen in order to replace spaces in your page URL
 * (ie. http://scality.com/gui/path-with-space)
 *
 * For the parameters, please use underscore.
 * (ie. http://scality.com/gui/path-with-space?sort_param=asc&filter_param=name)
 */

const AppContainer = styled.div`
  position: relative;
  z-index: 1;
`;

class App extends Component {
  componentDidMount() {
    /**
     * Check if the back-end is using LDAP
     */
    this.props.checkLDAP();
  }

  render() {
    return (
      <AppContainer>
        <Notification />
        <BrowserWarning />
        <LoadingOverlay
          active={this.props.globalOverlay}
          spinner
          text={this.props.globalOverlayText || 'Loading your content...'}
          zIndex={9999}
        >
          {this.props.isLDAPChecked ? (
            <React.Fragment>
              {check() ||
              (LDAPService.getChecked() && LDAPService.getLDAP()) ? (
                <Header platformName={this.props.platformName} />
              ) : null}
              <Switch>
                <PrivateRoute exact path="/" component={Overview} />
                <PrivateRoute path="/overview" component={Overview} />
                <PrivateRoute path="/documentation" component={Documentation} />
                <PrivateRoute path="/support" component={Support} />
                <PrivateRoute
                  path="/connection-error"
                  component={APIConnectionError}
                />
                <Route path="/login" component={Login} />
                <Route component={Overview} />
              </Switch>
            </React.Fragment>
          ) : null}
        </LoadingOverlay>
      </AppContainer>
    );
  }
}

class PrivateRoute extends Component {
  render() {
    let { component: Component, ...rest } = this.props;
    if (check() || LDAPService.getLDAP()) {
      return <Route {...rest} render={props => <Component {...props} />} />;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

// FIXME Patrick Ear : For some reason the build does not like this syntax.
// Need to investigate to understand why it does not work with simple function
// but work with Class.
// const PrivateRoute = ({ component: Component, ...props }) => {
//   if (check()) {
//     return <Route {...props} render={props => <Component {...props} />} />;
//   } else {
//     return <Redirect to="/login" />;
//   }
// };

const mapStateToProps = state => ({
  isLDAPChecked: state.get('overview').get('isLDAPChecked'),
  globalOverlay: state.get('overview').get('globalOverlay'),
  globalOverlayText: state.get('overview').get('globalOverlayText'),
  platformName: state.get('overview').get('platformName')
});

const mapDispatchToProps = dispatch => ({
  checkLDAP: () => dispatch(checkLDAP())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
