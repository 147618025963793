import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import { InfoCircle } from 'styled-icons/fa-solid/InfoCircle';
import { infoIcnClr } from '../ui/variables';

const propsTypes = {
  content: PropTypes.element
};

const defaultProps = {
  content: <div>Help Tips</div>
};

/**
 * FIXME Patrick EAR : We should check if react-popover
 * is really useful.
 */
export default class HelpPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.togglePopover = this.togglePopover.bind(this);
    this.onOuterAction = this.onOuterAction.bind(this);
  }

  togglePopover() {
    this.setState({ isOpen: true });
  }

  onOuterAction() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Popover
        isOpen={this.state.isOpen}
        body={this.props.content}
        tipSize={0.01}
        style={{ zIndex: '9999' }}
        onOuterAction={this.onOuterAction}
      >
        <InfoCircle
          size={13}
          style={{ color: infoIcnClr, cursor: 'pointer', margin: '0 5px' }}
          onClick={this.togglePopover}
        />
      </Popover>
    );
  }
}

HelpPopover.propsTypes = propsTypes;
HelpPopover.defaultProps = defaultProps;
