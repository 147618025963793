import React, { Component } from 'react';
import config from '../config';
import SelectBox from './common/Select';
import GrafanaPanel from './GrafanaPanel';
import { IFRAME_VARS, timeRangeOptions } from '../constants';
import SimpleTips from '../tips/SimpleTips';
import { getFrameUrlOfDisk } from '../utils/grafanaFrameSrcgenerator';

export default class SingleDiskPerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartoptions: timeRangeOptions,
      selectedChartOption: timeRangeOptions[1]
    };
  }

  onChartOptionChange(selectedOption) {
    this.setState({
      selectedChartOption: selectedOption
    });
  }

  render() {
    const { disk } = this.props;
    const { chartoptions, selectedChartOption } = this.state;

    const singleDiskOperationsUrl = getFrameUrlOfDisk(
      disk.name,
      config.singleDiskCharts.performance.operations,
      selectedChartOption.value,
      IFRAME_VARS.AUTO_REFRESH,
      disk.server
    );

    const singleDiskLatencyUrl = getFrameUrlOfDisk(
      disk.name,
      config.singleDiskCharts.performance.latency,
      selectedChartOption.value,
      IFRAME_VARS.AUTO_REFRESH,
      disk.server
    );

    const singleDiskBandwidthUrl = getFrameUrlOfDisk(
      disk.name,
      config.singleDiskCharts.performance.bandwidth,
      selectedChartOption.value,
      IFRAME_VARS.AUTO_REFRESH,
      disk.server
    );
    return (
      <div className="performance-tab">
        <div>
          <SelectBox
            options={chartoptions}
            name="chart_options"
            value={selectedChartOption}
            onChange={this.onChartOptionChange.bind(this)}
          />
        </div>
        <div className="vertical-scroll-wrapper">
          <GrafanaPanel
            iFrameSrc={singleDiskOperationsUrl}
            panelTitle="Operations per Sec"
            popOverTemplate={
              <SimpleTips
                title="Operations Per Sec"
                content={`Number per second of all GET, PUT, and DELETE operations at the Storage level
                for the time period selected.`}
              />
            }
          />
          <GrafanaPanel
            iFrameSrc={singleDiskLatencyUrl}
            panelTitle="Latency"
            popOverTemplate={
              <SimpleTips
                title="Latency"
                content={`Latency of all GET, PUT, and DELETE operations at the RING level
            for the time period selected.`}
              />
            }
          />
          <GrafanaPanel
            iFrameSrc={singleDiskBandwidthUrl}
            panelTitle="Bandwidth"
            popOverTemplate={
              <SimpleTips
                title="Bandwidth"
                content={`Bandwidth of all GET and PUT operations at the RING level for the time period selected.`}
              />
            }
          />
        </div>
      </div>
    );
  }
}
