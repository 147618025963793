import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, THead, Th, TBody, Tr, Td } from './common/Table';
import Tooltip from './Tooltip';
import * as cssVariables from '../components/ui/variables';

export const SimpleTable = ({ title, subtitle, columns, data }) => (
  <section>
    <TableHeader>
      <TableHeaderTitle>{title}</TableHeaderTitle>
      {subtitle && <SubTitleSpan>{subtitle}</SubTitleSpan>}
    </TableHeader>
    <Table>
      <THead>
        <Tr>
          {columns.map((column, index) => {
            return <StyledTh key={index}>{column.name}</StyledTh>;
          })}
        </Tr>
      </THead>
      <TBody>
        {data.map((item, index) => {
          return (
            <Tr key={index} hoverable>
              {columns.map((column, index) => {
                if (
                  column.type &&
                  column.type === 'boolean' &&
                  item[column.key]
                ) {
                  return (
                    <Td key={index}>
                      {column.tooltip ? (
                        <Tooltip
                          placement="top"
                          overlay={<span>{column.tooltip}</span>}
                        >
                          <DotContainer>
                            <Dot />
                          </DotContainer>
                        </Tooltip>
                      ) : (
                        <DotContainer>
                          <Dot />
                        </DotContainer>
                      )}
                    </Td>
                  );
                } else if (column.linkKey && item[column.linkKey]) {
                  return (
                    <Td key={index}>
                      <Link to={item[column.linkKey]}>{item[column.key]}</Link>
                    </Td>
                  );
                }
                return <Td key={index}>{item[column.key]}</Td>;
              })}
            </Tr>
          );
        })}
      </TBody>
    </Table>
  </section>
);

const StyledTh = styled(Th)`
  text-transform: none;
`;

const SubTitleSpan = styled.span`
  font-size: 12px;
  color: ${cssVariables.blu};
  padding: 0 10px;
`;

const TableHeader = styled.div`
  padding: 15px 0;
`;

const TableHeaderTitle = styled.h3`
  text-transform: capitalize;
  font-size: 16px;
  display: inline-block;
  padding-left: 10px;
  border-left: 2px solid ${cssVariables.fontClr};
`;

const Dot = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${cssVariables.fontClr};
  border-radius: 50%;
`;
const DotContainer = styled.span`
  display: flex;
  justify-content: center;
`;
