import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { authenticate } from '../actions/login';
import styled from 'styled-components';
import * as cssVariable from '../components/ui/variables.js';
import { Button } from '../components/ui/Button';
import scalityLogo from '../assets/images/branding.svg';

const LoginFormTitle = styled.h2`
  color: ${cssVariable.mainBlue};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 25px;
`;

const ValidationError = styled.p`
  float: left;
  text-align: left;
  padding: 15px 0 0 13px;
  color: #d71d4f;
`;

const ButtonContainer = styled.p`
  padding: 68px 0px;
  text-align: center;
  button {
    background-color: ${cssVariable.mainBlue};
    border-color: ${cssVariable.mainBlue};
    text-transform: uppercase;
    padding: 12px 46px;
    color: ${cssVariable.fontClr};
    font-size: 12px;
  }
`;

const LoginForm = styled.div`
  height: 343px;
  margin-top: 40px;
  padding: 40px;
  background-color: ${cssVariable.drkBg};
  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${cssVariable.brdrClrLogin};
    color: ${cssVariable.fontClr};
    font-size: 14px;
    outline: 0;
    box-shadow: none;
    border-radius: 0;

    &:first-child {
      margin-bottom: 25px;
    }

    &:focus {
      box-shadow: none;
      border-bottom-color: ${cssVariable.fontClr};
    }
  }
`;

const InnerHolder = styled.div`
  width: 390px;
  position: absolute;
  top: 22vh;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const Header = styled.div`
  img {
    height: 50px;
  }
`;

const LoginInContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
`;

export class LoginPage extends Component {
  // TODO : add validation & sanitize

  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      emptyCredentials: false
    };
  }

  doLogin(e) {
    e.preventDefault();

    const { username, password } = this.state;

    if (!username || !password) {
      this.setState({ emptyCredentials: true });
    } else {
      const data = { username: username, password: password };
      this.props.handleSubmit(data);
    }
  }

  handleUsernameChange(e) {
    this.setState({ emptyCredentials: false });

    let value = e.target.value;
    if (value.length <= 256) {
      this.setState({ username: value });
    }
  }

  handlePasswordChange(e) {
    this.setState({ emptyCredentials: false });

    let value = e.target.value;
    if (value.length <= 256) {
      this.setState({ password: value });
    }
  }

  render() {
    const { loginFail, authorizing } = this.props;
    let { username, password, emptyCredentials } = this.state;

    let brandImage;
    let brandSizeImg;
    const URL = window.location.protocol + '//' + window.location.host;
    if (process.env.NODE_ENV === 'development') {
      brandImage = scalityLogo;
      brandSizeImg = 'no_branding';
    } else {
      brandImage = URL + '/assets/images/branding.svg';
      brandSizeImg = 'with_branding';
    }

    if (!this.props.loggedOut) {
      return <Redirect to="/" />;
    }

    return (
      <LoginInContainer>
        <InnerHolder>
          <Header className="text-center">
            <span>
              <img className={brandSizeImg} src={brandImage} alt="Scality" />
            </span>
          </Header>

          <LoginForm>
            <LoginFormTitle>LOGIN</LoginFormTitle>
            <form onSubmit={e => this.doLogin(e)}>
              <input
                type="text"
                placeholder="USERNAME"
                name="username"
                value={username}
                onChange={e => this.handleUsernameChange(e)}
                className="form-control"
              />
              <input
                type="password"
                placeholder="PASSWORD"
                name="password"
                value={password}
                onChange={e => this.handlePasswordChange(e)}
                className="form-control"
              />
              {loginFail ? (
                <ValidationError>Invalid credentials!</ValidationError>
              ) : null}
              {emptyCredentials && (
                <ValidationError>
                  Please enter username &amp; password
                </ValidationError>
              )}
              <ButtonContainer>
                <Button
                  disabled={authorizing ? true : false}
                  type="submit"
                  className="btn btn-default"
                >
                  {authorizing ? 'authorizing...' : 'login'}
                </Button>
              </ButtonContainer>
            </form>
          </LoginForm>
        </InnerHolder>
      </LoginInContainer>
    );
  }
}

LoginPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loginFail: PropTypes.bool.isRequired,
  loggedOut: PropTypes.bool.isRequired,
  authorizing: PropTypes.bool
};

LoginPage.defaultProps = {
  loggedOut: true,
  loginFail: false,
  authorizing: false
};

const mapDispatchToProps = dispatch => {
  return {
    handleSubmit: data => dispatch(authenticate(data))
  };
};

const mapStateToProps = state => ({
  loginFail: state.get('login').get('loginFail'),
  loggedOut: state.get('login').get('loggedOut'),
  authorizing: state.get('login').get('authorizing')
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);
