import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ol,
  Ul,
  Li
} from './Tips';

const VolumeCreateTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`Create New Volume`}</HelpPopupTitle>
    <HelpPopupContent>
      {`To create a volume:`}
      <Ol>
        <Li>{`Enter a volume Name - e.g.: Mktg-2018-Bkp`}</Li>
        <Li>
          {`Enter a description (optional)  - e.g.: Marketing Backup 2018`}
        </Li>
        <Li>
          {`Select Backend RINGs, a RING pair with a Data type RING and a Metadata type RING  - e.g.: DATA + META`}
        </Li>
        <Li>{`Select a Protection Level - e.g.: E.C 8+4`}</Li>
      </Ol>
      {`Please Note:`}
      <Ul>
        <Li>
          <b>{`"Custom Protection Level" is to use with caution. Contact Scality Support for more information.`}</b>
        </Li>
        <Li>{`Overhead is indicative based on typical file sizes of 1MB`}</Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default VolumeCreateTips;
