import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const DisksPerformanceTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>Disks Top-10 Highest Latencies</HelpPopupTitle>
    <HelpPopupContent>
      The top-10 of the slowest <b>storage disks</b> in terms of latency.
      <Ul>
        <Li>
          <b>Top-10 Individual Disks Performance:</b> Disk latencies are considered individually
        </Li>
        <Li>
          <b>Top-10 By Server Disks Performance:</b> Disk latencies are aggregated per server
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default DisksPerformanceTips;
