import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChevronRight } from 'styled-icons/material/ChevronRight';
import { Link } from 'react-router-dom';

const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const CurrentPageTitle = styled.span`
  font-weight: 700;
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

const BreadcrumbChevron = styled(ChevronRight)`
  color: grey;
  margin: 0 3px;
`;

const propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired, // Required for Cypress
      customName: PropTypes.object,
      link: PropTypes.string
    })
  )
};

const defaultProps = {
  routes: []
};

class Breadcrumb extends React.Component {
  render() {
    const { routes } = this.props;
    const routesLength = routes.length;
    const breadcrumbItems =
      routes &&
      routes.reduce((prev, route, idx) => {
        const bcKey = 'bc_' + idx;
        let breadcrumbItem = null;

        if (route.link == null) {
          breadcrumbItem = (
            <div key={bcKey}>
              {route.customName ? route.customName : route.name}
            </div>
          );
        } else {
          breadcrumbItem = (
            <StyledLink
              key={bcKey}
              to={route.link}
              data-cy={'breadcrumb-link-' + route.name}
            >
              {route.customName ? route.customName : route.name}
            </StyledLink>
          );
        }

        if (routesLength !== idx + 1) {
          return [
            ...prev,
            breadcrumbItem,
            <BreadcrumbChevron key={'chevron_' + idx} size={20} />
          ];
        } else {
          // Last item
          return [
            ...prev,
            <CurrentPageTitle key={bcKey}>
              {route.customName ? route.customName : route.name}
            </CurrentPageTitle>
          ];
        }
      }, []);

    return <BreadcrumbContainer>{breadcrumbItems}</BreadcrumbContainer>;
  }
}

Breadcrumb.propTypes = propTypes;
Breadcrumb.defaultProps = defaultProps;

export default Breadcrumb;
