/**
 * Overview page
 *
 * This is the page user will see at the '/' route
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  checkAPIConnection,
  getVersion,
  resetInvalidBasicAuth,
  getInfraMetaData
} from '../actions/overview';
import ApiError from '../components/ApiError';
import { HASH_KEY } from '../actions/global/constants';
import Dashboard from './Dashboard';

export class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestError: false
    };
  }

  componentDidMount() {
    this.props.checkAPIConnection();
    this.props.getVersion();
    this.props.getInfraMetaData();

    if (this.props.invalidBasicAuth) {
      localStorage.removeItem(HASH_KEY);
      this.props.resetInvalidBasicAuth();
      this.props.history.push('/login');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invalidBasicAuth) {
      localStorage.removeItem(HASH_KEY);
      this.props.resetInvalidBasicAuth();
      this.props.history.push('/login');
    }
  }

  render() {
    const { checkingAPIConnection, apiConnectionError } = this.props;

    return !checkingAPIConnection ? (
      apiConnectionError ? (
        <ApiError />
      ) : (
        <Dashboard />
      )
    ) : null;
  }
}

Overview.propTypes = {
  apiConnectionError: PropTypes.bool.isRequired,
  checkAPIConnection: PropTypes.func,
  checkingAPIConnection: PropTypes.bool,
  getVersion: PropTypes.func
};

Overview.defaultProps = {
  apiConnectionError: false,
  checkingAPIConnection: true,
  requestError: false
};

const mapDispatchToProps = dispatch => {
  return {
    checkAPIConnection: () => dispatch(checkAPIConnection()),
    getVersion: () => dispatch(getVersion()),
    resetInvalidBasicAuth: () => dispatch(resetInvalidBasicAuth()),
    getInfraMetaData: () => dispatch(getInfraMetaData())
  };
};

const mapStateToProps = state => ({
  apiConnectionError: state.get('overview').get('apiConnectionError'),
  checkingAPIConnection: state.get('overview').get('checkingAPIConnection'),
  invalidBasicAuth: state.get('overview').get('invalidBasicAuth')
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Overview));
