import React from 'react';
import { HelpPopupContainer, HelpPopupTitle, HelpPopupContent } from './Tips';

const ScalityRingTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`The Scality RING`}</HelpPopupTitle>
    <HelpPopupContent>
      {`The Scality RING software is a Software-Defined Storage (SDS) solution that turns a pool of x86-based Linux commodity servers into an unbounded scale-out storage system that supports object and file based applications.`}
    </HelpPopupContent>
    <HelpPopupContent>
      {`Clicking on a RING displays its associated detailed information on the right panel.`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default ScalityRingTips;
