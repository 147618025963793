import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const RingObjectToRebuildChartTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`RING Objects To Rebuild`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Objects to rebuild in the RING, by type:`}
      <Ul>
        <Li>
          <b>{`OOS Chunks`}</b>
          {`: Rebuilt automatically, these are usually the result of a failed drive`}
        </Li>
        <Li>
          <b>{`BAD Chunks`}</b>
          {`: Rebuilt automatically, these are usually the results of a bad disk sector/block`}
        </Li>
      </Ul>
      {`Note that when a disk fails the count of OOS chunks increases quickly, and then decreases when the "rebuild or repair background tasks" are reconstructing the disk.`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default RingObjectToRebuildChartTips;
