import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';

import {
  STATUS_OK,
  STATUS_WARNING,
  STATUS_CRITICAL,
  STATUS_EMPTY,
  STATUS_NO_CONNECTOR,
  STATUS_NOMINAL,
  STATUS_DEGRADED,
  STATUS_UNAVAILABLE,
  STATUS_NOT_DEPLOYED
} from '../constants';
import okStatusIcon from '../assets/images/global-icons/success-icon.svg';
import warningStatusIcon from '../assets/images/global-icons/warning-icon.svg';
import errorStatusIcon from '../assets/images/global-icons/error-icon.svg';
import disabledStatusIcon from '../assets/images/global-icons/error-disable-icon.svg';
import { getStatusColor } from '../utils/status';

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Status = styled.span`
  display: flex;
  width: 15px;
  height: 15px;
  background: url(${props => props.icon || disabledStatusIcon}) no-repeat;
`;

const StatusText = styled.span`
  margin-left: 5px;
  font-size: 13px;
  font-weight: 700;
  color: ${props => props.color || '#76828f'};
`;

const InlineBreadcrumbItem = styled.div`
  display: flex;
`;

/**
 * FIXME Patrick Ear: We might need to check what status
 * can be together. We maybe should not mix the logic between :
 * - Ring Status
 * - Disk Status
 * - Volume Status
 * - Config Group Status
 * We may need to create a new component instead and give it a more
 * accurate name.
 * Also, we might need to put a new color in case the input is wrong
 * So that it's easy to figure out that something is wrong
 * (ex: The back-end add a new status but the front-end is not aware
 * of it)
 */
const statusToColorIcon = status => {
  let icon;

  switch (status) {
    case STATUS_OK:
    case STATUS_NOMINAL:
      icon = okStatusIcon;
      break;
    case STATUS_WARNING:
    case STATUS_DEGRADED:
      icon = warningStatusIcon;
      break;
    case STATUS_CRITICAL:
    case STATUS_UNAVAILABLE:
      icon = errorStatusIcon;
      break;
    case STATUS_EMPTY:
    case STATUS_NO_CONNECTOR:
    case STATUS_NOT_DEPLOYED:
      icon = disabledStatusIcon;
      break;
    default:
      icon = okStatusIcon;
      break;
  }

  return { color: getStatusColor(status), icon };
};

const InlineStatus = props => {
  const { status, text } = props;
  let { icon, color } = statusToColorIcon(status);

  return (
    <StatusContainer>
      <Status icon={icon} />
      <StatusText color={color}>{text}</StatusText>
    </StatusContainer>
  );
};

const StatusIcon = props => {
  const { status } = props;
  let { icon } = statusToColorIcon(status);

  return <Status icon={icon} />;
};

InlineStatus.propTypes = {
  status: Proptypes.oneOf([
    STATUS_OK,
    STATUS_WARNING,
    STATUS_CRITICAL,
    STATUS_EMPTY,
    STATUS_NO_CONNECTOR,
    STATUS_NOMINAL,
    STATUS_DEGRADED,
    STATUS_UNAVAILABLE
  ]),
  text: Proptypes.string
};

InlineStatus.defaultProps = {
  status: STATUS_EMPTY,
  text: 'NO STATUS'
};

export { InlineBreadcrumbItem, InlineStatus, StatusIcon };
