import styled, { css } from 'styled-components';
import * as cssVariables from './variables';

export const Panel = styled.section`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid transparent;
  ${props =>
    props.selected &&
    css`
      background: ${cssVariables.black};
      border: 1px solid ${cssVariables.brdrClr};
    `}

  .fa-question-circle {
    color: ${cssVariables.infoIcnClr};
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const PanelContent = styled.div`
  padding-top: 15px;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

export const PanelTitle = styled.h3`
  text-transform: capitalize;
  font-size: 16px;
  display: inline-block;
  padding-left: 10px;
  border-left: 2px solid ${cssVariables.fontClr};
`;
