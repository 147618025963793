import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Nav from '../containers/Nav';
import { editInfraMetadataAction } from '../actions/overview';
import scalityLogo from '../assets/images/branding.svg';

const hdrBg = '#211d1d';
const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${hdrBg};
  height: 56px;
  width: 100%;
  position: fixed;
  z-index: 750;
  box-shadow: 0px 5px 10px #000;
  padding: 0 15px;
`;

const Logo = styled.div`
  display: flex;
  margin: 12px 0 12px 0;
  cursor: pointer;
  img {
    width: 120px;
    height: 32px;
    &.with_branding {
      width: inherit !important;
    }
  }
`;

const PlatformName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  cursor: default;
  &:hover {
    background-color: #3c3c3c;
  }
`;

const PftNameFormContainer = styled.div`
  padding-left: 10px;
`;

const PtfName = styled.input`
  padding: 5px 5px;
  border-radius: 5px;
  border: 1px solid grey;
`;

const Button = styled.button`
  background-color: #2b65ad;
  border-radius: 5px;
  margin-left: 5px;
`;

const CancelButton = styled.button`
  background-color: #76828f;
  border-radius: 5px;
  margin-left: 5px;
`;

export class Header extends Component {
  state = {
    isEditingPtfName: false,
    editedName: null,
    currentPtfName: ''
  };

  componentWillReceiveProps(props) {
    if (props.platformName !== this.props.platformName) {
      this.setState({ currentPtfName: props.platformName });
    }
  }

  render() {
    let brandImage;
    let brandSizeImg;
    const URL = window.location.protocol + '//' + window.location.host;
    if (process.env.NODE_ENV === 'development') {
      brandImage = scalityLogo;
      brandSizeImg = 'no_branding';
    } else {
      brandImage = URL + '/assets/images/branding.svg';
      brandSizeImg = 'with_branding';
    }

    return (
      <NavBar>
        <Logo>
          <Link to="/">
            <img className={brandSizeImg} src={brandImage} alt="scality" />
          </Link>
          {this.state.isEditingPtfName ? (
            <PftNameFormContainer>
              <PtfName
                placeholder="Set supervisor's name"
                value={
                  this.state.editedName == null
                    ? this.state.currentPtfName
                    : this.state.editedName
                }
                onChange={e => this.setState({ editedName: e.target.value })}
              />
              <CancelButton
                onClick={() =>
                  this.setState({ isEditingPtfName: false, editedName: null })
                }
              >
                <i className="fa fa-times" />
              </CancelButton>
              <Button
                onClick={() => {
                  this.setState({ isEditingPtfName: false, editedName: null });
                  this.props.editInfraMetadata(this.state.editedName);
                }}
              >
                <i className="fa fa-check" />
              </Button>
            </PftNameFormContainer>
          ) : this.props.platformName ? (
            <PlatformName
              onClick={() => this.setState({ isEditingPtfName: true })}
            >
              {this.props.platformName}
            </PlatformName>
          ) : (
            <PlatformName
              onClick={() => this.setState({ isEditingPtfName: true })}
            >
              Supervisor
            </PlatformName>
          )}
        </Logo>
        <Nav />
      </NavBar>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    editInfraMetadata: ptfName => {
      dispatch(editInfraMetadataAction(ptfName));
    }
  };
};

export default connect(null, mapDispatchToProps)(Header);
