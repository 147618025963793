import {
  USER_LOGIN,
  AUTHORIZING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  RESTORE_HASH
} from './constants';

export const authenticate = data => ({
  data,
  type: USER_LOGIN
});

export const authorizing = () => ({
  type: AUTHORIZING
});

export const loginSuccess = hash => ({
  hash,
  type: USER_LOGIN_SUCCESS
});

export const loginFail = () => ({
  type: USER_LOGIN_FAIL
});

export const logout = () => ({
  type: USER_LOGOUT
});

export const restoreHash = hash => ({
  hash,
  type: RESTORE_HASH
});
