import React from 'react';
import styled from 'styled-components';

import HelpPopover from '../components/common/HelpPopover';
import S3ManagementTips from '../tips/S3ManagementTips';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

// formValidator might be needed later

const disabledColor = '#979797';
const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  background-color: ${props => (props.canSubmit ? '#2b65ad' : disabledColor)};
  border-radius: 3px;
  font-size: 18px;
  padding: 6px 20px;
  outline: none;

  &:hover {
    background-color: ${props => (props.canSubmit ? '#205aa5' : disabledColor)};
  }
  margin: 0 10px;
`;

const EditContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const EditHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const EditTitle = styled.div`
  margin: 10px 0;
  font-size: 18px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormActionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const FormField = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 6px 12px;
  height: 50px;
  background-color: #0e0b0b;
  border-radius: 0;
  color: #768290;
  border: 2px solid #222;
`;

const FormLabel = styled.label`
  width: 35%;
  font-family: Work Sans, sans-serif;
  font-weight: normal;
`;

const EditS3 = props => {
  const { s3Storage, onSubmit, closePopup } = props;
  let initialValues = {};
  if (s3Storage != null) {
    initialValues = {
      s3Endpoint: s3Storage.endpoint,
      description: s3Storage.name,
      s3ConsoleUrl: s3Storage.identisee_url,
      s3BrowserUrl: s3Storage.s3browser_url
    };
  }

  const submit = values => {
    const newS3Values = {
      endpoint: values.s3Endpoint,
      description: values.description,
      accountUrl: values.s3ConsoleUrl,
      browserUrl: values.s3BrowserUrl
    };
    onSubmit(newS3Values);
    closePopup();
  };

  const validationSchema = Yup.object().shape({
    s3Endpoint: Yup.string().required('Required'),
    description: Yup.string(),
    s3ConsoleUrl: Yup.string(),
    s3BrowserUrl: Yup.string()
  });

  return (
    <EditContainer>
      <EditHeader>
        <img src={require('../assets/images/s3-icon.png')} alt="s3-icon" />
        <EditTitle>
          S3 Service <HelpPopover content={<S3ManagementTips />} />
        </EditTitle>
      </EditHeader>

      {s3Storage !== null ? (
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={validationSchema}
        >
          {props => {
            const {
              values,
              errors,
              dirty,
              handleChange,
              handleSubmit,
              handleReset
            } = props;

            const canSubmit = dirty && _.isEmpty(errors);
            return (
              <Form onReset={handleReset} onSubmit={handleSubmit}>
                <FormContainer>
                  <FormSection>
                    <FormField>
                      <FormLabel>S3 Endpoint</FormLabel>
                      <FormInput
                        name="s3Endpoint"
                        value={values.s3Endpoint}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField>
                      <FormLabel>Description</FormLabel>
                      <FormInput
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField>
                      <FormLabel>S3 Console URL</FormLabel>
                      <FormInput
                        name="s3ConsoleUrl"
                        value={values.s3ConsoleUrl}
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField>
                      <FormLabel>S3 Browser URL</FormLabel>
                      <FormInput
                        name="s3BrowserUrl"
                        value={values.s3BrowserUrl}
                        onChange={handleChange}
                      />
                    </FormField>
                  </FormSection>
                  <FormActionSection>
                    <Button type="button" onClick={closePopup}>
                      Cancel
                    </Button>
                    <Button canSubmit={canSubmit} disabled={!canSubmit}>
                      Save
                    </Button>
                  </FormActionSection>
                </FormContainer>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </EditContainer>
  );
};

export default EditS3;
