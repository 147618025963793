import {
  ASC,
  DESC,
  STATUS_ALL,
  STATUS_CRITICAL,
  STATUS_EMPTY,
  STATUS_ERROR,
  STATUS_NO_CONNECTOR,
  STATUS_NOT_DEPLOYED,
  STATUS_OK,
  STATUS_WARNING,
  STATUS_THEME
} from '../constants';

import {
  STATUS_DEGRADED,
  STATUS_NOMINAL,
  STATUS_UNAVAILABLE
} from '../actions/overview/constants';

const statusWeights = {
  [STATUS_ALL]: 0,
  [STATUS_EMPTY]: 0,
  [STATUS_NO_CONNECTOR]: 0,
  [STATUS_NOT_DEPLOYED]: 0,
  [STATUS_UNAVAILABLE]: 0,
  [STATUS_OK]: 1,
  [STATUS_NOMINAL]: 1,
  [STATUS_DEGRADED]: 2,
  [STATUS_WARNING]: 2,
  [STATUS_CRITICAL]: 3,
  [STATUS_ERROR]: 3
};

const getStatusWeight = status => {
  return statusWeights[status];
};

const sortByStatusASC = data => {
  return [...data].sort((objA, objB) => {
    return getStatusWeight(objA.status) - getStatusWeight(objB.status);
  });
};

const sortByStatus = (data, order) => {
  if (order === ASC) {
    return sortByStatusASC(data);
  }
  if (order === DESC) return sortByStatusASC(data).reverse();
  else throw Error('Unknown order');
};

const getStatusColor = status => {
  switch (status) {
    case STATUS_OK:
    case STATUS_NOMINAL:
      return STATUS_THEME.green;
    case STATUS_WARNING:
    case STATUS_DEGRADED:
      return STATUS_THEME.orange;
    case STATUS_CRITICAL:
    case STATUS_UNAVAILABLE:
      return STATUS_THEME.red;
    default:
      return STATUS_THEME.grey;
  }
};

export { sortByStatus, getStatusWeight, getStatusColor };
