import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';

import HelpPopover from '../components/common/HelpPopover';
import StorageServiceTips from '../tips/StorageServiceTips';
import { compareByObjectValue } from '../utils/helpers';

import * as StyledStorage from '../components/ui/StorageContainer';
import * as StyledPanel from '../components/ui/Panel';

import VolumeStorage from '../components/StorageVolume';
import ObjectStorage from '../components/StorageObject';
import { KEY_NAME } from '../constants';

import { selectAllVolumesState } from '../ducks/volumes';
import { selectS3Storages } from '../ducks/s3';

class StorageServices extends Component {
  openSingleS3(s3) {
    const defaultPath = `/storages/object/${s3.id}`;
    if (
      this.props.history.location.pathname.endsWith('/create') ||
      this.props.history.location.pathname.endsWith('/edit')
    ) {
      this.props.history.push(defaultPath);
    } else {
      const match = matchPath(this.props.history.location.pathname, {
        path: '/storages/object/:id'
      });
      const nextPath =
        match && match.params && match.params.id
          ? this.props.history.location.pathname.replace(match.params.id, s3.id)
          : defaultPath;
      this.props.history.push(nextPath);
    }
  }

  openSingleVolume(volume) {
    const defaultPath = `/storages/file/${volume.id}`;
    if (
      this.props.history.location.pathname.endsWith('/create') ||
      this.props.history.location.pathname.endsWith('/edit')
    ) {
      this.props.history.push(defaultPath);
    } else {
      const match = matchPath(this.props.history.location.pathname, {
        path: '/storages/file/:id'
      });
      const nextPath =
        match && match.params && match.params.id
          ? this.props.history.location.pathname.replace(
              match.params.id,
              volume.id
            )
          : defaultPath;
      this.props.history.push(nextPath);
    }
  }

  render() {
    const { storages, volumes, location } = this.props;
    const exactPath = location.pathname;
    const isStoragesSelected = exactPath.startsWith('/storages');

    return (
      <StyledPanel.Panel selected={isStoragesSelected}>
        <div>
          <StyledPanel.PanelTitle>Storage Services</StyledPanel.PanelTitle>
          <HelpPopover content={<StorageServiceTips />} />
        </div>
        <StyledPanel.PanelContent>
          {storages.map(storage => (
            <ObjectStorage
              storage={storage}
              key={storage.endpoint}
              openDetailPanel={this.openSingleS3.bind(this)}
            />
          ))}

          {storages && storages.length && volumes && volumes.length ? (
            <StyledStorage.Separator />
          ) : null}

          {volumes.sort(compareByObjectValue(KEY_NAME)).map(volume => (
            <VolumeStorage
              volume={volume}
              key={volume.name}
              openDetailPanel={this.openSingleVolume.bind(this)}
            />
          ))}
        </StyledPanel.PanelContent>
      </StyledPanel.Panel>
    );
  }
}

StorageServices.propTypes = {
  storages: PropTypes.array,
  volumes: PropTypes.array
};

StorageServices.defaultProps = {
  storages: [],
  volumes: []
};

const mapStateToProps = state => ({
  storages: selectS3Storages(state),
  volumes: selectAllVolumesState(state)
});

export default withRouter(connect(mapStateToProps)(StorageServices));
