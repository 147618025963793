import React, { Component } from 'react';
import styled from 'styled-components';
import CountTo from 'react-count-to';

import SelectBox from './common/Select';
import SimpleTips from '../tips/SimpleTips';
import HelpPopover from './common/HelpPopover';
import storageOnline from '../assets/images/dashboard-icons/storage-online.png';
import storageWarning from '../assets/images/dashboard-icons/storage-warning.png';
import storageCritical from '../assets/images/dashboard-icons/storage-critical.png';
import { SectionHeader, SectionTitle } from './ui/AllPagesItems';

const SEVEN_DAYS = { value: 7, label: '7 days' };
const FOURTEEN_DAYS = { value: 14, label: '14 days' };
const THIRTY_DAYS = { value: 30, label: '30 days' };

const OnlineChartContainer = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const OnlineChart = styled.div`
  height: 97px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ onlinePercentage }) => {
    let status = storageCritical;
    if (onlinePercentage >= 100) {
      status = storageOnline;
    } else if (onlinePercentage < 100 && onlinePercentage >= 80) {
      status = storageWarning;
    }
    return `url(${status});`;
  }};
  margin: 20px 0;
`;

export default class Online extends Component {
  constructor(props) {
    super(props);

    const options = [
      { ...SEVEN_DAYS },
      { ...FOURTEEN_DAYS },
      { ...THIRTY_DAYS }
    ];

    this.state = {
      online_options: options,
      selectedOnlineFilterOption: options[0]
    };
  }

  onOnlineFilterChange(selectedOption) {
    this.setState({
      selectedOnlineFilterOption: selectedOption
    });

    this.props.onOnlineFilterChange(selectedOption);
  }

  render() {
    const storageAvailability = this.props.storageAvailability
      .availability_percentage;
    const { online_options, selectedOnlineFilterOption } = this.state;
    let onlinePercentage = 0;

    // FIXME Patrick Ear : Need to be refactor to handle the case correctly
    // It's just a temp fix.
    onlinePercentage = Number(
      storageAvailability == null
        ? (0).toFixed(4)
        : storageAvailability.toFixed(4)
    );

    const countToValue = value => (
      <span>
        {value}
        {'%'}
      </span>
    );

    const OnlineTitleContainer = styled.div`
      display: flex;
      justify-content: space-between;
    `;

    return (
      <div>
        <SectionHeader>
          <OnlineTitleContainer>
            <div>
              <SectionTitle>Online</SectionTitle>
              <HelpPopover
                content={
                  <SimpleTips
                    title="S3 Online"
                    content={`The percentage of time the S3 service has been online during the selected time period.`}
                  />
                }
              />
            </div>
            <SelectBox
              options={online_options}
              name="online_options"
              value={selectedOnlineFilterOption}
              onChange={this.onOnlineFilterChange.bind(this)}
            />
          </OnlineTitleContainer>
        </SectionHeader>
        <OnlineChartContainer>
          <OnlineChart onlinePercentage={onlinePercentage} />
          <CountTo digits={4} to={onlinePercentage} speed={300}>
            {countToValue}
          </CountTo>
        </OnlineChartContainer>
      </div>
    );
  }
}
