import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import memoizeOne from 'memoize-one';
import { matchPath } from 'react-router';

import {
  ASC,
  DESC,
  KEY_CAPACITY,
  KEY_HARDWARE_SERVER_TYPE,
  KEY_NAME,
  KEY_ROLES,
  KEY_STATUS,
  KEY_ZONE,
  itemsPerPageOptions,
  STATUS_ALL,
  statusFilterOptions
} from '../constants';
import Pagination from '../components/Pagination';
import SelectBox from '../components/common/Select';
import SearchBar from '../components/SearchBar';
import {
  Table,
  THead,
  Th,
  TBody,
  Tr,
  Td,
  NoDataRow
} from '../components/common/Table';
import { getFilteredItems } from '../utils/dataTableHelpers';
import {
  sortByPropertyOrder,
  prettifyBytes,
  createArrayFromKey,
  sortByCapacity,
  sortByRoles,
  filterServerRoles,
  getBrandNameFromServerType,
  sortByHardwareServerType,
  sortByZoneName
} from '../utils/helpers';
import ServerAdd from './ServerAdd';
import {
  PanelContainer,
  VerticalItemsContainer,
  PanelTools
} from '../components/ui/AllPagesItems';
import Tooltip from '../components/Tooltip';
import { CircleStatus } from '../components/CircleStatus';
import Breadcrumb from '../components/Breadcrumb';
import SortIcon from '../components/common/SortIcon';
import { TooltipOverlay } from '../components/ui/AllPagesItems';
import vmLogo from '../assets/images/brand/vm.svg';
import hpeLogo from '../assets/images/brand/hpe_mini.svg';
import dellLogo from '../assets/images/brand/dell.svg';
import ciscoLogo from '../assets/images/brand/cisco.svg';

const logoHPE = <img src={hpeLogo} height="26" alt="HPE" />;
const logoDELL = <img src={dellLogo} height="16" alt="DELL" />;
const logoCISCO = <img src={ciscoLogo} height="26" alt="CISCO" />;

const logoVM = (
  <img
    src={vmLogo}
    alt="VIRTUAL MACHINE"
    height="26"
    style={{ color: 'white' }}
  />
);

const logos = {
  HPE: logoHPE,
  DELL: logoDELL,
  CISCO: logoCISCO,
  VM: logoVM
};

class AllServers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsPerPageOptions,
      itemsPerPageOption: itemsPerPageOptions[0],
      activePage: 1,
      search: ''
    };

    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.onSearchUpdate = this.onSearchUpdate.bind(this);
    this.onFilterOptionChange = this.onFilterOptionChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  sortServers(servers, key, sortOrder = ASC) {
    return memoizeOne((servers, key, sortOrder = ASC) => {
      switch (key) {
        case KEY_ROLES:
          return sortByRoles(servers, sortOrder);
        case KEY_CAPACITY:
          return sortByCapacity(servers, sortOrder);
        case KEY_ZONE:
          return sortByZoneName(servers, sortOrder);
        case KEY_HARDWARE_SERVER_TYPE:
          return sortByHardwareServerType(servers, sortOrder);
        default:
          return sortByPropertyOrder(servers, key, sortOrder);
      }
    })(servers, key, sortOrder);
  }

  matchSearch(servers, term) {
    return memoizeOne((servers, term) => {
      let key = term.toLowerCase();

      const matchServers = servers.filter(server => {
        const serverName = server.name.toLowerCase();
        return serverName.indexOf(key) >= 0;
      });

      return matchServers;
    })(servers, term);
  }

  static getDerivedStateFromProps(props) {
    const { location, history } = props;

    const match = matchPath(location.pathname, {
      path: '/servers',
      exact: true
    });

    if (match) {
      let queryString = location.search || '';
      queryString = queryString.substring(1);
      let params = qs.parse(queryString);
      if (!params.order || !params.sort) {
        //update route with sort by default
        params.order = ASC;
        params.sort = KEY_NAME;
        history.replace(
          '/servers?' + qs.stringify(params, { skipNulls: true })
        );
      }
    }
    return null;
  }

  redirectToSort(key, location) {
    let queryString = location.search || '';
    queryString = queryString.substring(1);
    let params = qs.parse(queryString);
    let url = '/servers?';

    if (key === params.sort) {
      if (params.order === ASC) {
        params.order = DESC;
      } else {
        params.order = ASC;
      }
    } else {
      params.order = ASC;
    }

    params.sort = key;

    url = url + qs.stringify(params, { skipNulls: true });

    this.props.history.push(url);
  }

  onTableRowClick(server) {
    this.props.history.push(`/servers/${server.id}`);
  }

  onFilterOptionChange(option, location) {
    let queryString = location.search || '';
    queryString = queryString.substring(1);
    let params = qs.parse(queryString);
    let url = '/servers?';

    params.status = option.value;
    url = url + qs.stringify(params, { skipNulls: true });
    this.props.history.push(url);

    this.setState({ activePage: 1 });
  }

  onSearchUpdate(term) {
    this.setState({ search: term });
  }

  onPaginationChange(page) {
    this.setState({ activePage: page });
  }

  onItemsPerPageOptionChange(itemsPerPageOption) {
    this.setState({ itemsPerPageOption });
  }

  render() {
    const {
      itemsPerPageOption,
      itemsPerPageOptions,
      activePage,
      search
    } = this.state;
    const { zones, adminAccess, location, servers } = this.props;
    const pagerFirstItemIndex =
      activePage * itemsPerPageOption.value - itemsPerPageOption.value;
    const pagerLastItemIndex = activePage * itemsPerPageOption.value - 1;
    let queryString =
      location && location.search ? location.search.substring(1) : '';
    const queryParams = qs.parse(queryString) || {};
    const selectedSort = queryParams.sort || KEY_NAME;
    const selectedOrder = queryParams.order || ASC;

    const selectedFilter =
      statusFilterOptions.find(status => status.value === queryParams.status) ||
      statusFilterOptions[0];

    /**
     * Servers will be filter then sort
     * - Status
     * - Search
     * - Sort
     */
    let filteredServers = getFilteredItems(
      servers,
      queryParams.status || STATUS_ALL
    );
    let matchSearchServers = this.matchSearch(filteredServers, search);
    let matchServers = this.sortServers(
      matchSearchServers,
      selectedSort,
      selectedOrder
    );

    // FIXME Patrick Ear : This logic is only here because we do not have real datatable

    let tableRows = null;
    if (matchServers.length === 0) {
      tableRows = <NoDataRow colSpan={7} />;
    } else if (matchServers.length === 0) {
      tableRows = <NoDataRow colSpan={7} text="No data selected." />;
    } else {
      tableRows = matchServers.map((server, index) => {
        if (index >= pagerFirstItemIndex && index <= pagerLastItemIndex) {
          return (
            <Tablerow
              key={`node_row_${index}`}
              onTableRowClick={this.onTableRowClick}
              server={server}
            />
          );
        }
        return null;
      });
    }

    const dataIPAddressArray = createArrayFromKey(
      matchServers,
      'data_ip_address'
    );
    const dataZonesArray = createArrayFromKey(zones, KEY_NAME);
    const dataRolesArray = ['S3'];
    return (
      <PanelContainer>
        <VerticalItemsContainer>
          <div
            style={{
              display: 'flex',
              marginBottom: '15px'
            }}
          >
            <Breadcrumb routes={[{ name: 'SERVERS' }]} />
          </div>

          <PanelTools>
            <SearchBar
              onSearchChange={this.onSearchUpdate}
              placeholder="server1"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SelectBox
                options={statusFilterOptions}
                name="disks-filter"
                type="state"
                value={selectedFilter}
                onChange={option => this.onFilterOptionChange(option, location)}
              />
              <div>
                {adminAccess ? (
                  <ServerAdd
                    dataIPAddressArray={dataIPAddressArray}
                    dataZonesArray={dataZonesArray}
                    dataRolesArray={dataRolesArray}
                    {...this.props}
                  />
                ) : null}
              </div>
            </div>
          </PanelTools>
          <Table>
            <THead>
              <Tr>
                <Th>
                  Name
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_NAME, location)}
                    selected={selectedSort === KEY_NAME}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th>
                  Status
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_STATUS, location)}
                    selected={selectedSort === KEY_STATUS}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th>
                  Zone
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_ZONE, location)}
                    selected={selectedSort === KEY_ZONE}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th>
                  Hardware
                  <SortIcon
                    onSort={() =>
                      this.redirectToSort(KEY_HARDWARE_SERVER_TYPE, location)
                    }
                    selected={selectedSort === KEY_HARDWARE_SERVER_TYPE}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th>
                  Capacity
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_CAPACITY, location)}
                    selected={selectedSort === KEY_CAPACITY}
                    selectedOrder={selectedOrder}
                  />
                </Th>
                <Th colSpan={2}>
                  Roles
                  <SortIcon
                    onSort={() => this.redirectToSort(KEY_ROLES, location)}
                    selected={selectedSort === KEY_ROLES}
                    selectedOrder={selectedOrder}
                  />
                </Th>
              </Tr>
            </THead>
            <TBody>{tableRows}</TBody>
          </Table>
          <SelectBox
            options={itemsPerPageOptions}
            name="all_servers_options"
            value={itemsPerPageOption}
            onChange={this.onItemsPerPageOptionChange.bind(this)}
          />
          {matchServers.length > itemsPerPageOption.value ? (
            <Pagination
              totalItems={matchServers.length}
              itemsPerPage={itemsPerPageOption.value}
              activePage={activePage}
              switchPage={this.onPaginationChange}
            />
          ) : null}
        </VerticalItemsContainer>
      </PanelContainer>
    );
  }
}

const Tablerow = props => {
  const { server, onTableRowClick } = props;

  let diskCapacity;
  if (server && server.hardware && server.hardware.disks) {
    // Compute the total capacity
    const total_capacity = server.hardware.disks.reduce((acc, disk) => {
      return acc + disk.capacity;
    }, 0);
    diskCapacity = prettifyBytes(total_capacity);
  } else {
    diskCapacity = {
      value: 0,
      unit: 'B'
    };
  }

  let serverRoles = filterServerRoles(server.roles);

  const server_type = server.hardware.server_type;
  const brandName = getBrandNameFromServerType(server_type);
  const hardwareClass = logos[brandName] || server_type;

  return (
    <Tr onClick={() => onTableRowClick(server)} hoverable>
      <Td>
        <Tooltip
          placement="top"
          overlay={<TooltipOverlay>{server.name}</TooltipOverlay>}
        >
          <span>{server.name}</span>
        </Tooltip>
      </Td>
      <Td>
        <CircleStatus status={server.status} />
      </Td>
      <Td>{server.zone.name}</Td>
      <Td className="hardware">
        <Tooltip
          placement="top"
          overlay={<TooltipOverlay>{server_type}</TooltipOverlay>}
        >
          <span>{hardwareClass}</span>
        </Tooltip>
      </Td>
      <Td>
        {diskCapacity.value} {diskCapacity.unit}
      </Td>
      <Td colSpan={2}>{serverRoles.join(', ')}</Td>
    </Tr>
  );
};

Tablerow.propTypes = {
  server: PropTypes.object
};

Tablerow.defaultProps = {
  server: {}
};

AllServers.propTypes = {
  servers: PropTypes.array,
  zones: PropTypes.array,
  adminAccess: PropTypes.bool
};

AllServers.defaultProps = {
  servers: [],
  zones: [],
  adminAccess: false
};

const mapStateToProps = state => {
  return {
    servers: state.get('servers').get('list'),
    zones: state.get('zones').get('list'),
    adminAccess: state.get('overview').get('adminAccess')
  };
};

export default withRouter(connect(mapStateToProps)(AllServers));
