import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ServerIcon(props) {
  return (
    <SvgIcon width="461" height="128" viewBox="0 0 461 128" {...props}>
      <title>{props.title || 'Server Type'}</title>
      <desc>Made on figma.com</desc>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.58173 3.58173 0 8 0H453C457.418 0 461 3.58173 461 8V120C461 124.418 457.418 128 453 128H8C3.58173 128 0 124.418 0 120V8ZM166 58H269V69H166V58ZM76 89C89.8071 89 101 77.8071 101 64C101 50.1929 89.8071 39 76 39C62.1929 39 51 50.1929 51 64C51 77.8071 62.1929 89 76 89Z"
        fill="white"
      />
    </SvgIcon>
  );
}
