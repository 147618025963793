import React, { Component } from 'react';
import SelectBox from './common/Select';
import GrafanaPanel from './GrafanaPanel';
import SimpleTips from '../tips/SimpleTips';
import { CHART_PANEL_IDS, IFRAME_VARS, timeRangeOptions } from '../constants';

import { getGrafanaFrameUrl } from '../utils/grafanaFrameSrcgenerator';

export default class RingStatsPerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: timeRangeOptions,
      selectedPerformanceChartOption: timeRangeOptions[1]
    };
  }

  onChartOptionChange(selectedOption) {
    this.setState({
      selectedPerformanceChartOption: selectedOption
    });
  }

  render() {
    const { selectedPerformanceChartOption, chartOptions } = this.state;
    const { ring } = this.props;

    const ringOperationsUrl = getGrafanaFrameUrl(
      ring.id,
      CHART_PANEL_IDS.OPERATIONS,
      selectedPerformanceChartOption.value,
      IFRAME_VARS.AUTO_REFRESH
    );

    const ringLatencyUrl = getGrafanaFrameUrl(
      ring.id,
      CHART_PANEL_IDS.LATENCY,
      selectedPerformanceChartOption.value,
      IFRAME_VARS.AUTO_REFRESH
    );

    const ringBandwidthUrl = getGrafanaFrameUrl(
      ring.id,
      CHART_PANEL_IDS.BANDWIDTH,
      selectedPerformanceChartOption.value,
      IFRAME_VARS.AUTO_REFRESH
    );

    return (
      <div>
        <div>
          <SelectBox
            options={chartOptions}
            name="capacity_chart_options"
            value={selectedPerformanceChartOption}
            onChange={this.onChartOptionChange.bind(this)}
          />
        </div>
        <GrafanaPanel
          iFrameSrc={ringOperationsUrl}
          panelTitle="Operations per Sec"
          popOverTemplate={
            <SimpleTips
              title="RING Operations per Sec"
              content={`Number per second of all GET, PUT, and DELETE operations at the RING level
          for the time period selected.`}
            />
          }
        />
        <GrafanaPanel
          iFrameSrc={ringLatencyUrl}
          panelTitle="Latency"
          popOverTemplate={
            <SimpleTips
              title="RING Latency"
              content={`Latency of all GET, PUT, and DELETE operations at the RING level
          for the time period selected.`}
            />
          }
        />
        <GrafanaPanel
          iFrameSrc={ringBandwidthUrl}
          panelTitle="Bandwidth"
          popOverTemplate={
            <SimpleTips
              title="RING Bandwidth"
              content={`Bandwidth of all GET and PUT operations at the RING level for the time period selected.`}
            />
          }
        />
      </div>
    );
  }
}
