import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Circle } from 'styled-icons/fa-solid/Circle';
import {
  STATUS_OK,
  STATUS_WARNING,
  STATUS_CRITICAL,
  STATUS_NO_CONNECTOR,
  STATUS_EMPTY
} from '../constants';
import { getStatusColor } from '../utils/status';

const CustomCircle = styled(Circle)`
  color: ${props => props.color || '#21ac4d'};
`;

/**
 * FIXME Patrick Ear: We might need to check what status
 * can be together. We maybe should not mix the logic between :
 * - Ring Status
 * - Disk Status
 * - Volume Status
 * - Config Group Status
 * We may need to create a new component instead and give it a more
 * accurate name.
 * Also, we might need to put a new color in case the input is wrong
 * So that it's easy to figure out that something is wrong
 * (ex: The back-end add a new status but the front-end is not aware
 * of it)
 */
class CircleStatus extends React.Component {
  render() {
    const { status, size } = this.props;

    const color = getStatusColor(status);

    return <CustomCircle size={size} color={color} />;
  }
}

CircleStatus.propTypes = {
  status: PropTypes.oneOf([
    STATUS_OK,
    STATUS_WARNING,
    STATUS_CRITICAL,
    STATUS_NO_CONNECTOR,
    STATUS_EMPTY
  ]),
  size: PropTypes.number
};

CircleStatus.defaultProps = {
  status: STATUS_OK,
  size: 17
};

export { CircleStatus };
