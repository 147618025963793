import styled from 'styled-components';
import * as cssVariables from './variables';

const Button = styled.button`
  text-transform: uppercase;
  height: 45px;
  box-shadow: 1px 2px 6px -2px #000;

  &[disabled] {
    opacity: 0.2;
  }
`;

const ButtonOk = styled(Button)`
  color: #fff !important;
  background-color: ${cssVariables.mainBlue};
`;

const ButtonKo = styled(Button)`
  color: #fff !important;
  background-color: ${cssVariables.red};
`;

const ButtonM = styled(Button)`
  color: #fff !important;
  background-color: ${cssVariables.panelSlctBg};
`;

const ButtonBig = styled(Button)`
  padding: 5px 15px;
  i,
  .font--signe {
    font-weight: lighter;
    font-size: 1.6em;
  }
`;

const ButtonViewZone = styled(Button)`
  margin-left: 20px;
  background-color: ${cssVariables.hdrBg};

  &:hover {
    color: #fff;
    background-color: ${cssVariables.brdrClrLogin};
  }
`;

export { Button, ButtonOk, ButtonKo, ButtonM, ButtonBig, ButtonViewZone };
