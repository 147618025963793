import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import config from '../config';

import {
  deactivateGlobalOverlay,
  resetGlobalOverlayText
} from '../actions/overview';
import {
  getConfigGroupsAction,
  setCGCreationNoActionAction,
  setCGEditNoActionAction,
  setCGDeletionNoActionAction,
  getVolumesDataAction,
  setVolumeCreationNoActionAction,
  setVolumeEditionNoActionAction,
  setVolumeDeletionNoActionAction
} from '../ducks/volumes';
import { getServersAction } from '../ducks/servers';
import { getZonesAction } from '../ducks/zones';
import { getDisksDataAction } from '../ducks/disks';
import { getRingsDataAction } from '../ducks/rings';
import { getS3StorageServicesAction } from '../ducks/s3';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

const DashboardContainer = styled.div`
  display: flex;
  padding-top: 56px;
  height: 100vh;
  overflow-x: hidden;
`;

export class Dashboard extends Component {
  componentDidMount() {
    this.pollData();
    this.poll = setInterval(() => this.pollData(), config.longPollingInterval);
  }

  componentWillUnmount() {
    clearInterval(this.poll);
    this.poll = null;
  }

  pollData() {
    this.props.getServersData();
    this.props.getRingsData();
    this.props.getDisksData();
    this.props.getStorageServices();
    this.props.getVolumesData();
    this.props.getZonesData();
    this.props.getConfigGroups();
  }

  render() {
    return (
      <DashboardContainer>
        <LeftPanel />
        <RightPanel />
      </DashboardContainer>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRingsData: () => dispatch(getRingsDataAction()),
    getServersData: () => dispatch(getServersAction()),
    getDisksData: () => dispatch(getDisksDataAction()),
    getZonesData: () => dispatch(getZonesAction()),
    getStorageServices: () => dispatch(getS3StorageServicesAction()),
    getVolumesData: () => dispatch(getVolumesDataAction()),
    getConfigGroups: () => dispatch(getConfigGroupsAction()),
    resetVolumeCreation: () => dispatch(setVolumeCreationNoActionAction()),
    resetCGCreation: () => dispatch(setCGCreationNoActionAction()),
    resetCGEdit: () => dispatch(setCGEditNoActionAction()),
    resetCGDeletion: () => dispatch(setCGDeletionNoActionAction()),
    resetVolumeEdition: () => dispatch(setVolumeEditionNoActionAction()),
    resetVolumeDeletion: () => dispatch(setVolumeDeletionNoActionAction()),
    deactivateGlobalOverlay: () => dispatch(deactivateGlobalOverlay()),
    resetGlobalOverlayText: () => dispatch(resetGlobalOverlayText())
  };
};

export default withRouter(connect(undefined, mapDispatchToProps)(Dashboard));
