import styled from 'styled-components';
import * as cssVariables from './variables';

const TableMessage = styled.span`
  display: block;
  font-size: 0.9em;
  color: #fff;
  border-radius: 1px;
`;

const StatusText = styled.span`
  span {
    font-weight: bold;
  }
  &.OK {
    span {
      color: ${cssVariables.grn};
    }
  }

  &.WARNING {
    span {
      color: ${cssVariables.orng};
    }
  }

  &.ERROR {
    span {
      color: ${cssVariables.red};
    }
  }
`;

export { TableMessage, StatusText };
