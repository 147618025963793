import React from 'react';
import Proptypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

import { Table, THead, Th, TBody, Tr, Td, NoDataRow } from './common/Table';
import { CircleStatus } from './CircleStatus';
import { STATUS_CRITICAL } from '../constants';

/**
 * SelectableConnectorsTable
 * This component is mainly used in configuration group components.
 * FIXME Patrick EAR : Might need to remove the select all capabilities
 * to keep the component simple. Because less is more.
 *
 * Please note that this component is highly specialise to
 * CreateConfigGroup and EditConfigGroup use case.
 * It should not be modify to be reused for other purpose.
 * If you need something similar, please copy paste the code
 * and change it the way you want.
 * We currently do not have enough distance to generalise the usage
 * of this kind of table.
 */
class SelectableConnectorsTable extends React.Component {
  onRowChange(connector, isCheck, isRowdisabled) {
    if (this.props.restrictDeadConnectors) {
      if (!isRowdisabled) {
        this.props.onCheckboxChange([connector], isCheck);
      }
    } else {
      this.props.onCheckboxChange([connector], isCheck);
    }
  }

  onAllCheckboxChange(connectors, e, isCheck) {
    if (this.props.restrictDeadConnectors) {
      if (isCheck) {
        let aliveConnectors = connectors.filter(
          c => c.status !== STATUS_CRITICAL
        );
        this.props.onCheckboxChange(aliveConnectors, isCheck);
      } else {
        this.props.onCheckboxChange(connectors, isCheck);
      }
    } else {
      this.props.onCheckboxChange(connectors, isCheck);
    }
  }

  render() {
    const {
      connectors,
      selectedConnectors,
      linkedConnectors,
      restrictDeadConnectors,
      canSelect,
      emptyTableText
    } = this.props;
    let clength = restrictDeadConnectors
      ? connectors.filter(c => c.status !== STATUS_CRITICAL).length
      : connectors.length;
    let sclength = selectedConnectors.length;

    return (
      <Table>
        <THead>
          <Tr>
            {canSelect ? (
              <Th padding="0">
                <Checkbox
                  onChange={this.onAllCheckboxChange.bind(this, connectors)}
                  // All the connectors are selected
                  checked={clength !== 0 && clength === sclength}
                />
              </Th>
            ) : null}
            <Th>Name</Th>
            <Th>IP Address</Th>
            <Th>Status</Th>
          </Tr>
        </THead>
        <TBody>
          {connectors.length === 0 ? (
            <NoDataRow colSpan={4} text={emptyTableText} />
          ) : (
            connectors.map(connector => {
              let isChecked = !!selectedConnectors.find(
                c => c.id === connector.id
              );
              let isLinked = !!linkedConnectors.find(
                c => c.id === connector.id
              );
              let isRowdisabled =
                restrictDeadConnectors &&
                !isChecked &&
                connector.status === STATUS_CRITICAL;
              let ipAdress = connector.address
                ? connector.address.substring(0, connector.address.indexOf(':'))
                : '';
              return (
                <Tr
                  key={connector.id}
                  style={{
                    backgroundColor: isChecked ? '#162d3f' : null,
                    fontWeight: isLinked ? 'bold' : null,
                    cursor: isRowdisabled ? 'default' : null
                  }}
                  onClick={this.onRowChange.bind(
                    this,
                    connector,
                    !isChecked,
                    isRowdisabled
                  )}
                  hoverable={canSelect}
                >
                  {canSelect ? (
                    <Td padding="0">
                      <Checkbox
                        checked={isChecked}
                        disabled={isRowdisabled}
                        data-cy={'checkbox-connector-' + connector.name}
                      />
                    </Td>
                  ) : null}
                  <Td>{connector.name || ''}</Td>
                  <Td>{ipAdress || ''}</Td>
                  <Td>
                    <CircleStatus status={connector.status} />
                  </Td>
                </Tr>
              );
            })
          )}
        </TBody>
      </Table>
    );
  }
}

SelectableConnectorsTable.propTypes = {
  /**
   * Displayed Connectors.
   */
  connectors: Proptypes.array,
  selectedConnectors: Proptypes.array,
  /**
   * Connectors already associated to the config group.
   */
  linkedConnectors: Proptypes.array,
  /**
   * Set if you want to avoid the selection of the connectors that
   * are in a critical state.
   */
  restrictDeadConnectors: Proptypes.bool,
  /**
   * Set it if you want to let the user to select the connector.
   * It will call onCheckboxChange and pass the selected connector.
   */
  canSelect: Proptypes.bool,
  /**
   * Set this props if you want to customise the text when the table is empty.
   */
  emptyTableText: Proptypes.string,
  onCheckboxChange: Proptypes.func
};

SelectableConnectorsTable.defaultProps = {
  connectors: [],
  selectedConnectors: [],
  linkedConnectors: [],
  restrictDeadConnectors: false,
  canSelect: false,
  emptyTableText: null,
  onCheckboxChange: () => {}
};

export default SelectableConnectorsTable;
