import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';

import config from '../config';
import SimpleTips from '../tips/SimpleTips';
import RingGlobalHealthTips from '../tips/RingGlobalHealthTips';
import GlobalHealthDonut from '../components/RingStatsGlobalHealth';
import { NodeStatusDataTable } from '../components/RingStatsNodeStatus';
import { NodeTaskDataTable } from '../components/RingStatsNodeTask';
import HelpPopover from '../components/common/HelpPopover';
import { SectionHeader, SectionTitle } from '../components/ui/AllPagesItems';
import { TableMessage } from '../components/ui/Table';

import {
  getRingGlobalHealthDataAction,
  getRingNodeStatusDataAction,
  getRingTasksDataAction,
  selectRingTasks,
  selectRingNodeStatus,
  selectRingGlobalHealth
} from '../ducks/rings';

const NodesSection = styled.div`
  display: flex;
`;

const NodeTaskDataTableWrapper = styled.div`
  margin-top: 10px;
`;

const GlobalHealthContainer = styled.div`
  width: 200px;
`;

const NodeStatusContainer = styled.div`
  flex-grow: 1;
  margin-left: 30px;
`;

class RingStatsNodes extends Component {
  componentDidMount() {
    this.pollData();
    this.poll = setInterval(() => this.pollData(), config.longPollingInterval);
  }

  componentWillUnmount() {
    clearInterval(this.poll);
    this.poll = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.path !== this.props.history.location.pathname) {
      clearInterval(this.poll);
      this.poll = null;
      this.pollData();
      this.poll = setInterval(
        () => this.pollData(),
        config.longPollingInterval
      );
    }
  }

  pollData() {
    const match = matchPath(this.props.history.location.pathname, {
      path: '/rings/:id/nodes',
      exact: true,
      strict: false
    });

    const ringId = match.params.id || '';
    this.props.getRingGlobalHealth(ringId);
    this.props.getRingNodeStatus(ringId);
    this.props.getRingTasks(ringId);
  }

  render() {
    const { globalHealthRing, nodeStatusRing, ringTasks } = this.props;

    const globalHealthData = { globalHealthRing };
    const nodeStatusData = { nodeStatusRing };

    let globalHealth;
    if (globalHealthRing !== undefined) {
      globalHealth = <GlobalHealthDonut {...globalHealthData} />;
    } else {
      globalHealth = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> Loading
          content
        </TableMessage>
      );
    }

    let nodeStatus;
    if (nodeStatusRing !== undefined) {
      nodeStatus = <NodeStatusDataTable {...nodeStatusData} />;
    } else {
      nodeStatus = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> Loading
          content
        </TableMessage>
      );
    }

    return (
      <div>
        <NodesSection>
          <GlobalHealthContainer>
            <SectionHeader>
              <SectionTitle>Global Health</SectionTitle>
              <HelpPopover content={<RingGlobalHealthTips />} />
            </SectionHeader>
            {globalHealth}
          </GlobalHealthContainer>
          <NodeStatusContainer>
            <SectionHeader>
              <SectionTitle>Node Status</SectionTitle>
              <HelpPopover
                content={
                  <SimpleTips
                    title="RING Node Status"
                    content={`Node Status presents the RUN/OUT_OF_SERVICE status for all RING storage node components, which can help in identifying faulty servers.`}
                  />
                }
              />
            </SectionHeader>
            {nodeStatus}
          </NodeStatusContainer>
        </NodesSection>
        <SectionHeader>
          <SectionTitle>Tasks List</SectionTitle>
          <HelpPopover
            content={
              <SimpleTips
                title="RING Tasks List"
                content={`List of running RING background tasks among Balance, Rebuild, Repair and Purge.`}
              />
            }
          />
        </SectionHeader>
        <NodeTaskDataTableWrapper>
          <NodeTaskDataTable data={ringTasks} />
        </NodeTaskDataTableWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRingGlobalHealth: value => dispatch(getRingGlobalHealthDataAction(value)),
    getRingNodeStatus: value => dispatch(getRingNodeStatusDataAction(value)),
    getRingTasks: value => dispatch(getRingTasksDataAction(value))
  };
};

const mapStateToProps = state => ({
  globalHealthRing: selectRingGlobalHealth(state),
  nodeStatusRing: selectRingNodeStatus(state),
  ringTasks: selectRingTasks(state)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RingStatsNodes)
);
