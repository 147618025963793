import React, { Component } from 'react';

import config from '../config';
import SelectBox from './common/Select';
import {
  NOBANDWIDTH,
  IFRAME_VARS,
  BANDWIDTH,
  timeRangeOptions
} from '../constants';
import GrafanaPanel from './GrafanaPanel';
import { getFrameUrlOfVolume } from '../utils/grafanaFrameSrcgenerator';
import SimpleTips from '../tips/SimpleTips';

class Performances extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartoptions: timeRangeOptions,
      selectedChartOption: timeRangeOptions[1]
    };
  }

  onChartOptionChange(selectedOption) {
    this.setState({
      selectedChartOption: selectedOption
    });
  }

  render() {
    const { chartoptions, selectedChartOption } = this.state;
    const { volumeConnectors } = this.props;

    const singleVolumeOpsUrl = getFrameUrlOfVolume(
      config.singleVolumeCharts.performance.operations,
      selectedChartOption.value,
      IFRAME_VARS.AUTO_REFRESH,
      volumeConnectors,
      NOBANDWIDTH
    );

    const singleVolumeLatencyUrl = getFrameUrlOfVolume(
      config.singleVolumeCharts.performance.latency,
      selectedChartOption.value,
      IFRAME_VARS.AUTO_REFRESH,
      volumeConnectors,
      NOBANDWIDTH
    );

    const singleVolumeBandwidth = getFrameUrlOfVolume(
      config.singleVolumeCharts.performance.bandwidth,
      selectedChartOption.value,
      IFRAME_VARS.AUTO_REFRESH,
      volumeConnectors,
      BANDWIDTH
    );

    return (
      <div className="performance-tab">
        <div>
          <SelectBox
            options={chartoptions}
            name="chart_options"
            value={selectedChartOption}
            onChange={this.onChartOptionChange.bind(this)}
          />
        </div>
        <GrafanaPanel
          iFrameSrc={singleVolumeOpsUrl}
          panelTitle="Operations per Sec"
          popOverTemplate={
            <SimpleTips
              title="Volume Operations per Sec"
              content={`Number per second of all IN and OUT operations at the volume level for the time period selected.`}
            />
          }
        />
        <GrafanaPanel
          iFrameSrc={singleVolumeLatencyUrl}
          panelTitle="Latency"
          popOverTemplate={
            <SimpleTips
              title="Volume Latency"
              content={`Latency of all IN and OUT operations at the volume level for the time period selected.`}
            />
          }
        />
        <GrafanaPanel
          iFrameSrc={singleVolumeBandwidth}
          panelTitle="Bandwidth"
          popOverTemplate={
            <SimpleTips
              title="Volume Bandwidth"
              content={`Bandwidth of all IN and OUT operations at the volume level for the time period selected.`}
            />
          }
        />
      </div>
    );
  }
}

export default Performances;
