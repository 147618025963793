import { call, put, takeEvery, all } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { callApi, callApiPost } from '../utils/callApi';
import { setInvalidBasicAuth, setApiRequestErrorMsg, setApiRequestError, apiConnectionNoError } from '../actions/overview';
import moment from 'moment';
import { ASC } from '../constants';
import * as actions from '../actions/overview';

// Actions
const GET_RINGS_DATA = 'GET_RINGS_DATA';
const GET_RINGS_DATA_SUCCESS = 'GET_RINGS_DATA_SUCCESS';
const SET_ACTIVE_RING = 'SET_ACTIVE_RING';

const GET_RING_GLOBAL_HEALTH_DATA = 'GET_RING_GLOBAL_HEALTH_DATA';
const GET_RING_GLOBAL_HEALTH_DATA_SUCCESS =
  'GET_RING_GLOBAL_HEALTH_DATA_SUCCESS';

const GET_RING_NODE_STATUS_DATA = 'GET_RING_NODE_STATUS_DATA';
const GET_RING_NODE_STATUS_DATA_SUCCESS =
  'GET_RING_NODE_STATUS_DATA_SUCCESS';

const GET_RING_TASKS_DATA = 'GET_RING_TASKS_DATA';
const GET_RING_TASKS_DATA_SUCCESS = 'GET_RING_TASKS_DATA_SUCCESS';

// Reducer
const defaultState = fromJS({
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case GET_RINGS_DATA_SUCCESS:
      return state.set('list', action.rings);
    case SET_ACTIVE_RING:
      return state.set('activeRing', action.ring);
    case GET_RING_GLOBAL_HEALTH_DATA_SUCCESS:
      return state.set('globalHealthRing', action.globalHealthRing);
    case GET_RING_NODE_STATUS_DATA_SUCCESS:
      return state.set('nodeStatusRing', action.nodeStatusRing);
    case GET_RING_TASKS_DATA_SUCCESS:
      return state.set('ringTasks', action.tasks);
    default:
      return state;
  }
}

// Action Creators
function getRingsDataAction() {
  return {
    type: GET_RINGS_DATA
  };
}

function getRingsDataSuccessAction(rings) {
  return {
    type: GET_RINGS_DATA_SUCCESS,
    rings
  };
}

function getRingTasksDataAction(ring) {
  return {
    type: GET_RING_TASKS_DATA,
    ring: ring
  };
}

function getRingTasksDataSuccessAction(tasks) {
  return {
    type: GET_RING_TASKS_DATA_SUCCESS,
    tasks
  };
}

function setActiveRingAction(ring) {
  return {
    type: SET_ACTIVE_RING,
    ring
  };
}

function getRingGlobalHealthDataAction(value) {
  return {
    type: GET_RING_GLOBAL_HEALTH_DATA,
    value
  };
}

function getRingGlobalHealthDataSuccessAction(globalHealthRing) {
  return {
    type: GET_RING_GLOBAL_HEALTH_DATA_SUCCESS,
    globalHealthRing
  };
}

function getRingNodeStatusDataAction(value) {
  return {
    type: GET_RING_NODE_STATUS_DATA,
    value
  };
}

function getRingNodeStatusDataSuccessAction(nodeStatusRing) {
  return {
    type: GET_RING_NODE_STATUS_DATA_SUCCESS,
    nodeStatusRing
  };
}

// Sagas
function* getRingsData() {
  let requestURL = 'rings/?limit=20';

  let responseItems = [];

  try {
    let response = yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    responseItems = response._items;

    do {
      if (response._links.hasOwnProperty('next')) {
        requestURL = `rings/?${response._links.next.split('?')[1]}`;

        response = yield call(callApi, requestURL, {
          method: 'GET',
          mode: 'cors'
        });

        responseItems = responseItems.concat(response._items);
      }
    } while (response._links.hasOwnProperty('next'));

    yield put(getRingsDataSuccessAction(responseItems));
    yield put(apiConnectionNoError());
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
    if (error && (error.status >= 500 && error.status < 600)) {
      yield put(setApiRequestErrorMsg({ message: 'Unable to fetch Rings information.' }));
      yield put(setApiRequestError());
    }
  }
}

/**
 * Global health
 * GET Global health state
 * -> Request: GET
 */
function* getRingGlobalHealthData(value) {
  const now = moment().valueOf();
  const nowMinus = now - 150 * 1000;

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const ringQuery = 'ring:' + value.value;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              '@timestamp': {
                gte: nowMinus,
                lte: now,
                format: 'epoch_millis'
              }
            }
          },
          {
            query_string: {
              analyze_wildcard: true,
              query: ringQuery
            }
          }
        ]
      }
    },
    aggs: {
      '3': {
        terms: {
          field: 'status',
          size: 500,
          order: {
            _term: ASC
          },
          min_doc_count: 1
        },
        aggs: {
          '2': {
            date_histogram: {
              interval: '150s',
              field: '@timestamp',
              min_doc_count: 1,
              extended_bounds: {
                min: nowMinus,
                max: now
              },
              format: 'epoch_millis'
            },
            aggs: {}
          }
        }
      }
    }
  };
  const requestURL = 'es_proxy/status-ov-summary/_search';

  try {
    const response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    yield put(
      getRingGlobalHealthDataSuccessAction(response.aggregations[3].buckets)
    );
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

/**
 * Ring Node status
 * GET Ring Node status state
 * -> Request: POST
 */
function* getRingNodeStatusData(value) {
  const now = moment().valueOf();
  const nowMinus = now - 150 * 1000;

  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const ringQuery = 'ring:' + value.value;

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              '@timestamp': {
                gte: nowMinus,
                lte: now,
                format: 'epoch_millis'
              }
            }
          },
          {
            query_string: {
              analyze_wildcard: true,
              query: ringQuery
            }
          }
        ]
      }
    },
    aggs: {
      '4': {
        terms: {
          field: 'address',
          size: 500,
          order: {
            _term: ASC
          },
          min_doc_count: 1
        },
        aggs: {
          '3': {
            terms: {
              field: 'status',
              size: 500,
              order: {
                _term: ASC
              },
              min_doc_count: 1
            },
            aggs: {}
          }
        }
      }
    }
  };
  const requestURL = 'es_proxy/status-ov-summary/_search';

  try {
    const response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    yield put(
      getRingNodeStatusDataSuccessAction(response.aggregations[4].buckets)
    );
  } catch (error) {
    if (error && error.status === 401) {
      yield put(actions.setInvalidBasicAuth());
    }
  }
}

function* getRingTasksData(ring) {
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const query = {
    _source: {
      include: [
        'node',
        'tasks.type',
        'tasks.status',
        'tasks.taskid',
        'tasks.nbchunkdone',
        'tasks.nbtotalchunk',
        'tasks.starting_time',
        'tasks.size_done',
        'tasks.est_size_total',
        // Optional one
        'tasks.target',
        'tasks.is_diskrebuild'
      ]
    },
    from: 0,
    query: {
      bool: {
        filter: [
          {
            exists: {
              field: 'tasks'
            }
          }
        ],
        must: [
          {
            range: {
              '@timestamp': {
                gt: 'now-150s',
                lt: 'now'
              }
            }
          },
          {
            match: {
              ring: ring.ring
            }
          }
        ]
      }
    },
    size: 5000
  };

  const requestURL = 'es_proxy/status-ov-summary/_search';

  try {
    const response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    const responseData = response.hits.hits.map(items => items._source);
    yield put(getRingTasksDataSuccessAction(responseData));
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* ringsSaga() {
  yield all([
    takeEvery(GET_RINGS_DATA, getRingsData),
    takeEvery(GET_RING_GLOBAL_HEALTH_DATA, getRingGlobalHealthData),
    takeEvery(GET_RING_NODE_STATUS_DATA, getRingNodeStatusData),
    takeEvery(GET_RING_TASKS_DATA, getRingTasksData),
  ]);
}

// Selectors
function selectRingsState(state) {
  return state.get('rings');
}

function selectRingsList(state) {
  return selectRingsState(state).get('list');
}

function selectRingTasks(state) {
  return selectRingsState(state).get('ringTasks');
}

function selectRingNodeStatus(state) {
  return selectRingsState(state).get('nodeStatusRing');
}

function selectRingGlobalHealth(state) {
  return selectRingsState(state).get('globalHealthRing');
}

function getRingFromUrlId(state, props) {
  const rings = selectRingsList(state) || [];

  if (props && props.match && props.match.params && props.match.params.id) {
    return rings.find(ring => ring.id === props.match.params.id) || {};
  } else {
    return {};
  }
}

export {
  getRingsDataAction,
  getRingsDataSuccessAction,
  getRingTasksDataAction,
  getRingTasksDataSuccessAction,
  setActiveRingAction,
  getRingGlobalHealthDataAction,
  getRingGlobalHealthDataSuccessAction,
  getRingNodeStatusDataAction,
  getRingNodeStatusDataSuccessAction,
  ringsSaga,
  selectRingsList,
  selectRingTasks,
  selectRingNodeStatus,
  selectRingGlobalHealth,
  getRingFromUrlId
};
