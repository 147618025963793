import styled from 'styled-components';

const white = '#fff';
const infoIcnSlctClr = '#76828f';

const HelpPopupContainer = styled.div`
  background-color: ${white};
  width: 400px;
  padding: 20px 30px 20px 30px;
  border-radius: 5px;
  border: 1px solid #181212;
  box-shadow: 2px 2px 15px #000;
  color: ${infoIcnSlctClr};
  margin: 0 auto;
  position: relative;
`;

const HelpPopupTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const HelpPopupContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

const Ul = styled.ul`
  padding-left: 18px;
  margin: 0;
`;

const Ol = styled.ol`
  padding-left: 18px;
  margin: 0;
`;

const Li = styled.li``;

export { HelpPopupContainer, HelpPopupTitle, HelpPopupContent, Ul, Ol, Li };
