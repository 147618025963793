import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import isPlainObject from 'lodash/isPlainObject';

import {
  deactivateGlobalOverlay,
  resetGlobalOverlayText,
  setApiRequestNoAction
} from '../actions/overview';
import {
  setVolumeCreationNoActionAction,
  setVolumeEditionNoActionAction,
  setVolumeDeletionNoActionAction,
  getVolumeCreationState,
  getVolumeCreationError,
  getVolumeEditionState,
  getVolumeEditionError,
  getVolumeDeletionState,
  getVolumeDeletionError,
  setCGCreationNoActionAction,
  setCGEditNoActionAction,
  setCGDeletionNoActionAction,
  getConfigGroupCreationState,
  getConfigGroupEditState,
  getConfigGroupDeletionState,
  getConfigGroupCreationError,
  getConfigGroupEditError,
  getConfigGroupDeletionError
} from '../ducks/volumes';
import { ACTION_ERROR } from '../constants';

class Notification extends React.Component {
  componentWillReceiveProps(nextProps) {
    const {
      volumeCreationState,
      volumeCreationError,
      cgCreationState,
      cgCreationError,
      cgEditState,
      cgEditError,
      cgDeletionState,
      cgDeletionError,
      volumeEditionState,
      volumeEditionError,
      volumeDeletionState,
      volumeDeletionError,
      apiRequestState,
      apiRequestError
    } = nextProps;

    // Dashboard will only handle notification error
    if (volumeCreationState === ACTION_ERROR) {
      this.props.deactivateGlobalOverlay();
      this.props.resetGlobalOverlayText();
      this.props.resetVolumeCreation();

      let defaultErrorMsg = 'Error during the creation of a Volume';
      if (volumeCreationError.errors) {
        this.notifyErrorDom(
          this.genErrorMsg(volumeCreationError.errors),
          defaultErrorMsg
        );
      } else {
        this.notifyError(volumeCreationError, defaultErrorMsg);
      }
    }

    if (cgCreationState === ACTION_ERROR) {
      this.props.deactivateGlobalOverlay();
      this.props.resetGlobalOverlayText();
      this.props.resetCGCreation();

      let defaultErrorMsg =
        'Error during the creation of a Configuration Group';
      if (cgCreationError.errors) {
        this.notifyErrorDom(
          this.genErrorMsg(volumeCreationError.errors),
          defaultErrorMsg
        );
      } else {
        this.notifyError(cgCreationError, defaultErrorMsg);
      }
    }

    if (cgEditState === ACTION_ERROR) {
      this.props.deactivateGlobalOverlay();
      this.props.resetGlobalOverlayText();
      this.props.resetCGEdit();

      let defaultErrorMsg = 'Error during the edition of a Configuration Group';
      if (cgEditError.errors) {
        this.notifyErrorDom(
          this.genErrorMsg(cgEditError.errors),
          defaultErrorMsg
        );
      } else {
        this.notifyError(cgEditError, defaultErrorMsg);
      }
    }

    if (cgDeletionState === ACTION_ERROR) {
      this.props.deactivateGlobalOverlay();
      this.props.resetGlobalOverlayText();
      this.props.resetCGDeletion();

      let defaultErrorMsg =
        'Error during the deletion of a Configuration Group';
      this.notifyError(cgDeletionError, defaultErrorMsg);
    }

    if (volumeEditionState === ACTION_ERROR) {
      this.props.deactivateGlobalOverlay();
      this.props.resetGlobalOverlayText();
      this.props.resetVolumeEdition();

      let defaultErrorMsg = 'Error during the edition of a Volume';
      if (volumeEditionError.errors) {
        this.notifyErrorDom(
          this.genErrorMsg(volumeEditionError.errors),
          defaultErrorMsg
        );
      } else {
        this.notifyError(volumeEditionError, defaultErrorMsg);
      }
    }

    if (volumeDeletionState === ACTION_ERROR) {
      this.props.deactivateGlobalOverlay();
      this.props.resetGlobalOverlayText();

      let defaultErrorMsg = 'Error during the deletion of a Volume';
      if (this.props.location.pathname.endsWith('/edit')) {
        this.props.history.push(`/storages/file/`);
      } else {
        this.props.resetVolumeDeletion();
        this.notifyError(volumeDeletionError, defaultErrorMsg);
      }
    }

    if (apiRequestState === ACTION_ERROR) {
      this.props.deactivateGlobalOverlay();
      this.props.resetGlobalOverlayText();
      this.props.resetApiRequest();

      const defaultErrorMsg = 'Error API server';

      this.notifyError(apiRequestError, defaultErrorMsg);
    }
  }

  genErrorMsg(errors) {
    if (errors && isPlainObject(errors)) {
      let errorKeys = Object.keys(errors);
      let errorMsg = errorKeys.map(k => `${k}: ${errors[k]}`);

      if (errorMsg.length) {
        return (
          <div>
            {errorMsg.map((msg, idx) => (
              <div key={idx} style={{ textAlign: 'center' }}>
                {msg}
              </div>
            ))}
          </div>
        );
      }
    }
    return null;
  }

  notifyErrorDom(dom, defaultErrorMessage) {
    toast.error(dom || defaultErrorMessage, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true
    });
  }

  notifyError(error, defaultErrorMessage) {
    const message = error && error.message ? `Error: ${error.message}` : null;

    toast.error(message || defaultErrorMessage, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true
    });
  }

  render() {
    return <ToastContainer />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetVolumeCreation: () => dispatch(setVolumeCreationNoActionAction()),
    resetCGCreation: () => dispatch(setCGCreationNoActionAction()),
    resetCGEdit: () => dispatch(setCGEditNoActionAction()),
    resetCGDeletion: () => dispatch(setCGDeletionNoActionAction()),
    resetVolumeEdition: () => dispatch(setVolumeEditionNoActionAction()),
    resetVolumeDeletion: () => dispatch(setVolumeDeletionNoActionAction()),
    resetApiRequest: () => dispatch(setApiRequestNoAction()),
    deactivateGlobalOverlay: () => dispatch(deactivateGlobalOverlay()),
    resetGlobalOverlayText: () => dispatch(resetGlobalOverlayText())
  };
};

const mapStateToProps = state => ({
  volumeCreationState: getVolumeCreationState(state),
  volumeCreationError: getVolumeCreationError(state),
  cgCreationState: getConfigGroupCreationState(state),
  cgCreationError: getConfigGroupCreationError(state),
  cgEditState: getConfigGroupEditState(state),
  cgEditError: getConfigGroupEditError(state),
  cgDeletionState: getConfigGroupDeletionState(state),
  cgDeletionError: getConfigGroupDeletionError(state),
  volumeEditionState: getVolumeEditionState(state),
  volumeEditionError: getVolumeEditionError(state),
  volumeDeletionState: getVolumeDeletionState(state),
  volumeDeletionError: getVolumeDeletionError(state),
  apiRequestState: state.get('overview').get('apiRequestState'),
  apiRequestError: state.get('overview').get('apiRequestError')
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
