import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import Tooltip from '../components/Tooltip';
import { withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';

import {
  STATUS_OK,
  STATUS_WARNING,
  STATUS_CRITICAL,
  STATUS_ALL
} from '../constants';
import HelpPopover from '../components/common/HelpPopover';

import HardwareStatusTips from '../tips/HardwareStatusTips';

import * as cssVariables from '../components/ui/variables';
import * as StyledPanel from '../components/ui/Panel';
import * as StyledTooltip from '../components/ui/Tooltip';

import imgListIcon from '../assets/images/global-icons/list.png';
import imgOKIcon from '../assets/images/global-icons/success-icon.svg';
import imgOKDisableIcon from '../assets/images/global-icons/success-disable-icon.svg';
import imgWarnIcon from '../assets/images/global-icons/warning-icon.svg';
import imgWarnDisableIcon from '../assets/images/global-icons/warning-disable-icon.svg';
import imgErrorIcon from '../assets/images/global-icons/error-icon.svg';
import imgErrorDisableIcon from '../assets/images/global-icons/error-disable-icon.svg';

import imgNodeIcon from '../assets/images/dashboard-icons/node.svg';
import imgNodeHoverIcon from '../assets/images/dashboard-icons/node-hover.svg';
import imgNodeLightIcon from '../assets/images/dashboard-icons/node-light.svg';
import imgNodeWarnIcon from '../assets/images/dashboard-icons/node-warn.svg';
import imgNodeWarnHoverIcon from '../assets/images/dashboard-icons/node-warn-hover.svg';
import imgNodeWarnLightIcon from '../assets/images/dashboard-icons/node-warn-light.svg';
import imgNodeErrorIcon from '../assets/images/dashboard-icons/node-err.svg';
import imgNodeErrorHoverIcon from '../assets/images/dashboard-icons/node-error-hover.svg';
import imgNodeErrorLightIcon from '../assets/images/dashboard-icons/node-err-light.svg';

import { selectAllDisksState } from '../ducks/disks';
import { selectAllServersState } from '../ducks/servers';

const ServersListsDiv = styled.div`
  display: flex;
  padding: 10px 0;
  flex-wrap: wrap;
`;

const StoragePanelDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StoragePanelTitle = styled.p`
  width: 47px;
  color: ${cssVariables.serviceBlkTitlClr};
  font-size: 13px;
  font-weight: ${cssVariables.semiBold};
  margin: 5px 10px 0 0;
  text-align: right;
`;

const StyledStatusBar = styled.div`
  display: flex;
  justify-content: center;
`;

const StatusBarContent = styled.div`
  display: inline-flex;
  background: #211d1d;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin: 3px 0;
`;

const StatusBarItem = styled.div`
  display: inline-flex;
  margin-right: 12px;
  width: 55px;
  height: 15px;
  :last-child {
    margin-right: 0px;
  }
`;

const StatusBarIcon = styled.span`
  width: 15px;
  height: 15px;
  ${props =>
    props.disable &&
    css`
      opacity: 0.4;
    `}
  background: url(${props => {
    if (props.status === STATUS_OK) {
      if (props.disable) {
        return imgOKDisableIcon;
      }
      return imgOKIcon;
    } else if (props.status === STATUS_WARNING) {
      if (props.disable) {
        return imgWarnDisableIcon;
      }
      return imgWarnIcon;
    } else if (props.status === STATUS_CRITICAL) {
      if (props.disable) {
        return imgErrorDisableIcon;
      }
      return imgErrorIcon;
    }
  }})
    no-repeat;
`;

const StatusBarCount = styled.span`
  color: ${cssVariables.serviceBlkTitlClr};
  font-size: 13px;
  margin-left: 5px;
  line-height: normal;
  ${props =>
    props.disable &&
    css`
      opacity: 0.4;
    `};
`;

const ListIcon = styled.span`
  float: right;
  margin: 7px 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: url(${imgListIcon}) no-repeat;
`;

const ServerZone = styled.div`
  border-right: 1px solid ${cssVariables.brdrClr};
  display: inline-block;
  margin: 0 1em;
  flex-grow: 1;
  width: 25%;
  padding: 10px;
  margin: 0 0 7px 0;
  &:last-child {
    border-right: none;
  }
`;

const ServerZoneName = styled.p`
  color: ${cssVariables.serviceBlkTitlClr};
  text-align: center;
  font-size: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: 'nowrap';
`;

const ServerZoneDisk = styled.span`
  width: 59px;
  height: 16px;
  cursor: pointer;
  margin: 5px 10px;
  ${props => {
    if (props.active) {
      if (props.selected) {
        return css`
          background: url(${props => {
              switch (props.status) {
                case STATUS_WARNING:
                  return imgNodeWarnHoverIcon;
                case STATUS_CRITICAL:
                  return imgNodeErrorHoverIcon;
                default:
                  return imgNodeHoverIcon;
              }
            }})
            no-repeat;
        `;
      }
      return css`
        background: url(${props => {
            switch (props.status) {
              case STATUS_WARNING:
                return imgNodeWarnLightIcon;
              case STATUS_CRITICAL:
                return imgNodeErrorLightIcon;
              default:
                return imgNodeLightIcon;
            }
          }})
          no-repeat;
      `;
    } else {
      return css`
        background: url(${props => {
            switch (props.status) {
              case STATUS_WARNING:
                return imgNodeWarnIcon;
              case STATUS_CRITICAL:
                return imgNodeErrorIcon;
              default:
                return imgNodeIcon;
            }
          }})
          no-repeat;
      `;
    }
  }} &:hover {
    background: url(${props => {
        switch (props.status) {
          case STATUS_WARNING:
            return imgNodeWarnHoverIcon;
          case STATUS_CRITICAL:
            return imgNodeErrorHoverIcon;
          default:
            return imgNodeHoverIcon;
        }
      }})
      no-repeat;
  }
`;

const ServerZoneDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

class HardwareStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedServer: null
    };

    this.goToSingleNode = this.goToSingleNode.bind(this);
    this.onNodesStatusClick = this.onNodesStatusClick.bind(this);
    this.onDisksStatusClick = this.onDisksStatusClick.bind(this);
  }

  isPanelActive() {
    const exactPath = this.props.location.pathname;
    return exactPath.startsWith('/servers') || exactPath.startsWith('/disks');
  }

  goToSingleNode(node) {
    this.setState({ selectedServer: node.id });

    const match = matchPath(this.props.history.location.pathname, {
      path: '/servers/:id'
    });

    let nextPath =
      match && match.params && match.params.id
        ? this.props.history.location.pathname.replace(match.params.id, node.id)
        : `/servers/${node.id}`;

    const matchServerDisk = matchPath(this.props.history.location.pathname, {
      path: '/servers/:serverId/disks/:diskId/performance'
    });
    // The user is on a server's disk page
    if (matchServerDisk && matchServerDisk.isExact) {
      nextPath = `/servers/${node.id}/disks`;
    }

    this.props.history.push(nextPath);
  }

  onNodesStatusClick(status) {
    this.setState({ selectedServer: null });
    this.props.history.push(`/servers?status=${status}`);
  }

  onDisksStatusClick(status) {
    this.setState({ selectedServer: null });
    this.props.history.push(`/disks?status=${status}`);
  }

  getStatusCount(items) {
    const countByStatusResult = _.chain(items)
      .countBy('status')
      .map((count, name) => ({ name, count }))
      .value();

    const okStatus = countByStatusResult.find(
      status => status.name === STATUS_OK
    );
    const ok = okStatus ? okStatus.count : 0;

    const warningStatus = countByStatusResult.find(
      status => status.name === STATUS_WARNING
    );
    const warning = warningStatus ? warningStatus.count : 0;

    const criticalStatus = countByStatusResult.find(
      status => status.name === STATUS_CRITICAL
    );
    const critical = criticalStatus ? criticalStatus.count : 0;

    return { ok, warning, critical };
  }

  groupServersByZone(servers) {
    const { selectedServer } = this.state;

    return _.chain(servers)
      .sort(
        (s1, s2) =>
          s1.zone.name
            .toLowerCase()
            .localeCompare(s2.zone.name.toLowerCase()) ||
          s1.name.toLowerCase().localeCompare(s2.name.toLowerCase())
      )
      .groupBy('zone.id')
      .sortBy(data => {
        return data[0].zone.name.toLocaleLowerCase();
      })
      .map((serversByZone, serverIdx) => {
        const zone = serversByZone.map((server, idx) => {
          return (
            <Tooltip
              key={'tooltip_' + idx}
              placement="top"
              overlay={
                <StyledTooltip.StyledTooltipDiv>
                  <h5>
                    <b>Name: </b>
                    {server.name}
                  </h5>
                  {server.data_ip_address ? (
                    <h5>
                      <b>Data IP: </b>
                      {server.data_ip_address}
                    </h5>
                  ) : null}
                  {server.management_ip_address ? (
                    <h5>
                      <b>Mngt IP: </b>
                      {server.management_ip_address}
                    </h5>
                  ) : null}
                </StyledTooltip.StyledTooltipDiv>
              }
            >
              <ServerZoneDisk
                status={server.status}
                selected={selectedServer && server.id === selectedServer}
                active={this.isPanelActive()}
                onClick={() => this.goToSingleNode(server)}
              />
            </Tooltip>
          );
        });

        return (
          <ServerZone key={serversByZone[0].zone.id}>
            <Tooltip
              key={'tooltip_zone_name' + serverIdx}
              placement="top"
              overlay={serversByZone[0].zone.name}
            >
              <ServerZoneName>{serversByZone[0].zone.name}</ServerZoneName>
            </Tooltip>
            <ServerZoneDiv>{zone}</ServerZoneDiv>
          </ServerZone>
        );
      })
      .value();
  }

  render() {
    let { servers, disks } = this.props;
    const serversByZone = this.groupServersByZone(servers);
    const serversStatusCount = this.getStatusCount(servers);
    const disksStatusCount = this.getStatusCount(disks);

    return (
      <StyledPanel.Panel selected={this.isPanelActive()}>
        <div>
          <StyledPanel.PanelTitle>Hardware</StyledPanel.PanelTitle>
          <HelpPopover content={<HardwareStatusTips />} />
        </div>
        <StyledPanel.PanelContent>
          <StoragePanelDiv>
            <StatusBar
              title={'Servers'}
              data={serversStatusCount}
              onStatusClick={this.onNodesStatusClick}
            />
            <StatusBar
              title={'Disks'}
              data={disksStatusCount}
              onStatusClick={this.onDisksStatusClick}
            />
            <ServersListsDiv>{serversByZone}</ServersListsDiv>
          </StoragePanelDiv>
        </StyledPanel.PanelContent>
      </StyledPanel.Panel>
    );
  }
}

const StatusBar = props => {
  const { title, data, onStatusClick } = props;

  return (
    <StyledStatusBar>
      <StoragePanelTitle>{title}</StoragePanelTitle>
      <div>
        <StatusBarContent>
          <StatusBarItem onClick={() => onStatusClick(STATUS_OK)}>
            <StatusBarIcon status={STATUS_OK} disable={data.ok === 0} />
            <StatusBarCount disable={data.ok === 0}>{data.ok}</StatusBarCount>
          </StatusBarItem>
          <StatusBarItem onClick={() => onStatusClick(STATUS_WARNING)}>
            <StatusBarIcon
              status={STATUS_WARNING}
              disable={data.warning === 0}
            />
            <StatusBarCount disable={data.warning === 0}>
              {data.warning}
            </StatusBarCount>
          </StatusBarItem>
          <StatusBarItem onClick={() => onStatusClick(STATUS_CRITICAL)}>
            <StatusBarIcon
              status={STATUS_CRITICAL}
              disable={data.critical === 0}
            />
            <StatusBarCount disable={data.critical === 0}>
              {data.critical}
            </StatusBarCount>
          </StatusBarItem>
        </StatusBarContent>
        <ListIcon onClick={() => onStatusClick(STATUS_ALL)} />
      </div>
    </StyledStatusBar>
  );
};

StatusBar.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  onStatusClick: PropTypes.func
};

HardwareStatus.propTypes = {
  servers: PropTypes.array,
  disks: PropTypes.array
};

HardwareStatus.defaultProps = {
  servers: [],
  disks: []
};

const mapStateToProps = state => ({
  servers: selectAllServersState(state),
  disks: selectAllDisksState(state)
});

export default withRouter(connect(mapStateToProps)(HardwareStatus));
