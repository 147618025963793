import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import {
  PanelContainer,
  VerticalItemsContainer
} from '../components/ui/AllPagesItems';
import Breadcrumb from '../components/Breadcrumb';

import { Tab } from '../components/Tab';
import PerformanceTab from './AllDisksPerformance';
import AllDisksTab from './AllDisksTab';
class AllDisks extends Component {
  onTabClick(tab) {
    this.props.history.push(`${this.props.match.url}/${tab}`);
  }

  render() {
    const { location, match } = this.props;
    const exactPath = location.pathname;

    const tabs = [
      {
        selected: exactPath.endsWith('all'),
        title: 'All Disks',
        onClick: () => this.onTabClick('all')
      },
      {
        selected: exactPath.endsWith('performance'),
        title: 'Performance',
        onClick: () => this.onTabClick('performance')
      }
    ];

    return (
      <PanelContainer>
        <VerticalItemsContainer>
          <Breadcrumb routes={[{ name: 'DISKS' }]} />
          <Tab items={tabs}>
            <Switch>
              <Route exact path={`${match.url}/all`} component={AllDisksTab} />
              <Route
                exact
                path={`${match.url}/performance`}
                component={PerformanceTab}
              />
              <Redirect to={`${match.url}/all${location.search}`} />
            </Switch>
          </Tab>
        </VerticalItemsContainer>
      </PanelContainer>
    );
  }
}

export default withRouter(AllDisks);
