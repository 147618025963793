import React, { Component } from 'react';
import { connect } from 'react-redux';
import SingleStorageUsageTopTenBucketTips from '../tips/SingleStorageUsageTopTenBucketTips';
import SelectBox from '../components/common/Select';
import HelpPopover from '../components/common/HelpPopover';
import TopTenBucketOPSChartTable from '../components/SingleStorageTopTenOPS';
import TopTenBucketInChartTable from '../components/SingleStorageTopTenIN';
import TopTenBucketOutChartTable from '../components/SingleStorageTopTenOUT';
import { SectionTitle } from '../components/ui/AllPagesItems';
import {
  getTopTenBucketOPSDataAction,
  getTopTenBucketINDataAction,
  getTopTenBucketOUTDataAction,
  selectTopTenBucketOPS,
  selectTopTenBucketIN,
  selectTopTenBucketOUT
} from '../ducks/s3';
import config from '../config';

class SingleS3UsageTab extends Component {
  constructor(props) {
    super(props);

    const options = [
      { value: '1h', label: '1 hour' },
      { value: '6h', label: '6 hours' },
      { value: '24h', label: '24 hours' },
      { value: '1w', label: '1 week' }
    ];

    this.state = {
      topTenBucketsFilterOptions: options,
      selectedTopTenBucketsFilter: options[1]
    };
  }

  componentDidMount() {
    this.pollData(this.state.selectedTopTenBucketsFilter);
    this.poll = setInterval(
      () => this.pollData(this.state.selectedTopTenBucketsFilter),
      config.longPollingInterval
    );
  }

  componentWillUnmount() {
    clearInterval(this.poll);
    this.poll = null;
  }

  pollData(selectedOption) {
    this.props.getTopTenBucketOPS(selectedOption);
    this.props.getTopTenBucketIN(selectedOption);
    this.props.getTopTenBucketOUT(selectedOption);
  }

  onTopTenBucketsFilterChange(selectedOption) {
    this.setState({
      selectedTopTenBucketsFilter: selectedOption
    });
    this.pollData(selectedOption);
  }

  render() {
    const {
      topTenBucketsFilterOptions,
      selectedTopTenBucketsFilter
    } = this.state;

    const {
      activeTopTenBucketOPS,
      activeTopTenBucketIN,
      activeTopTenBucketOUT,
      storage
    } = this.props;

    const topTenBucketOPSProps = {
      activeTopTenBucketOPS,
      storage
    };
    const topTenBucketINProps = {
      activeTopTenBucketIN,
      storage
    };
    const topTenBucketOUTProps = {
      activeTopTenBucketOUT,
      storage
    };

    return (
      <>
        <div>
          <SectionTitle>
            Top-10 Buckets
            <HelpPopover content={<SingleStorageUsageTopTenBucketTips />} />
          </SectionTitle>
          <SelectBox
            options={topTenBucketsFilterOptions}
            name="top_ten_buckets_options"
            value={selectedTopTenBucketsFilter}
            onChange={this.onTopTenBucketsFilterChange.bind(this)}
          />
        </div>
        <div className="row">
          <div className="col-sm-4">
            <TopTenBucketOPSChartTable {...topTenBucketOPSProps} />
          </div>
          <div className="col-sm-4">
            <TopTenBucketInChartTable {...topTenBucketINProps} />
          </div>
          <div className="col-sm-4">
            <TopTenBucketOutChartTable {...topTenBucketOUTProps} />
          </div>
        </div>
      </>
    );
  }
}

SingleS3UsageTab.defaultProps = {
  activeTopTenBucketOPS: {},
  activeTopTenBucketIN: {},
  activeTopTenBucketOUT: {}
};

const mapStateToProps = state => ({
  activeTopTenBucketOPS: selectTopTenBucketOPS(state),
  activeTopTenBucketIN: selectTopTenBucketIN(state),
  activeTopTenBucketOUT: selectTopTenBucketOUT(state)
});

const mapDispatchToProps = dispatch => {
  return {
    getTopTenBucketOPS: value => {
      dispatch(getTopTenBucketOPSDataAction(value));
    },
    getTopTenBucketIN: value => {
      dispatch(getTopTenBucketINDataAction(value));
    },
    getTopTenBucketOUT: value => {
      dispatch(getTopTenBucketOUTDataAction(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleS3UsageTab);
