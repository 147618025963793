import React from 'react';
import { FormContent, FormError } from '../ui/Form';

export const FormFieldRender = ({
  input,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <FormContent className="left form--content">
      <input {...input} type={type} className="form-control" />
      {touched && error && <FormError className="form--error">{error}</FormError>}
    </FormContent>
  </div>
);

export const FormFieldTextAreaRender = ({
  input,
  label,
  type,
  row,
  meta: { touched, error }
}) => (
  <div>
    <label>{label}</label>
    <FormContent className="left form--content">
      <textarea {...input} rows={row} type={type} className="form-control" />
      {touched && error && <FormError className="form--error">{error}</FormError>}
    </FormContent>
  </div>
);
