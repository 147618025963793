/**
 * VolumeStorage component, included in the StorageServices panel
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getProtocolsFromVolume, computeCSSClasses } from '../utils/helpers';

import Tooltip from './Tooltip';

import * as StyledStorage from './ui/StorageContainer';
import * as StyledTooltip from './ui/Tooltip';

import imgDatabaseIcon from '../assets/images/dashboard-icons/database-icon.png';
import imgDatabaseIconWarning from '../assets/images/dashboard-icons/database-icon-warning.png';
import imgDatabaseIconCritical from '../assets/images/dashboard-icons/database-icon-critical.png';
import imgDatabaseIconInactive from '../assets/images/dashboard-icons/database-icon-inactive.png';

const VolumeStorage = props => {
  const { volume, openDetailPanel } = props;
  const { statusClass } = computeCSSClasses(volume.status);
  const protocolName = {
    cifs: 'SMB',
    nfs: 'NFS',
    localfs: 'LocalFS',
    cdmi: 'CDMI'
  };

  const protocols = getProtocolsFromVolume(volume || {}).map(
    protocol => protocolName[protocol]
  );
  const protocolStr = protocols.join(', ');

  const VolumeStorageContainer = styled(StyledStorage.StorageContainer)`
    &:before {
      background: url(${props => {
          switch (props.status) {
            case 'warning':
              return imgDatabaseIconWarning;
            case 'empty':
              return imgDatabaseIconInactive;
            case 'critical':
              return imgDatabaseIconCritical;
            default:
              return imgDatabaseIcon;
          }
        }})
        no-repeat;
    }
  `;

  return (
    <Tooltip
      overlay={
        <StyledTooltip.StyledTooltipDiv>
          <h5>
            <b>Name: </b>
            {volume.name}
          </h5>
          <h5>
            <b>Protocols: </b>
            {protocolStr || 'No connector'}
          </h5>
        </StyledTooltip.StyledTooltipDiv>
      }
    >
      <VolumeStorageContainer
        status={statusClass}
        onClick={() => openDetailPanel(volume)}
      >
        <StyledStorage.StorageContent>
          <StyledStorage.EllipsisTitle>
            {volume.name}
          </StyledStorage.EllipsisTitle>
        </StyledStorage.StorageContent>
      </VolumeStorageContainer>
    </Tooltip>
  );
};

VolumeStorage.propTypes = {
  volume: PropTypes.shape({
    name: PropTypes.string.isRequired,
    config_groups: PropTypes.arrayOf(
      PropTypes.shape({
        protocol: PropTypes.string.isRequired
      })
    ),
    status: PropTypes.string
  }),
  openDetailPanel: PropTypes.func.isRequired
};

export default VolumeStorage;
