import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RAMIcon(props) {
  return (
    <SvgIcon width="18" height="7" viewBox="0 0 18 7" {...props}>
      <title>RAM</title>
      <defs>
        <path id="fv50a" d="M660.6 380.6v-.9h6.6v.9z" />
        <path id="fv50b" d="M667.8 380.6v-.9h9.6v.9z" />
        <path
          id="fv50c"
          d="M660 374h18v1.5h-.3a.3.3 0 0 0 0 .6h.3v3h-18v-3h.3a.3.3 0 0 0 0-.6h-.3zm14.1 3.9h1.8v-2.4h-1.8zm-3 0h1.8v-2.4h-1.8zm-3 0h1.8v-2.4h-1.8zm-3 0h1.8v-2.4h-1.8zm-3 0h1.8v-2.4h-1.8z"
        />
      </defs>
      <g>
        <g transform="translate(-660 -374)">
          <g>
            <use fill="#fff" xlinkHref="#fv50a" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#fv50b" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#fv50c" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
