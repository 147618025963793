import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const RingTotalObjectChartTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`RING Total Objects`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Number of Objects in the RING:`}
      <Ul>
        <Li>
          <b>{`NEW`}</b>
          {`: All Objects`}
        </Li>
        <Li>
          <b>{`DELETED/PLACEHOLDERS`}</b>
          {`: Deleted Objects soon to be purged`}
        </Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default RingTotalObjectChartTips;
