import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/webfonts/WorkSans.css';
import './components/ui/GlobalStyle';
import 'sanitize.css/sanitize.css';
import 'font-awesome/css/font-awesome.min.css';
import 'typeface-roboto';
// FIXME Patrick Ear : Move to the right component
import 'react-select/dist/react-select.css';
// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-webpack-loader-syntax */
import '!file-loader?name=[name].[ext]!./favicon.ico';

import config from './config';
import store from './store';
import App from './containers/App';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: blue['200'],
      main: blue['500'],
      dark: blue['900'],
      contrastText: blue['200']
    },
    secondary: {
      light: blue['200'],
      main: blue['500'],
      dark: blue['900'],
      contrastText: blue['500']
    },
    text: {
      primary: '#a4acb4',
      secondary: '#919ba5'
    }
  },
  typography: {
    fontFamily: [
      '"Work Sans"',
      '"Helvetica Neue"',
      'Roboto',
      'sans-serif'
    ].join(','),
    subheading: {
      fontSize: 14,
      color: '#a4acb4'
    },
    body1: {
      fontSize: 12
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={config.rootPath}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('app')
);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // FIXME Patrick Ear
  // require("offline-plugin/runtime").install(); // eslint-disable-line global-require
}
