import { takeEvery, put, call, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { HASH_KEY } from '../../actions/global/constants';
import {
  authorizing,
  loginSuccess,
  loginFail,
  restoreHash as restoreHashAction
} from '../../actions/login';
import { resetInvalidBasicAuth } from '../../actions/overview';
import { USER_LOGIN, USER_LOGOUT } from '../../actions/login/constants';
import { callApi, setHash } from '../../utils/callApi';

export function* userLogin(data) {
  const requestURL = 'status';

  yield put(authorizing());

  const { username, password } = data.data;

  const hash = btoa(`${username}:${password}`);

  setHash(hash);

  try {
    yield call(callApi, requestURL, {
      method: 'GET',
      mode: 'cors'
    });

    localStorage.setItem(HASH_KEY, hash);
    yield put(resetInvalidBasicAuth());
    yield put(loginSuccess());

    yield put(push('/'));
  } catch (error) {
    setHash(null);
    if (error.status === 401) {
      yield put(loginFail());
    } else {
      yield put(push('/connection-error'));
    }
  }
}

export function* userLogout() {
  setHash(null);
  localStorage.removeItem(HASH_KEY);
  yield put({ type: '', payload: '' });
}

export function* restoreHash() {
  const hash = localStorage.getItem(HASH_KEY);
  if (hash) {
    setHash(hash);
    yield put(restoreHashAction(hash));
  }
}

export default function* overViewSaga() {
  yield all([
    restoreHash(),
    takeEvery(USER_LOGIN, userLogin),
    takeEvery(USER_LOGOUT, userLogout)
  ]);
}
