import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const SingleStorageResponseCodeTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`S3 Response Code`}</HelpPopupTitle>
    <HelpPopupContent>
      {`Response Code presents the number of request statuses answered by the S3 frontend service.`}
      <Ul>
        <Li>
          <b>{`Success`}</b>
          {`: Succesfull HTTP requests (e.g., 200:OK, 202: Accepted...)`}
        </Li>
        <Li>
          <b>{`User Errors`}</b>
          {`: Client Error HTTP requests (e.g., 400:bad requests, 403:Forbiden, 404:Not Found...)`}
        </Li>
        <Li>
          <b>{`System Errors`}</b>
          {`: Server Error HTTP requests (e.g., 500:Internal Server Error, 501:Not implemented...)`}
        </Li>
      </Ul>
      {`Note that although system errors are common, numerous system errors can indicate a significant problem.`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default SingleStorageResponseCodeTips;
