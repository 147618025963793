import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import * as cssVariables from './variables';

const WrappedModal = styled(ReactModal)`
  &.ReactModal__Content {
    position: absolute;
    padding: 50px !important;
    background-color: ${cssVariables.tableHoverBck} !important;
    border: 0 !important;
    top: 50%;
    left: 50%;
    border-radius: 6px;

    &:focus {
      outline: 0;
    }
  }

  &.ReactModal__Content_Zone {
    width: 700px;
    height: 400px;
    margin-top: -200px;
    margin-left: -350px;
  }

  &.ReactModal__Content_Server {
    width: 700px;
    height: 710px;
    margin-top: -355px;
    margin-left: -350px;
    overflow-y: auto;
  }

  &.ReactModal__Content_Prompt_Confirm {
    width: 700px;
    height: 220px;
    margin-top: -110px;
    margin-left: -350px;
    overflow-y: auto;

    .alert--text {
      span {
        font-size: 0.9em;
      }

      margin-bottom: 15px;
    }
  }

  .close_modal {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px;
    color: ${cssVariables.tableHoverBck};
    background-color: #171b1d;
  }
`;

const Modal = (props) => (
  <WrappedModal
    {...props}
    style={{
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        zIndex: 1000
      },
      ...props.style
    }}
  />);

export { Modal };
