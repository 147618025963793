import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deleteZoneAction, editZoneAction } from '../ducks/zones';
import { Tr, Td } from '../components/common/Table';
import { ButtonOk, ButtonM } from '../components/ui/Button';
import { Modal } from '../components/ui/Modal';

import EditZoneForm from './ZonesEditForm';

class Zone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDeleteZone: false,
      deleteName: ''
    };
  }

  handleOnClick = zone => {
    this.props.history.push('/zones/' + zone.id + '/edit');
  };

  removeZone = value => {
    this.setState({ showModalDeleteZone: true, deleteName: value });
  };

  removeZoneValid = () => {
    this.props.deleteZone(this.props.zone.id);
    this.setState({ showModalDeleteZone: false, deleteName: '' });
    this.handleCloseModal();
  };

  cancelEdit = () => {
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    this.props.history.push('/zones');
  };

  handleCloseModalDelete = () => {
    this.setState({ showModalDeleteZone: false });
  };

  alertAddZone = values => {
    this.props.editZone(values, this.props.zone.id);
    this.handleCloseModal();
  };

  render() {
    const { zone, servers, adminAccess, location, match } = this.props;

    const defaultForValue = {
      name: zone.name,
      location: zone.location,
      description: zone.description
    };

    const canBeDeleted = !servers.find(x => x.zone.name === zone.name);

    const isEditingZone =
      location.pathname.endsWith('edit') &&
      zone.id === parseInt(match.params.id);

    return (
      <Tr>
        <Td>{zone.name}</Td>
        <Td>{zone.location}</Td>
        <Td>{zone.description}</Td>
        {adminAccess ? (
          <Td padding='0px'>
            <i
              className="fa fa-edit fa-lg hover-icon"
              style={{ verticalAlign: 'middle' }}
              onClick={() => this.handleOnClick(zone)}
            />
          </Td>
        ) : null}
        <Modal
          isOpen={isEditingZone}
          ariaHideApp={true}
          onRequestClose={this.handleCloseModal}
          className="ReactModal__Content_Zone"
        >
          <h3>Edit Zone</h3>
          <div className="modal--content clearfix">
            <EditZoneForm
              initialValues={defaultForValue}
              onSubmit={this.alertAddZone}
              zone={zone}
              parentZoneMethod={this.removeZone}
              parentZoneMethodCloseModal={this.cancelEdit}
              deleteZoneAvailability={canBeDeleted}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showModalDeleteZone}
          ariaHideApp={false}
          className="ReactModal__Content_Prompt_Confirm"
        >
          <div className="alert--text centerAlign">
            <h4>
              <b>{zone.name}</b>
            </h4>
            You are going to delete this zone permanently
          </div>
          <div className="centerAlign">
            <ButtonM
              className="btn btn--m"
              onClick={this.handleCloseModalDelete}
            >
              Cancel
            </ButtonM>
            <ButtonOk className="btn btn--ok" onClick={this.removeZoneValid}>
              Delete
            </ButtonOk>
          </div>
        </Modal>
      </Tr>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteZone: zone => {
      dispatch(deleteZoneAction(zone));
    },
    editZone: (zone, name) => {
      dispatch(editZoneAction(zone, name));
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Zone));
