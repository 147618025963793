import React from 'react';
import styled from 'styled-components';

// Patrick Ear : Improve the documentation

/**
 * @example
 * <DiskWrapper>
 *   <DiskGauge>
 *    <CapacityUsed width={'10%'} used />
 *    <CapacityUsed width={'90%'} />
 *   </DiskGauge>
 *   <DiskLegendWrapper>
 *    <DiskLegend>
 *      10% USED | 90% FREE
 *    </DiskLegend>
 *   </DiskLegendWrapper>
 * </DiskWrapper>
 */
const DiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DiskLegendWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DiskLegend = styled.div`
  font-size: 10px;
  margin: 0;
  text-transform: uppercase;
  align-self: ${props => (props.right ? 'flex-end' : null)};
`;

/**
 * Inside the DiskGauge you can put as many
 * <CapacityUsed /> as you want.
 * The only requirement is that the sum of the width
 * of all the CapacityUsed component is 100%.
 * Otherwise it might break
 *
 */
const DiskGauge = styled.div`
  display: flex;
  width: ${props => props.width || '100%'};
  height: 12px;
  border-radius: 5px;
  overflow: hidden;
`;

/**
 * CapacityUsed have different color possible :
 * - stored => blue
 * - used => dark blue
 * - The default color is dark gray
 */
const CapacityUsed = styled.div`
  background-color: ${props =>
    props.stored
      ? colorMap.stored
      : props.used
        ? colorMap.used
        : colorMap.available};
  width: ${props => props.width || '25%'};
  height: 100%;
  text-align: center;
  font-size: 10px;
`;

const colorMap = {
  stored: '#2b65ad',
  used: '#184680',
  available: '#332c2c'
};

const ColorLegendWrapper = styled.div`
  display: flex;
`;

const ColorLegendItem = styled.div`
  display: block;
  float: left;
  margin-right: 10px;
  font-size: 10px;
  text-transform: uppercase;
  color: #a4acb4;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    display: block;
    height: 9px;
    float: left;
    width: 9px;
    margin-right: 6px;
    border-radius: 10px;
    margin-top: 3px;
    background-color: ${props =>
      props.stored
        ? colorMap.stored
        : props.used
          ? colorMap.used
          : colorMap.available};
  }
`;

const ColorLegend = props => (
  <ColorLegendWrapper style={props.wrapperStyle}>
    <ColorLegendItem stored>stored</ColorLegendItem>
    <ColorLegendItem used>used</ColorLegendItem>
    <ColorLegendItem>available</ColorLegendItem>
  </ColorLegendWrapper>
);

export {
  DiskWrapper,
  DiskLegendWrapper,
  DiskLegend,
  DiskGauge,
  CapacityUsed,
  ColorLegend
};
