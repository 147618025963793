/* eslint-disable */

import { merge } from 'lodash';
import main from './main';

/*
 exports merged local and main configs
 providing local config is optional
 local config overrides main config properties
 for development you should provide host

 example of local config:

 export default {
 api: {
 host: 'http://217.182.11.12:7000'
 },
 grafanaLink: '/stats/'
 };

 */

let resultConfig;
try {
  const local = require('./local');
  resultConfig = merge(main, local.default);
} catch (err) {
  resultConfig = main;
}

export default resultConfig;
