import React, { Component } from 'react';
import styled from 'styled-components';

import config from '../config';
import downloadIcon from '../assets/images/download-icon.png';
import * as cssVariables from '../components/ui/variables';

const DocumentationContainer = styled.div`
  padding-top: 56px;
  height: 100vh;
  overflow-x: hidden;
`;

const DocumentationBodyContainer = styled.div`
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const PanelContainterTitle = styled.h3`
  color: ${cssVariables.fontClr};
  font-size: 25px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 2px solid ${cssVariables.fontClr};
  font-weight: 500;
  letter-spacing: -1px;
`;

const PanelBodyTitle = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: ${cssVariables.fontClr};
  font-weight: 300;
`;

const SubTitleContainer = styled.div`
  border: 1px solid #211d1d;
  border-radius: 6px;
  padding: 20px 200px;
  margin: 30px 0 50px;
  p {
    text-align: center;
    color: ${cssVariables.serviceBlkTitlClr};
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.2em;
    a {
      text-decoration: underline;
    }
  }
`;

const DownloadIcon = styled.div`
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;
  border-radius: 30px;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  background-image: url(${downloadIcon});
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;

  &:hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  min-height: 60px;
`;

const PanelBody = styled.div`
  padding: 25px 15px;
  background-color: #211d1d;
  border-radius: 6px;
  .panel-margin {
    margin-top: 20px;
  }
`;

const HelpDocPanel = styled.div`
  margin-bottom: 35px;
`;

export default class Documentation extends Component {
  render() {
    const { docs } = config;

    return (
      <DocumentationContainer>
        <DocumentationBodyContainer>
          <SubTitleContainer>
            <div className="row">
              <div className="col-sm-12">
                <p>
                  To ensure having the latest publication version, check the{' '}
                  <i>Publication History</i> of
                  <br />
                  the PDFs available here against the <i>
                    Publication History
                  </i>{' '}
                  of the PDFs available
                  <br />
                  via{' '}
                  <a target="_blank" rel="noopener noreferrer" href={docs.base}>
                    RING {docs.ring_version} Product Documentation and Resources
                  </a>
                  .
                </p>
              </div>
            </div>
          </SubTitleContainer>

          <HelpDocPanel>
            <PanelContainterTitle>RING 9</PanelContainterTitle>
            <PanelBody>
              <div className="row">
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.ring.setup_and_installation_guide.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Setup and Installation Guide, v{docs.ring_version}
                  </PanelBodyTitle>
                </div>
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.ring.operations_guide.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Operations Guide, v{docs.ring_version}
                  </PanelBodyTitle>
                </div>
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.ring.reference_manual.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Reference Manual, v{docs.ring_version}
                  </PanelBodyTitle>
                </div>
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.ring.handbook.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Supervisor Handbook, v{docs.ring_version}
                  </PanelBodyTitle>
                </div>
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.ring.quick_start_guide.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Quick Start Guide - CentOS-RedHat 7.x
                  </PanelBodyTitle>
                </div>
              </div>
            </PanelBody>
          </HelpDocPanel>

          <HelpDocPanel>
            <PanelContainterTitle>S3 Connector</PanelContainterTitle>
            <PanelBody>
              <div className="row">
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.s3_connector.setup_and_installation_guide.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Setup and Installation Guide, v{docs.s3_version}
                  </PanelBodyTitle>
                </div>
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.s3_connector.operations_guide.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Operations Guide, v{docs.s3_version}
                  </PanelBodyTitle>
                </div>
                <div className="col-sm-4 panel-margin">
                  <ImageContainer>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={docs.s3_connector.reference_manual.link}
                    >
                      <DownloadIcon />
                    </a>
                  </ImageContainer>
                  <PanelBodyTitle>
                    Reference Manual, v{docs.s3_version}
                  </PanelBodyTitle>
                </div>
              </div>
            </PanelBody>
          </HelpDocPanel>
        </DocumentationBodyContainer>
      </DocumentationContainer>
    );
  }
}
