import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HelpPopover from './common/HelpPopover';
import GrafanaIframe from './GrafanaFrame';
import { SectionHeader, SectionTitle } from './ui/AllPagesItems';

const GrafanaContainer = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const GrafanaPanel = props => (
  <GrafanaContainer>
    <SectionHeader>
      <SectionTitle>{props.panelTitle}</SectionTitle>
      {props.popOverTemplate ? (
        <HelpPopover content={props.popOverTemplate} />
      ) : null}
    </SectionHeader>
    <GrafanaIframe height={props.iFrameHeight} src={props.iFrameSrc} />
  </GrafanaContainer>
);

GrafanaPanel.propTypes = {
  iFrameSrc: PropTypes.string.isRequired,
  panelTitle: PropTypes.string.isRequired,
  iFrameHeight: PropTypes.string,
  popOverTemplate: PropTypes.object
};

GrafanaPanel.defaultProps = {
  iFrameHeight: '230'
};

export default GrafanaPanel;
