import React from 'react';
import styled from 'styled-components';
import { SortDown, SortUp } from 'styled-icons/fa-solid';
import * as cssVariables from '../ui/variables';
import { ASC, DESC } from '../../constants';

const StyledSortDown = styled(SortDown)`
  position: absolute;
  bottom: 0;
  height: 12px;
  width: 12px;
  color: ${props =>
    props.selected ? cssVariables.orng : cssVariables.fontClr};
`;

const StyledSortUp = styled(SortUp)`
  position: absolute;
  top: 2px;
  height: 12px;
  width: 12px;
  color: ${props =>
    props.selected ? cssVariables.orng : cssVariables.fontClr};
`;

const IconContainer = styled.span`
  position: relative;
  padding: 0 2px;
  cursor: pointer;
`;

const SortIcon = props => (
  <IconContainer onClick={props.onSort}>
    <StyledSortUp selected={props.selected && props.selectedOrder === ASC} />
    <StyledSortDown selected={props.selected && props.selectedOrder === DESC} />
  </IconContainer>
);

export default SortIcon;
