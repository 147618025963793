import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import echarts from 'echarts';

const PieChartContainer = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto;
`;

export default class PieChart extends Component {
  constructor() {
    super();

    const chartOptions = {
      title: {
        x: 'center',
        textStyle: {
          fontSize: 11,
          color: '#a4acb4'
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: function(data) {
          var v = data.data.hoverTitle;
          return v + '';
        },
        textStyle: {
          fontSize: 10,
          fontWeight: 500
        }
      },
      series: [
        {
          color: [
            {
              type: 'radial',
              x: 0.8,
              y: 0.6,
              r: 0.7,
              colorStops: [
                {
                  offset: 0,
                  color: '#7aca34' // color at 0% position
                },
                {
                  offset: 1,
                  color: '#49ba42' // color at 100% position
                }
              ],
              globalCoord: false // false by default
            },
            {
              type: 'radial',
              x: 0.3,
              y: 0.5,
              r: 0.7,
              colorStops: [
                {
                  offset: 0,
                  color: '#636c74' // color at 0% position
                },
                {
                  offset: 1,
                  color: '#474f56' // color at 100% position
                }
              ],
              globalCoord: false // false by default
            }
          ],
          name: '',
          type: 'pie',
          radius: '84%',
          center: ['50%', '50%'],
          label: {
            normal: {
              position: 'inside',
              textStyle: {
                fontSize: 11
              },
              textBorderColor: 'transparent'
            }
          },
          data: [],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    this.state = {
      options: chartOptions,
      chart: null
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    let { options, chart } = this.state;

    options.series[0].data = data;

    if (!_.isNil(chart)) {
      this.setState({ options: options, chart: chart }, () => {
        chart.setOption(options, true);
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const nextData = nextProps.data;
    const { data } = this.props;

    if (nextData && nextData.length === 2 && data && data.length === 2) {
      if (
        nextData[0].value === data[0].value &&
        nextData[1].value === data[1].value
      ) {
        return false;
      }
    }

    if (data.length === 0 && nextData.length === 0) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    const { data } = this.props;

    let { options } = this.state;

    if (!_.isEmpty(data)) {
      options.series[0].data = data;
    }

    let pieChart = echarts.init(document.getElementById('pie-chart-container'));

    this.setState({ options: options, chart: pieChart }, () => {
      pieChart.setOption(options, true);
    });
  }

  render() {
    return <PieChartContainer id="pie-chart-container" />;
  }
}

PieChart.propTypes = {
  data: PropTypes.array
};

PieChart.defaultProps = {
  data: []
};
