/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 *
 * FIXME(gd): Nice docstring, sad no one read it...
 */

// FIXME(gd): Nothing to do here
export const DEFAULT_LOCALE = 'en';
// FIXME(gd): Nothing to do here
export const API_PREFIX = '/api/v0.1/';
// FIXME(gd): Nothing to do here
export const TEST_ENV = 'test';

export const HASH_KEY = 'HASH_KEY';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';

// FIXME(gd): Nothing to do here
// errors statuses
export const UNAUTHORIZED = 401;

// last api call time
export const LAST_CALL_API_TIME = 'LAST_CALL_API_TIME';
