import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function NetworkIcon(props) {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <title>Network Interface</title>
      <defs>
        <path id="oqqza" d="M666.41 418.09v-4.27h4.27v4.27z" />
        <path id="oqqzb" d="M666.41 405.27V401h4.27v4.27z" />
        <path id="oqqzc" d="M672.82 418.09v-4.27h4.27v4.27z" />
        <path id="oqqzd" d="M660 418.09v-4.27h4.27v4.27z" />
        <path id="oqqze" d="M668.01 409.01v-2.67h1.07v2.67z" />
        <path id="oqqzf" d="M668.01 412.74v-3.2h1.07v3.2z" />
        <path
          id="oqqzg"
          d="M675.48 412.75h-1.06v-2.67h-11.75v2.67h-1.07V409h13.88z"
        />
      </defs>
      <g>
        <g transform="translate(-660 -401)">
          <g>
            <use fill="#fff" xlinkHref="#oqqza" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#oqqzb" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#oqqzc" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#oqqzd" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#oqqze" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#oqqzf" />
          </g>
          <g>
            <use fill="#fff" xlinkHref="#oqqzg" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
