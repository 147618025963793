import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { TableMessage } from './ui/Table';

export default class GlobalHealthDonut extends Component {
  render() {
    const { globalHealthRing } = this.props;

    let tableContent;

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontSize: 8,
          fontColor: '#000'
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      cutoutPercentage: 70
    };

    if (Object.keys(globalHealthRing).length) {
      let datas, datasKey, backgroundColors;
      if (globalHealthRing.length === 1) {
        datas = [globalHealthRing[0].doc_count];
        datasKey = [globalHealthRing[0].key];
        globalHealthRing[0].key.toUpperCase() === 'RUN'
          ? (backgroundColors = ['rgba(126, 178, 108, 1)'])
          : (backgroundColors = ['rgba(234, 184, 57, 1)']);
      } else {
        datas = [globalHealthRing[0].doc_count, globalHealthRing[1].doc_count];
        datasKey = [globalHealthRing[0].key, globalHealthRing[1].key];
        const bgColor0 =
          globalHealthRing[0].key.toUpperCase() === 'RUN'
            ? 'rgba(126, 178, 108, 1)'
            : 'rgba(234, 184, 57, 1)';

        const bgColor1 =
          globalHealthRing[1].key.toUpperCase() === 'RUN'
            ? 'rgba(126, 178, 108, 1)'
            : 'rgba(234, 184, 57, 1)';

        backgroundColors = [bgColor0, bgColor1];
      }
      const chartData = {
        responsive: false,
        datasets: [
          {
            data: datas,
            backgroundColor: backgroundColors,
            borderWidth: 0,
            borderColor: '#201d1d'
          }
        ],
        labels: datasKey
      };
      tableContent = (
        <Doughnut
          data={chartData}
          width={170}
          height={170}
          options={chartOptions}
        />
      );
    } else if (globalHealthRing.length === 0) {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> No data
          available.
        </TableMessage>
      );
    } else {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" /> Loading
          content...
        </TableMessage>
      );
    }

    return <div>{tableContent}</div>;
  }
}
