import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ul,
  Li
} from './Tips';

const DLMTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>RPC Address Selector</HelpPopupTitle>
    <HelpPopupContent>
      <Ul>
        <Li>{`Select AUTO to have connectors exchange their DLM messages over the DATA network.`}</Li>
        <Li>{`Select MANUAL to use a different network for DLM messages.`}</Li>
      </Ul>
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default DLMTips;
