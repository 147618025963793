import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import HardwareStatus from './HardwareStatus';
import Rings from './Rings';
import StorageServices from './StorageServices';
import * as cssVariables from '../components/ui/variables';

const LeftPanelContainer = styled.div`
  width: 480px;
  background: ${cssVariables.drkBg};
  padding: 20px;
  overflow-x: auto;
  border-right: 1px solid #1f1a1a;
`;

export class LeftPanel extends Component {
  render() {
    return (
      <LeftPanelContainer data-cy="left-panel">
        <StorageServices />
        <Switch>
          <Route path="/rings/:id" component={Rings} />
          <Route component={Rings} />
        </Switch>
        <HardwareStatus />
      </LeftPanelContainer>
    );
  }
}

export default withRouter(LeftPanel);
