import React, { Component } from 'react';

import HelpPopover from './common/HelpPopover';
import SingleStorageServiceListTips from '../tips/SingleStorageServiceListTips';
import { Table, THead, Th, TBody, Tr, Td } from './common/Table';
import { SectionHeader, SectionTitle } from './ui/AllPagesItems';
import { TableMessage } from './ui/Table';

export default class ServiceListChartTable extends Component {
  render() {
    const { activeServiceList } = this.props;
    let tableContent;

    const bgColorChooser = statusCount => {
      return statusCount > 0
        ? { backgroundColor: '#ff8f1c', color: '#201d1d' }
        : {};
    };

    if (Object.keys(activeServiceList).length) {
      tableContent = (
        <Table>
          <THead>
            <Tr>
              <Th>Service</Th>
              <Th>Up</Th>
              <Th>Down</Th>
            </Tr>
          </THead>
          <TBody>
            {activeServiceList
              .sort((a, b) => {
                const x = a._source.groupName.toLowerCase();
                const y = b._source.groupName.toLowerCase();
                return x < y ? -1 : 1;
              })
              .map(hit => (
                <Tr key={hit._id}>
                  <Td>{hit._source.groupName}</Td>
                  <Td>{hit._source.upCount}</Td>
                  <Td style={bgColorChooser(hit._source.downCount)}>
                    {hit._source.downCount}
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      );
    } else if (activeServiceList.length === 0) {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-exclamation-circle" aria-hidden="true" /> No data
          available.
        </TableMessage>
      );
    } else {
      tableContent = (
        <TableMessage className="graph_table--message">
          <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" /> Loading
          content...
        </TableMessage>
      );
    }

    return (
      <div>
        <SectionHeader>
          <SectionTitle>Service List</SectionTitle>
          <HelpPopover content={<SingleStorageServiceListTips />} />
        </SectionHeader>
        {tableContent}
      </div>
    );
  }
}
