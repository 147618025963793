import React from 'react';
import {
  HelpPopupContainer,
  HelpPopupTitle,
  HelpPopupContent,
  Ol,
  Li
} from './Tips';
import databaseIcon from '../assets/images/dashboard-icons/database-icon-inactive.png';
import cloudIcon from '../assets/images/dashboard-icons/cloud-icon-inactive.png';

const StorageServiceTips = () => (
  <HelpPopupContainer>
    <HelpPopupTitle>{`Storage Services`}</HelpPopupTitle>
    <HelpPopupContent>
      {`The Storage Services constitute the services enabling applications to access Scality RING storage.`}
      <br />
      {`Two types are available:`}
      <Ol>
        <Li>
          <b>{`File based volumes `}</b>
          <img alt="File based volumes" src={databaseIcon} /> {':'}
          <br />
          {`Scale-Out File System services (SOFS) that natively provides NFS, SMB,
        and FUSE accesses`}
        </Li>
        <Li>
          <b>{`Object based services `}</b>
          <img alt="Object based services" src={cloudIcon} /> {':'}
          <br />
          {`S3 and Native REST API accesses`}
        </Li>
      </Ol>
    </HelpPopupContent>
    <HelpPopupContent>
      {`Clicking on a service displays its associated detailed information on the right panel.`}
    </HelpPopupContent>
  </HelpPopupContainer>
);

export default StorageServiceTips;
