import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RAIDIcon(props) {
  return (
    <SvgIcon viewBox="0 0 18 17" {...props}>
      <title>RAID Icon</title>
      <defs>
        <path
          id="zapda"
          d="M678 504.6v9h-.9v1.8h-4.5v-1.8h-1.8v1.8h-4.5v-1.8h-1.8v3.6h-1.8v-4.5h-1.8v-1.8h1.8v-1.8h-1.8v-1.8h1.8v-4.5H660V501h4.5v3.6zm-10.8 5.4h-2.7v1.8h2.7zm0-3.6h-2.7v1.8h2.7zm5.4.9H669v3.6h3.6zm3.6.9h-1.8v1.8h1.8z"
        />
      </defs>
      <g>
        <g transform="translate(-660 -501)">
          <use fill="#fff" xlinkHref="#zapda" />
        </g>
      </g>
    </SvgIcon>
  );
}
