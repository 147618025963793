import { put, call, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import _ from 'lodash';
import { selectServers } from './topServers';
import { setInvalidBasicAuth } from '../actions/overview';
import { callApiPost } from '../utils/callApi';
import {
  floatStringsValueChecker,
  objectValueCheckerCreator
} from '../selectors/utils';
import { selectDisksRelocationState } from './disks';

// Actions
const GET_TOP_DISKS_SSD_READ = 'GET_TOP_DISKS_SSD_READ';
const SET_TOP_DISKS_SSD_READ = 'SET_TOP_DISKS_SSD_READ';
const GET_TOP_DISKS_SSD_WRITE = 'GET_TOP_DISKS_SSD_WRITE';
const SET_TOP_DISKS_SSD_WRITE = 'SET_TOP_DISKS_SSD_WRITE';
const GET_TOP_DISKS_READ = 'GET_TOP_DISKS_READ';
const SET_TOP_DISKS_READ = 'SET_TOP_DISKS_READ';
const GET_TOP_DISKS_WRITE = 'GET_TOP_DISKS_WRITE';
const SET_TOP_DISKS_WRITE = 'SET_TOP_DISKS_WRITE';

// Reducer
const defaultState = fromJS({
  topDisksSSDRead: [],
  topDisksSSDWrite: [],
  topDisksRead: [],
  topDisksWrite: []
});

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_TOP_DISKS_SSD_READ:
      return state.set('topDisksSSDRead', action.disks);
    case SET_TOP_DISKS_SSD_WRITE:
      return state.set('topDisksSSDWrite', action.disks);
    case SET_TOP_DISKS_READ:
      return state.set('topDisksRead', action.disks);
    case SET_TOP_DISKS_WRITE:
      return state.set('topDisksWrite', action.disks);
    default:
      return state;
  }
}

// Action Creators
function setTopDisksSSDReadAction(disks) {
  return {
    type: SET_TOP_DISKS_SSD_READ,
    disks
  };
}

function getTopDisksSSDReadAction(timeFrame) {
  return {
    type: GET_TOP_DISKS_SSD_READ,
    timeFrame
  };
}

function getTopDisksSSDWriteAction(timeFrame) {
  return {
    type: GET_TOP_DISKS_SSD_WRITE,
    timeFrame
  };
}

function setTopDisksSSDWriteAction(disks) {
  return {
    type: SET_TOP_DISKS_SSD_WRITE,
    disks
  };
}

function getTopDisksReadAction(timeFrame) {
  return {
    type: GET_TOP_DISKS_READ,
    timeFrame
  };
}

function setTopDisksReadAction(disks) {
  return {
    type: SET_TOP_DISKS_READ,
    disks
  };
}

function getTopDisksWriteAction(timeFrame) {
  return {
    type: GET_TOP_DISKS_WRITE,
    timeFrame
  };
}

function setTopDisksWriteAction(disks) {
  return {
    type: SET_TOP_DISKS_WRITE,
    disks
  };
}

// Sagas
function* getTopDisksSSDRead({ timeFrame }) {
  yield call(
    getTopDisks,
    timeFrame,
    'ssd',
    'send_data',
    setTopDisksSSDReadAction
  );
}

function* getTopDisksSSDWrite({ timeFrame }) {
  yield call(
    getTopDisks,
    timeFrame,
    'ssd',
    '(receive_data OR get_data)',
    setTopDisksSSDWriteAction
  );
}

function* getTopDisksRead({ timeFrame }) {
  yield call(
    getTopDisks,
    timeFrame,
    'disk',
    'send_data',
    setTopDisksReadAction
  );
}

function* getTopDisksWrite({ timeFrame }) {
  yield call(
    getTopDisks,
    timeFrame,
    'disk',
    '(receive_data OR get_data)',
    setTopDisksWriteAction
  );
}

function* getTopDisks(timeFrame, type, operation, setTopDisks) {
  let header = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  });

  const query = {
    size: 0,
    query: {
      bool: {
        filter: [
          {
            query_string: {
              query: `operation:${operation} AND diskname:*${type}*`
            }
          },
          {
            range: {
              '@timestamp': {
                gte: `now-${timeFrame}`
              }
            }
          }
        ]
      }
    },
    aggs: {
      agg_by_host: {
        terms: {
          field: 'ip',
          size: 500,
          min_doc_count: 1
        },
        aggs: {
          agg_by_disk: {
            terms: {
              field: 'diskname',
              size: 500,
              min_doc_count: 1
            },
            aggs: {
              agg_by_range: {
                avg: {
                  field: 'avg_ms'
                }
              }
            }
          }
        }
      }
    }
  };

  const requestURL = 'es_proxy/scality-stats-disk-*/_search';

  try {
    const response = yield call(callApiPost, requestURL, {
      method: 'POST',
      mode: 'cors',
      headers: header,
      body: JSON.stringify(query)
    });

    const topDisks = [];
    response.aggregations.agg_by_host.buckets.forEach(host => {
      const hostDisks = host.agg_by_disk.buckets.map(disk => {
        return {
          diskName: disk.key,
          hostIp: host.key,
          value: disk.agg_by_range.value.toFixed(2)
        };
      });
      topDisks.push(...hostDisks);
    });
    yield put(
      setTopDisks(
        _.sortBy(topDisks, 'value')
          .reverse()
          .slice(0, 10)
      )
    );
  } catch (error) {
    if (error && error.status === 401) {
      yield put(setInvalidBasicAuth());
    }
  }
}

function* topDisksSaga() {
  yield takeEvery(GET_TOP_DISKS_SSD_READ, getTopDisksSSDRead);
  yield takeEvery(GET_TOP_DISKS_SSD_WRITE, getTopDisksSSDWrite);
  yield takeEvery(GET_TOP_DISKS_READ, getTopDisksRead);
  yield takeEvery(GET_TOP_DISKS_WRITE, getTopDisksWrite);
}

// Selectors
function selectTopDisksState(state) {
  return state.get('topDisks');
}

function selectTopDisksSSDReadState(state) {
  return selectTopDisksState(state).get('topDisksSSDRead');
}

function selectTopDisksSSDWriteState(state) {
  return selectTopDisksState(state).get('topDisksSSDWrite');
}

export function selectTopDisksReadState(state) {
  return selectTopDisksState(state).get('topDisksRead');
}

function selectTopDisksWriteState(state) {
  return selectTopDisksState(state).get('topDisksWrite');
}

const selectTopDisksCreateSelector = selectDisksState =>
  createSelector(
    [selectDisksState, selectServers, selectDisksRelocationState],
    (disks, servers, disksRelocationList) => {
      if (disks && servers) {
        return disks
          .map(disk => {
            const selectedServer = servers.find(server => {
              return server.ip_addresses.includes(disk.hostIp);
            });
            return {
              ...disk,
              serverName: selectedServer ? selectedServer.name : '',
              serverLink:
                selectedServer && selectedServer.id
                  ? `/servers/${selectedServer.id}`
                  : '',
              diskLink:
                selectedServer &&
                selectedServer.id &&
                disk.hostIp &&
                disk.diskName
                  ? `/servers/${selectedServer.id}/disks/${disk.hostIp}:${disk.diskName}`
                  : '',
              relocation: !!disksRelocationList.find(diskRelocation => {
                return (
                  diskRelocation.status === 'RUN_RELOC' &&
                  diskRelocation.diskname === disk.diskName &&
                  diskRelocation.ip === disk.hostIp
                );
              })
            };
          })
          .sort(objectValueCheckerCreator(floatStringsValueChecker));
      } else {
        return [];
      }
    }
  );

const selectTopDisksSSDRead = selectTopDisksCreateSelector(
  selectTopDisksSSDReadState
);
const selectTopDisksSSDWrite = selectTopDisksCreateSelector(
  selectTopDisksSSDWriteState
);
const selectTopDisksRead = selectTopDisksCreateSelector(
  selectTopDisksReadState
);
const selectTopDisksWrite = selectTopDisksCreateSelector(
  selectTopDisksWriteState
);

export {
  getTopDisksSSDReadAction,
  getTopDisksSSDWriteAction,
  getTopDisksReadAction,
  getTopDisksWriteAction,
  getTopDisksSSDRead,
  getTopDisksSSDWrite,
  getTopDisksRead,
  getTopDisksWrite,
  getTopDisks,
  topDisksSaga,
  selectTopDisksState,
  selectTopDisksSSDRead,
  selectTopDisksSSDWrite,
  selectTopDisksRead,
  selectTopDisksWrite,
  selectTopDisksCreateSelector
};
